import { cva, VariantProps } from 'class-variance-authority';

const paginationButtonStyles = cva(
  'w-8 h-8 rounded typography-loud-xs disabled:pointer-events-none',
  {
    variants: {
      active: {
        true: 'md:text-neutral-white md:bg-secondary-400',
        false: 'text-neutral-300 hover:bg-neutral-900',
      },
    },
    compoundVariants: [],
    defaultVariants: {
      active: false,
    },
  },
);

export type PaginationButtonProps = React.ComponentProps<'button'> &
  VariantProps<typeof paginationButtonStyles>;

const PaginationButton = ({ active, children, ...rest }: PaginationButtonProps) => (
  <button className={paginationButtonStyles({ active })} {...rest}>
    {children}
  </button>
);

export default PaginationButton;
