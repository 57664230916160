import { createContext, Dispatch, ReactNode, useEffect, useReducer } from 'react';
import { StudentGoalBase } from 'src/pages/single-student/singleStudent.types';

import { RecordSessionFull, ScheduleEvent } from '../schedule.types';

export type UninitializedForm = {
  isInitialized: false;
  formData?: never;
};

export type FormData = {
  date?: string;
  location?: string;
  finishTime?: string;
  startTime?: string;
  studentId?: string;
  note?: string;
  goals: StudentGoalBase[];
  cancellationReason?: string;
  cancellationReasonId?: string;
  numberOfStudents?: number;
};

export type FormDataErrors = Partial<Record<keyof FormData | (string & {}), string>>; // eslint-disable-line @typescript-eslint/ban-types

export type FormOptions = { limitDatePicker?: boolean };

export type EditionForm = {
  isInitialized: true;
  parentEvent: ScheduleEvent;
  existingSession?: RecordSessionFull;
  isLoading: boolean;
  formData: FormData;
  errors?: FormDataErrors;
  options?: FormOptions;
};

export type CreationForm = {
  isInitialized: true;
  parentDate: Date;
  formData: FormData;
  errors?: FormDataErrors;
  options?: FormOptions;
};

export type FormState = UninitializedForm | EditionForm | CreationForm;

export type FormActions =
  | {
      type: 'init';
      payload: Omit<EditionForm, 'isInitialized'> | Omit<CreationForm, 'isInitialized'>;
    }
  | { type: 'loadingDone'; payload: RecordSessionFull }
  | { type: 'selectStudent'; payload?: string }
  | { type: 'clearStudent' }
  | { type: 'setDate'; payload: string }
  | { type: 'setGoalProgress'; payload: { goalId: string; progress: number } }
  | { type: 'setStartTime'; payload: string | undefined }
  | { type: 'setFinishTime'; payload: string | undefined }
  | { type: 'setLocation'; payload?: string }
  | { type: 'addNewGoal'; payload: StudentGoalBase }
  | { type: 'removeGoal'; payload: StudentGoalBase }
  | { type: 'clearGoals' }
  | { type: 'setCancellationReason'; payload: string }
  | { type: 'setNote'; payload: string }
  | { type: 'removeNote' }
  | { type: 'reset' }
  | { type: 'setErrors'; payload?: FormDataErrors }
  | { type: 'numberOfStudents'; payload: number };

const formStateReducer = (state: FormState, action: FormActions): FormState => {
  const type = action.type;
  if (type === 'init') {
    return { isInitialized: true, ...action.payload } as CreationForm | EditionForm;
  }
  if (type === 'reset') {
    return { isInitialized: false } as UninitializedForm;
  }

  if (state.isInitialized && ('parentEvent' in state || 'parentDate' in state)) {
    const mutateFormData = (partial: Partial<FormData>): CreationForm | EditionForm => ({
      ...state,
      formData: { ...state.formData, ...partial },
    });
    switch (type) {
      case 'loadingDone':
        return {
          ...state,
          isLoading: false,
          existingSession: action.payload,
          formData:
            action.payload.isCancelled && !action.payload.isRescheduled
              ? {
                  studentId: action.payload.student.id,
                  goals: [],
                }
              : {
                  goals: action.payload.goals,
                  date: action.payload.date,
                  startTime: action.payload.startTime,
                  finishTime: action.payload.finishTime,
                  note: action.payload.note,
                  studentId: action.payload.student.id,
                  location: action.payload.location,
                  cancellationReason: action.payload.cancellationReason,
                  cancellationReasonId: action.payload.cancellationReasonId,
                  numberOfStudents: action.payload.numberOfStudents,
                },
        };
      case 'setErrors':
        return {
          ...state,
          errors: action.payload,
        };

      case 'setDate':
        return mutateFormData({ date: action.payload });
      case 'setLocation':
        return mutateFormData({ location: action.payload });
      case 'selectStudent':
        return mutateFormData({ studentId: action.payload });
      case 'clearStudent':
        return mutateFormData({ studentId: undefined });
      case 'setStartTime':
        return mutateFormData({ startTime: action.payload });
      case 'setFinishTime':
        return mutateFormData({ finishTime: action.payload });
      case 'setCancellationReason':
        return mutateFormData({ cancellationReason: action.payload });
      case 'addNewGoal':
        return mutateFormData({
          goals: [...state.formData.goals, { ...action.payload, progress: 0 }],
        });
      case 'removeGoal':
        return mutateFormData({
          goals: state.formData.goals.filter((goal) => goal.id !== action.payload.id),
        });
      case 'clearGoals':
        return mutateFormData({
          goals: [],
        });
      case 'setGoalProgress':
        return mutateFormData({
          goals: state.formData.goals.map((goal) =>
            goal.id === action.payload.goalId
              ? { ...goal, progress: action.payload.progress }
              : goal,
          ),
        });
      case 'removeNote':
        return mutateFormData({ note: undefined });
      case 'setNote':
        return mutateFormData({ note: action.payload });
      case 'numberOfStudents':
        return mutateFormData({ numberOfStudents: Number(action.payload) });
      default:
        return state;
    }
  }

  return state;
};

type ReactSessionContextProps = {
  state: FormState;
  dispatch: Dispatch<FormActions>;
  features: { lockOnStudentId?: string };
};
export const RecordSessionContext = createContext<ReactSessionContextProps>({
  state: { isInitialized: false },
  dispatch: () => undefined,
  features: {},
});

type RecordSessionContextProviderProps = { lockOnStudentId?: string; children: ReactNode };

export const RecordSessionContextProvider = ({
  children,
  lockOnStudentId,
}: RecordSessionContextProviderProps) => {
  const [state, dispatch] = useReducer(formStateReducer, {
    isInitialized: false,
  });
  const features = { lockOnStudentId: lockOnStudentId };

  useEffect(() => {
    if (state.isInitialized && lockOnStudentId && state?.formData?.studentId !== lockOnStudentId) {
      dispatch({ type: 'selectStudent', payload: lockOnStudentId });
    }
  }, [state.isInitialized, lockOnStudentId, state?.formData?.studentId]);

  return (
    <RecordSessionContext.Provider value={{ state, dispatch, features }}>
      {children}
    </RecordSessionContext.Provider>
  );
};

RecordSessionContext.displayName = 'RecordSessionContext';
