import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import PasswordField from 'src/components/PasswordField';
import PasswordValidation from 'src/components/PasswordValidation';
import TextField from 'src/components/TextField';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconEnvlope } from 'src/icons';
import { isPasswordValid } from 'src/logic/isPasswordValid';
import { routes } from 'src/routes';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { twMerge } from 'tailwind-merge';

const SetPassword = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const predefinedUsername = params.get('username');
  const tmpPassword = params.get('tmp-password');
  const code = params.get('code');
  const dispatch = useAppDispatch();

  const isSetFirstPasswordMode = predefinedUsername && tmpPassword;
  const isResetPasswordMode = code;

  const [username, setUsername] = useState(predefinedUsername || '');
  const { signIn, completeNewPassword, forgotPasswordSubmit } = useAuth();

  useEffect(() => {
    if (!isSetFirstPasswordMode && !isResetPasswordMode) {
      navigate(routes.auth.login);
    }
  }, [isSetFirstPasswordMode, isResetPasswordMode, navigate]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>();

  const [password, setPassword] = useState('');

  const isValid = isPasswordValid(password);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (isSetFirstPasswordMode) {
        const user = await signIn(username, tmpPassword);
        await completeNewPassword(user, password);
        navigate(routes.home);
        dispatch(
          addToast({
            text: 'Password has been changed',
            type: 'positive',
          }),
        );
      } else if (isResetPasswordMode) {
        await forgotPasswordSubmit(username, code, password);
        navigate(`${routes.auth.login}?username=${username}`);
      }
    } catch (e: any) {
      const message = e?.message as string;
      if (!message) {
        setErrors({ _: 'Something went wrong. Try again later.' });
        return;
      }
      setErrors({ _: e?.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const { isSm } = useBreakpoint('sm');
  return (
    <>
      <div
        className={twMerge(
          clsx(
            'mb-2 text-secondary-300 md:mb-4',
            !isSm ? 'typography-heading-lg' : 'typography-heading-2xl',
          ),
        )}
      >
        Create Password
      </div>

      <div
        className={twMerge(
          clsx(
            'mb-8 text-neutral-100 md:mb-12',
            !isSm ? 'typography-body-sm' : 'typography-body-lg',
          ),
        )}
      >
        Make it unique - like you.
      </div>

      {isResetPasswordMode && (
        <TextField
          label="Email"
          id="username"
          size={isSm ? 'lg' : 'sm'}
          PrefixIcon={IconEnvlope}
          className="mb-6"
          placeholder="Email"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          error={errors?.username}
          onKeyDown={handleKeyPress}
        />
      )}

      <PasswordField
        label="Password"
        size={isSm ? 'lg' : 'sm'}
        className="mb-4"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyDown={handleKeyPress}
      />

      <PasswordValidation password={password} className="mb-8" />

      <ErrorMessage className="mb-6">{errors?._}</ErrorMessage>

      <Button
        size={isSm ? 'lg' : 'sm'}
        className="mb-4"
        disabled={!!isValid.length}
        isLoading={isLoading}
        onClick={onSubmit}
      >
        Set Password
      </Button>
    </>
  );
};

export default SetPassword;
