import type { SVGProps } from 'react';
const SvgEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path d="M12 22.5A10.5 10.5 0 1 1 22.5 12 10.512 10.512 0 0 1 12 22.5Zm0-18a7.5 7.5 0 1 0 7.5 7.5A7.51 7.51 0 0 0 12 4.5Z" />
      <path d="M1.5 24a1.5 1.5 0 0 1-1.06-2.56l21-21a1.5 1.5 0 0 1 2.12 2.12l-21 21A1.5 1.5 0 0 1 1.5 24Z" />
    </g>
  </svg>
);
export default SvgEmpty;
