import type { SVGProps } from 'react';
const SvgLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M1.5 2.5h8M5.5.5v2M3.5 2.5A6.106 6.106 0 0 0 8 8.184" />
      <path d="M7.5 2.5c-.4 5.625-6 6-6 6M8 14.5l3.5-8 3.5 8M9.313 11.5h4.375" />
    </g>
  </svg>
);
export default SvgLanguage;
