import type { SVGProps } from 'react';
const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g stroke="currentColor" strokeMiterlimit={10} strokeWidth={1.2}>
      <path d="M14.6 9.4a9.337 9.337 0 0 0-13.2 0" />
      <path strokeLinecap="square" d="M6.04 10.232 1.4 9.4l.832-4.64" />
    </g>
  </svg>
);
export default SvgUndo;
