import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import SignatureStatusBar from 'src/components/SignatureStatusBar';
import { useUserType } from 'src/hooks/useUserType';

import Navigation from './Navigation';
import SimpleHeader from './SimpleHeader';

export type LayoutProps = {
  withNavigation?: boolean;
  withSimpleHeader?: boolean;
  withoutProfile?: boolean;
};

const Layout = ({ withNavigation, withSimpleHeader, withoutProfile }: LayoutProps) => {
  const { userType } = useUserType();

  return (
    <div className="relative flex h-screen flex-col sm:flex-row">
      {withNavigation && <Navigation />}
      <Sentry.ErrorBoundary
        fallback={
          <div className="flex w-full flex-col items-center justify-center gap-2">
            <p className="typography-heading-md">An error has occurred.</p>
            <p className="typography-body-md">
              {`Please use "Contact support" option to let us know about the problem.`}
            </p>
            <p>{`We'll fix it as soon as possible.`}</p>
          </div>
        }
      >
        <div className="relative mt-mobile-header min-w-0 flex-grow overflow-x-clip bg-neutral-950 sm:mt-0">
          {withSimpleHeader && <SimpleHeader withoutProfile={withoutProfile} />}
          {userType && !['Provider', 'Parent'].includes(userType) && <SignatureStatusBar />}
          <Outlet />
        </div>
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default Layout;
