import './Calendar.css';

import {
  CalendarApi,
  CalendarOptions,
  EventContentArg,
  EventInput,
  SlotLaneContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import clsx from 'clsx';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SingleLineEvent } from 'src/components/calendar/SingleLineEvent';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { ScheduleEvent } from 'src/pages/schedule/schedule.types';
import { SingleStudentDetails } from 'src/pages/single-student/singleStudent.types';
import StudentHeaderForPdfExport from 'src/pages/single-student/student-header/StudentHeaderForPdfExport';
import { DayOfWeekTranslation } from 'src/utils/dayOfWeek.const';

import DayCellRecordSession from './calendar/DayCellRecordSession';
import DayHeaderContent from './calendar/DayHeaderContent';
import { DetailedEvent } from './calendar/DetailedEvent';
import { ListEvent } from './calendar/ListEvent';
import SlotLaneRecordSession from './calendar/SlotLaneRecordSession';
/*
 * @description
 * - If paid version will be needed, remove test key and uncomment schedulerLicenseKey={VITE_FULL_CALENDAR_API_KEY}
 * - Paid version will be needed if you will see a watermark on the calendar
 */
const VITE_FULL_CALENDAR_API_KEY: string =
  import.meta.env.VITE_FULL_CALENDAR_API_KEY || 'CC-Attribution-NonCommercial-NoDerivatives';

const SlotLimitTime = {
  slotMinTime: '07:00:00',
  slotMaxTime: '22:00:00',
};

const EXPORT_CONFIG = {
  exportAreaId: 'export-area-id',
  calendarPlugContainerId: 'calendar-plug-container-id',
  exportCalendarContainerId: 'export-calendar-container-id',
  containerClass: 'fc-view-harness-passive',
  defaultFileName: 'CalendarToPdf',
  errorMsg: 'Something went wrong while trying to export the calendar!',
  withoutStudentInfoDimenstions: [314, 249],
  withStudentInfoDimenstions: [314, 261],
};

const CALENDAR_CONTAINER_ID = 'calendar-container-id';

type Options = CalendarOptions & {
  export?: MutableRefObject<(withStudentHeader: boolean, fileName?: string) => void>;
  onEventClick?: (event: ScheduleEvent) => void;
  onRecordSessionClick?: (date: Date) => void;
  disableInteractions?: boolean;
  studenDetailsForPdfExport?: SingleStudentDetails;
  disableSessionRecordHover?: (date: Date) => boolean;
};

const emptyFunction = () => undefined;

function getCalendarConfiguration(
  baseProps: CalendarOptions,
  onRecordSessionClick: (date: Date) => void = emptyFunction,
  onEventClick: (event: ScheduleEvent) => void = emptyFunction,
  disableInteractions?: boolean,
  disableSessionRecordHover?: (date: Date) => boolean,
) {
  const fullCalendarProps: Options = { ...baseProps, events: baseProps.events, export: undefined };

  const slotLaneContent = (args: SlotLaneContentArg) => (
    <SlotLaneRecordSession
      disableSessionRecordHover={disableSessionRecordHover}
      {...args}
      onClick={onRecordSessionClick}
    />
  );
  const detailedEventContent = (args: EventContentArg) => (
    <DetailedEvent isWideEvent={false} {...args} onClick={onEventClick} />
  );
  const config: CalendarOptions['views'] = {
    common: {
      slotLaneContent,
      eventContent: detailedEventContent,
      slotLabelFormat: [{ hour: '2-digit', minute: '2-digit', hour12: true, meridiem: 'narrow' }],
      ...SlotLimitTime,
      dayHeaderContent: DayHeaderContent,
      slotEventOverlap: false,
    },
  };

  if (!fullCalendarProps.views) {
    fullCalendarProps.views = {
      list: {
        eventContent: (args) => <ListEvent onClick={onEventClick} {...args} />,
      },
      day: {
        slotLaneContent,
        eventContent: (args) => (
          <DetailedEvent isWideEvent={true} onClick={onEventClick} {...args} />
        ),
        slotLabelFormat: [{ hour: '2-digit', minute: '2-digit', hour12: true, meridiem: 'narrow' }],
        ...SlotLimitTime,
        dayHeaderContent: DayHeaderContent,
        slotEventOverlap: false,
      },
      week: config.common,
      month: {
        dayCellContent: disableInteractions
          ? null
          : (args) => (
              <DayCellRecordSession
                {...args}
                disableSessionRecordHover={disableSessionRecordHover}
                onClick={onRecordSessionClick}
              />
            ),
        eventContent: (args) => (
          <SingleLineEvent
            {...args}
            onClick={onEventClick}
            disableInteractions={disableInteractions}
          />
        ),
      },
    };
  }
  return fullCalendarProps;
}

const Calendar = forwardRef<CalendarApi, Options>(
  (
    {
      onEventClick,
      onRecordSessionClick,
      disableInteractions,
      disableSessionRecordHover,
      studenDetailsForPdfExport,
      initialDate,
      ...props
    },
    calRef,
  ) => {
    const [isExportProcessing, setIsExportProcessing] = useState(false);
    const [activeMonth, setActiveMonth] = useState('');
    const { timeZone, format } = useTimeFormat();
    const calendarRef = useRef<FullCalendar>(null);
    useImperativeHandle(calRef, () => calendarRef.current?.getApi() as CalendarApi);
    useEffect(() => {
      if (props.export) {
        props.export.current = generatePDF;
      }
    }, [props.export]);

    const generatePDF = async (
      withStudentHeader: boolean,
      fileName = EXPORT_CONFIG.defaultFileName,
    ) => {
      const originCalendarContainer = document?.getElementById(CALENDAR_CONTAINER_ID);
      if (originCalendarContainer) {
        const originCalendar = (originCalendarContainer as Element).firstChild as Element;
        const calendarPlug = originCalendar.cloneNode(true) as Element;
        document.getElementById(EXPORT_CONFIG.calendarPlugContainerId)?.appendChild(calendarPlug);
        setIsExportProcessing(true);
        setTimeout(() => {
          const exportArea = document?.getElementById(EXPORT_CONFIG.exportAreaId);
          const exportCalendarContainer = document?.getElementById(
            EXPORT_CONFIG.exportCalendarContainerId,
          );
          const calendarContainerCopy = originCalendarContainer?.cloneNode(true);
          if (
            exportArea &&
            exportCalendarContainer &&
            calendarContainerCopy &&
            originCalendarContainer
          ) {
            exportCalendarContainer?.appendChild(calendarContainerCopy);
            domtoimage
              .toPng(exportArea)
              .then((dataUrl: string) => {
                const pdf = new jsPDF(
                  'l',
                  'mm',
                  withStudentHeader
                    ? EXPORT_CONFIG.withStudentInfoDimenstions
                    : EXPORT_CONFIG.withoutStudentInfoDimenstions,
                );
                pdf.addImage(dataUrl, 'PNG', 0, 0, 0, 0);
                pdf.save(`${fileName}.pdf`);
              })
              .catch(() => {
                console.log(EXPORT_CONFIG.errorMsg);
              })
              .then(() => {
                setIsExportProcessing(false);
                setTimeout(() => {
                  calendarPlug.remove();
                  Array.from(exportCalendarContainer.children).forEach((child) => child.remove());
                });
              });
          }
        });
      }
    };

    const fullCalendarProps: Options = getCalendarConfiguration(
      props,
      onRecordSessionClick,
      onEventClick,
      disableInteractions,
      disableSessionRecordHover,
    );

    const { isSm } = useBreakpoint('sm');
    const events = useMemo(() => {
      if (props.initialView === 'listMonth' && fullCalendarProps.events) {
        return (fullCalendarProps.events as EventInput[]).filter((event) => {
          const scheduledEvent = event.extendedProps as ScheduleEvent;
          return scheduledEvent.eventType !== 'Holiday';
        });
      }
      return fullCalendarProps.events;
    }, [props.initialView, fullCalendarProps.events]);

    const CalendarElement = (
      <FullCalendar
        {...fullCalendarProps}
        events={events}
        ref={calendarRef}
        plugins={[momentPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
        contentHeight={'auto'}
        initialDate={initialDate}
        firstDay={1}
        dayHeaderContent={(args) => {
          if (isSm) {
            return DayOfWeekTranslation[args.dow].eeee;
          } else {
            return DayOfWeekTranslation[args.dow].eee;
          }
        }}
        dayPopoverFormat={{ day: 'numeric', month: 'long' }}
        eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
        // schedulerLicenseKey={VITE_FULL_CALENDAR_API_KEY}
        {...(isExportProcessing ? { height: '100%' } : {})}
        timeZone={timeZone}
        datesSet={(args) => {
          setActiveMonth(format(args.start, 'LLLL YYY'));
        }}
      />
    );
    return (
      <div className="relative">
        {isExportProcessing && (
          <div style={{ left: '-2000px' }} className="fixed h-[924px] w-[1186px]">
            <div className="px-6" id={EXPORT_CONFIG.exportAreaId}>
              {studenDetailsForPdfExport ? (
                <div>
                  <StudentHeaderForPdfExport className="pt-6" student={studenDetailsForPdfExport} />
                  <div className="absolute right-6 top-20 text-button-xs-desktop text-neutral-100">
                    {activeMonth}
                  </div>
                </div>
              ) : (
                <div className="pb-2 pt-6 text-button-xs-desktop text-neutral-100">
                  {activeMonth}
                </div>
              )}

              <div id={EXPORT_CONFIG.exportCalendarContainerId}></div>
            </div>
          </div>
        )}

        <div
          className={clsx({
            'h-[924px] w-[1140px]': isExportProcessing,
          })}
          id={CALENDAR_CONTAINER_ID}
        >
          {CalendarElement}
        </div>
        <div className="absolute left-0 top-0" id={EXPORT_CONFIG.calendarPlugContainerId}></div>
      </div>
    );
  },
);

Calendar.displayName = 'Calendar';

export default Calendar;
