import { Navigate, Route, Routes } from 'react-router-dom';
import ScrollArea from 'src/components/ScrollArea';
import Tabs from 'src/components/Tabs';
import { routes } from 'src/routes';
import { useAppDispatch, useAppSelector } from 'src/store';

import { Tooltip } from '../../components/Tooltip';
import { BillingMonthSummary } from './billing.types';
import { useBillingSummaryQuery } from './billingApi';
import BillingMonthInvoices from './BillingMonthInvoices';
import BillingMonthSummaryCard from './BillingMonthSummaryCard';
import BillingMonthSummaryCardLoading from './BillingMonthSummaryCardLoading';
import { selectBillingActiveMonthIndex, selectBillingActiveSchoolYear } from './billingSelectors';
import { setActiveMonthIndex } from './billingSlice';

const BillingMonthDefault = () => {
  const { isFetching } = useBillingSummaryQuery({ query: '' });
  const dispatch = useAppDispatch();
  const activeSchoolYear = useAppSelector(selectBillingActiveSchoolYear);
  const activeMonthIndex = useAppSelector(selectBillingActiveMonthIndex);

  const invoices = activeSchoolYear?.invoices ?? [];

  const incompleteCounts = invoices.map((invoice) => invoice.incompleteCount);
  const pendingCounts = invoices.map((invoice) => invoice.pendingCount);
  const submittedCounts = invoices.map((invoice) => invoice.submittedCount);
  const paidCounts = invoices.map((invoice) => invoice.paidCount);

  const isMonthDisabled = (invoice: BillingMonthSummary) => {
    return invoice.hasActivePlacement === false || invoice.isHoliday || !invoice.canBeSelected;
  };

  const tabs = [
    {
      label: 'Incomplete',
      count: incompleteCounts[activeMonthIndex],
      id: routes.billing.month.incompleteInvoices,
      to: routes.billing.month.incompleteInvoices,
    },
    {
      label: 'Pending',
      count: pendingCounts[activeMonthIndex],
      id: routes.billing.month.pendingInvoices,
      to: routes.billing.month.pendingInvoices,
    },
    {
      label: 'Submitted',
      count: submittedCounts[activeMonthIndex],
      id: routes.billing.month.submittedInvoices,
      to: routes.billing.month.submittedInvoices,
    },
    {
      label: 'Paid',
      count: paidCounts[activeMonthIndex],
      id: routes.billing.month.paidInvoices,
      to: routes.billing.month.paidInvoices,
    },
  ];

  return (
    <>
      {isFetching || !activeSchoolYear || invoices.length === 0 ? (
        <div className="mb-4 flex h-40 items-start justify-start gap-4">
          {Array.from({ length: 3 }, (_, index) => (
            <div key={index}>
              <BillingMonthSummaryCardLoading
                className={index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : ''}
              />
            </div>
          ))}
        </div>
      ) : (
        <ScrollArea className="mb-4" orientation="horizontal">
          <div className="flex gap-4 md:h-40 md:pb-4">
            {activeSchoolYear?.invoices.map((invoice, index) => (
              <button
                key={`${invoice.year}-${invoice.month}`}
                onClick={() => {
                  !isMonthDisabled(invoice) && dispatch(setActiveMonthIndex(index));
                }}
              >
                {invoice.canBeSelected ? (
                  <BillingMonthSummaryCard
                    {...invoice}
                    isActive={activeMonthIndex === index}
                    disabled={isMonthDisabled(invoice)}
                  />
                ) : (
                  <Tooltip
                    content="Invoices for future months are unavailable."
                    asChild={false}
                    trigger="hover"
                    side="top"
                  >
                    <BillingMonthSummaryCard
                      {...invoice}
                      isActive={activeMonthIndex === index}
                      disabled={isMonthDisabled(invoice)}
                    />
                  </Tooltip>
                )}
              </button>
            ))}
          </div>
        </ScrollArea>
      )}
      <div className="px-4 md:px-0">
        <Tabs items={tabs} underline className="mb-6 " />

        {activeSchoolYear && (
          <Routes>
            <Route
              path={routes.billing?.month.incompleteInvoices}
              element={
                <BillingMonthInvoices
                  month={activeSchoolYear.invoices[activeMonthIndex]?.month}
                  schoolYearId={activeSchoolYear.schoolYearId}
                  status="Incomplete"
                />
              }
            />
            <Route
              path={routes.billing?.month.pendingInvoices}
              element={
                <BillingMonthInvoices
                  month={activeSchoolYear.invoices[activeMonthIndex]?.month}
                  schoolYearId={activeSchoolYear.schoolYearId}
                  status="Pending"
                />
              }
            />
            <Route
              path={routes.billing?.month.submittedInvoices}
              element={
                <BillingMonthInvoices
                  month={activeSchoolYear.invoices[activeMonthIndex]?.month}
                  schoolYearId={activeSchoolYear.schoolYearId}
                  status="Submitted"
                />
              }
            />
            <Route
              path={routes.billing?.month.paidInvoices}
              element={
                <BillingMonthInvoices
                  month={activeSchoolYear.invoices[activeMonthIndex]?.month}
                  schoolYearId={activeSchoolYear.schoolYearId}
                  status="Paid"
                />
              }
            />
            <Route
              index
              element={<Navigate to={routes.billing?.month.incompleteInvoices} replace />}
            />
          </Routes>
        )}
      </div>
    </>
  );
};

export default BillingMonthDefault;
