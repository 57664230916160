import { useState } from 'react';
import Pagination from 'src/components/Pagination';
import { useAppSelector } from 'src/store';
import { getPageCount } from 'src/utils/pagination.utils';

import { InvoiceStatus } from './billing.types';
import { useInvoicesListQuery } from './billingApi';
import BillingMonthSingleInvoice from './BillingMonthSingleInvoice';
import BillingMonthSingleInvoiceLoading from './BillingMonthSingleInvoiceLoading';
import { selectBillingMonthFilters, selectBillingQueryString } from './billingSelectors';

type BillingListGroupProps = {
  status: InvoiceStatus;
  schoolYearId: string;
};

const BillingListGroup = ({ status, schoolYearId }: BillingListGroupProps) => {
  const [page, setPage] = useState(1);
  const query = useAppSelector(selectBillingQueryString);
  const filters = useAppSelector(selectBillingMonthFilters);
  const { data, isFetching } = useInvoicesListQuery({
    page,
    status,
    schoolYearId,
    query,
    ...filters,
  });

  const pagesCount = getPageCount(data!);

  return (
    <div>
      <div className="text-bg-neutral-100 typography-heading-sm">
        {status} {isFetching ? '' : `(${data?.total || 0})`}
      </div>
      <div>
        {isFetching ? (
          <div className="py-8 text-center">
            <BillingMonthSingleInvoiceLoading />
          </div>
        ) : data?.items?.length ? (
          <div className="mb-8 mt-6 flex flex-col gap-6">
            {data.items.map((item) => (
              <>
                <BillingMonthSingleInvoice key={item.id} {...item} />
              </>
            ))}
            <div className="flex items-center justify-between sm:mt-8">
              <Pagination
                itemsPerPage={data?.pageSize ?? 10}
                totalSize={data?.total}
                pagesCount={pagesCount}
                activePage={page}
                onChangeActivePage={setPage}
              />
            </div>
          </div>
        ) : (
          <div className="py-8 text-center text-neutral-300 typography-body-sm">
            No matching records found.
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingListGroup;
