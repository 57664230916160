import clsx from 'clsx';
import { Fragment } from 'react';
import { IconChevronLeft, IconChevronRight } from 'src/icons';

import Button from './Button';
import PaginationButton from './PaginationButton';
import { getItemsToDisplay, getPagesToDisplay } from './paginationLogic';

type PaginationProps = {
  activePage?: number;
  pagesCount?: number;
  onChangeActivePage?: (page: number) => void;
  totalSize?: number;
  itemsPerPage?: number;
  searchingItemsPlaceholder?: string;
  className?: string;
};

const Pagination = ({
  activePage = 1,
  pagesCount = 1,
  onChangeActivePage = () => null,
  totalSize = 0,
  itemsPerPage = 10,
  searchingItemsPlaceholder,
  className,
}: PaginationProps) => {
  const pagesToDisplay = getPagesToDisplay(activePage, pagesCount);
  const [start, end] = getItemsToDisplay(activePage, itemsPerPage, totalSize);

  const onChange = (value: number) => onChangeActivePage(Math.min(Math.max(1, value), pagesCount));

  let prevPage = 1;

  return (
    <div className={clsx('flex w-full items-center justify-between ', className)}>
      <div className="flex items-center gap-2">
        <Button
          preset="tertiary"
          size="xs"
          Icon={IconChevronLeft}
          disabled={activePage <= 1}
          onClick={() => onChange(activePage - 1)}
        />
        <div className="flex gap-0.5">
          {pagesToDisplay.map((page) => {
            const shouldDisplayThreeDots = prevPage < page - 1;
            prevPage = page;
            return (
              <Fragment key={page}>
                {shouldDisplayThreeDots && <PaginationButton disabled>...</PaginationButton>}
                <PaginationButton
                  active={activePage === page}
                  disabled={activePage === page}
                  onClick={() => onChange(page)}
                >
                  {page}
                </PaginationButton>
              </Fragment>
            );
          })}
        </div>
        <Button
          preset="tertiary"
          size="xs"
          Icon={IconChevronRight}
          disabled={activePage >= pagesCount}
          onClick={() => onChange(activePage + 1)}
        />
      </div>
      {totalSize > 0 && (
        <div className="text-neutral-400 typography-body-xs">
          Showing{' '}
          <span className="text-secondary-300 typography-loud-xs">
            {start}
            {end ? `-${end}` : ''}
          </span>{' '}
          of <span className="text-secondary-100">{totalSize}</span> {searchingItemsPlaceholder}
        </div>
      )}
    </div>
  );
};

export default Pagination;
