import { useLocation } from 'react-router-dom';
import { routes } from 'src/routes';

export enum ScheduleType {
  STUDENT_SCHEDULE,
  PROVIDER_SCHEDULE,
}
export const useScheduleType = () => {
  const location = useLocation();
  const isStudentSchedule = location.pathname.startsWith(routes.students);
  return isStudentSchedule ? ScheduleType.STUDENT_SCHEDULE : ScheduleType.PROVIDER_SCHEDULE;
};
