import clsx from 'clsx';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { isPasswordValid, VALIDATORS } from 'src/logic/isPasswordValid';
import { twMerge } from 'tailwind-merge';

import Dot from './Dot';

type PasswordValidationProps = {
  password: string;
  className?: string;
};

const PasswordValidation = ({ password, className }: PasswordValidationProps) => {
  const isValid = isPasswordValid(password);
  const { isSm } = useBreakpoint('sm');

  return (
    <div className={twMerge('grid grid-cols-2 gap-2', className)}>
      {VALIDATORS.map((validator) => {
        const isRuleInvalid = !!isValid.find((x) => x.validation === validator.name);
        return (
          <div
            key={validator.name}
            className={twMerge(
              clsx(
                'flex items-center text-neutral-400',
                !isSm ? 'typography-body-xs' : 'typography-body-sm',
              ),
            )}
          >
            <Dot preset={isRuleInvalid ? 'primary' : 'success'} />
            {validator.description}
          </div>
        );
      })}
    </div>
  );
};

export default PasswordValidation;
