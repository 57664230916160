import { twMerge } from 'tailwind-merge';

type SkeletonPieceProps = {
  className?: string;
};

const SkeletonPiece = ({ className }: SkeletonPieceProps) => (
  <div
    className={twMerge(
      'h-4 w-28 animate-pulse bg-gradient-to-r from-neutral-900 to-neutral-900/30',
      className,
    )}
  />
);

export default SkeletonPiece;
