import clsx from 'clsx';
import { pick } from 'ramda';
import React from 'react';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Dot from 'src/components/Dot';
import Dropdown from 'src/components/Dropdown';
import DropdownContent from 'src/components/DropdownContent';
import { DropdownItem } from 'src/components/DropdownItem';
import DropdownTrigger from 'src/components/DropdownTrigger';
import Separator from 'src/components/Separator';
import Tag from 'src/components/Tag';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconTripleDots } from 'src/icons';
import { buildName } from 'src/logic/buildName';
import { formatTime } from 'src/logic/formatTime';

import { ScheduleItem, SchedulePlan } from './schedule.types';

type ScheduleSinglePlanCardProps = SchedulePlan & {
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
};

const ScheduleSinglePlanCard = ({
  plans,
  student,
  onDelete,
  onEdit,
}: ScheduleSinglePlanCardProps) => {
  const { isXl } = useBreakpoint('xl');
  const { formatDate } = useTimeFormat();
  const getCellClasses = (schedule: ScheduleItem[]): string => {
    return clsx('pt-4', schedule.length > 1 ? 'mb-auto' : 'h-12');
  };

  return (
    <>
      {isXl ? (
        <Card className="my-2 grid grid-cols-[30%,70%] items-center gap-2 px-10  text-neutral-100 typography-body-sm">
          <div className="mb-auto flex gap-4">
            <Avatar {...pick(['firstName', 'lastName', 'url'], student)} />
            <div>
              <div className="text-neutral-100 typography-heading-md">{buildName(student)}</div>
              <div className="flex items-center gap-2">
                <div className="text-neutral-300 typography-body-sm">{student.schoolName}</div>
                {student.isNew && isXl && (
                  <Tag preset="red" size="md">
                    New
                  </Tag>
                )}
                {student.grade && (
                  <Tag preset="lightBlue" size="md">
                    {student.grade}
                  </Tag>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            {plans.map((plan) => (
              <div key={plan.id} className="grid grid-cols-[25%,25%,25%,25%] items-center">
                <div className={getCellClasses(plan?.schedule)}>
                  <div className="h-14 ">
                    {formatDate(plan.start)} - {formatDate(plan.finish)}
                  </div>
                </div>

                <div className={getCellClasses(plan?.schedule)}>
                  {plan?.schedule?.map((s, index) => (
                    <div key={index} className={clsx('flex h-14 ')}>
                      <div className="w-7">{s.dayOfWeek.substring(0, 3)} </div>
                      <Dot className="mb-8 ml-2" preset="pacific" />
                      <div className="flex">
                        {formatTime(s.start)} - {formatTime(s.finish)}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={getCellClasses(plan?.schedule)}>
                  {plan?.schedule?.map((s, index) => (
                    <div className="h-14" key={index}>
                      Location: {s.location}
                    </div>
                  ))}
                </div>

                <div
                  className={clsx('ml-auto align-middle', plan?.schedule.length > 1 ? 'pb-1' : '')}
                >
                  <Dropdown>
                    <DropdownTrigger>
                      <Button preset="tertiary" className="rounded" Icon={IconTripleDots} />
                    </DropdownTrigger>
                    <DropdownContent className="w-36" align="end" side="bottom">
                      {
                        <DropdownItem handleClick={() => plan.id && onEdit(plan.id)}>
                          Edit
                        </DropdownItem>
                      }
                      {plan.canBeDeleted && (
                        <DropdownItem handleClick={() => plan.id && onDelete(plan.id)}>
                          Delete
                        </DropdownItem>
                      )}
                    </DropdownContent>
                  </Dropdown>
                </div>
              </div>
            ))}
          </div>
        </Card>
      ) : (
        <Card className="relative my-4 flex flex-col gap-4 px-6 py-4 text-neutral-100 typography-body-sm">
          <div className="mb-auto flex items-center gap-4">
            <Avatar {...pick(['firstName', 'lastName', 'url'], student)} />
            <div className="flex flex-col gap-1">
              <div className="text-neutral-100 typography-loud-lg">{buildName(student)}</div>
              <div className="flex items-center gap-2 ">
                <div className="text-neutral-300 typography-body-sm ">{student.schoolName}</div>
                {student.grade && (
                  <Tag preset="lightBlue" size="sm" className="absolute right-2 top-2">
                    {student.grade}
                  </Tag>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {plans.map((plan) => (
              <React.Fragment key={plan.id}>
                <Separator />
                <div key={plan.id} className="w-full">
                  <div className="mt-4 flex w-full items-center  justify-between ">
                    <div>
                      <div className="mb-2 text-neutral-300 typography-loud-xs">
                        Start Dates - End Dates
                      </div>
                      <div className="text-neutral-black typography-loud-sm">
                        {formatDate(plan.start)} - {formatDate(plan.finish)}
                      </div>
                    </div>
                    <div className={clsx('ml-autoalign-middle')}>
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            preset="tertiary"
                            size="xs"
                            className="rounded"
                            Icon={IconTripleDots}
                          />
                        </DropdownTrigger>
                        <DropdownContent className="w-36" align="end" side="bottom">
                          {
                            <DropdownItem handleClick={() => plan.id && onEdit(plan.id)}>
                              Edit
                            </DropdownItem>
                          }
                          {plan.canBeDeleted && (
                            <DropdownItem handleClick={() => plan.id && onDelete(plan.id)}>
                              Delete
                            </DropdownItem>
                          )}
                        </DropdownContent>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="mb-4 flex w-full justify-between">
                    <div>
                      {plan?.schedule?.map((s, index) => (
                        <div key={index} className={clsx('mt-4 flex flex-col')}>
                          <div className="mb-2 text-neutral-300 typography-loud-xs">Day</div>
                          <div className="text-neutral-black typography-loud-sm">
                            {s.dayOfWeek.substring(0, 3)}{' '}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      {plan?.schedule?.map((s, index) => (
                        <div key={index} className={clsx('mt-4 flex flex-col')}>
                          <div className="mb-2 text-neutral-300 typography-loud-xs">Time</div>
                          <div className="text-neutral-black typography-loud-sm">
                            {s.start} - {s.finish}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      {plan?.schedule?.map((s, index) => (
                        <div key={index} className={clsx('mt-4 flex flex-col')}>
                          <div className="mb-2 text-neutral-300 typography-loud-xs">Location</div>
                          <div className="text-neutral-black typography-loud-sm">{s.location}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </Card>
      )}
    </>
  );
};

export default ScheduleSinglePlanCard;
