import * as RadixToast from '@radix-ui/react-toast';
import clsx from 'clsx';
import { IconClose } from 'src/icons';

export type SingleToast = {
  text: string;
  id: number;
  type?: 'positive' | 'negative';
  isOpen?: boolean;
};

export type ToastProps = SingleToast & {
  onOpenChange: (id: number) => void;
};

const Toast = ({ text, id, type, isOpen, onOpenChange }: ToastProps) => {
  return (
    <RadixToast.Root
      className={clsx(
        'mb-0.5 flex justify-between gap-4 rounded-lg p-4 text-neutral-white shadow-elevation-2 md:items-center md:whitespace-nowrap',
        'radix-state-open:animate-toast-slide-in-top',
        'radix-state-closed:animate-toast-hide',
        type === 'positive' ? 'bg-accent-verdigris-500' : 'bg-accent-tomato-500',
      )}
      open={isOpen}
      onOpenChange={() => onOpenChange(id)}
      key={id}
      duration={5000}
    >
      <RadixToast.Title className="text-left font-medium typography-body-md md:whitespace-pre md:text-center">
        {text}
      </RadixToast.Title>
      <RadixToast.Action asChild altText="Goto schedule to undo">
        <IconClose
          className="h-6 w-6 cursor-pointer text-neutral-white"
          onClick={() => onOpenChange(id)}
        />
      </RadixToast.Action>
    </RadixToast.Root>
  );
};

export default Toast;
