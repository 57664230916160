import { EventContentArg } from '@fullcalendar/core';
import clsx from 'clsx';
import { isValid } from 'date-fns';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { useScheduleType } from 'src/hooks/useScheduleType';
import useTimeFormat from 'src/hooks/useTimeFormat';

import { ScheduleEvent } from '../../pages/schedule/schedule.types';
import Dot from '../Dot';
import {
  EVENT_TYPE_TO_BACKGROUND_COLOR_MAP,
  EVENT_TYPE_TO_DOT_PRESET_MAP,
  EVENT_TYPE_TO_TEXT_COLOR_MAP,
  getEventDisplayType,
  getEventIcon,
} from './calendar-events.utils';

type DetailedEventProps = EventContentArg & {
  onClick: (event: ScheduleEvent) => void;
};

export const ListEvent = (props: DetailedEventProps) => {
  const { format, formatLongHourMinute } = useTimeFormat();
  const { event } = props;

  const scheduledEvent = event.extendedProps as ScheduleEvent;
  const { eventType = '', name } = scheduledEvent;

  const eventStartDate = event.start || new Date();
  const eventDay = eventStartDate.getDate();
  const eventDayName = format(eventStartDate, 'eeee');
  const eventDayNameMobile = format(eventStartDate, 'eee');
  const eventMonth = format(eventStartDate, 'MMM');
  const eventStartTime = formatLongHourMinute(eventStartDate);
  const eventEndTime = event.end ? (isValid(event.end) ? format(event.end, 'h:mmaaa') : '') : '';
  const { isSm } = useBreakpoint('sm');
  const eventTime = eventEndTime ? `${eventStartTime} - ${eventEndTime}` : eventStartTime;
  const displayType = getEventDisplayType(scheduledEvent, useScheduleType());
  const todayDay = format(new Date(), 'd');

  return (
    <div
      onClick={() => props.onClick(scheduledEvent)}
      onKeyDown={() => props.onClick(scheduledEvent)}
      role="button"
      tabIndex={0}
    >
      {isSm ? (
        <div
          className={clsx(
            'flex justify-between  border-0 border-neutral-800 px-10 py-2 typography-loud-sm md:border-b',
            EVENT_TYPE_TO_BACKGROUND_COLOR_MAP[displayType],
            EVENT_TYPE_TO_TEXT_COLOR_MAP[displayType],
          )}
        >
          <td className="flex gap-1 xl:w-10">
            <div className="">
              <span
                className={clsx(
                  String(eventDay) === todayDay
                    ? 'color: flex w-6 rounded-full bg-secondary-400 text-neutral-white'
                    : 'pl-1',
                )}
              >
                {eventDay}
              </span>
            </div>
          </td>
          <td className="flex gap-5 xl:w-60">
            <div className="">
              {eventDayName}, {eventMonth}
            </div>
          </td>
          <td className="flex items-center gap-2 xl:w-60">
            <Dot preset={EVENT_TYPE_TO_DOT_PRESET_MAP[displayType]} />
            <div>{eventTime}</div>
          </td>
          <td className={clsx('xl:w-20', eventType === 'OutOfOffice' ? 'opacity-0' : '')}>
            {scheduledEvent.eventType}
          </td>
          <td className="flex items-center gap-2 xl:w-96">
            {name}
            {getEventIcon(displayType)}
          </td>
          <td className={clsx('xl:w-80', eventType === 'OutOfOffice' ? 'opacity-0' : '')}>
            Location: {scheduledEvent.location}
          </td>
        </div>
      ) : (
        <div
          className={clsx(
            'flex items-start justify-between gap-4 border-0 border-neutral-800 px-3 py-1 typography-loud-sm md:border-b md:px-10 md:py-2',
          )}
        >
          <td className="mr-3 flex flex-col gap-2  xl:w-60">
            <div className="text-schedule-gray">{eventDayNameMobile}</div>
            <div className="flex w-6 items-center justify-center md:h-6	">{eventDay}</div>
          </td>
          <td
            className={clsx(
              'mr-1 flex w-full items-start gap-1 rounded md:items-center md:gap-2',
              EVENT_TYPE_TO_BACKGROUND_COLOR_MAP[displayType],
              EVENT_TYPE_TO_TEXT_COLOR_MAP[displayType],
            )}
          >
            <Dot
              className="pl-1 pt-2"
              size="sm"
              preset={EVENT_TYPE_TO_DOT_PRESET_MAP[displayType]}
            />
            <div className="relative flex h-full w-full flex-col">
              <div>
                {eventTime} {scheduledEvent.eventType}
              </div>
              <div>{name}</div>
            </div>
            <div className="pr-2 pt-2">{getEventIcon(displayType)}</div>
          </td>
        </div>
      )}
    </div>
  );
};
