import { NavLink } from 'react-router-dom';
import { useIsAdmin } from 'src/hooks/useIsAdmin';
import { IconCalendarCheck, IconKidStudent, IconMoney, IconUser } from 'src/icons';
import { routes } from 'src/routes';

import NavigationButton from './NavigationButton';

const NAV_ITEMS = [
  { label: 'Schedule', to: routes.schedule.root, Icon: IconCalendarCheck },
  { label: 'Students', to: routes.students, Icon: IconKidStudent },
  { label: 'Invoices', to: routes.billing.root, Icon: IconMoney },
  { label: 'Users', to: routes.users.root, Icon: IconUser, adminOnly: true },
];

type NavigationLinksProps = {
  isCollapsed: boolean;
  showNotifications?: boolean;
  clickLinkCb?: () => void;
};

const NavigationLinks = ({ isCollapsed, clickLinkCb }: NavigationLinksProps) => {
  const isAdmin = useIsAdmin();
  const navPredicate = (n: (typeof NAV_ITEMS)[number]) => (n.adminOnly ? isAdmin : true);

  return (
    <>
      {NAV_ITEMS.filter(navPredicate).map((item) => (
        <NavLink key={item.to} to={item.to}>
          {({ isActive }) => (
            <NavigationButton
              isActive={isActive}
              Icon={item.Icon}
              label={item.label}
              isCollapsed={isCollapsed}
              title={item.label}
              onClick={clickLinkCb}
            />
          )}
        </NavLink>
      ))}
    </>
  );
};

export default NavigationLinks;
