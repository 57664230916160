import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Loader from 'src/components/Loader';
import ProgressIndicator from 'src/components/ProgressIndicator';
import Tag from 'src/components/Tag';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useExportToPDF from 'src/hooks/useExportToPDF';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconChevronLeft } from 'src/icons';
import { buildName } from 'src/logic/buildName';
import { formatTime } from 'src/logic/formatTime';
import { getMonthName } from 'src/logic/getMonthName';

import StudentHeaderLoading from '../single-student/student-header/StudentHeaderLoading';
import StudentHeaderProfile from '../single-student/student-header/StudentHeaderProfile';
import { useInvoiceStatementQuery } from './billingApi';
import BillingInvoiceHeaderForPdfExport from './BillingInvoiceHeaderForPdfExport';

type BillingInvoiceSummaryDetailsProps = {
  backTo: string;
  showGoals?: boolean;
  showDownloadBtn?: boolean;
  onDownload?: (id: string) => void;
  showProvider?: boolean;
  showSignatureRequestedAndDueDate?: boolean;
};
const BillingInvoiceSummaryDetails = ({
  showGoals,
  showProvider,
  showSignatureRequestedAndDueDate,
  showDownloadBtn = true,
}: BillingInvoiceSummaryDetailsProps) => {
  const { formatDate, formatDateOnly, FORMAT_ISO } = useTimeFormat();
  const [isExportProcessing, setIsExportProcessing] = useState(false);
  const { id } = useParams();
  const contentRef = useRef<HTMLDivElement>(null);
  const exportToPDF = useExportToPDF();
  const navigate = useNavigate();
  const { isFetching, data } = useInvoiceStatementQuery(id!);

  const { isMd } = useBreakpoint('md');
  const { isLg } = useBreakpoint('lg');
  const { isSm } = useBreakpoint('sm');

  const statement = data;

  const onExportRequest = () => {
    if (contentRef.current) {
      exportToPDF(contentRef, `${buildName(statement?.student)}-billing-invoices.pdf`, {
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      }).then(() => {
        setIsExportProcessing(false);
      });
    }
  };

  const onExport = () => {
    setIsExportProcessing(true);
    setTimeout(() => {
      onExportRequest();
    }, 1000);
  };

  if (isFetching || !statement) {
    return <Loader className="my-60 w-full" />;
  }

  const SessionsElement = (
    <div className="mdp-6 flex h-full flex-col justify-start gap-4 border-t border-neutral-800 p-4 md:border-none">
      {statement.sessions.map((session) => {
        return (
          <Card
            key={session.id}
            titleClassName={clsx(
              statement.provider ? '' : 'border-b border-b-neutral-900 mb-4 pb-4 md:mb-5 md:pb-5',
            )}
            title={
              <div className="grid grid-cols-1 md:grid-cols-3">
                <span>{formatDateOnly(session.date)}</span>

                {!isMd ? (
                  <div className="mt-3 flex w-full justify-between">
                    <span className="text-neutral-300 typography-body-sm">
                      {formatTime(session.startTime)} - {formatTime(session.finishTime)}
                    </span>
                    <span className="text-neutral-300 typography-body-sm">
                      Location: {session.location}
                    </span>
                  </div>
                ) : (
                  <>
                    <span className="text-neutral-300 typography-body-sm">
                      {formatTime(session.startTime)} - {formatTime(session.finishTime)}
                    </span>
                    <span className="text-neutral-300 typography-body-sm">
                      Location: {session.location}
                    </span>
                  </>
                )}
              </div>
            }
          >
            {showGoals &&
              session.goals.map((goal) => {
                return (
                  <div
                    key={goal.id}
                    className="flex w-full flex-col justify-between gap-4 md:flex-row md:items-center md:gap-0"
                  >
                    <span className="text-neutral-300 typography-loud-sm">{goal.name}</span>
                    <ProgressIndicator max={5} value={goal.progress} disabled />{' '}
                  </div>
                );
              })}
          </Card>
        );
      })}
    </div>
  );

  return (
    <div className="flex min-h-full flex-col bg-neutral-950">
      {showDownloadBtn && !isLg && (
        <div className="fixed bottom-0 left-0 w-full border-t border-neutral-800 bg-neutral-white p-6 sm:absolute">
          <Button
            className="lgv:mt-0 w-full lg:absolute lg:right-0 lg:top-0 lg:w-fit"
            size="md"
            onClick={onExport}
            preset="secondary"
          >
            Download Invoice
          </Button>
        </div>
      )}
      <div className="h-auto w-full bg-neutral-white px-4 pb-6 md:px-10 md:pb-10 md:pt-10">
        <Button
          preset="ghost"
          Icon={IconChevronLeft}
          onClick={() => navigate(-1)}
          className="mb-6 mt-4 inline-flex h-auto px-0 md:h-12"
        >
          Back to Invoices
        </Button>

        {showProvider && (
          <div className="mb-6 flex items-center gap-4 border-b border-b-neutral-800 pb-6">
            <span className="typography-heading-md">{buildName(statement.provider)}</span>
            <Tag>Provider</Tag>
          </div>
        )}
        <div className="relative">
          {!statement.student ? (
            <StudentHeaderLoading />
          ) : (
            <div className="flex flex-col md:flex-row">
              <StudentHeaderProfile
                size="md"
                student={{ ...statement.student, englishGrade: statement.student.grade }}
                className="mb-4 mr-6 md:mb-0"
              />
              <div className="flex flex-col content-between justify-between gap-2 md:gap-0">
                {showSignatureRequestedAndDueDate ? (
                  <div className="flex gap-4 overflow-auto">
                    {statement.requestAt && (
                      <Tag size={isMd ? 'md' : 'sm'} preset="yellow">
                        Signature requested: {formatDate(statement.requestAt)}
                      </Tag>
                    )}
                    {statement.dueDate && (
                      <Tag size={isMd ? 'md' : 'sm'} preset="gray">
                        Invoice due date: {formatDate(statement.dueDate)}
                      </Tag>
                    )}
                  </div>
                ) : (
                  <div className="flex gap-4">
                    <Tag
                      size={isMd ? 'md' : 'sm'}
                      preset={
                        statement.status === 'Pending'
                          ? 'yellow'
                          : statement.status === 'Submitted'
                          ? 'lightGreen'
                          : 'pacific'
                      }
                    >
                      {statement.status}: {formatDate(statement.statusChangedAt)}
                    </Tag>
                    {statement.amount && statement.status === 'Paid' && (
                      <Tag size={isSm ? 'md' : 'sm'} preset="grayStroke">
                        {new Intl.NumberFormat('en-US').format(statement.amount.value)}{' '}
                        {statement.amount.currency}
                      </Tag>
                    )}
                  </div>
                )}
                <div className="flex gap-4">
                  <Tag size={isMd ? 'md' : 'sm'} preset="grayStroke">
                    {getMonthName(statement.month)} {statement.year}
                  </Tag>
                  {(statement.hours || statement.hours === 0) && (
                    <Tag size={isMd ? 'md' : 'sm'} preset="lightBlue">
                      {statement.hours} hours
                    </Tag>
                  )}
                </div>
              </div>
            </div>
          )}

          {showDownloadBtn && isLg && (
            <Button
              className="mt-4 w-full lg:absolute lg:right-0 lg:top-0 lg:mt-0 lg:w-fit"
              size={isMd ? 'md' : 'xs'}
              onClick={onExport}
              preset="secondary"
            >
              Download Invoice
            </Button>
          )}
        </div>
      </div>
      {isExportProcessing && (
        <div style={{ left: '-2000px' }} className="fixed h-[924px] w-[1186px]">
          <div className="min-h-full w-full bg-neutral-white" ref={contentRef}>
            <BillingInvoiceHeaderForPdfExport
              size="md"
              student={{ ...statement.student, englishGrade: statement.student.grade }}
              statement={statement}
            />
            {SessionsElement}
          </div>
        </div>
      )}
      {SessionsElement}
    </div>
  );
};

export default BillingInvoiceSummaryDetails;
