import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from 'src/hooks/useWindowSize';

type OverflowTooltipTextProps = TooltipPrimitive.TooltipProps & {
  content?: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  asChild?: boolean;
};

export const OverflowTooltipText: React.FC<OverflowTooltipTextProps> = ({
  asChild = true,
  ...props
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isOverflow, setOverflow] = useState(false);
  let pressTimer: number | undefined;
  let isLongPressActive = false;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const isOverflowing = contentElement.scrollWidth > contentElement.clientWidth;
      setOverflow(isOverflowing);
    }
  }, [props.children, width]);

  const handleMobileLongPressStart = () => {
    if (isOverflow) {
      isLongPressActive = true;
      pressTimer = window.setTimeout(() => {
        setTooltipVisible(true);
      }, 1000);
    }
  };

  const handleMobileLongPressEnd = () => {
    if (isOverflow) {
      if (pressTimer) {
        clearTimeout(pressTimer);
      }
      isLongPressActive = false;
      setTooltipVisible(false);
    }
  };

  const handleMouseEnter = () => {
    if (!isLongPressActive && isOverflow) {
      setTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isLongPressActive && isOverflow) {
      setTooltipVisible(false);
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    if (isLongPressActive && isOverflow) {
      event.preventDefault();
    }
  };

  const addClassNameToChildren = (
    children: React.ReactNode,
    className: string,
  ): React.ReactNode => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children as React.ReactElement, {
        className: clsx(children.props.className, className),
      });
    }
    return children;
  };

  const childrenWithClassnames = isOverflow
    ? addClassNameToChildren(props.children, 'truncate' || '')
    : props.children;

  const getChildrenText = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return child.props.children;
    } else if (typeof child === 'string') {
      return child;
    }

    return '';
  });

  const childrenText = Array.isArray(getChildrenText) ? getChildrenText.join('') : getChildrenText;

  return (
    <TooltipPrimitive.Provider delayDuration={props.delayDuration || 0}>
      <TooltipPrimitive.Root
        open={tooltipVisible}
        defaultOpen={props.defaultOpen}
        onOpenChange={props.onOpenChange}
      >
        <TooltipPrimitive.Trigger
          asChild={asChild}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleMobileLongPressStart}
          onTouchEnd={handleMobileLongPressEnd}
          onTouchCancel={handleMobileLongPressEnd}
          onClick={handleClick}
        >
          <div className="overflow-hidden">
            <div ref={contentRef} className="truncate">
              {childrenWithClassnames}
            </div>
          </div>
        </TooltipPrimitive.Trigger>
        {tooltipVisible && (
          <TooltipPrimitive.Content
            side={props.side || 'top'}
            align="center"
            className={clsx(
              'items-center whitespace-pre-line rounded-md px-3 py-2',
              'z-[9999] bg-neutral-100 text-neutral-white typography-caption-md',
              'radix-side-top:animate-slide-down-fade',
              'radix-side-right:animate-slide-left-fade',
              'radix-side-bottom:animate-slide-up-fade',
              'radix-side-left:animate-slide-right-fade',
              props.className,
            )}
          >
            {props.content || childrenText}
            <TooltipPrimitive.Arrow className="fill-neutral-100 text-neutral-100" />
          </TooltipPrimitive.Content>
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
