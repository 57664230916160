import Avatar from 'src/components/Avatar';
import { buildName } from 'src/logic/buildName';

import { User } from './singleUser.types';

export type SingleUserSummaryProps = {
  user: User;
};

const SingleUserSummary = ({ user }: SingleUserSummaryProps) => (
  <div className="flex">
    <Avatar
      firstName={user.firstName}
      lastName={user.lastName}
      url={user.avatar}
      size="3xl"
      className="mr-10"
    />
    <div className="flex flex-col">
      <div className="mb-2 flex typography-heading-lg">
        {buildName({
          firstName: user.firstName,
          lastName: user.lastName,
        })}
      </div>
      <span className="mb-1 text-neutral-100 typography-body-md">{user.email}</span>
      <span className="text-neutral-400 typography-loud-xs"> {user.userType}</span>
    </div>
  </div>
);

export default SingleUserSummary;
