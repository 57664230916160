import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import Modal, { ModalFooter } from 'src/components/Modal';
import RadioGroup from 'src/components/RadioGroup';
import Separator from 'src/components/Separator';
import TextField from 'src/components/TextField';
import { useSessionCancellationReasonQuery } from 'src/dictionaries/dictionariesApi';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { convertHourMinuteToStep } from 'src/hooks/useTimeSlots';
import { invalidateBillingTags } from 'src/pages/billing/billingApi';
import {
  invalidateSingleStudentTags,
  useSingleStudentDetailsQuery,
} from 'src/pages/single-student/singleStudentApi';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { removeStringAfterColon } from 'src/utils/removeStringAfterColon';

import { useCancelSessionMutation } from '../scheduleApi';

export type SessionData = {
  date: string | Date;
  location: string;
  sessionId: string;
  startTime: string;
  finishTime: string;
};

export const useCancelSession = () => {
  const [session, setSession] = useState<SessionData>();

  const onCancelSession = useCallback((session: SessionData) => {
    setSession(session);
  }, []);

  const onAbortSessionCancellation = useCallback(() => {
    setSession(undefined);
  }, []);

  return { session, onCancelSession, onAbortSessionCancellation };
};

type CancelSessionModalProps = {
  onAbort: () => void;
  onSuccessCb?: () => void;
  cancelSessionData?: SessionData;
};

const CancelSessionModal = ({
  onAbort,
  onSuccessCb,
  cancelSessionData,
}: CancelSessionModalProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data } = useSessionCancellationReasonQuery();
  const [cancelSession, { isLoading, isError, isSuccess }] = useCancelSessionMutation();
  const [cancellationReasonId, setCancellationReasonId] = useState<string>();
  const [cancellationReason, setCancellationReason] = useState('');
  const { format } = useTimeFormat();
  const studentId = removeStringAfterColon(id);
  const { refetch, isUninitialized } = useSingleStudentDetailsQuery(studentId!, {
    skip: !studentId,
  });

  useEffect(() => {
    if (isError) {
      dispatch(addToast({ text: 'Something went wrong. Try again later.', type: 'negative' }));
      onAbort();
    }
  }, [dispatch, isError, onAbort]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addToast({ text: 'Session has been successfully cancelled.', type: 'positive' }));
      dispatch(invalidateBillingTags(['InvoiceDetails']));
      dispatch(invalidateSingleStudentTags(['StudentEvents']));
      onAbort();
      onSuccessCb?.();
    }
  }, [dispatch, isSuccess, onAbort, onSuccessCb]);

  useEffect(() => {
    if (!cancelSessionData?.sessionId) {
      setCancellationReasonId(undefined);
      setCancellationReason('');
    }
  }, [cancelSessionData?.sessionId]);

  const onCancelSession = async () => {
    if (cancelSessionData?.sessionId && cancellationReasonId) {
      await cancelSession({
        id: cancelSessionData?.sessionId,
        cancellationReasonId,
        cancellationReason,
      });

      if (!isUninitialized) {
        refetch();
      }
    }
  };

  const isLastOptionSelected =
    cancellationReasonId &&
    data?.length &&
    data?.findIndex((d) => d.key === cancellationReasonId) === data.length - 1;

  const formattedDate = cancelSessionData?.date
    ? format(cancelSessionData.date, 'MMM dd, yyyy')
    : format(new Date().toISOString(), 'MMM dd, yyyy');

  return (
    <Modal
      title="Cancelation details"
      open={!!cancelSessionData?.sessionId}
      onOpenChange={onAbort}
      className="w-9/10 max-w-lg sm:w-inherit"
    >
      <div className="flex flex-wrap items-center gap-2">
        <div className="flex gap-x-1 whitespace-nowrap text-neutral-100">
          <div className="text-neutral-300">Date:</div> {formattedDate}
        </div>
        <Separator vertical />
        <div className="flex gap-x-1 whitespace-nowrap text-neutral-100">
          <div className="text-neutral-300">Time:</div>{' '}
          {cancelSessionData?.startTime &&
            convertHourMinuteToStep(cancelSessionData?.startTime).label}{' '}
          -{' '}
          {cancelSessionData?.finishTime &&
            convertHourMinuteToStep(cancelSessionData?.finishTime).label}
        </div>
        <Separator vertical />
        <div className="flex gap-x-1 whitespace-nowrap text-neutral-100">
          <div className="text-neutral-300">Location:</div> {cancelSessionData?.location}
        </div>
      </div>
      <div className="flex flex-col py-4">
        <RadioGroup
          className="grid grid-cols-1 md:grid-cols-2"
          options={
            data?.map((reason) => ({
              label: reason.value,
              value: reason.key,
            })) || []
          }
          value={cancellationReasonId}
          onValueChange={setCancellationReasonId}
        />

        {isLastOptionSelected && (
          <TextField
            onChange={(e) => {
              setCancellationReason(e.target.value);
            }}
            placeholder="Enter cancelation reason"
            className="mt-4"
            size="md"
          />
        )}
        <ModalFooter className="-mx-0 px-0">
          <div className=" grid w-full grid-cols-2 gap-x-4  md:flex">
            <Button
              preset="secondary"
              disabled={!cancellationReasonId || (!!isLastOptionSelected && !cancellationReason)}
              onClick={onCancelSession}
              isLoading={isLoading}
            >
              Save
            </Button>
            <Button preset="tertiary" onClick={onAbort}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CancelSessionModal;
