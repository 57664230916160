import type { SVGProps } from 'react';
const SvgWriting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g stroke="currentColor" strokeMiterlimit={10} clipPath="url(#writing_svg__a)">
      <path d="M8.646 1.896 3.5 4.25l-1.75 9 9.25-1.5 2.238-5.262M1.75 13.25l4.866-4.616" />
      <path
        strokeLinecap="square"
        d="m14.5 7.75-7-7M8.384 8.634a1.25 1.25 0 1 0-1.768-1.768 1.25 1.25 0 0 0 1.768 1.768ZM.5 15.5h15"
      />
    </g>
    <defs>
      <clipPath id="writing_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWriting;
