import Card from 'src/components/Card';
import SkeletonPiece from 'src/components/SkeletonPiece';
import { twMerge } from 'tailwind-merge';

type BillingMonthSummaryCardLoadingProps = {
  className?: string;
  size?: 'md' | 'lg';
};

const BillingMonthSummaryCardLoading = ({
  className,
  size,
}: BillingMonthSummaryCardLoadingProps) => {
  return (
    <Card
      className={twMerge(
        'box-border w-60 flex-shrink-0 flex-grow-0 px-6 py-4',
        size === 'lg' ? 'h-36 w-full' : 'h-30',
        className,
      )}
    >
      <div className="mb-7 flex items-center typography-heading-md">
        <SkeletonPiece className="h-6" />
      </div>
      <SkeletonPiece className="h-1.5 w-full" />
      <div className="mt-4 whitespace-nowrap typography-body-sm">
        <SkeletonPiece className="h-6 w-48" />
      </div>
    </Card>
  );
};

export default BillingMonthSummaryCardLoading;
