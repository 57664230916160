import { useMemo } from 'react';
import { StudentBase } from 'src/pages/students/students.types';
import { useStudentsDictQuery } from 'src/pages/students/studentsApi';

const useSelectedStudent = (
  showInactiveStudents: boolean,
  studentId?: string,
  savedStudent?: StudentBase,
) => {
  const { data: students = [], isFetching, isSuccess } = useStudentsDictQuery(showInactiveStudents);
  const selectedStudent = useMemo(() => {
    const foundStudent = students.find((student) => student.id === studentId);
    return foundStudent || savedStudent;
  }, [students, studentId, savedStudent]);
  return { selectedStudent, students, isFetching, isSuccess };
};

export default useSelectedStudent;
