import clsx from 'clsx';
import { useState } from 'react';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import RadioCheck from 'src/components/RadioCheck';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconCheck, IconClose, IconUndo } from 'src/icons';

import { StudentGoal } from './singleStudent.types';
import SingleStudentGoalDetails from './SingleStudentGoalDetails';

export type SingleStudentGoalProps = {
  goal: StudentGoal;
  className: string;
  statusDeleted: boolean;
  statusMastered: boolean;
  onDeleteClick?: () => void;
  onUndoClick?: () => void;
  onMasteredClick?: () => void;
  onUndoMasteredClick?: () => void;
};

const SingleStudentGoal = ({
  goal,
  className,
  onDeleteClick,
  onUndoClick,
  onUndoMasteredClick,
  onMasteredClick,
  statusDeleted,
  statusMastered,
}: SingleStudentGoalProps) => {
  const [isDeleted, setIsDeleted] = useState(statusDeleted ?? false);
  const [showMaster, setShowMaster] = useState(statusMastered ?? false);

  const handleDeleteClick = () => {
    setIsDeleted(true);
    if (onDeleteClick) {
      onDeleteClick();
    }
  };

  const handleUndoClick = () => {
    setIsDeleted(false);
    if (onUndoClick) {
      onUndoClick();
    }
  };

  const handleUndoMasteredClick = () => {
    setShowMaster(false);
    if (onUndoMasteredClick) {
      onUndoMasteredClick();
    }
  };

  const handleMasteredClick = () => {
    setShowMaster(true);
    if (onMasteredClick) {
      onMasteredClick();
    }
  };

  const { isSm } = useBreakpoint('sm');

  const handleOptionChange = (selected: boolean) => {
    setShowMaster(selected);
    handleMasteredClick();
  };
  return (
    <Card
      className={clsx(
        'bg group flex flex-col items-center justify-between rounded-lg border border-neutral-800 py-4 shadow-elevation-1 sm:px-4 sm:py-4 md:flex-row md:px-4 md:py-4',
        showMaster ? 'md:bg-neutral-950' : '',
        className,
      )}
    >
      <SingleStudentGoalDetails goal={goal} isDeleted={isDeleted}></SingleStudentGoalDetails>
      <div className="mt-6 flex w-full text-end md:mt-0 md:w-auto">
        {showMaster && isSm && (
          <div className=" flex w-full items-center">
            <div
              role="button"
              tabIndex={0}
              className="flex cursor-pointer items-center text-secondary-300 typography-loud-xs"
              onKeyDown={handleUndoMasteredClick}
              onClick={handleUndoMasteredClick}
            >
              Mastered
              <IconCheck className="ml-2" />
            </div>
          </div>
        )}
        <div
          className={clsx(
            'flex w-full items-center',
            isDeleted ? 'justify-end' : 'justify-between',
          )}
        >
          {!isSm && !isDeleted && (
            <div className="flex items-center gap-x-2">
              <div>
                {showMaster ? (
                  <div className="text-secondary-300 typography-loud-xs">Mastered</div>
                ) : (
                  <div className="!leading-none typography-loud-xs">Mark as mastered</div>
                )}
              </div>
              <RadioCheck onlyCheck={true} selected={showMaster} onChange={handleOptionChange} />
            </div>
          )}
          <div className="cursor-pointer items-center justify-center gap-4 md:hidden   md:group-hover:flex">
            {!isDeleted && !showMaster && isSm && (
              <div
                role="button"
                tabIndex={0}
                className="flex items-center "
                onKeyDown={handleMasteredClick}
                onClick={handleMasteredClick}
              >
                <Button
                  preset="ghost"
                  size="xs"
                  className="flex h-5 px-0 text-neutral-400 "
                  textClassName="!font-medium"
                >
                  Mark as mastered
                </Button>
                <IconCheck className="ml-2 text-neutral-400" />
              </div>
            )}

            {!isDeleted && !showMaster && isSm && (
              <Tooltip
                side="bottom"
                content="You have to keep activate at least 6 goals."
                delayDuration={0}
              >
                <div
                  role="button"
                  tabIndex={0}
                  className="flex items-center "
                  onKeyDown={handleDeleteClick}
                  onClick={handleDeleteClick}
                >
                  <Button
                    preset="ghost"
                    size="xs"
                    className="hidden h-5 px-0 text-neutral-400 md:flex"
                    textClassName="!font-medium"
                  >
                    Remove
                  </Button>
                  <IconClose
                    className="ml-2 h-5 w-5 text-neutral-400"
                    onClick={handleDeleteClick}
                    onKeyDown={handleDeleteClick}
                  />
                </div>
              </Tooltip>
            )}

            {!isDeleted && !isSm && (
              <Tooltip
                side="bottom"
                content="You have to keep activate at least 6 goals."
                delayDuration={0}
              >
                <div
                  role="button"
                  tabIndex={0}
                  className="flex items-center "
                  onKeyDown={handleDeleteClick}
                  onClick={handleDeleteClick}
                >
                  <IconClose
                    className="ml-2 h-5 w-5 text-neutral-400"
                    onClick={handleDeleteClick}
                    onKeyDown={handleDeleteClick}
                  />
                </div>
              </Tooltip>
            )}
            {isDeleted && !showMaster && !isSm && (
              <Tooltip
                side="bottom"
                content="Re-activate the goal for th current placement."
                delayDuration={0}
              >
                <div
                  role="button"
                  tabIndex={0}
                  className="flex items-center "
                  onKeyDown={handleUndoClick}
                  onClick={handleUndoClick}
                >
                  <Button preset="ghost" size="xs" className="flex h-5 text-neutral-400">
                    Restore
                  </Button>
                  <IconUndo className="ml-2 text-neutral-400"></IconUndo>
                </div>
              </Tooltip>
            )}
            {isDeleted && showMaster && !isSm && (
              <Tooltip
                side="bottom"
                content="Re-activate the goal for th current placement."
                delayDuration={0}
              >
                <div
                  role="button"
                  tabIndex={0}
                  className="flex items-center "
                  onKeyDown={handleUndoClick}
                  onClick={handleUndoClick}
                >
                  <Button preset="ghost" size="xs" className="flex h-5 text-neutral-400">
                    Restore
                  </Button>
                  <IconUndo className="ml-2 text-neutral-400"></IconUndo>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SingleStudentGoal;
