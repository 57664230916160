import Button from 'src/components/Button';
import { IconEmpty, IconPlus } from 'src/icons';

import StudentNoGoalsByFilter from './StudentNoGoalsByFilter';

type StudentNoGoalsProps = {
  totalGoals?: number;
  filtersCount?: number;
  studentName?: string;
  isFilterMasteredApplyied: boolean;
  onAddGoalsClicked?: () => void;
  onChangeGoalsFiltersClicked?: () => void;
};

const StudentNoGoals = ({
  totalGoals,
  filtersCount,
  studentName,
  isFilterMasteredApplyied = false,
  onAddGoalsClicked,
  onChangeGoalsFiltersClicked,
}: StudentNoGoalsProps) => {
  return (
    <div className="noGoal rounded-lg bg-neutral-950 py-6 text-center">
      {(filtersCount && filtersCount > 1) ||
      (filtersCount && filtersCount > 0 && !isFilterMasteredApplyied) ? (
        <StudentNoGoalsByFilter />
      ) : (
        <div>
          <div className="flex justify-center text-center">
            <div className="h-10 w-10 rounded-[200px] bg-secondary-900">
              <IconEmpty className="m-2 h-6 w-6 text-secondary-300" />
            </div>
          </div>
          <div className="mb-4 mt-4 typography-heading-md md:mt-6">
            {studentName ?? ''}
            {totalGoals ? null : ' has no goals :('}
          </div>

          <div className="mb-8 text-neutral-400 typography-loud-md">
            Add a minimum of 6 goals to get progress rolling.
          </div>

          <div className="flex justify-center">
            <Button Icon={IconPlus} preset="secondary" size="sm" onClick={onAddGoalsClicked}>
              Add Goals
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentNoGoals;
