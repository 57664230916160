import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { TPagination } from 'src/types/pagination.type';
import { toQueryParams } from 'src/utils/pagination.utils';

import { Student, StudentFilters, StudentsListApiResponse } from './students.types';

const PAGE_SIZE = 9;

export const studentsListApi = createApi({
  reducerPath: 'studentsListApi',
  tagTypes: ['StudentsList'],
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    studentsDict: build.query<Student[], boolean>({
      query: (showInactive) => `/myStudents/students?showInactive=${showInactive}`,
      providesTags: ['StudentsList'],
    }),

    studentsList: build.query<
      StudentsListApiResponse,
      { showInactive?: boolean } & TPagination & StudentFilters
    >({
      query: ({ page, queryString, showInactive, pageSize, ...filters }) => ({
        method: 'POST',
        url: `/myStudents/search?${toQueryParams({ page, pageSize: pageSize || PAGE_SIZE })}`,
        body: {
          queryString,
          showInactive,
          ...filters,
        },
        invalidatesTags: ['StudentsList'],
      }),
      providesTags: ['StudentsList'],
    }),
  }),
});

export const {
  useStudentsDictQuery,
  useStudentsListQuery,
  util: { invalidateTags: invalidateStudentsListTags },
} = studentsListApi;
