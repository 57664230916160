import AppliedFiltersList from 'src/filters/AppliedFiltersList';
import { useAppDispatch, useAppSelector } from 'src/store';

import { BillingMonthFilters } from './billing.types';
import { selectBillingMonthFilters, selectBillingMonthFiltersCount } from './billingSelectors';
import { setBillingMonthFilters } from './billingSlice';

const BillingMonthFiltersList = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectBillingMonthFilters);
  const filtersCount = useAppSelector(selectBillingMonthFiltersCount);

  const onChangeFilters = (filters: BillingMonthFilters) => {
    dispatch(setBillingMonthFilters(filters));
  };

  return (
    <AppliedFiltersList filters={filters} onChange={onChangeFilters} filtersCount={filtersCount} />
  );
};

export default BillingMonthFiltersList;
