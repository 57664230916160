import { CalendarApi } from '@fullcalendar/core';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { ScheduleEvent } from 'src/pages/schedule/schedule.types';

export function isRestrictedHolidayOrOutOfOffice(date: Date, calendar: CalendarApi) {
  return calendar
    .getEvents()
    .filter((f) => {
      const { eventType, isRestricted } = f.extendedProps as ScheduleEvent;
      return (isRestricted && eventType === 'Holiday') || eventType === 'OutOfOffice';
    })
    .some((f) => {
      if (!f.start || !f.end) {
        return false;
      }
      return isEqual(f.start, date) || (isBefore(f.start, date) && isAfter(f.end, date));
    });
}
