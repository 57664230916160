import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/auth/useAuth';
import { buildName } from 'src/logic/buildName';
import { useMeQuery } from 'src/pages/auth/authApi';

const useUser = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const { currentAuthenticatedUser } = useAuth();
  const { data: me, refetch: invalidate } = useMeQuery();

  const getUserInfo = useCallback(async () => {
    const data = await currentAuthenticatedUser();
    setFirstName(data.attributes.given_name);
    setLastName(data.attributes.family_name);
    setEmail(data.attributes.email);
    setFullName(
      buildName({
        firstName: data.attributes.given_name,
        lastName: data.attributes.family_name,
      }),
    );
    setAvatar(me?.avatar || '');
  }, [currentAuthenticatedUser, me]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo, me]);

  return { firstName, lastName, email, fullName, avatar, invalidate };
};

export default useUser;
