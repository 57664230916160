import Card from 'src/components/Card';
import Separator from 'src/components/Separator';
import SkeletonPiece from 'src/components/SkeletonPiece';

type BillingMonthSingleInvoiceLoadingProps = {
  className?: string;
};

const BillingMonthSingleInvoiceLoading = ({ className }: BillingMonthSingleInvoiceLoadingProps) => (
  <Card className={className}>
    <div className="mb-4 flex items-center">
      <SkeletonPiece className="mr-4 h-14 w-14 rounded-full" />
      <div className="flex flex-col gap-1">
        <SkeletonPiece className="h-6" />
        <SkeletonPiece className="h-8 w-80" />
      </div>
      <SkeletonPiece className="ml-auto h-12 w-48" />
    </div>
    <Separator className="mb-4" />
    <SkeletonPiece className="h-6 w-44" />
  </Card>
);

export default BillingMonthSingleInvoiceLoading;
