import type { SVGProps } from 'react';
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 25"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.986 7.045c0-.502.403-.909.9-.909h16.2c.497 0 .9.407.9.91 0 .502-.403.909-.9.909h-16.2a.905.905 0 0 1-.9-.91Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.186 4.318a.895.895 0 0 0-.636.266.914.914 0 0 0-.264.643v1.818c0 .503-.403.91-.9.91a.905.905 0 0 1-.9-.91V5.227c0-.723.285-1.417.791-1.928a2.686 2.686 0 0 1 1.91-.799h3.6c.715 0 1.402.287 1.908.799.507.511.791 1.205.791 1.928v1.818c0 .503-.403.91-.9.91a.905.905 0 0 1-.9-.91V5.227a.914.914 0 0 0-.263-.643.895.895 0 0 0-.637-.266h-3.6Zm-4.5 1.818c.497 0 .9.407.9.91v12.727c0 .24.095.472.264.643.169.17.398.266.636.266h9a.896.896 0 0 0 .637-.266.914.914 0 0 0 .263-.643V7.045c0-.502.403-.909.9-.909s.9.407.9.91v12.727c0 .723-.284 1.417-.79 1.928a2.686 2.686 0 0 1-1.91.799h-9a2.686 2.686 0 0 1-1.909-.799 2.741 2.741 0 0 1-.79-1.928V7.045c0-.502.402-.909.9-.909Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.186 10.682c.497 0 .9.407.9.909v5.455a.905.905 0 0 1-.9.909.905.905 0 0 1-.9-.91v-5.454c0-.502.403-.91.9-.91ZM12.786 10.682c.497 0 .9.407.9.909v5.455a.905.905 0 0 1-.9.909.905.905 0 0 1-.9-.91v-5.454c0-.502.403-.91.9-.91Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrash;
