import * as Sentry from '@sentry/react';
import { useState } from 'react';
import Smartlook from 'smartlook-client';
import Button from 'src/components/Button';
import SelectField from 'src/components/SelectField';
import TextAreaField from 'src/components/TextAreaField';
import useUser from 'src/hooks/useUser';
import { IconClose } from 'src/icons';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';

import { useSubmitTicketMutation } from './contactSupportApi';

const VITE_SMARTLOOK_PROJECT_KEY = import.meta.env.VITE_SMARTLOOK_PROJECT_KEY;

const CategoryOptions = [
  { label: 'Tech Support', value: 'Tech Support' },
  { label: 'Placement Inquiry', value: 'Placement Inquiry' },
  { label: 'Finance Inquiry', value: 'Finance Inquiry' },
  { label: 'Other', value: 'Other' },
];

type ContactSupportFormProps = {
  onClose: () => void;
  onMenuClose?: () => void;
};

const ContactSupportForm = ({ onClose, onMenuClose }: ContactSupportFormProps) => {
  const dispatch = useAppDispatch();
  const [submit, { isLoading }] = useSubmitTicketMutation();
  const { email, firstName, lastName } = useUser();
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>();

  const onSubmit = async () => {
    setErrors(undefined);

    if (!category) {
      setErrors({ category: 'Category is missing' });
      return;
    }

    if (!message) {
      setErrors({ message: 'Message is missing' });
      return;
    }

    const isValidSmartlookKey =
      VITE_SMARTLOOK_PROJECT_KEY && VITE_SMARTLOOK_PROJECT_KEY !== 'false';

    try {
      await submit({
        category,
        message,
        email,
        firstName,
        lastName,
        smartLookSessionLink: isValidSmartlookKey ? Smartlook.playUrl : '',
        errorId: Sentry.lastEventId(),
        sessionId: isValidSmartlookKey ? Smartlook.sessionId : '',
      });

      dispatch(
        addToast({
          text: 'Tada! \n Your ticket was submitted. \n An ES service rep will be in touch with you via email. We look forward to resolving your matter within 24 hours.',
          type: 'positive',
        }),
      );
      onClose();
      onMenuClose && onMenuClose();
    } catch (error) {
      dispatch(addToast({ text: 'Something went wrong. Try again later.', type: 'negative' }));
    }
  };

  return (
    <div className="h-screen flex-col items-center justify-center px-6 pb-6 pt-4.5 text-neutral-100 sm:h-auto sm:w-notifications-dropdown">
      <div className="mb-2 hidden items-center justify-between sm:flex">
        <div className="typography-heading-md">Submit a ticket</div>
        <Button Icon={IconClose} preset="ghost" size="sm" onClick={onClose} />
      </div>
      <div className="mb-6 text-neutral-300 typography-loud-sm">Iffy? Assistance in a jiffy!</div>
      <SelectField
        label="Category"
        options={CategoryOptions}
        placeholder={'How can we help you today?'}
        className="mb-4"
        value={category}
        onValueChange={setCategory}
        error={errors?.category}
      />
      <TextAreaField
        label="Message"
        className="mb-6"
        placeholder="Share some details with us."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        error={errors?.message}
      />
      <div className="absolute bottom-0 left-0 right-0 border-t border-t-neutral-800 p-4 sm:static sm:border-none sm:p-0">
        <Button
          className="w-full sm:w-auto"
          preset="secondary"
          isLoading={isLoading}
          onClick={onSubmit}
        >
          Submit Ticket
        </Button>
      </div>
    </div>
  );
};

export default ContactSupportForm;
