import type { SVGProps } from 'react';
const SvgElipseDocks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 53"
    {...props}
  >
    <circle cx={9.366} cy={9.366} r={9.366} fill="url(#elipse_docks_svg__a)" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28.488 9.756h-15.61a3.902 3.902 0 0 0-3.902 3.902v31.22a3.903 3.903 0 0 0 3.902 3.903h23.415a3.902 3.902 0 0 0 3.902-3.903V21.463L28.488 9.756Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28.488 9.756v11.707h11.707"
    />
    <path
      fill="currentColor"
      d="M20.683 29.268a1.951 1.951 0 1 1-3.903 0 1.951 1.951 0 0 1 3.903 0ZM32.39 29.268a1.951 1.951 0 1 1-3.902 0 1.951 1.951 0 0 1 3.902 0ZM18.732 37.073h11.707v1.951H18.732v-1.95Z"
    />
    <defs>
      <linearGradient
        id="elipse_docks_svg__a"
        x1={9.366}
        x2={9.366}
        y1={0}
        y2={18.732}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset={1} stopColor="currentColor" stopOpacity={0.2} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgElipseDocks;
