import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { toQueryParams } from 'src/utils/pagination.utils';

import { TPagination } from '../../types/pagination.type';
import {
  InvoiceOnList,
  InvoiceOnListRequestPayload,
  InvoiceSignRequest,
  InvoicesToSignRequest,
  InvoiceToSign,
} from './principal.types';

export const principalApi = createApi({
  reducerPath: 'principalApi',
  baseQuery,
  tagTypes: ['InvoiceDetails', 'InvoiceList'],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    invoices: build.query<InvoiceOnList, InvoiceOnListRequestPayload & TPagination>({
      query: ({ pageSize = 999, page = 1, ...body }) => ({
        method: 'POST',
        url: `/invoice/approver/list?${toQueryParams({ pageSize, page })}`,
        body: body,
      }),
      providesTags: ['InvoiceList'],
    }),
    invoicesToSignDetails: build.query<InvoiceToSign, InvoicesToSignRequest>({
      query: (body) => ({
        method: 'POST',
        url: `/invoice/approver/sign/details`,
        body,
      }),
      providesTags: ['InvoiceDetails'],
    }),
    signInvoices: build.mutation<any, InvoiceSignRequest>({
      query: (body) => ({
        url: `/invoice/approver/sign`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InvoiceList', 'InvoiceDetails'],
    }),
  }),
});

export const { useInvoicesQuery, useInvoicesToSignDetailsQuery, useSignInvoicesMutation } =
  principalApi;
