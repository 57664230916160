export const base64ToBlob = (base64: string): Blob => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes]);
};

export const getFileNameFromHeaders = (headers: Headers): string | undefined => {
  const contentDisposition = headers.get('content-disposition');
  let fileNamePart = contentDisposition?.split(';').find((part) => part.includes('filename='));
  fileNamePart = fileNamePart?.split('=')[1];
  fileNamePart = fileNamePart?.replace(/["]/g, '');
  fileNamePart = fileNamePart?.replace('filename=', '');
  return fileNamePart?.replace(/^\s+|\s+$/g, '');
};

export const downloadBlob = (blob: Blob, fileName: string): void => {
  const blobURL = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobURL;
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(blobURL);
};
