import { cva, VariantProps } from 'class-variance-authority';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { TabItem, TabsProps } from './Tabs';

const tabButtonStyles = cva('typography-loud-sm box-border border-b-2 h-10 pt-1', {
  variants: {
    size: {
      md: 'typography-loud-sm px-1',
      sm: 'typography-loud-xs px-4',
    },
    active: {
      true: 'text-neutral-black border-b-primary-500',
      false: 'text-neutral-400 border-b-transparent',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

type TabButtonProps = VariantProps<typeof tabButtonStyles> &
  Pick<TabItem, 'id' | 'to'> &
  Pick<TabsProps, 'onSelect'> & {
    active?: boolean;
  } & {
    children: ReactNode;
  };

const Item = (
  props: VariantProps<typeof tabButtonStyles> &
    Partial<Pick<TabItem, 'id'>> & {
      children: ReactNode;
      onClick?: (s: string) => void;
    },
) => {
  if (props.onClick && props.id) {
    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.id && props.onClick?.(props.id);
          }
        }}
        onClick={() => {
          props.id && props.onClick?.(props.id);
        }}
        className={tabButtonStyles(props)}
      >
        {props.children}
      </div>
    );
  }
  return <div className={tabButtonStyles(props)}>{props.children}</div>;
};

const TabButton = ({ id, children, size, to, active, onSelect }: TabButtonProps) => {
  if (to) {
    return (
      <NavLink to={to}>
        {({ isActive }) => (
          <Item size={size} active={isActive}>
            {children}
          </Item>
        )}
      </NavLink>
    );
  }
  return (
    <Item onClick={onSelect} size={size} id={id} active={active}>
      {children}
    </Item>
  );
};

export default TabButton;
