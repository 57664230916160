import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StudentFilters } from './students.types';

type StudentsState = {
  page: number;
  query?: string;
  filters: StudentFilters;
  showInactive: boolean;
};

const initialState: StudentsState = {
  page: 1,
  filters: {},
  showInactive: false,
};

const studentsPageSlice = createSlice({
  name: 'studentsPage',
  initialState,
  reducers: {
    setStudentsPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setStudentsQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
      state.page = initialState.page;
    },
    setStudentsFilters(state, action: PayloadAction<StudentsState['filters']>) {
      state.filters = action.payload;
      state.page = initialState.page;
    },
    setShowInactiveStudents(state, action: PayloadAction<boolean>) {
      state.showInactive = action.payload;
      state.page = initialState.page;
    },
    resetStudents() {
      return initialState;
    },
  },
});

export const {
  setStudentsQuery,
  setStudentsPage,
  setStudentsFilters,
  resetStudents,
  setShowInactiveStudents,
} = studentsPageSlice.actions;

export default studentsPageSlice;
