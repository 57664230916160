import { ComponentType, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from './Button';

type HorizontalTabsButtonsProps = {
  tabs: {
    value: string;
    label?: string;
    Icon?: ComponentType<SVGProps<SVGSVGElement>>;
    to?: string;
  }[];
  activeTab?: string;
  onSelect?: (v: string) => void;
};

const HorizontalTabsButtons = ({ tabs, activeTab, onSelect }: HorizontalTabsButtonsProps) => (
  <div className="box-border flex h-12 items-center justify-between gap-2 rounded-lg border border-neutral-800 bg-neutral-white p-2">
    {tabs.map((tab) => (
      <Button
        key={tab.value}
        preset={activeTab !== tab.value ? 'tertiary' : 'secondary'}
        onClick={() => onSelect && onSelect(tab.value)}
        size="xs"
        className={twMerge(
          'h-8 grow border-none px-2 shadow-none hover:bg-secondary-300 hover:text-neutral-white md:h-8 md:px-2',
          activeTab === tab.value ? 'bg-secondary-400' : '',
        )}
        Icon={tab.Icon}
        iconClassName="h-6 w-6"
        to={tab.to}
      >
        {tab?.label && <span className="typography-loud-sm">{tab?.label}</span>}
      </Button>
    ))}
  </div>
);

export default HorizontalTabsButtons;
