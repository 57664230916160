import { useRef, useState } from 'react';
import Button from 'src/components/Button';
import EmptyState from 'src/components/EmptyState';
import Pagination from 'src/components/Pagination';
import Search from 'src/components/Search';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { useSignatureStatus } from 'src/hooks/useSignatureStatus';
import { routes } from 'src/routes';
import { useAppDispatch, useAppSelector } from 'src/store';
import { ListApiResponse } from 'src/types/list-api-response';
import { getPageCount } from 'src/utils/pagination.utils';

import { BillingMonthSingleInvoiceBase } from '../billing/BillingMonthSingleInvoice';
import BillingMonthSingleInvoiceLoading from '../billing/BillingMonthSingleInvoiceLoading';
import InvoicesFilters, { FiltersState } from './InvoicesFilters';
import InvoicesSignatureModal, { InvoicesSignatureModalRefProps } from './InvoicesSignatureModal';
import { InvoiceOn } from './principal.types';
import { useInvoicesQuery } from './principalApi';
import { selectPrincipalQuery } from './principalSelectors';
import { setPrincipalQuery } from './principalSlice';

type InvoicesToSignProps = {
  hideFilters?: boolean;
};

const InvoicesToSign = ({ hideFilters }: InvoicesToSignProps) => {
  const [filtersState, setFiltersState] = useState<{
    filters: FiltersState;
    appliedFiltersCount: number;
  }>({ filters: {}, appliedFiltersCount: 0 });
  const query = useAppSelector(selectPrincipalQuery);
  const [page, setPage] = useState(1);
  const { isFetching, data: invoices } = useInvoicesQuery({ page, query, ...filtersState.filters });

  const dispatch = useAppDispatch();
  const ref = useRef<InvoicesSignatureModalRefProps>(null);
  const { isMd } = useBreakpoint('md');
  const { status } = useSignatureStatus();

  const pagesCount = getPageCount(invoices as ListApiResponse<InvoiceOn>);

  return (
    <div className="p-4 md:p-10">
      <InvoicesSignatureModal ref={ref} />
      <div>
        <div className="flex flex-col py-6 md:flex-row md:items-start md:items-center">
          <div className="flex items-center justify-between">
            Invoices: {invoices?.total || 0}
            {!hideFilters && !isMd && (
              <div className="flex gap-x-4">
                <Search
                  onChange={(e) => dispatch(setPrincipalQuery(e.target.value))}
                  size="sm"
                  value={query}
                  active={false}
                  className="w-10 md:w-80"
                />
                <InvoicesFilters
                  onApply={(filters, count) =>
                    setFiltersState({ filters, appliedFiltersCount: count })
                  }
                  appliedFiltersCount={filtersState.appliedFiltersCount}
                />
              </div>
            )}
          </div>

          {invoices && invoices?.total > 0 && (
            <div className="mt-4 flex gap-4 md:ml-auto md:mt-0">
              <Button
                to={routes.principal.invoices.submitAll}
                disabled={!invoices?.total || status !== 'Approved'}
                className="w-full md:w-auto"
              >
                Sign All Invoices
              </Button>
              {!hideFilters && isMd && (
                <>
                  <Search
                    onChange={(e) => dispatch(setPrincipalQuery(e.target.value))}
                    size="md"
                    value={query}
                    className="w-80"
                  />
                  <InvoicesFilters
                    onApply={(filters, count) =>
                      setFiltersState({ filters, appliedFiltersCount: count })
                    }
                    appliedFiltersCount={filtersState.appliedFiltersCount}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {isFetching && (
          <div className="flex flex-col gap-4">
            {Array.from({ length: 3 }, (_, index) => (
              <BillingMonthSingleInvoiceLoading
                key={index}
                className={index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : ''}
              />
            ))}
          </div>
        )}
        {!isFetching && invoices && invoices?.total > 0 && (
          <div className="flex flex-col gap-6">
            {invoices &&
              invoices.items.map((invoice) => (
                <BillingMonthSingleInvoiceBase
                  key={invoice.id}
                  {...invoice}
                  onFinalize={() => ref.current && ref.current?.open({ ids: [invoice.id] })}
                  showMonth
                  principalView
                />
              ))}
            <div className="flex items-center justify-between">
              <Pagination
                itemsPerPage={invoices.pageSize}
                totalSize={invoices.total}
                pagesCount={pagesCount}
                activePage={page}
                onChangeActivePage={setPage}
                searchingItemsPlaceholder="Invoices"
              />
            </div>
          </div>
        )}
        {!isFetching && invoices?.total === 0 && (
          <EmptyState title="Hooray!" description="No pending invoices." />
        )}
      </div>
    </div>
  );
};

export default InvoicesToSign;
