import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BillingInvoicesFilters, BillingMonthFilters } from './billing.types';

type BillingState = {
  query?: string;
  monthFilters: BillingMonthFilters;
  invoicesFilters: BillingInvoicesFilters;
  activeSchoolYearIndex?: number;
  activeMonthIndex: number;
  invoicesIdsForSignature?: string[];
};

const initialState: BillingState = {
  monthFilters: {},
  invoicesFilters: {},
  activeSchoolYearIndex: undefined,
  activeMonthIndex: 0,
};

const studentsPageSlice = createSlice({
  name: 'billingPage',
  initialState,
  reducers: {
    setBillingMonthQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    setBillingMonthFilters(state, action: PayloadAction<BillingState['monthFilters']>) {
      state.monthFilters = action.payload;
    },
    setBillingInvoicesFilters(state, action: PayloadAction<BillingState['invoicesFilters']>) {
      state.invoicesFilters = action.payload;
    },
    setActiveSchoolYearIndex(state, action: PayloadAction<number>) {
      state.activeSchoolYearIndex = action.payload;
    },
    setActiveMonthIndex(state, action: PayloadAction<number>) {
      state.activeMonthIndex = action.payload;
    },
    setInvoicesIdsForSignature(state, action: PayloadAction<string[] | undefined>) {
      state.invoicesIdsForSignature = action.payload;
    },
    resetBilling() {
      return initialState;
    },
  },
});

export const {
  setBillingMonthQuery,
  setBillingMonthFilters,
  setBillingInvoicesFilters,
  setActiveSchoolYearIndex,
  setActiveMonthIndex,
  setInvoicesIdsForSignature,
  resetBilling,
} = studentsPageSlice.actions;

export default studentsPageSlice;
