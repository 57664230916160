import AppliedFiltersList from 'src/filters/AppliedFiltersList';
import { useAppDispatch, useAppSelector } from 'src/store';

import { BillingMonthFilters } from './billing.types';
import {
  selectBillingInvoicesFilters,
  selectBillingInvoicesFiltersCount,
} from './billingSelectors';
import { setBillingMonthFilters } from './billingSlice';

const BillingInvoicesFiltersList = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectBillingInvoicesFilters);
  const filtersCount = useAppSelector(selectBillingInvoicesFiltersCount);

  const onChangeFilters = (filters: BillingMonthFilters) => {
    dispatch(setBillingMonthFilters(filters));
  };

  return (
    <AppliedFiltersList filters={filters} onChange={onChangeFilters} filtersCount={filtersCount} />
  );
};

export default BillingInvoicesFiltersList;
