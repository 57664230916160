import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import useUser from 'src/hooks/useUser';
import ChevronRight from 'src/icons/ChevronRight';
import { pasteAsPlainText } from 'src/utils/contentEditable.utils';

export type StudentAddNoteProps = {
  isLoading: boolean;
  saveNote: (content: string) => void;
  className?: string;
};

const StudentAddNote = ({ isLoading, className, saveNote }: StudentAddNoteProps) => {
  const { firstName, lastName, avatar } = useUser();
  const [content, setContent] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const contendtEditable = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && contendtEditable.current) {
      contendtEditable.current.textContent = '';
      setContent('');
    }
  }, [isLoading]);

  return (
    <div
      className={clsx(
        'relative rounded-lg border border-neutral-800 bg-neutral-white px-4 py-3',
        className,
        {
          'border-secondary-500': isFocus,
        },
      )}
    >
      <Avatar
        size="xs"
        firstName={firstName}
        lastName={lastName}
        url={avatar}
        className="absolute top-4 mr-4"
      />

      <div className="flex w-full flex-wrap pl-10 outline-0">
        <div
          contentEditable={!isLoading}
          ref={contendtEditable}
          className="min-h-[32px] min-w-[calc(100%-105px)] pt-1 outline-0 typography-loud-sm"
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onPaste={pasteAsPlainText}
          onInput={(e) => setContent(e.currentTarget.innerText)}
        ></div>
        {!content && (
          <div
            className="absolute top-4 text-neutral-500 typography-loud-sm"
            onClick={() => contendtEditable?.current?.focus()}
            aria-hidden={true}
          >
            Add note...
          </div>
        )}
        <Button
          size="xs"
          preset="secondary"
          className="ml-auto self-end"
          isLoading={isLoading}
          onClick={() => saveNote(content)}
        >
          <div className="flex">
            Save
            <ChevronRight fontSize="16" className="ml-2" />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default StudentAddNote;
