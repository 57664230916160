import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ScrollAreaProps = {
  children: ReactNode;
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  indicatorHidden?: boolean;
};

const ScrollArea = ({ orientation, indicatorHidden, children, className }: ScrollAreaProps) => (
  <RadixScrollArea.Root className={clsx('overflow-hidden', className)}>
    <RadixScrollArea.Viewport className="h-full w-full">{children}</RadixScrollArea.Viewport>
    <RadixScrollArea.Scrollbar
      className={twMerge(
        clsx(
          'flex touch-none select-none rounded-xl bg-neutral-black/10 p-0.5 transition-colors hover:bg-neutral-black/20',
          orientation === 'horizontal' ? 'h-2.5 flex-col' : 'w-2.5',
          indicatorHidden && 'h-0 w-0 bg-transparent',
        ),
      )}
      orientation={orientation || 'vertical'}
    >
      <RadixScrollArea.Thumb className="relative flex-1 rounded-lg bg-neutral-500" />
    </RadixScrollArea.Scrollbar>
    <RadixScrollArea.Corner />
  </RadixScrollArea.Root>
);

export default ScrollArea;
