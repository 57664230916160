export type NotificationType =
  | 'NewStudentAssigned'
  | 'SetupStudentGoals'
  | 'SignatureRejected'
  | 'SignatureAccepted'
  | 'PrincipalAddedAsSigner '
  | 'ApproverSignedInvoices';

export type NotificationPriority = 'Information' | 'Warning' | 'Critical';

export enum NotificationGroup {
  ACTIONABLE = 'Actionable',
  INFORMATIONAL = 'Informational',
}

export type Notification = {
  id: string;
  title: string;
  content: string;
  createdAt: string;
  isRead: boolean;
  readAt: string;
  type: NotificationType;
  priority: NotificationPriority;
  group: NotificationGroup;
  data: Record<string, string>;
};

export type Notifications = {
  items: Notification[];
  scrollId?: number;
  totalCount: number;
  unreadCount: number;
};

export enum EAblyEventTypes {
  NEW_STUDENT_ASSIGNED = 'NewStudentAssigned',
  SETUP_STUDENT_GOALS = 'SetupStudentGoals',
  SIGNATURE_REJECTED = 'SignatureRejected',
  SIGNATURE_ACCEPTED = 'SignatureAccepted',
  PRINCIPAL_ADDED_AS_SIGNER = 'PrincipalAddedAsSigner',
  APPROVER_SIGNED_INVOICES = 'ApproverSignedInvoices',
}

export type AblyEvent = {
  Content: string;
  CreatedAt: number;
  Id: string;
  IsRead: boolean;
  Priority: number;
  Title: string;
  Type: EAblyEventTypes;
};
