import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-scroll';
import { twMerge } from 'tailwind-merge';

type ScrollMenuProps = {
  items: {
    label: string;
    name: string;
  }[];
  className?: string;
};

const ScrollMenu = ({ items, className }: ScrollMenuProps) => {
  const [activeLinkIndex, setActiveLinkIndex] = useState(0);

  const activeClassName = 'border-l-primary-500 border-l-2 text-neutral-100 -ml-0.5';

  return (
    <div
      className={twMerge(
        'sticky top-0 flex flex-col gap-6 border-l border-neutral-800 pl-0.25 text-neutral-300 typography-body-md',
        className,
      )}
    >
      {items.map((item, index) => {
        return (
          <Link
            key={item.label}
            to={item.name}
            className={clsx(
              'cursor-pointer pl-4 text-left',
              !activeLinkIndex && !index && activeClassName,
            )}
            activeClass={activeClassName}
            duration={300}
            spy
            smooth
            offset={-20}
            onSetActive={() => setActiveLinkIndex(index)}
          >
            {item.label}
          </Link>
        );
      })}
    </div>
  );
};

export default ScrollMenu;
