import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import { IconChevronDown, IconChevronUp } from 'src/icons';
import { twMerge } from 'tailwind-merge';

import SelectItem from './SelectItem';
import { textInputIconStyles, TextInputProps, textInputStyles } from './TextInput';

export type SelectProps = Omit<SelectPrimitive.SelectProps, 'value'> &
  Omit<SelectPrimitive.SelectValueProps, 'value'> &
  SelectPrimitive.PopperContentProps & {
    options?: { label: string; value: string }[];
    size?: TextInputProps['size'];
    multiple?: boolean;
    value?: string[] | string | undefined;
    contentClassName?: string;
    hideArrow?: boolean;
    allowWiderDropdown?: boolean;
    formatSelectedLabel?: (value?: string) => string;
  };

const formatValueForTrigger = (
  value: string | string[],
  options: { label: string; value: string }[],
) => {
  if (Array.isArray(value)) {
    return value
      .map((selectedValue, index) => {
        const label = options.find((o) => o.value === selectedValue)?.label;
        return label ? label + (index < value.length - 1 ? ', ' : '') : '';
      })
      .join('');
  } else {
    return options.find((o) => o.value === value)?.label || '';
  }
};

const Select = ({
  value,
  onValueChange,
  options,
  placeholder,
  size,
  className,
  contentClassName,
  multiple,
  disabled,
  hideArrow,
  allowWiderDropdown,
  align,
  formatSelectedLabel,
}: SelectProps) => {
  const formattedValue =
    (formatSelectedLabel && value && !Array.isArray(value) && formatSelectedLabel(value)) ||
    (options && value && formatValueForTrigger(value, options)) ||
    placeholder;

  return (
    <SelectPrimitive.Root
      value={value ? (value as string) : undefined}
      onValueChange={onValueChange}
      disabled={disabled}
    >
      <SelectPrimitive.Trigger
        disabled={disabled}
        className={twMerge(
          'relative text-left data-[placeholder]:text-neutral-500',
          textInputStyles({ size }),
          className,
        )}
      >
        <SelectPrimitive.Value
          placeholder={formattedValue || placeholder || 'Select'}
          className="text-left"
        >
          <div className={clsx('truncate', { 'pr-6': !hideArrow })}>{formattedValue}</div>
        </SelectPrimitive.Value>
        {!hideArrow && (
          <SelectPrimitive.Icon>
            <IconChevronDown className={textInputIconStyles({ position: 'sufix', size })} />
          </SelectPrimitive.Icon>
        )}
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          className={twMerge(
            clsx(
              'z-portal max-h-[--radix-select-content-available-height] overflow-hidden rounded-lg border border-neutral-800 bg-neutral-white p-1.5 shadow-elevation-4',
              { 'w-[--radix-select-trigger-width]': !allowWiderDropdown },
              contentClassName,
            ),
          )}
          position="popper"
          align={align}
          sideOffset={5}
        >
          <SelectPrimitive.ScrollUpButton className="flex h-6 cursor-default items-center justify-center bg-neutral-white">
            <IconChevronUp />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>
            {options?.map((o) => (
              <SelectItem
                key={o.value}
                value={o.value}
                multiple={multiple}
                selected={value?.includes(o.value)}
              >
                {o.label}
              </SelectItem>
            ))}
          </SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className="flex h-6 cursor-default items-center justify-center bg-neutral-white">
            <IconChevronDown />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
};

Select.defaultProps = {
  size: 'sm' as TextInputProps['size'],
};

export default Select;
