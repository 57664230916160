import { useEffect } from 'react';
import LeftRightButtons from 'src/components/LeftRightButtons';
import SkeletonPiece from 'src/components/SkeletonPiece';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { useAppDispatch, useAppSelector } from 'src/store';

import { useBillingSummaryQuery } from './billingApi';
import {
  selectBillingActiveSchoolYear,
  selectBillingActiveSchoolYearIndex,
} from './billingSelectors';
import { setActiveMonthIndex, setActiveSchoolYearIndex } from './billingSlice';

type BillingSchoolYearPickerProps = {
  isFetching?: boolean;
};

const BillingSchoolYearPicker = ({ isFetching }: BillingSchoolYearPickerProps) => {
  const dispatch = useAppDispatch();
  const activeSchoolYearIndex = useAppSelector(selectBillingActiveSchoolYearIndex);
  const activeSchoolYear = useAppSelector(selectBillingActiveSchoolYear);
  const { data: billingSummary } = useBillingSummaryQuery({ query: '' });
  const { isSm } = useBreakpoint('sm');
  const { isXl } = useBreakpoint('xl');

  useEffect(() => {
    if (activeSchoolYearIndex === undefined && billingSummary?.length) {
      dispatch(setActiveSchoolYearIndex(billingSummary?.length - 1));
    }
  }, [activeSchoolYearIndex, billingSummary?.length, dispatch]);

  const onNextYear = () => {
    dispatch(setActiveSchoolYearIndex((activeSchoolYearIndex || 0) + 1));
    dispatch(setActiveMonthIndex(0));
  };

  const onPrevYear = () => {
    dispatch(setActiveSchoolYearIndex((activeSchoolYearIndex || 0) - 1));
    dispatch(setActiveMonthIndex(0));
  };

  return (
    <div className="flex flex-col-reverse md:flex-row md:items-center md:px-0">
      <LeftRightButtons
        handleNext={onNextYear}
        handlePrevious={onPrevYear}
        prevDisabled={activeSchoolYearIndex === 0}
        nextDisabled={(activeSchoolYearIndex || 0) >= (billingSummary?.length || 0) - 1}
        size={isSm ? 'md' : 'sm'}
      />
      {isXl && (
        <div className=" typography-heading-lg md:ml-4">
          {isFetching ? (
            <SkeletonPiece className="md:h-7 md:w-72" />
          ) : (
            `School Year ${activeSchoolYear?.schoolYearStart || '2023'}/${
              activeSchoolYear?.schoolYearEnd || '2024'
            }`
          )}
        </div>
      )}
    </div>
  );
};

export default BillingSchoolYearPicker;
