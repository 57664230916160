import { Link } from 'react-router-dom';
import Logo from 'src/components/Logo';
import UserDropdown from 'src/components/UserDropdown';

const SimpleHeader = ({ withoutProfile }: { withoutProfile?: boolean }) => {
  return (
    <div className="flex w-full justify-between border-b-2 border-solid  border-b-neutral-800 bg-neutral-white p-4 px-6">
      <Link to="/">
        <Logo />
      </Link>
      <UserDropdown withoutProfile={withoutProfile} onlyAvatar side="bottom" />
    </div>
  );
};

export default SimpleHeader;
