import clsx from 'clsx';
import { ComponentType, forwardRef, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';

type NavigationButtonProps = React.ComponentProps<'button'> & {
  isActive?: boolean;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  className?: string;
  iconClassName?: string;
  isCollapsed?: boolean;
  rotateIcon?: boolean;
  count?: number;
};

const NavigationButton = forwardRef<HTMLButtonElement, NavigationButtonProps>(
  (
    { isActive, Icon, label, className, iconClassName, isCollapsed, rotateIcon, count, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      className={clsx(
        'group mx-auto mb-2 flex h-12 items-center gap-3 rounded-lg p-3.5 text-neutral-200 typography-loud-xs hover:bg-neutral-950 md:mb-0',
        {
          'active bg-neutral-950': isActive && !count,
          active: isActive && count,
        },
        isCollapsed ? 'w-12' : 'w-full',
        className,
      )}
      {...props}
    >
      {Icon && (
        <span className="relative">
          <Icon
            className={twMerge(
              'h-5 w-5 shrink-0 text-neutral-200 transition-all group-[.active]:text-secondary-400 md:text-neutral-500',
              rotateIcon ? (isCollapsed ? 'rotate-180' : 'rotate-0') : '',
              iconClassName,
            )}
          />
          {count && (
            <div
              className={clsx(
                'absolute rounded-full bg-primary-500 text-neutral-white transition-all typography-body-xs',
                isCollapsed ? '-right-2.5 -top-2.5 h-4 w-4' : '-top-0.5 right-0.25 h-2 w-2',
              )}
            >
              <span
                className={clsx('transition-all', isCollapsed ? 'opacity-100' : 'none opacity-0')}
              >
                {count}
              </span>
            </div>
          )}
        </span>
      )}
      <span
        className={clsx(
          'whitespace-nowrap transition-opacity',
          isCollapsed ? 'opacity-0' : 'opacity-1',
        )}
      >
        {label}
      </span>
    </button>
  ),
);
NavigationButton.displayName = 'NavigationButton';

export default NavigationButton;
