import clsx from 'clsx';
import { useEffect, useState } from 'react';
import SetSignatureModal from 'src/components/SetSignatureModal';
import { useIsAdmin } from 'src/hooks/useIsAdmin';
import { useSignatureStatus } from 'src/hooks/useSignatureStatus';
import { IconDraw, IconInfo } from 'src/icons';
import {
  selectSignatureAcceptedEvent,
  selectSignatureRejectedEvent,
} from 'src/notification/ablyNotificationsSelector';
import { clearAblyEvent } from 'src/notification/ablyNotificationsSlice';
import { EAblyEventTypes } from 'src/notification/notification.types';
import { SignatureStatus } from 'src/pages/auth/auth.types';
import { useMeQuery } from 'src/pages/auth/authApi';
import { useAppDispatch, useAppSelector } from 'src/store';

import Button from './Button';

const SignatureStatusBar = () => {
  const { status } = useSignatureStatus();
  const [showModal, setShowModal] = useState(status !== 'PendingApproval');
  const isAdmin = useIsAdmin();
  const { refetch: refeshMe } = useMeQuery();
  const dispatch = useAppDispatch();
  const rejectedEvent = useAppSelector(selectSignatureRejectedEvent);
  const acceptedEvent = useAppSelector(selectSignatureAcceptedEvent);

  useEffect(() => {
    if (rejectedEvent || acceptedEvent) {
      dispatch(clearAblyEvent(EAblyEventTypes.SIGNATURE_REJECTED));
      dispatch(clearAblyEvent(EAblyEventTypes.SIGNATURE_ACCEPTED));
      refeshMe();
    }
  }, [rejectedEvent, acceptedEvent, dispatch, refeshMe]);

  if (isAdmin || status === 'Approved') return null;

  return (
    <div
      className={clsx(
        'flex min-h-16 w-full flex-col items-start justify-between gap-4 px-4 py-4 md:flex-row md:items-center md:px-10 md:py-0',
        {
          'bg-secondary-900': !status,
          'bg-accent-selective-yellow-800': status === 'PendingApproval',
          ' bg-accent-tomato-800 md:flex-row': status === 'Rejected',
        },
      )}
    >
      <div className="flex">
        {!status && <IconDraw className="h-5 w-5 " />}
        {status && (
          <IconInfo
            className={clsx('h-5 w-5', {
              'text-accent-selective-yellow-500': status === 'PendingApproval',
              'text-primary-400': status === 'Rejected',
            })}
          />
        )}
        <div className="pl-2 text-neutral-100 typography-loud-sm md:typography-loud-md">
          {status ? STATUS_TO_MSG_MAP[status] : NO_STATUS_MSG}
        </div>
      </div>
      {(!status || status === 'Rejected') && (
        <Button size="sm" preset="secondary" onClick={() => setShowModal(true)} className="ml-4">
          Add Signature
        </Button>
      )}
      {showModal && <SetSignatureModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

const STATUS_TO_MSG_MAP: Record<SignatureStatus, string> = {
  PendingApproval: 'Signature pending approval. Approval will enable invoice finalization.',
  Rejected: `Signature rejected. Add new signature to enable invoice finalization.`,
  Approved: '',
};

const NO_STATUS_MSG =
  'No signature uploaded to system. Add signature to enable invoice finalization.';

export default SignatureStatusBar;
