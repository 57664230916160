import { SlotLaneContentArg } from '@fullcalendar/core';
import { addDays, addMilliseconds } from 'date-fns';
import { isRestrictedHolidayOrOutOfOffice } from 'src/utils/isRestrictedHolidayOrOutOfOffice';

import { RecordSessionButton } from '../../pages/schedule/record-session/RecordSessionButton';

type SlotLaneRecordSessionProps = SlotLaneContentArg & {
  onClick: (selectedDate: Date) => void;
  disableSessionRecordHover?: (date: Date) => boolean;
};

const SlotLaneRecordSession = (props: SlotLaneRecordSessionProps) => {
  if (!props.time) {
    return null;
  }
  const checkIsWorkingDay = (date: Date) =>
    !isRestrictedHolidayOrOutOfOffice(date, props.view.calendar);

  const baseDate = addMilliseconds(props.view.activeStart, props.time.milliseconds);

  if (props.view.type === 'timeGridDay') {
    const isWorkingDay = checkIsWorkingDay(baseDate);
    if (isWorkingDay && !props.disableSessionRecordHover?.(baseDate)) {
      return (
        <div className="p-1">
          <RecordSessionButton onClick={() => props.onClick(baseDate)} />
        </div>
      );
    }
  }

  if (props.view.type === 'timeGridWeek') {
    return (
      <div className="flex w-full justify-between">
        {Array(7)
          .fill(0)
          .map((_, index) => addDays(baseDate, index))
          .map((date) => {
            const isWorkingDay = checkIsWorkingDay(date);
            if (!isWorkingDay || props.disableSessionRecordHover?.(date)) {
              return <div className=" w-full flex-grow p-1" key={date.getTime()}></div>;
            }
            return (
              <div key={date.getTime()} className="flex w-full flex-grow p-1">
                <RecordSessionButton
                  className="w-full justify-start"
                  onClick={() => props.onClick(date)}
                />
              </div>
            );
          })}
      </div>
    );
  }
  return null;
};

export default SlotLaneRecordSession;
