import { useState } from 'react';
import { useAuth } from 'src/auth/useAuth';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import PasswordField from 'src/components/PasswordField';
import { UploadAvatar } from 'src/components/UploadAvatar';
import { useUploadImage } from 'src/hooks/useUploadImage';
import useUser from 'src/hooks/useUser';
import { IconChevronLeft } from 'src/icons';
import { routes } from 'src/routes';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { createFormData } from 'src/utils/createFormData';

import { useGetProfileSettingsQuery, useUpdateProfileSettingsMutation } from './settingsApi';

const ProfileSettings = () => {
  const [passwordData, setPasswordData] = useState({ current: '', new: '', verify: '' });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { data } = useGetProfileSettingsQuery();
  const [submit] = useUpdateProfileSettingsMutation();
  const { file, filePreviewUrl, handleFileSelect: handleAvatarChange } = useUploadImage();
  const dispatch = useAppDispatch();
  const { currentAuthenticatedUser, changePassword: authChangePassword } = useAuth();
  const { invalidate: invalidateAvatar } = useUser();
  const passwordMatchErrorMessage = !passwordMatch ? 'Passwords do not match' : undefined;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await currentAuthenticatedUser();
      await authChangePassword(user, oldPassword, newPassword);

      dispatch(
        addToast({
          text: 'Password has been changed',
          type: 'positive',
        }),
      );
    } catch (error: any) {
      const message = error?.message as string;
      setErrors({ changePassword: message });
    }
  };

  const updateProfile = async (data) => {
    try {
      await submit(createFormData(data)).unwrap();
      invalidateAvatar();
      dispatch(
        addToast({
          text: 'Profile has been successfully updated!',
          type: 'positive',
        }),
      );
    } catch (error: any) {
      if (error.originalStatus === 413) {
        setErrors({ message: 'Avatar too large' });
      } else {
        setErrors({ message: error?.message });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (passwordData.new !== passwordData.verify) {
      setPasswordMatch(false);
      return;
    }

    const avatarChanged = file !== null;

    if (avatarChanged) {
      await updateProfile({ avatar: file });
    }

    if (passwordData.current.trim() !== '' || passwordData.new.trim() !== '') {
      await changePassword(passwordData.current, passwordData.new);
    }

    setPasswordData({ current: '', new: '', verify: '' });
    setPasswordMatch(true);
  };

  const handleCancel = () => {
    setPasswordData({ current: '', new: '', verify: '' });
    setPasswordMatch(true);
    setErrors({});
  };

  return (
    <>
      <div className="items-start gap-2 bg-neutral-white px-4 pt-2 sm:hidden ">
        <div>
          <Button
            preset="ghost"
            Icon={IconChevronLeft}
            className="mb-6 inline-flex"
            to={`${routes.students}`}
          >
            Back to All Students
          </Button>
          <div className="bg-neutral-white pb-6 text-neutral-100 typography-heading-xl">
            Profile
          </div>
        </div>
      </div>
      <div className="bg-neutral-950 px-4 py-6 sm:p-10">
        <div className="hidden items-start gap-2 bg-neutral-white sm:block sm:bg-neutral-950">
          <div className="mb-6 bg-neutral-white text-neutral-100 typography-heading-xl sm:mb-0 sm:bg-neutral-950 sm:pb-10">
            Profile
          </div>
        </div>
        <div className="rounded-lg bg-neutral-white sm:pb-6 ">
          <div className="max-w-md items-start gap-5 p-4 pb-5 sm:p-6 sm:pb-4">
            <div className="mb-2 typography-loud-xs">Avatar</div>
            <UploadAvatar
              buttonTitle="Edit avatar"
              previewUrl={filePreviewUrl ? filePreviewUrl : data?.avatar}
              handleFileChange={handleAvatarChange}
            />
            <div className="mb-4 mt-1 typography-loud-sm">{`${data?.firstName || ''} ${
              data?.lastName || ''
            }`}</div>
          </div>

          <div className="max-w-md items-start gap-8 p-4 pb-2 pt-5 sm:p-6 sm:pt-4">
            <div className="mb-6 typography-heading-sm">Change Password</div>
            <div className="mb-2 typography-loud-xs">Current Password</div>
            <PasswordField
              name="current"
              size="md"
              placeholder="Current password"
              className="mb-4"
              isPrefixIcon={false}
              value={passwordData.current}
              onChange={handleInputChange}
              error={passwordMatchErrorMessage}
            />
            <div className="mb-2 typography-loud-xs">New Password</div>
            <PasswordField
              name="new"
              size="md"
              placeholder="New password"
              className="mb-4"
              isPrefixIcon={false}
              value={passwordData.new}
              onChange={handleInputChange}
              error={passwordMatchErrorMessage}
            />
            <div className="mb-2 typography-loud-xs">Confirm New Password</div>
            <PasswordField
              name="verify"
              size="md"
              placeholder="Confirm new password"
              className="mb-4"
              isPrefixIcon={false}
              value={passwordData.verify}
              onChange={handleInputChange}
              error={passwordMatchErrorMessage}
            />
            {Object.keys(errors).length > 0
              ? Object.values(errors).map((errorMessage, index) => (
                  <ErrorMessage key={index} className="mb-6">
                    {errorMessage === 'Incorrect username or password.'
                      ? 'Incorrect password.'
                      : errorMessage}
                  </ErrorMessage>
                ))
              : null}
          </div>
          <div className="mt-auto hidden justify-center gap-4 px-8 pb-6 sm:flex sm:justify-start sm:px-6 sm:pb-0">
            <Button className="w-1/2 sm:w-auto" preset={'secondary'} onClick={handleSubmit}>
              Save
            </Button>
            <Button className="w-1/2 sm:w-auto" preset={'tertiary'} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-auto flex justify-center gap-4 bg-neutral-white px-8 pb-6 pt-6 sm:hidden sm:justify-start sm:px-6 sm:pb-0">
        <Button className="w-1/2 sm:w-auto" preset={'secondary'} onClick={handleSubmit}>
          Save
        </Button>
        <Button className="w-1/2 sm:w-auto" preset={'tertiary'} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ProfileSettings;
