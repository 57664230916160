import clsx from 'clsx';
import { ElementType } from 'react';

type CardInfoItemProps = {
  label: string;
  value?: string;
  icon?: ElementType;
};

const CardInfoItem = ({ label, value, icon: Icon }: CardInfoItemProps) => (
  <div className="flex gap-4">
    {Icon && (
      <Icon className="mt-0.5 h-8 w-8 min-w-8 rounded-lg bg-secondary-900 p-2 text-secondary-300" />
    )}
    <div>
      <div className="mb-1 text-neutral-300 typography-body-sm">{label}</div>
      <div
        className={clsx(
          'typography-loud-sm',
          value ? 'text-neutral-100' : 'text-accent-tomato-500',
        )}
      >
        {value || '-'}
      </div>
    </div>
  </div>
);

export default CardInfoItem;
