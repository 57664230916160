import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import Button from 'src/components/Button';
import CenteredLoader from 'src/components/CenteredLoader';
import ErrorMessage from 'src/components/ErrorMessage';
import { routes } from 'src/routes';

import { useAuthTokenQuery } from './unauthorizedApi';

const CodeLogin = () => {
  const [params] = useSearchParams();
  const { backdoorSignIn } = useAuth();
  const code = params.get('code');

  const { data, isLoading, isError, isSuccess } = useAuthTokenQuery(code!);

  useEffect(() => {
    if (isSuccess) {
      backdoorSignIn(data);
    }
  }, [isSuccess, backdoorSignIn, data]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (isError) {
    return (
      <div className="text-center">
        <ErrorMessage className="mb-10">Seems like this link is not valid anymore</ErrorMessage>
        <Button size="lg" preset="secondary" to={routes.auth.login}>
          Go to log in
        </Button>
      </div>
    );
  }

  return null;
};

export default CodeLogin;
