import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { animated, easings, useTransition } from 'react-spring';

type MobileSidebarMenuProps = Dialog.DialogProps & {
  children?: ReactNode;
};

const MobileSidebarMenu = ({ open, onOpenChange, children }: MobileSidebarMenuProps) => {
  const transitions = useTransition(open, {
    from: { opacity: 0, x: -288 },
    enter: { opacity: 0.25, x: 0 },
    config: {
      duration: 150,
      easing: easings.easeOutSine,
      clamp: true,
      bounce: 0,
    },
  });

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        {transitions((styles, item) =>
          item ? (
            <>
              <Dialog.Overlay
                className={clsx('fixed inset-0 bg-neutral-100 opacity-25')}
                forceMount
                asChild
              >
                <animated.div style={{ opacity: styles.opacity }} />
              </Dialog.Overlay>
              <Dialog.Content
                className={clsx(
                  'fixed left-0 top-0 z-sidebar flex h-screen w-72 flex-col overflow-hidden bg-neutral-white px-4 py-14 opacity-100',
                )}
                forceMount
                asChild
              >
                <animated.div style={{ ...styles, opacity: 1 }}>{children}</animated.div>
              </Dialog.Content>
            </>
          ) : null,
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default MobileSidebarMenu;
