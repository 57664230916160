import Button from 'src/components/Button';

import { ReviewSignatureQuery, Signature, User } from './singleUser.types';

type SingleUsersSettingsProps = {
  userId: User['id'];
  signature: Signature;
  reviewSignature: (q: ReviewSignatureQuery) => void;
};

const SingleUserSignature = ({ userId, signature, reviewSignature }: SingleUsersSettingsProps) => {
  const approve = () => reviewSignature({ id: userId, body: { status: 'Approved' } });
  const reject = () => reviewSignature({ id: userId, body: { status: 'Rejected' } });

  const SignatureReview = () => (
    <div className="flex flex-row gap-2 pt-3">
      <Button preset="secondary" size="md" className="w-32" onClick={approve}>
        Approve
      </Button>
      <Button preset="primary" size="md" className="w-32" onClick={reject}>
        Reject
      </Button>
    </div>
  );

  return (
    <div className="mb-10 bg-neutral-white p-10">
      <div className="pb-5">
        <span className="text-neutral-100 typography-heading-md">
          Signature - {signature.status}
        </span>
      </div>
      <img className="max-h-96 max-w-2xl" src={signature.url} alt="signature" />
      {signature.status === 'PendingApproval' ? <SignatureReview /> : null}
    </div>
  );
};

export default SingleUserSignature;
