import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseUrl } from 'src/api';
import { BackdoorToken } from 'src/auth/auth.types';

export const unauthorizedApi = createApi({
  reducerPath: 'unauthorizedApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    authToken: build.query<BackdoorToken, string>({
      query: (code) => ({
        method: 'POST',
        url: `/auth/token?code=${code}`,
      }),
    }),
  }),
});

export const { useAuthTokenQuery } = unauthorizedApi;
