import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import { IconEmpty } from 'src/icons';

import {
  useSingleStudentDetailsQuery,
  useSingleStudentGoalConfirmContactWithParentMutation,
} from './singleStudentApi';

const StudentNoConfirmedGoals = () => {
  const { id } = useParams();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [submit, { isLoading, error, isError, isSuccess, reset }] =
    useSingleStudentGoalConfirmContactWithParentMutation();

  const { data: student } = useSingleStudentDetailsQuery(id!);

  const onConfirmCLick = (): void => {
    console.log(id);
    setIsButtonClicked(true);
    submit({ studentId: id ?? '' });
  };

  // useEffect(() => {
  //     submit({studentId})
  // }, [isButtonClicked, studentId]);

  return (
    <div className="noGoal rounded-lg bg-neutral-white p-6 text-center">
      <div className="flex justify-center text-center">
        <div className="h-10 w-10 rounded-[200px] bg-secondary-900">
          <IconEmpty className="m-2 h-6 w-6 text-secondary-300" />
        </div>
      </div>
      <div className="mb-4 mt-6 typography-heading-md">Parent outreach confirmation</div>
      <div className="mb-8 text-neutral-400 typography-loud-md">
        To proceed with goals selection, confirm below that you have contacted one of{' '}
        {student?.firstName || ''} {student?.lastName || ''} parents to introduce yourself and get
        acquainted with all case details.
      </div>
      {/* <div className="mb-4 typography-heading-xs">
        I have contacted one of {student?.firstName} parents to introduce myself and get acquainted
        with all case details
      </div> */}
      <div className="flex justify-center">
        <Button preset="secondary" size="md" onClick={onConfirmCLick}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default StudentNoConfirmedGoals;
