import * as SwitchPrimitive from '@radix-ui/react-switch';
import clsx from 'clsx';
import { IconCheck, IconClose } from 'src/icons';
import { twMerge } from 'tailwind-merge';

type SwitchProps = Omit<SwitchPrimitive.SwitchProps, 'checked' | 'onCheckedChange'> & {
  checked?: NonNullable<SwitchPrimitive.SwitchProps['checked']>;
  onCheckedChange?: NonNullable<SwitchPrimitive.SwitchProps['onCheckedChange']>;
  label?: string;
  className?: string;
  labelClassName?: string;
  withFrame?: boolean;
  size?: 'sm' | 'md';
};

const Switch = ({
  id,
  label,
  className,
  labelClassName,
  withFrame,
  size = 'md',
  ...rest
}: SwitchProps) => {
  const control = (
    <div className={twMerge('flex items-center gap-2', className)}>
      <SwitchPrimitive.Root
        className={clsx(
          'group',
          'h-6 w-11 rounded-full disabled:opacity-30',
          'radix-state-checked:bg-secondary-300',
          'radix-state-unchecked:bg-neutral-700',
        )}
        id={id}
        {...rest}
      >
        <SwitchPrimitive.Thumb
          className={clsx(
            'flex h-4 w-5 items-center justify-center rounded-full bg-neutral-white shadow-switch-thumb transition-transform md:h-5 md:w-5',
            'group-radix-state-unchecked:translate-x-1',
            'group-radix-state-checked:translate-x-5',
          )}
        >
          {rest.checked ? (
            <IconCheck className="text-secondary-300" />
          ) : (
            <IconClose className="text-neutral-500" />
          )}
        </SwitchPrimitive.Thumb>
      </SwitchPrimitive.Root>
      {label && (
        <label className="typography-loud-sm" htmlFor={id}>
          <span className={twMerge('text-neutral-200', labelClassName)}>{label}</span>
        </label>
      )}
    </div>
  );
  return withFrame ? (
    <div
      className={twMerge(
        'flex items-center whitespace-nowrap rounded-lg border border-neutral-800 bg-neutral-white pl-2 pr-4 md:w-auto xl:flex',
        size === 'sm' ? 'h-10' : 'h-12',
      )}
    >
      {control}
    </div>
  ) : (
    control
  );
};

export default Switch;
