import Checkbox from './Checkbox';

type CheckboxesGroupProps<T> = {
  options?: { label: string; value: T }[];
  value?: T[];
  onChange?: (v?: T[]) => void;
};

const CheckboxesGroup = <T extends string | number>({
  options,
  value,
  onChange,
}: CheckboxesGroupProps<T>) => {
  const handleChange = (id: T, isChecked: boolean) => {
    if (isChecked) {
      onChange && onChange([...(value || []), id]);
    } else {
      const newValue = (value || []).filter((v) => v !== id);
      onChange && onChange(newValue);
    }
  };

  return (
    <>
      {options?.map((opt) => (
        <Checkbox
          label={opt.label}
          key={opt.value}
          isChecked={!!value?.includes(opt.value)}
          onCheckedChange={handleChange.bind(null, opt.value)}
        />
      ))}
    </>
  );
};

export default CheckboxesGroup;
