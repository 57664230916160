import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';

import { useSchoolsForFiltersQuery } from './filtersApi';
import { selectedCountLabel } from './selectedCountLabel';

type FilterBySchoolsProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
};

const FilterBySchools = ({ value, onChange, separator = true }: FilterBySchoolsProps) => {
  const { data: schools } = useSchoolsForFiltersQuery();

  return (
    <Collapsible
      title={`School${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="flex flex-col gap-2">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={schools?.map((s) => ({
            label: s.name,
            value: s.id,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterBySchools;
