import clsx from 'clsx';

import { Tooltip } from './Tooltip';

type DistributionBarProps = {
  items: {
    label: string;
    value: number;
  }[];
  inactive?: boolean;
};

const COLORS_PALETTE = [
  'bg-neutral-700',
  'bg-accent-selective-yellow-500',
  'bg-accent-pacific-500',
  'bg-accent-verdigris-500',
];

const DistributionBar = ({ items, inactive }: DistributionBarProps) => {
  const sum = items.reduce((prev, item) => prev + item.value, 0);

  const itemsWithColorAndPercentage = items.map((i, index) => ({
    ...i,
    percentage: sum ? (i.value / sum) * 100 : 0,
    color: COLORS_PALETTE[index],
  }));

  return (
    <div className="flex h-1.5 w-full gap-0.5 overflow-hidden rounded-lg">
      {itemsWithColorAndPercentage
        .filter((i, index) => (!sum ? index === 0 : i.value))
        .map((i, index) => (
          <Tooltip key={index} content={`${i.label}: ${i.value}`} delayDuration={100}>
            <div
              className={clsx('h-full flex-grow', inactive ? 'bg-neutral-900' : i.color)}
              style={{ width: `${i.percentage}%` }}
            />
          </Tooltip>
        ))}
    </div>
  );
};

export default DistributionBar;
