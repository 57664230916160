import type { SVGProps } from 'react';
const SvgDrag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      clipPath="url(#drag_svg__a)"
    >
      <path d="M.75 3V.75H3M.75 16.125v2.25H3M18.375 3V.75h-2.25M5.625.75H8.25M.75 5.625V8.25M18.375 5.625V8.25M.75 10.875V13.5M10.875.75H13.5M5.625 18.375H8.25M21.75 13.5l-11.625-3.375L13.5 21.75l2.25-4.5 4.5 4.5L21 21l.75-.75-4.5-4.5 4.5-2.25Z" />
    </g>
    <defs>
      <clipPath id="drag_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDrag;
