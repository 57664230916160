import type { SVGProps } from 'react';
const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.345 8.228c-.345.292-.595.738-.595 1.397a.75.75 0 0 1-1.5 0c0-1.091.437-1.958 1.124-2.54.67-.57 1.538-.835 2.376-.835.838 0 1.705.265 2.376.834.687.583 1.124 1.45 1.124 2.541 0 .766-.196 1.35-.517 1.83-.269.404-.619.716-.894.962l-.087.078c-.307.276-.539.504-.709.804-.162.287-.293.688-.293 1.326a.75.75 0 0 1-1.5 0c0-.862.181-1.524.488-2.065.299-.528.692-.894 1.01-1.18l.072-.065c.3-.27.508-.455.665-.692.149-.223.265-.514.265-.998 0-.659-.25-1.105-.595-1.397-.36-.306-.868-.478-1.405-.478s-1.045.172-1.405.478ZM12.514 17.069a.75.75 0 0 1 .05 1.06l-.01.01a.75.75 0 1 1-1.109-1.008l.01-.012a.75.75 0 0 1 1.06-.05Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQuestion;
