import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';

import { UpdateUserSettingsQuery, User } from './singleUser.types';

type SingleUsersSettingsProps = {
  user: User;
  updateSettings: (q: UpdateUserSettingsQuery) => void;
};

const SingleUserSettings = ({ user, updateSettings }: SingleUsersSettingsProps) => {
  const { id, settings } = user;
  const initialInput = JSON.stringify(settings?.settings ?? {}, null, 2);
  const [settingsJson, setSettingsJson] = useState(initialInput);
  const [hasErrors, setHasErrors] = useState(false);

  const save = () => {
    if (hasErrors) return;
    updateSettings({ id, body: { settings: JSON.parse(settingsJson) } });
  };

  const validateJson = useCallback(() => {
    try {
      JSON.parse(settingsJson);
      setHasErrors(false);
    } catch (e) {
      setHasErrors(true);
    }
  }, [settingsJson, setHasErrors]);

  useEffect(() => {
    validateJson();
  }, [settingsJson, validateJson]);

  return (
    <div className="bg-neutral-white p-10">
      <div className="pb-2">
        <span className="text-neutral-100 typography-heading-md">Settings</span>
      </div>
      <pre
        className={clsx(
          'w-full rounded-lg bg-neutral-white px-4 py-5 text-neutral-black typography-body-sm focus:outline-none',
          'border',
          hasErrors ? 'border-primary-400' : 'border-neutral-800',
          hasErrors ? 'focus:border-primary-400' : 'focus:border-secondary-300',
          'focus:shadow-focus-primary',
        )}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={(e) => setSettingsJson(e.currentTarget.innerText)}
      >
        {initialInput}
      </pre>
      <div className="flex flex-row-reverse pt-2">
        <Button preset="secondary" size="md" className="w-32" onClick={save}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default SingleUserSettings;
