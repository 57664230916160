import React, { useState } from 'react';

type UploadImage = {
  file: File | null;
  filePreviewUrl: string | null;
  fileName: string | null | undefined;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFileSelection: () => void;
};

export const useUploadImage = (): UploadImage => {
  const [file, setFile] = useState<File | null>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null | undefined>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const reader = new FileReader();
    let fileUpload: File | null = null;

    if (event.target && event.target.files) {
      fileUpload = event.target.files[0];
    }

    reader.onloadend = () => {
      setFile(fileUpload);
      setFilePreviewUrl(reader.result as string);
      setFileName(fileUpload?.name);
    };

    reader.readAsDataURL(fileUpload as Blob);
  };

  const clearFileSelection = () => {
    setFile(null);
    setFilePreviewUrl(null);
    setFileName(null);
  };

  return { file, filePreviewUrl, fileName, handleFileSelect, clearFileSelection };
};
