import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { IconClose } from 'src/icons';
import { twMerge } from 'tailwind-merge';

import Button from './Button';

const tagStyles = cva('typography-loud-xs flex items-center rounded whitespace-nowrap', {
  variants: {
    preset: {
      darkBlue: 'bg-secondary-400 text-neutral-white',
      lightBlue: 'bg-secondary-900 text-secondary-300',
      pacific: 'bg-accent-pacific-800 text-accent-pacific-500',
      grayStroke: 'border-neutral-800 text-neutral-100 bg-neutral-white border',
      yellow: 'text-accent-selective-yellow-500 bg-accent-selective-yellow-800',
      lightGreen: 'text-accent-verdigris-500 bg-accent-verdigris-800',
      lightViolet: 'text-accent-wisteria-500 bg-accent-wisteria-800',
      gray: 'text-neutral-200 bg-neutral-950',
      inactive: 'text-neutral-500 bg-neutral-800',
      red: 'text-primary-900 bg-primary-500',
      white: 'text-neutral-100 bg-neutral-white',
      lightCoral: 'text-accent-tomato-500 bg-accent-tomato-800',
    },
    size: {
      sm: 'h-6 px-2',
      md: 'h-8 px-3',
    },
  },
  defaultVariants: {
    preset: 'darkBlue',
    size: 'sm',
  },
});

export type TagProps = React.ComponentProps<'div'> &
  VariantProps<typeof tagStyles> & {
    onRemove?: (t: any) => void;
  };

const Tag = ({ children, className, preset, size, onRemove, ...props }: TagProps) => (
  <div className={twMerge(clsx(tagStyles({ preset, size }), className))} {...props}>
    {children}
    {onRemove && (
      <Button
        Icon={IconClose}
        preset="ghost"
        size="xs"
        className="ml-2 h-4 w-4 bg-transparent text-neutral-400 hover:text-neutral-600 disabled:text-neutral-600"
        onClick={onRemove}
      />
    )}
  </div>
);

export default Tag;
