import type { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.178 3.808c-.269 0-.527.107-.717.297l-8.412 8.412-.478 1.912 1.912-.478 8.412-8.412a1.014 1.014 0 0 0-.717-1.73Zm-1.995-.981a2.822 2.822 0 0 1 3.99 3.99l-8.588 8.59a.905.905 0 0 1-.42.237l-3.617.904a.904.904 0 0 1-1.096-1.096l.904-3.616a.904.904 0 0 1 .238-.42l8.589-8.59ZM2.794 4.712a2.712 2.712 0 0 1 1.918-.794h6.33a.904.904 0 0 1 0 1.808h-6.33a.904.904 0 0 0-.904.904v12.658a.904.904 0 0 0 .904.904H17.37a.904.904 0 0 0 .904-.904v-6.33a.904.904 0 0 1 1.808 0v6.33A2.713 2.713 0 0 1 17.37 22H4.712A2.713 2.713 0 0 1 2 19.288V6.63c0-.72.286-1.409.794-1.918Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEdit;
