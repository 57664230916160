import { IconSpinner } from 'src/icons';
import { twMerge } from 'tailwind-merge';
type LoaderProps = {
  className?: string;
};

const Loader = ({ className }: LoaderProps) => (
  <IconSpinner className={twMerge('animate-spin', className)} />
);

export default Loader;
