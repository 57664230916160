export const countAllFilters = (filters: Record<string, any[] | any>) => {
  const filtersKeys = Object.keys(filters);
  let count = 0;

  filtersKeys.forEach((key) => {
    if (key === 'schoolYearId') return;
    const filter = filters[key];

    if (Array.isArray(filter)) {
      count += filter.length;
    } else if (filter) count += 1;
  });

  return count;
};
