import { ChangeEvent, useState } from 'react';
import Button from 'src/components/Button';
import Search from 'src/components/Search';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconSearch } from 'src/icons';
import { useAppSelector } from 'src/store';

import { useInvoiceStatementYearsQuery } from './billingApi';
import BillingInvoicesFilters from './BillingInvoicesFilters';
import BillingInvoicesFiltersList from './BillingInvoicesFiltersList';
import BillingInvoicesForSchoolYear from './BillingInvoicesForSchoolYear';
import BillingSchoolYearPicker from './BillingSchoolYearPicker';
import { selectBillingActiveSchoolYear } from './billingSelectors';

const BillingInvoices = () => {
  const [searchValue, setSearchValue] = useState('');
  const { isFetching } = useInvoiceStatementYearsQuery();
  const activeSchoolYear = useAppSelector(selectBillingActiveSchoolYear);
  const { isSm } = useBreakpoint('sm');
  const { isXl } = useBreakpoint('xl');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  return (
    <div className="px-4 md:px-0">
      {!isXl && (
        <div className="mb-2 mt-4 typography-heading-lg md:ml-4">
          {!isXl &&
            `School Year ${activeSchoolYear?.schoolYearStart || '2023'}/${
              activeSchoolYear?.schoolYearEnd || '2024'
            }`}
        </div>
      )}
      <div className="flex gap-x-4 pb-4 pt-6 md:py-6">
        <BillingSchoolYearPicker isFetching={isFetching} />
        <div className="ml-auto flex gap-2 md:gap-4">
          {isSm ? (
            <Search onChange={handleSearch} size={isSm ? 'md' : 'sm'} className="w-auto xl:w-80" />
          ) : (
            <Button
              Icon={IconSearch}
              preset="tertiary"
              size="md"
              // onClick={ to do }
              className="h-10 w-10 gap-0 md:h-12 md:w-auto md:gap-2"
            />
          )}
          <BillingInvoicesFilters />
        </div>
      </div>
      <BillingInvoicesFiltersList />
      {!isFetching && activeSchoolYear?.schoolYearId && (
        <BillingInvoicesForSchoolYear
          schoolYearId={activeSchoolYear?.schoolYearId}
          query={searchValue}
        />
      )}
    </div>
  );
};

export default BillingInvoices;
