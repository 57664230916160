import * as RadixToast from '@radix-ui/react-toast';
import Toast from 'src/components/Toast';
import { useAppDispatch, useAppSelector } from 'src/store';

import { selectToasts } from './ToastsSelectors';
import { dismissToast, removeToast } from './ToastsSlice';

const ToastsProvider = () => {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector(selectToasts);

  const onOpenChange = (id: number) => {
    dispatch(dismissToast(id));
    setTimeout(() => {
      dispatch(removeToast(id));
    }, 400);
  };

  return (
    <RadixToast.Provider>
      {toasts.map((toast) => (
        <Toast {...toast} key={toast.id} onOpenChange={onOpenChange} />
      ))}
      <RadixToast.Viewport className="fixed left-1/2 top-16 z-toast m-0 h-auto w-full -translate-x-1/2 px-4 text-center md:w-auto" />
    </RadixToast.Provider>
  );
};

export default ToastsProvider;
