import { ChangeEvent, useState } from 'react';
import CheckboxesNestedGroup from 'src/components/CheckBoxNestedGroup';
import Collapsible from 'src/components/Collapsible';
import Search from 'src/components/Search';

import { useSubDomainsForFiltersQuery } from './filtersApi';
import { selectedCountLabel } from './selectedCountLabel';

type FilterByGoalSubdomainsProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
  studentId?: string;
};

type FilteredAlfanumericResults = {
  label: string;
  value: string;
};

const FilterByGoalSubdomains = ({
  value,
  onChange,
  separator = true,
  studentId,
}: FilterByGoalSubdomainsProps) => {
  const { data: subDomains } = useSubDomainsForFiltersQuery(studentId ?? '');
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const filteredOptions = subDomains
    ?.filter((s) => s.name.toLowerCase().includes(searchValue.toLowerCase()))
    .map((s) => ({
      label: s.name,
      value: s.id,
    }));

  const groupByStartingLetter = (
    arr: FilteredAlfanumericResults[] | undefined,
  ): Record<string, FilteredAlfanumericResults[]> => {
    if (!arr) return {};

    const result: Record<string, FilteredAlfanumericResults[]> = {};

    for (const item of arr) {
      const firstLetter = item.label.charAt(0).toUpperCase();

      if (!result[firstLetter]) {
        result[firstLetter] = [];
      }

      result[firstLetter].push(item);
    }

    return result;
  };

  const nestedFilteredOptions = groupByStartingLetter(filteredOptions);

  return (
    <Collapsible
      title={`Subdomain${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="pb-4">
        <Search onChange={handleSearch} />
      </div>
      <div className="grid grid-cols-1 gap-y-2">
        <CheckboxesNestedGroup
          value={value}
          onChange={onChange}
          nestedOptions={nestedFilteredOptions}
        />
      </div>
    </Collapsible>
  );
};

export default FilterByGoalSubdomains;
