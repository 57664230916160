import type { SVGProps } from 'react';
const SvgFilters = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.084 3.521A.91.91 0 0 1 2.91 3h18.182a.91.91 0 0 1 .825.521.893.893 0 0 1-.13.96l-7.059 8.262V20.1a.898.898 0 0 1-.43.766.917.917 0 0 1-.885.039l-3.637-1.8a.9.9 0 0 1-.502-.805v-5.557L2.215 4.481a.893.893 0 0 1-.13-.96ZM4.868 4.8l6.008 7.033a.895.895 0 0 1 .215.581v5.33l1.818.9v-6.23c0-.213.076-.419.215-.581L19.131 4.8H4.868Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFilters;
