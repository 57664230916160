import { differenceInDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { Sidebar, SidebarContainer, SidebarContent } from 'src/components/Sidebar';
import { countAllFilters } from 'src/filters/countAllFilters';
import FilterByDateRange from 'src/filters/FilterByDateRange';
import FilterByGrades from 'src/filters/FilterByGrades';
import FilterBySchools from 'src/filters/FilterBySchools';
import FilterByStudents from 'src/filters/FilterByStudents';
import FilterBySupervisor from 'src/filters/FilterBySupervisor';
import FiltersSidebarFooter from 'src/filters/FiltersSidebarFooter';
import FiltersSidebarHeader from 'src/filters/FiltersSidebarHeader';
import FiltersTriggerButton from 'src/filters/FiltersTriggerButton';
import { useAppDispatch, useAppSelector } from 'src/store';

import { selectStudentsFilters, selectStudentsFiltersCount } from './studentsSelectors';
import { setStudentsFilters } from './studentsSlice';

const StudentsFilters = () => {
  const dispatch = useAppDispatch();
  const initialFilters = useAppSelector(selectStudentsFilters);
  const initialFiltersCount = useAppSelector(selectStudentsFiltersCount);

  const [studentIds, setStudentsIds] = useState<string[]>();
  const [schoolIds, setSchoolIds] = useState<string[]>();
  const [gradeIds, setGradeIds] = useState<string[]>();
  const [supervisorIds, setSupervisorIds] = useState<string[]>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [open, setOpen] = useState(false);

  const filters = {
    studentIds,
    schoolIds,
    grades: gradeIds,
    supervisorIds,
    startDate,
    endDate,
  };

  useEffect(() => {
    setStudentsIds(initialFilters.studentIds);
    setSchoolIds(initialFilters.schoolIds);
    setGradeIds(initialFilters.grades);
    setSupervisorIds(initialFilters.supervisorIds);
    setStartDate(initialFilters.startDate);
    setEndDate(initialFilters.endDate);
  }, [initialFilters, open]);

  const onApply = () => {
    dispatch(setStudentsFilters(filters));
  };

  const onReset = () => {
    setStudentsIds(undefined);
    setSchoolIds(undefined);
    setGradeIds(undefined);
    setSupervisorIds(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const filtersCount = countAllFilters(filters);

  return (
    <SidebarContainer onOpenChange={setOpen} open={open}>
      <FiltersTriggerButton initialFiltersCount={initialFiltersCount} />
      <Sidebar open={open}>
        <FiltersSidebarHeader
          initialFiltersCount={initialFiltersCount}
          onReset={onReset}
          filtersCount={filtersCount}
        />
        <SidebarContent className="p-4 pt-1 md:p-6">
          <FilterByStudents value={studentIds} onChange={setStudentsIds} />
          <FilterBySchools value={schoolIds} onChange={setSchoolIds} />
          <FilterByGrades value={gradeIds} onChange={setGradeIds} />
          <FilterBySupervisor value={supervisorIds} onChange={setSupervisorIds} />
          <FilterByDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            separator={false}
          />
        </SidebarContent>
        <FiltersSidebarFooter
          onApply={onApply}
          filtersCount={filtersCount}
          disabled={differenceInDays(Date.parse(endDate ?? ''), Date.parse(startDate ?? '')) <= 0}
        />
      </Sidebar>
    </SidebarContainer>
  );
};

export default StudentsFilters;
