import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

import { currentSessionHookless } from './auth/hookless';

// export const baseUrl = 'http://dev.enhancedservices.org/api/';
export const baseUrl = '/api';

export const customBaseQuery = (unauthorizedEndpoints: string[] = []) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      if (unauthorizedEndpoints.includes(endpoint)) {
        return headers;
      }
      const session = await currentSessionHookless(getState());
      headers.set('Authorization', `Bearer ${session.getIdToken().getJwtToken()}`);

      return headers;
    },
  });
};
export const baseQuery = customBaseQuery();
