import type { SVGProps } from 'react';
const SvgInfoWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.793 2H15.207c.173 0 .39-.001.604.05.186.045.363.118.526.218.188.115.34.27.463.392l.032.032 4.476 4.476.032.032c.123.122.277.275.392.463.1.163.173.34.218.526.051.213.05.43.05.604V15.207c0 .173.001.39-.05.604a1.817 1.817 0 0 1-.218.526 2.54 2.54 0 0 1-.392.463l-.032.032-4.476 4.476-.032.032a2.54 2.54 0 0 1-.463.392c-.163.1-.34.173-.525.218-.214.051-.432.05-.604.05H8.793c-.173 0-.39.001-.604-.05a1.817 1.817 0 0 1-.526-.218c-.188-.115-.34-.27-.463-.392l-.032-.032-4.476-4.476-.032-.032c-.123-.122-.277-.275-.392-.463-.1-.163-.173-.34-.218-.525C2 15.598 2 15.38 2 15.208V8.793c0-.173-.001-.39.05-.604.045-.186.118-.363.218-.526.115-.188.27-.34.392-.463l.032-.032 4.476-4.476.032-.032a2.54 2.54 0 0 1 .463-.392c.163-.1.34-.173.526-.218.213-.05.43-.05.604-.05Zm-.179 1.82-.002.003c-.032.029-.075.07-.159.155L3.978 8.453a5.601 5.601 0 0 0-.155.159l-.002.002v.003a5.628 5.628 0 0 0-.003.222v6.322c0 .12 0 .179.003.222v.003l.002.003c.029.031.07.074.155.158l4.475 4.475c.084.084.127.126.159.155l.002.002h.003c.043.003.103.003.222.003h6.322c.12 0 .179 0 .222-.002h.003l.003-.003c.031-.029.074-.07.158-.155l4.475-4.475c.084-.084.126-.127.155-.159l.002-.002v-.003a5.56 5.56 0 0 0 .003-.222V8.84c0-.12 0-.179-.002-.222v-.003l-.003-.002a5.567 5.567 0 0 0-.155-.159l-4.475-4.475a5.639 5.639 0 0 0-.159-.155l-.002-.002h-.003a5.628 5.628 0 0 0-.222-.003H8.84c-.12 0-.179 0-.222.003h-.003Zm2.477 4.544a.91.91 0 0 1 .909-.91h.01a.91.91 0 1 1 0 1.819H12a.91.91 0 0 1-.91-.91ZM12 11.09a.91.91 0 0 1 .91.909v3.636a.91.91 0 1 1-1.82 0V12a.91.91 0 0 1 .91-.91Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfoWarning;
