import type { SVGProps } from 'react';
const SvgKidStudent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M8.625 11.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM15.375 11.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.602 13.384a1 1 0 0 1 1.366.366 3.502 3.502 0 0 0 6.064 0 1 1 0 0 1 1.732 1 5.501 5.501 0 0 1-9.528 0 1 1 0 0 1 .366-1.366Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKidStudent;
