import { useMediaQuery } from 'react-responsive';
import { uppercaseFirstLetter } from 'src/logic/uppercaseFirstLetter';

import tailwindConfig from '../../tailwind.config.cjs';

const breakpoints = tailwindConfig.theme.extend.screens;

export function useBreakpoint(breakpointKey: 'sm' | 'md' | 'lg' | 'xl') {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });
  const capitalizedKey = uppercaseFirstLetter(breakpointKey);
  type Key = `is${Capitalize<string>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}
