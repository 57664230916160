import type { SVGProps } from 'react';
const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.5 7.988C.5 12.4 4.062 16 8.475 16c3.09 0 5.833-1.748 7.227-4.461l.005-.01a7.953 7.953 0 0 0 .793-3.492c0-.822-.132-1.64-.369-2.404l-.003-.01c-.179-.538-.753-.913-1.365-.708-.537.179-.909.751-.708 1.362.18.563.27 1.149.27 1.735 0 .88-.203 1.738-.587 2.53-.996 2.017-3.005 3.28-5.238 3.28-3.204 0-5.824-2.623-5.824-5.834a5.803 5.803 0 0 1 5.8-5.81c.883 0 1.736.18 2.517.558a1.072 1.072 0 0 0 1.459-.484 1.074 1.074 0 0 0-.495-1.46A7.922 7.922 0 0 0 8.475 0C4.065 0 .5 3.572.5 7.988Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpinner;
