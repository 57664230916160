import { useEffect, useState } from 'react';
import CenteredLoader from 'src/components/CenteredLoader';
import NoResults from 'src/components/NoResults';
import Pagination from 'src/components/Pagination';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getPageCount } from 'src/utils/pagination.utils';

import { useSchedulePlansQuery } from './scheduleApi';
import ScheduleMyPlansDeleteAlert from './ScheduleMyPlansDeleteAlert';
import {
  selectScheduleFilters,
  selectSchedulePage,
  selectScheduleQueryString,
} from './scheduleSelectors';
import ScheduleSessionsForm from './ScheduleSessionsForm';
import ScheduleSinglePlanCard from './ScheduleSinglePlanCard';
import { setSchedulePage } from './scheduleSlice';

const ScheduleMyPlans = () => {
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectSchedulePage);
  const queryString = useAppSelector(selectScheduleQueryString);
  const filters = useAppSelector(selectScheduleFilters);
  const { data, isFetching } = useSchedulePlansQuery({ page, queryString, ...filters });
  const [planToDeleteId, setPlanToDelete] = useState<string>('');
  const [planToEdit, setPlanToEdit] = useState<string>('');

  const onDelete = (id: string) => {
    setPlanToDelete(id);
  };

  const onEdit = (id: string) => {
    setPlanToEdit(id);
  };

  const handleCloseEditForm = () => {
    setPlanToEdit('');
  };

  const onSetSchedulePage = (p: number) => {
    dispatch(setSchedulePage(p));
  };

  useEffect(() => {
    const onSetSchedulePage = (p: number) => {
      dispatch(setSchedulePage(p));
    };

    if (data?.items.length === 0 && page !== 1) {
      onSetSchedulePage(page - 1);
    }
  }, [data, page, dispatch]);

  if (isFetching) return <CenteredLoader />;

  if (data?.items.length === 0)
    return (
      <div className="py-10 text-center text-neutral-300 typography-body-lg">
        {/* TODO: make "no plans" screen prettier */}
        no plans yet...
      </div>
    );

  return (
    <div className="bg-neutral-950 px-4 pb-10 md:px-10">
      {data?.items ? (
        <>
          {data?.items.map(({ plans, student }) => {
            return (
              <ScheduleSinglePlanCard
                key={student.id}
                plans={plans}
                student={student}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            );
          })}
          <ScheduleMyPlansDeleteAlert
            planToDeleteId={planToDeleteId}
            setPlanToDelete={setPlanToDelete}
          />
          <div className="flex items-center justify-between sm:mt-8">
            <Pagination
              pagesCount={getPageCount(data) || 1}
              activePage={page}
              onChangeActivePage={onSetSchedulePage}
              itemsPerPage={data.pageSize}
              totalSize={data.total}
              searchingItemsPlaceholder="plans"
            />
          </div>
          {planToEdit && (
            <ScheduleSessionsForm scheduleId={planToEdit} handleClose={handleCloseEditForm} />
          )}
        </>
      ) : (
        <NoResults />
      )}
    </div>
  );
};

export default ScheduleMyPlans;
