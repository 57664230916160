import clsx from 'clsx';
import { IconEnvlope, IconPhoneCall } from 'src/icons';
import { buildName } from 'src/logic/buildName';

import Avatar from './Avatar';
import Separator from './Separator';
import Tag from './Tag';

type CardRowContactInfoProps = {
  email?: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber?: string;
  tags: string[];
  contactsAmount?: number;
  currentContactIndex?: number;
  isParentsSection?: boolean;
};

const CardRowContactInfo = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  tags,
  contactsAmount,
  currentContactIndex,
  isParentsSection,
}: CardRowContactInfoProps) => {
  const isLastElement =
    contactsAmount !== undefined &&
    currentContactIndex !== undefined &&
    contactsAmount - 1 > currentContactIndex
      ? true
      : false;
  return (
    <div
      className={clsx(
        'relative flex flex-col items-start justify-between gap-4  text-neutral-100 typography-loud-sm md:gap-2 xl:flex-row xl:items-center',
        isLastElement ? 'pb-5 sm:pb-6' : 'pb-0',
      )}
    >
      <div className="flex xl:items-center">
        <div className="flex w-auto items-center gap-4 md:w-64 xl:flex">
          <Avatar
            firstName={firstName}
            lastName={lastName}
            className={clsx('hidden xl:block ', isParentsSection ? 'bg-accent-verdigris-800' : '')}
          />
          <div className="truncate">{buildName({ firstName, lastName })}</div>
        </div>
        <div className="flex-growgap-2 absolute right-0 top-0 flex xl:relative ">
          {tags?.map((t) => (
            <Tag key={t} preset="grayStroke" size="sm">
              {t}
            </Tag>
          ))}
        </div>
      </div>
      <div className="flex flex-col-reverse gap-4 md:gap-2 xl:flex-row xl:items-center">
        <div className="flex w-auto items-center gap-3 xl:w-80">
          <IconEnvlope className="h-10 w-10 shrink-0 rounded border border-neutral-800 p-2 text-neutral-500 xl:h-5 xl:w-5 xl:border-none xl:p-0" />
          <div className="truncate text-neutral-100 typography-body-sm">{email || '-'}</div>
        </div>
        <div className="flex w-auto items-center gap-3  xl:w-36">
          <IconPhoneCall className="h-10 w-10 shrink-0 rounded border border-neutral-800 p-2 text-neutral-500 xl:h-5 xl:w-5 xl:border-none xl:p-0" />
          <div className="truncate text-neutral-100 typography-body-sm">{phoneNumber || '-'}</div>
        </div>
      </div>
      {isLastElement ? <Separator className="mb-2 mt-2 bg-neutral-900 sm:hidden" /> : null}
    </div>
  );
};
export default CardRowContactInfo;
