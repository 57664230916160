import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';
import { buildName } from 'src/logic/buildName';

import { useProvidersForFiltersQuery } from './filtersApi';
import { selectedCountLabel } from './selectedCountLabel';

type FilterByProvidersProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
};

const FilterByProviders = ({ value, onChange, separator = true }: FilterByProvidersProps) => {
  const { data: providers } = useProvidersForFiltersQuery();

  return (
    <Collapsible
      title={`Providers's name${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="grid grid-cols-2 gap-x-6 gap-y-2">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={providers?.map((s) => ({
            label: buildName(s),
            value: s.providerId,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterByProviders;
