import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { IconClose } from 'src/icons';
import { twMerge } from 'tailwind-merge';

import Button from './Button';

type ModalProps = Dialog.DialogProps & {
  children: ReactNode;
  title?: string;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  Footer?: ReactNode;
  Header?: ReactNode;
};

const Modal = ({
  open,
  onOpenChange,
  title,
  children,
  className,
  contentClassName,
  headerClassName,
  Footer,
  Header,
}: ModalProps) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-modalOverlay bg-neutral-100/25" />
      <Dialog.Content
        className={twMerge(
          clsx(
            'fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-neutral-white lg:max-h-[85vh] lg:w-[90vw]',
            'z-modal flex flex-col',
            className,
          ),
        )}
      >
        <div
          className={twMerge(
            clsx('flex items-center justify-between px-4 py-6 md:px-6', headerClassName),
          )}
        >
          <Dialog.Title className="text-neutral-200 typography-heading-lg md:text-neutral-black md:typography-heading-md">
            {title}
          </Dialog.Title>
          <div className="flex items-center gap-x-4">
            {Header}
            <Dialog.Close asChild>
              <Button
                Icon={IconClose}
                preset="ghost"
                size="xs"
                className="h-6 w-6"
                iconClassName="w-6 h-6 text-neutral-400 hover:text-neutral-600"
              />
            </Dialog.Close>
          </div>
        </div>
        <div className={twMerge('overflow-auto px-4 md:px-6', contentClassName)}>{children}</div>
        {Footer}
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default Modal;

type ModalFooterProps = {
  children?: ReactNode;
  className?: string;
};

export const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return (
    <div
      className={twMerge(
        '-mx-6 mt-6 flex gap-4 border-t border-t-neutral-800 px-6 pb-2 pt-6',
        className,
      )}
    >
      {children}
    </div>
  );
};
