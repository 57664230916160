// TODO - Remove
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CalendarApi } from '@fullcalendar/core';
import { isAfter, isBefore } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'src/components/Calendar';
import useCalendarToPdf from 'src/hooks/calendarToPdf';
import useCalendarRange from 'src/hooks/useCalendarRange';

import ScheduleHeader, { ScheduleHeaderProps } from '../../components/ScheduleHeader';
import { mapScheduleEventToFullCalendarEvent } from '../schedule/mapScheduleEventToFullCalendarEvent';
import RecordSessionForm, {
  RecordSessionSidebarRefProps,
} from '../schedule/record-session/RecordSessionForm';
import {
  useLazyStudentEventsQuery,
  useSingleStudentAvailableFeaturesQuery,
  useSingleStudentDetailsQuery,
} from './singleStudentApi';

type StudentScheduleProps = {
  studentId?: string;
  headerProps?: Partial<ScheduleHeaderProps>;
  disableInteractions?: boolean;
  initialDate?: string;
};

const StudentSchedule = ({
  studentId,
  headerProps,
  disableInteractions,
  initialDate,
}: StudentScheduleProps) => {
  const generatePDF = useCalendarToPdf();
  const calendarRef = useRef<CalendarApi>(null);
  const getCalendarRange = useCalendarRange(calendarRef);
  const showOnlyMyClassesRef = useRef<boolean>(false);
  const [getEvents, { data: events = [], isUninitialized }] = useLazyStudentEventsQuery();
  const { data: currentStudent } = useSingleStudentDetailsQuery(studentId!);
  const { data: studentAvailableFeatures } = useSingleStudentAvailableFeaturesQuery(studentId!);

  const getEventsLazily = useCallback(() => {
    const range = getCalendarRange();
    if (range && studentId) {
      getEvents({ ...range, showOnlyMyClasses: showOnlyMyClassesRef.current, studentId });
    }
  }, [getCalendarRange, getEvents, studentId]);

  useEffect(() => {
    if (isUninitialized && calendarRef) {
      getEventsLazily();
      calendarRef.current?.on('datesSet', getEventsLazily);
    }
  }, [isUninitialized, calendarRef, getEventsLazily]);

  const [currentView, setCurrentView] = useState<string | undefined>('dayGridMonth');

  const onExportRequest = () => {
    generatePDF.current(true);
  };

  const fullCalendarEvents = useMemo(
    () => events.map(mapScheduleEventToFullCalendarEvent),
    [events],
  );
  const sidebarFormRef = useRef<RecordSessionSidebarRefProps>(null);
  const checkDisableRecordSessionHoverOnDate = (date: Date) => {
    if (!studentAvailableFeatures?.canScheduleSession) return true;
    if (currentStudent) {
      return (
        isBefore(date, new Date(currentStudent.startDate)) ||
        isAfter(date, new Date(currentStudent.finishDate))
      );
    }
    return false;
  };
  return (
    <div className="m-6 h-[calc(100vh-200px)] md:m-0">
      <div className="relative h-full">
        <RecordSessionForm lockOnStudentId={studentId} ref={sidebarFormRef} />
        <ScheduleHeader
          calendarRef={calendarRef}
          onExport={onExportRequest}
          currentView={currentView}
          setCurrentView={setCurrentView}
          onChange={getEventsLazily}
          hasShowOnlyMyClasses
          showOnlyMyClassesRef={showOnlyMyClassesRef}
          initialDate={initialDate}
          {...headerProps}
        />
        <Calendar
          ref={calendarRef}
          initialView={currentView}
          initialDate={initialDate}
          events={fullCalendarEvents}
          dayMaxEventRows={false}
          headerToolbar={false}
          dayMaxEvents={3}
          moreLinkContent={function (args) {
            return args.num + ' more...';
          }}
          disableSessionRecordHover={checkDisableRecordSessionHoverOnDate}
          allDaySlot={false}
          editable={true}
          export={generatePDF}
          buttonText={{
            month: 'Month',
            day: 'Day',
            week: 'Week',
            list: 'List',
            today: 'Show Today',
          }}
          onRecordSessionClick={(selectedDate) => {
            if (disableInteractions) return;
            sidebarFormRef.current?.openWithSelectedDate(selectedDate);
          }}
          onEventClick={(event) => {
            if (disableInteractions) return;
            if (event.eventType === 'Session') {
              sidebarFormRef.current?.openWithSelectedSession(event);
            }
            // Possible to add OOF Form and open it
            //  else if (event.eventType === 'OutOfOffice' && outOfOfficeFormRef) {
            //   outOfOfficeFormRef?.current?.open(event);
            // }
          }}
          disableInteractions={disableInteractions}
          studenDetailsForPdfExport={currentStudent}
        />
      </div>
    </div>
  );
};

export default StudentSchedule;
