import { ComponentProps, useState } from 'react';
import { IconEye, IconEyeOff, IconLock } from 'src/icons';

import TextField from './TextField';

type PasswordFieldProps = ComponentProps<typeof TextField> & {
  isPrefixIcon?: boolean;
};

const PasswordField = ({
  placeholder = 'Password',
  isPrefixIcon = true,
  ...rest
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const onToggleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <TextField
      PrefixIcon={isPrefixIcon ? IconLock : undefined}
      SufixIcon={showPassword ? IconEyeOff : IconEye}
      onSuffixIconClick={onToggleShowPassword}
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      {...rest}
    />
  );
};

export default PasswordField;
