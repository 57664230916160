import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Avatar from 'src/components/Avatar';
import Card from 'src/components/Card';
import { routes } from 'src/routes';

import { User } from './users.types';

type UserCardProps = {
  user: User;
};
const UserCard = ({ user }: UserCardProps) => {
  const pendingSignature = user.signature?.status === 'PendingApproval';

  return (
    <Link key={user.id} to={`${routes.singleUser.root.replace(':id', user.id)}`}>
      <Card
        className={
          'my-2 grid auto-cols-max grid-flow-col items-center gap-4 hover:shadow-elevation-4'
        }
      >
        <Avatar firstName={user.firstName} lastName={user.lastName} url={user.avatar} />
        <div className="col-span-2 text-neutral-100 typography-heading-md">
          {user.firstName} {user.lastName}
        </div>
        <div className="col-span-2 text-neutral-100">{user.email}</div>
        <div className="font-bold text-neutral-100">{user.userType}</div>
        {user.signature ? (
          <div
            className={clsx(
              'font-bold',
              pendingSignature ? 'text-accent-pink-500' : 'text-neutral-100',
            )}
          >
            Signature: {user.signature.status}
          </div>
        ) : null}
      </Card>
    </Link>
  );
};

export default UserCard;
