import FormField, { FormFieldProps } from './FormField';
import Select, { SelectProps } from './Select';

type SelectFieldProps = Omit<SelectProps, 'hasError'> & Omit<FormFieldProps, 'children'>;

const SelectField = ({ label, hint, error, id, className, ...props }: SelectFieldProps) => {
  return (
    <FormField
      label={label}
      hint={hint}
      error={error}
      id={id}
      disabled={props.disabled}
      className={className}
    >
      <Select disabled={props.disabled} id={id} {...props} />
    </FormField>
  );
};

export default SelectField;
