import * as Dialog from '@radix-ui/react-dialog';
import { PointerDownOutsideEvent } from '@radix-ui/react-dismissable-layer';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { animated, easings, useTransition } from 'react-spring';
import { IconClose } from 'src/icons';
import { twMerge } from 'tailwind-merge';

import Button from './Button';
import ErrorMessage from './ErrorMessage';
import ScrollArea from './ScrollArea';
import Separator from './Separator';

type SidebarContainerProps = Dialog.DialogProps & {
  children: ReactNode;
};

export const SidebarContainer = ({ children, open, onOpenChange }: SidebarContainerProps) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </Dialog.Root>
  );
};

type SidebarTriggerProps = {
  children: ReactNode;
};

export const SidebarTrigger = ({ children }: SidebarTriggerProps) => (
  <Dialog.Trigger asChild>{children}</Dialog.Trigger>
);

type SidebarHeaderProps = {
  children: ReactNode;
  className?: string;
  onClose?: () => void | undefined;
};

export const SidebarHeader = ({ children, className, onClose }: SidebarHeaderProps) => {
  return (
    <header className="px-0 md:p-0">
      <div className="px-4 py-4 md:p-6">
        <div className="flex w-full items-center justify-between">
          <div className={twMerge('pt-2', className)}>{children}</div>
          <Dialog.Close asChild>
            <Button
              Icon={IconClose}
              preset="ghost"
              size="xs"
              iconClassName="w-6 h-6 text-neutral-400 hover:text-neutral-600"
              onClick={onClose}
            />
          </Dialog.Close>
        </div>
      </div>
      <Separator />
    </header>
  );
};

type SidebarTitleProps = {
  children: ReactNode;
};

export const SidebarTitle = ({ children }: SidebarTitleProps) => (
  <Dialog.Title className="text-neutral-100 typography-heading-xl  md:text-neutral-200 md:typography-heading-lg">
    {children}
  </Dialog.Title>
);

type SidebarProps = {
  open: boolean;
  children: ReactNode;
  size?: 'xs' | 'xl';
};

export const Sidebar = ({ open, children, size }: SidebarProps) => {
  const transitions = useTransition(open, {
    from: { opacity: 0, x: size === 'xl' ? 540 : 480 },
    enter: { opacity: 0.25, x: 0 },
    config: {
      duration: 150,
      easing: easings.easeOutSine,
      clamp: true,
      bounce: 0,
    },
  });

  const onPointerDownOutside = (e: PointerDownOutsideEvent) => e.preventDefault();

  return (
    <Dialog.Portal>
      {transitions((styles, item) =>
        item ? (
          <>
            <Dialog.Overlay
              className={clsx('fixed inset-0 bg-neutral-100 opacity-25')}
              forceMount
              asChild
            >
              <animated.div
                style={{
                  opacity: styles.opacity,
                }}
              />
            </Dialog.Overlay>
            <Dialog.Content
              onPointerDownOutside={onPointerDownOutside}
              className={clsx(
                'fixed right-0 top-0 z-sidebar flex h-d-screen flex-col overflow-hidden bg-neutral-white opacity-100',
                size === 'xl' ? 'w-full md:w-135' : 'w-full md:w-120',
              )}
              forceMount
              asChild
            >
              <animated.div style={{ ...styles, opacity: 1 }}>{children}</animated.div>
            </Dialog.Content>
          </>
        ) : null,
      )}
    </Dialog.Portal>
  );
};

type SidebarContentProps = {
  children: ReactNode;
  className?: string;
};

export const SidebarContent = ({ children, className }: SidebarContentProps) => {
  return (
    <ScrollArea className="grow">
      <div className={twMerge('p-6', className)}>{children}</div>
    </ScrollArea>
  );
};

type SidebarFooterProps = {
  children: ReactNode;
  error?: string;
};

export const SidebarFooter = ({ children, error }: SidebarFooterProps) => {
  return (
    <footer>
      <Separator />
      <ErrorMessage className="px-6 pt-6">{error}</ErrorMessage>
      <div className="flex gap-4 p-6">{children}</div>
    </footer>
  );
};
