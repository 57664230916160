import { CalendarApi } from '@fullcalendar/core';
import { addMinutes } from 'date-fns';
import { RefObject } from 'react';

const useCalendarRange = (ref: RefObject<CalendarApi>) => {
  return () => {
    if (ref.current) {
      const tz = new Date().getTimezoneOffset();
      const start = addMinutes(ref.current.view.activeStart, -tz).toISOString();
      const finish = addMinutes(ref.current.view.activeEnd, -tz).toISOString();
      return { start, finish };
    }
    return null;
  };
};

export default useCalendarRange;
