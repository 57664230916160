import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ScheduleFilters } from './schedule.types';

type ScheduleState = {
  page: number;
  query?: string;
  filters: ScheduleFilters;
  showInactive: boolean;
};

const initialState: ScheduleState = {
  page: 1,
  filters: {},
  showInactive: false,
};

const schedulePageSlice = createSlice({
  name: 'schedulePage',
  initialState,
  reducers: {
    setSchedulePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setScheduleQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
      state.page = initialState.page;
    },
    setScheduleFilters(state, action: PayloadAction<ScheduleState['filters']>) {
      state.filters = action.payload;
      state.page = initialState.page;
    },
    resetSchedule() {
      return initialState;
    },
  },
});

export const { setSchedulePage, setScheduleQuery, setScheduleFilters, resetSchedule } =
  schedulePageSlice.actions;

export default schedulePageSlice;
