import type { SVGProps } from 'react';
const SvgOt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#37AAA1"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 3.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM2.5 7.5l4-2h3l4 2M6.5 12.5v-7M9.5 15.5v-10M7.5 15.476a5.824 5.824 0 0 1-5-5.976M13.5 9.5a6.215 6.215 0 0 1-2 4.629"
    />
  </svg>
);
export default SvgOt;
