import clsx from 'clsx';
import Tag from 'src/components/Tag';

import { StudentGoal } from './singleStudent.types';

export type SingleStudentGoalDetailsProps = {
  goal: StudentGoal;
  isDeleted: boolean;
};

const SingleStudentGoalDetails = ({ goal, isDeleted }: SingleStudentGoalDetailsProps) => {
  const tags = [
    goal.definition.domain.name,
    goal.definition.subdomain.name,
    goal.definition.grade.name,
  ];

  return (
    <div className="flex w-full flex-col">
      <div
        className={clsx(
          'break-words pb-2 text-neutral-100 typography-loud-sm',
          isDeleted ? 'text-neutral-700' : '',
        )}
      >
        {goal.definition.name}
      </div>
      <div className="flex w-full overflow-scroll md:overflow-auto">
        {tags.map((tag, idx) => (
          <div className="flex" key={idx}>
            <div className="text-neutral-700 typography-loud-xs">
              <Tag
                preset={isDeleted ? 'inactive' : 'grayStroke'}
                className={clsx('mr-2', isDeleted ? 'text-neutral-500' : '')}
              >
                {tag}
              </Tag>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleStudentGoalDetails;
