import { CalendarDate, CalendarDateTime, createCalendar, DateValue } from '@internationalized/date';
import { useRef } from 'react';
import { CalendarProps, useCalendar, useLocale } from 'react-aria';
import { useCalendarState } from 'react-stately';
import { IconChevronLeft, IconChevronRight } from 'src/icons';

import Button from '../Button';
import CalendarGrid from './CalendarGrid';

const Calendar = (props: CalendarProps<DateValue | CalendarDate | CalendarDateTime>) => {
  const { locale } = useLocale();

  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

  return (
    <div {...calendarProps} ref={ref} className="text-gray-800 inline-block p-1.5">
      <div className="flex items-center px-2 pb-4 pt-2">
        <h2 className="ml-2 flex-1 typography-heading-sm">{title}</h2>
        <Button
          Icon={IconChevronLeft}
          onClick={prevButtonProps.onPress as () => void}
          preset="tertiary"
          size="xs"
          className="mr-2"
        />
        <Button
          Icon={IconChevronRight}
          onClick={nextButtonProps.onPress as () => void}
          preset="tertiary"
          size="xs"
        />
      </div>
      <CalendarGrid state={state} />
    </div>
  );
};

export default Calendar;
