import { ListApiResponse } from 'src/types/list-api-response';
import { TPagination } from 'src/types/pagination.type';

export const getPageCount = <T>(pagination: ListApiResponse<T>) => {
  return pagination?.total && pagination?.pageSize
    ? Math.ceil(pagination?.total / pagination?.pageSize)
    : 0;
};

export const toQueryParams = (pagination: TPagination, defaultPageSize = 10) => {
  return `pageSize=${pagination.pageSize || defaultPageSize}&from=${
    (pagination.page - 1) * (pagination.pageSize || defaultPageSize)
  }`;
};
