import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import Card from 'src/components/Card';
import CardRowContactInfo from 'src/components/CardRowContactInfo';
import CenteredLoader from 'src/components/CenteredLoader';
import ScrollMenu from 'src/components/ScrollMenu';
import { splitCamelCase } from 'src/logic/splitCamelCase';
import { uppercaseFirstLetter } from 'src/logic/uppercaseFirstLetter';

import { SingleStudentContactGroups } from './singleStudent.types';
import { useSingleStudentContactsQuery } from './singleStudentApi';

const renameMapping = {
  providers: 'Provider(s)',
  supervisors: 'Supervisor(s)',
  schoolContacts: 'School Personnel',
  parents: 'Parent(s)',
};

const convertKeyToTitle = (key: string) => {
  if (renameMapping[key]) {
    return renameMapping[key];
  }
  return uppercaseFirstLetter(splitCamelCase(key));
};

const StudentContact = () => {
  const { id } = useParams();
  const { data } = useSingleStudentContactsQuery(id!);

  const contactGroups = data && (Object.keys(data) as SingleStudentContactGroups[]);

  const menu = contactGroups?.map((group) => ({
    label: convertKeyToTitle(group),
    name: group as string,
  }));

  return (
    <>
      {contactGroups?.length && menu ? (
        <div className="flex gap-6">
          <div className="hidden w-48 md:block">
            <ScrollMenu items={menu} className="top-10 " />
          </div>
          <div className="flex flex-grow flex-col">
            {contactGroups?.map((groupName, index) => (
              <Element key={groupName} name={menu[index].name} className="relative pb-4 ">
                <Card
                  className="!pb-6 !pt-4"
                  titleClassName="pb-4 mb-6 border-b border-b-neutral-900"
                  title={
                    convertKeyToTitle(groupName) === 'Parents'
                      ? 'Parent(s)'
                      : convertKeyToTitle(groupName)
                  }
                >
                  <div className="">
                    {data?.[groupName].length ? (
                      data?.[groupName].map((contact, index) => (
                        <CardRowContactInfo
                          key={contact.id}
                          contactsAmount={data?.[groupName].length}
                          currentContactIndex={index}
                          {...contact}
                          isParentsSection={convertKeyToTitle(groupName) === 'Parent(s)'}
                        />
                      ))
                    ) : (
                      <div className="text-neutral-300 typography-body-sm">
                        No contacts here yet
                      </div>
                    )}
                  </div>
                </Card>
              </Element>
            ))}
          </div>
        </div>
      ) : (
        <CenteredLoader />
      )}
    </>
  );
};

export default StudentContact;
