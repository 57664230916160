import Button from 'src/components/Button';
import Dot from 'src/components/Dot';
import ErrorMessage from 'src/components/ErrorMessage';
import ExtraLabel from 'src/components/ExtraLabel';
import Separator from 'src/components/Separator';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { formatTime } from 'src/logic/formatTime';

import { ConflictedSession, ScheduleItem } from './schedule.types';

type ScheduleSessionsFormSummaryProps = {
  start: string;
  finish: string;
  schedule: ScheduleItem[];
  conflictedSessions: ConflictedSession[];
  message?: string;
  onEdit: () => void;
};

const ScheduleSessionsFormSummary = ({
  start,
  finish,
  schedule,
  conflictedSessions,
  message,
  onEdit,
}: ScheduleSessionsFormSummaryProps) => {
  const { formatDate } = useTimeFormat();
  return (
    <div className="flex flex-col gap-6">
      <Separator />
      <div className="flex items-center justify-between">
        <ExtraLabel>Schedule</ExtraLabel>
        <Button preset="tertiary" size="sm" onClick={onEdit}>
          Edit
        </Button>
      </div>
      <div>
        <div className="text-neutral-300 typography-body-sm">Start - End Date</div>
        <div className="text-neutral-100 typography-loud-sm">
          {formatDate(start)} - {formatDate(finish)}
        </div>
      </div>
      <div>
        <div className="text-neutral-300 typography-body-sm">Session</div>
        {schedule.map((scheduleItem, index) => (
          <div className="flex gap-6 text-neutral-100 typography-loud-sm" key={index}>
            <div>Location: {scheduleItem.location}</div>
            <div className="flex">
              {scheduleItem.dayOfWeek.slice(0, 3)} <Dot preset="pacific" className="ml-2" />{' '}
              {formatTime(scheduleItem.start)} - {formatTime(scheduleItem.finish)}
            </div>
          </div>
        ))}
      </div>
      <ErrorMessage>{message}</ErrorMessage>
      {conflictedSessions.map((session) => (
        <div
          key={session.id}
          className="flex border-t border-t-neutral-800 pt-6 text-neutral-100 typography-loud-sm"
        >
          {formatDate(session.date)}{' '}
          <span className="ml-2">
            {formatTime(session.startTime)} - {formatTime(session.finishTime)}
          </span>
          <span className="ml-auto">{session.reason}</span>
        </div>
      ))}
      <ErrorMessage>{`Click "schedule" to save the schedule without these sessions.`}</ErrorMessage>
    </div>
  );
};

export default ScheduleSessionsFormSummary;
