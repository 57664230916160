import { IconEmpty } from 'src/icons';

const StudentNoNotes = () => {
  return (
    <div className="noGoal rounded-lg bg-neutral-950 p-6 text-center">
      <div className="flex justify-center text-center">
        <div className="h-10 w-10 rounded-[200px] bg-secondary-900">
          <IconEmpty className="m-2 h-6 w-6 text-secondary-300" />
        </div>
      </div>
      <div className="mb-4 mt-4 typography-heading-md">No notes yet</div>
    </div>
  );
};

export default StudentNoNotes;
