import { AppStorage } from './storage.type';

const buildKey = (prefix: string, key: string) => `${prefix}:${key}`;

export const lStorage = (keyPrefix: string): AppStorage => ({
  load: (k) => {
    const key = buildKey(keyPrefix, k);
    const v = localStorage.getItem(key);
    return v ? JSON.parse(v) : undefined;
  },
  save: (k, v) => {
    const key = buildKey(keyPrefix, k);
    localStorage.setItem(key, JSON.stringify(v));
  },
  remove: (k) => {
    const key = buildKey(keyPrefix, k);
    localStorage.removeItem(key);
  },
});
