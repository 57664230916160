import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';

import { NotificationGroup, Notifications } from './notification.types';

export const NOTIFICATIONS_PAGE_SIZE = 50;

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  tagTypes: ['Notifications'],
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    getNotifications: build.query<Notifications, { scrollId?: number; group: NotificationGroup }>({
      query: ({ scrollId, group }) => ({
        url: `/Notification?pageSize=${NOTIFICATIONS_PAGE_SIZE}`,
        params: {
          scrollId,
          pageSize: NOTIFICATIONS_PAGE_SIZE,
          group,
        },
      }),
      providesTags: (_response, _err, args) => {
        return [{ type: 'Notifications', id: args.group }];
      },
    }),
    getNotification: build.query<Notification, string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/Notification/${id}`,
      }),
    }),
    readAllNotifications: build.mutation<void, null>({
      query: () => ({
        method: 'PUT',
        url: `/Notification/read-all`,
      }),
      invalidatesTags: ['Notifications'],
    }),
    readNotification: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/Notification/${id}/read`,
        method: 'PUT',
      }),
      invalidatesTags: ['Notifications'],
    }),
    dismissNotification: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/Notification/${id}/dismiss`,
        method: 'PUT',
      }),
      invalidatesTags: ['Notifications'],
    }),
    getRealTimeEvents: build.query<{ notifications: string }, void>({
      query: () => ({
        method: 'GET',
        url: `/RealTimeEvents/channels`,
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationQuery,
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
  useDismissNotificationMutation,
  useGetRealTimeEventsQuery,
  util: { invalidateTags: invalidateNotificationsListTags },
} = notificationApi;
