import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BackdoorToken } from '../auth.types';
import { authStorage } from '../authStorage';

export type BackendAuthState = {
  accessToken?: string;
  user?: {
    firstName: string;
    lastName: string;
    email: string;
  };
};

const initialState: BackendAuthState = {
  accessToken: undefined,
  user: undefined,
};

const backendAuthSlice = createSlice({
  name: 'backendAuth',
  initialState: () => authStorage.load('backendAuthSlice') ?? initialState,
  reducers: {
    setBackdoorToken(state, action: PayloadAction<BackdoorToken>) {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    reset() {
      return initialState;
    },
  },
});

export const { reset, setBackdoorToken } = backendAuthSlice.actions;

export default backendAuthSlice;
