import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';
import { useGradesQuery } from 'src/dictionaries/dictionariesApi';

import { selectedCountLabel } from './selectedCountLabel';

type FilterByGradesProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
};

const FilterByGrades = ({ value, onChange, separator = true }: FilterByGradesProps) => {
  const { data: grades } = useGradesQuery();

  return (
    <Collapsible
      title={`Grade${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="grid grid-cols-2 gap-x-6 gap-y-2">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={grades?.map((s) => ({
            label: s.value,
            value: s.key,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterByGrades;
