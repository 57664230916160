import type { SVGProps } from 'react';
const SvgEmojiEmotions1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor">
      <path d="M10.333 7.333a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5.667 7.333a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8 12c1.52 0 2.813-1.107 3.333-2.667H4.667C5.187 10.893 6.48 12 8 12Z" />
      <path d="M7.993 1.333A6.663 6.663 0 0 0 1.333 8c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0 0 14.667 8a6.67 6.67 0 0 0-6.674-6.667Zm.007 12A5.332 5.332 0 0 1 2.667 8 5.332 5.332 0 0 1 8 2.667 5.332 5.332 0 0 1 13.333 8 5.332 5.332 0 0 1 8 13.333Z" />
    </g>
  </svg>
);
export default SvgEmojiEmotions1;
