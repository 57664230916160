import clsx from 'clsx';
import { isAfter, isBefore, isSameDay, isSameMonth, parseISO } from 'date-fns';
import { assoc } from 'ramda';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { DateValue } from 'react-aria';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import CenteredLoader from 'src/components/CenteredLoader';
import ErrorMessage from 'src/components/ErrorMessage';
import ExtraLabel from 'src/components/ExtraLabel';
import {
  Sidebar,
  SidebarContainer,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarTitle,
} from 'src/components/Sidebar';
import { Tooltip } from 'src/components/Tooltip';
import { useLocationQuery } from 'src/dictionaries/dictionariesApi';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useSelectedStudent from 'src/hooks/useSelectedStudent';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconCheck } from 'src/icons';
import { useSingleStudentDetailsQuery } from 'src/pages/single-student/singleStudentApi';
import { Student } from 'src/pages/students/students.types';
import { isFutureDate } from 'src/utils/isFutureDate';
import { keyValueToSelect } from 'src/utils/keyValueToSelect';
import { removeStringAfterColon } from 'src/utils/removeStringAfterColon';

import { ScheduleEvent } from '../schedule.types';
import { useLazyGetRecordSessionQuery, useStudentDisabledDaysQuery } from '../scheduleApi';
import CancelSessionModal, { useCancelSession } from './CancelSessionModal';
import DeleteSessionAlertDialog, { useDeleteSession } from './DeleteSessionAlertDialog';
import { RecordSessionContextProvider } from './RecordSessionContext';
import SessionFormPart from './SessionFormPart';
import StudentForm from './StudentForm';
import { useRecordSession } from './useRecordSession';

export type RecordSessionSidebarRefProps = {
  openWithSelectedDate: (selectedDate: Date) => void;
  openWithSelectedSession: (event: ScheduleEvent, options?: { limitDatePicker?: boolean }) => void;
};

type RecordSessionFormProps = {
  lockOnStudentId?: string;
  onSuccessCb?: () => void;
};

type RecordSessionFormBaseProps = {
  onSuccessCb: RecordSessionFormProps['onSuccessCb'];
};

const RecordSessionFormBase = forwardRef<RecordSessionSidebarRefProps, RecordSessionFormBaseProps>(
  function RecordSessionForm({ onSuccessCb }, ref) {
    const { data, dispatch, actions, isEdit, features } = useRecordSession({ onSuccessCb });
    const {
      state,
      formData,
      isLoading,
      errors: errorsTmp,
      isLoadingConfirm,
      isLoadingCreate,
      isLoadingReschedule,
      options,
    } = data;
    const { confirmSession, rescheduleSession } = actions;
    const { id } = useParams();
    const studentId = removeStringAfterColon(id);
    const { refetch, isUninitialized, currentData } = useSingleStudentDetailsQuery(studentId!, {
      skip: !studentId,
    });

    const { data: disabledDays } = useStudentDisabledDaysQuery(formData.studentId!, {
      skip: !formData.studentId,
    });

    const {
      session: sessionData,
      onCancelSession,
      onAbortSessionCancellation,
    } = useCancelSession();

    const [getRecordSession] = useLazyGetRecordSessionQuery();

    const { data: locations = [] } = useLocationQuery();
    const [isEditingConfirmedSession, setIsEditingConfirmedSession] = useState(false);
    const [isReschedulingCancelledSession, setIsReschedulingCancelledSession] = useState(false);
    const isSidebarOpen = state.isInitialized;
    const existingSession = 'existingSession' in state ? state.existingSession : undefined;
    const originDate = existingSession?.originDate || existingSession?.date;
    const isConfirmed = existingSession?.isConfirmed;
    const isEditable = existingSession?.isEditable;
    const isCancelled = existingSession?.isCancelled;
    const isRescheduled = existingSession?.isRescheduled;
    const canReschedule = existingSession?.canReschedule !== false;
    const existingSessionId = existingSession?.id;
    const serviceType = existingSession?.student?.serviceType;
    const { format } = useTimeFormat();
    const { isSm } = useBreakpoint('sm');
    const { selectedStudent } = useSelectedStudent(true, formData.studentId);

    const formDisabled = isConfirmed && !isEditingConfirmedSession;

    const locationsOptions = useMemo(
      () =>
        keyValueToSelect(
          locations.filter(
            (l) =>
              data.availability?.canSchedule &&
              data.availability.parameters.locations.includes(l.value),
          ),
        ),
      [data.availability, locations],
    );

    useEffect(() => {
      if (!isSidebarOpen) {
        setIsEditingConfirmedSession(false);
        setIsReschedulingCancelledSession(false);
      }
    }, [isSidebarOpen]);

    useEffect(() => {
      if (selectedStudent?.groupSize) {
        dispatch({ type: 'numberOfStudents', payload: 1 });
      }
    }, [selectedStudent?.groupSize, dispatch]);

    const {
      onDeleteSession,
      onCancelDeleteSession,
      sessionId: sessionIdToDelete,
    } = useDeleteSession();

    const closeForm = useCallback(() => {
      dispatch({ type: 'reset' });
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        openWithSelectedDate: (selectedDate: Date) => {
          const startTime = format(selectedDate, 'HH:mm');
          dispatch({
            type: 'init',
            payload: {
              parentDate: selectedDate,
              formData: {
                date: selectedDate.toISOString(),
                goals: [],
                startTime: startTime !== '00:00' ? startTime : undefined,
              },
            },
          });
        },
        openWithSelectedSession: async (event, options) => {
          try {
            dispatch({
              type: 'init',
              payload: { isLoading: true, parentEvent: event, formData: { goals: [] }, options },
            });
            dispatch({ type: 'loadingDone', payload: await getRecordSession(event.id).unwrap() });
          } catch (err) {
            dispatch({ type: 'reset' });
            console.error(err);
          }
        },
      }),
      [getRecordSession, dispatch, format],
    );

    const handleConfirmSession = () => {
      confirmSession({});
      if (!isUninitialized) {
        refetch();
      }
    };

    const handleCancelSession = () => {
      if ('existingSession' in state && state.existingSession?.id) {
        onCancelSession({
          sessionId: state.existingSession.id ?? '',
          date: formData.date ?? '',
          startTime: formData.startTime ?? '',
          location: formData.location ?? '',
          finishTime: formData.finishTime ?? '',
        });
        if (!isUninitialized) {
          refetch();
        }
      }
    };

    const handleStudentChange = useCallback(
      (student: Student) => {
        dispatch({ type: 'selectStudent', payload: student.id });
      },
      [dispatch],
    );

    const isDateDisabled = useCallback(
      (d: DateValue) => {
        const dDate = new Date(d.year, d.month - 1, d.day);
        const isLimitedToMonthAndIsNotThisMonth =
          !options.limitDatePicker || !originDate
            ? false
            : !isSameMonth(parseISO(originDate), dDate);

        const isReschedulingAndIsBeforeOriginDate =
          isReschedulingCancelledSession &&
          originDate &&
          !(isSameDay(dDate, parseISO(originDate)) || isAfter(dDate, parseISO(originDate)));

        const isOutOfPlacementRange =
          disabledDays?.startDate && disabledDays.finishDate
            ? isBefore(dDate, parseISO(disabledDays.startDate)) ||
              isAfter(dDate, parseISO(disabledDays.finishDate))
            : false;

        const isUnavailableDate = disabledDays?.unavailableDates.length
          ? disabledDays?.unavailableDates.some((unavailableDay) =>
              isSameDay(dDate, parseISO(unavailableDay)),
            )
          : false;

        return (
          isLimitedToMonthAndIsNotThisMonth ||
          !!isReschedulingAndIsBeforeOriginDate ||
          isOutOfPlacementRange ||
          isUnavailableDate
        );
      },
      [options.limitDatePicker, originDate, isReschedulingCancelledSession, disabledDays],
    );

    const selectedDateIsInFuture = useMemo(() => isFutureDate(formData.date), [formData.date]);

    const errors =
      selectedDateIsInFuture && !isReschedulingCancelledSession
        ? assoc('date', 'You cannot confirm sessions for future days', errorsTmp)
        : errorsTmp;

    const errorReschedule =
      !canReschedule && (isReschedulingCancelledSession || isRescheduled) && serviceType === 'Para'
        ? 'PARA sessions cannot be rescheduled'
        : '';

    const isMissingGoals =
      isEdit && formData.goals?.length === 0 && selectedStudent?.isPara !== true;
    const name = useMemo(() => {
      if (isEdit) {
        if (isLoading) {
          return '';
        }
        if (isCancelled) {
          return 'Reschedule session';
        }
        if (isConfirmed) {
          return 'Edit Session';
        }
        return 'Confirm session';
      }
      return 'Add session';
    }, [isEdit, isCancelled, isLoading, isConfirmed]);

    const confirmSessionValidationTooltip = useMemo(() => {
      if (selectedDateIsInFuture) return 'You cannot confirm session in the future';
      if (isMissingGoals) return `Session cannot be confirmed without goal progress.`;
      return;
    }, [selectedDateIsInFuture, isMissingGoals]);
    return (
      <>
        <SidebarContainer
          open={isSidebarOpen}
          onOpenChange={(open) => !open && dispatch({ type: 'reset' })}
        >
          <Sidebar open={isSidebarOpen}>
            <SidebarHeader>
              <SidebarTitle>{name}</SidebarTitle>
            </SidebarHeader>
            <SidebarContent className="flex flex-col gap-8">
              {isLoading ? (
                <CenteredLoader />
              ) : (
                <>
                  <StudentForm
                    isStudentLocked={!!isEdit || features.isStudentLocked}
                    onStudentSelect={handleStudentChange}
                    onStudentClear={() => dispatch({ type: 'clearStudent' })}
                    selectedStudentId={formData.studentId}
                    savedStudent={existingSession?.student}
                    error={errors?.studentId}
                    disabled={formDisabled}
                  />

                  <SessionFormPart
                    dispatch={dispatch}
                    studentId={formData.studentId}
                    locationsOptions={locationsOptions}
                    goals={formData.goals}
                    disabled={isCancelled || isRescheduled || formDisabled}
                    date={
                      isCancelled || isRescheduled
                        ? existingSession?.originDate || existingSession?.date
                        : formData.date
                    }
                    availability={data.availability}
                    startTime={
                      isCancelled || isRescheduled
                        ? existingSession?.originStartTime || existingSession?.startTime
                        : formData.startTime
                    }
                    finishTime={
                      isCancelled || isRescheduled
                        ? existingSession?.originFinishTime || existingSession?.finishTime
                        : formData.finishTime
                    }
                    location={
                      isCancelled || isRescheduled
                        ? existingSession?.originLocation || existingSession?.location
                        : formData.location
                    }
                    errors={isCancelled || isRescheduled || isRescheduled ? undefined : errors}
                    isDateDisabled={isDateDisabled}
                    note={isCancelled || isRescheduled ? undefined : formData.note}
                    cancellationReason={
                      isCancelled || isRescheduled
                        ? existingSession?.cancellationReason
                        : formData.cancellationReason
                    }
                    cancellationReasonId={
                      isCancelled || isRescheduled
                        ? existingSession?.cancellationReasonId
                        : formData.cancellationReasonId
                    }
                    numberOfStudents={
                      isCancelled || isRescheduled
                        ? existingSession?.numberOfStudents
                        : formData.numberOfStudents
                    }
                  />

                  {(isReschedulingCancelledSession || isRescheduled) && (
                    <div className="-mx-6 border-t border-t-neutral-800 px-6 pt-6">
                      <ExtraLabel className="mb-6">Makeup session</ExtraLabel>
                      <SessionFormPart
                        dispatch={dispatch}
                        studentId={formData.studentId}
                        locationsOptions={locationsOptions}
                        goals={formData.goals}
                        disabled={formDisabled}
                        date={formData.date}
                        availability={data.availability}
                        startTime={formData.startTime}
                        finishTime={formData.finishTime}
                        location={formData.location}
                        errors={errors}
                        isDateDisabled={isDateDisabled}
                        note={formData.note}
                        numberOfStudents={formData.numberOfStudents}
                      />
                    </div>
                  )}
                  {isConfirmed && !isEditable && (
                    <ErrorMessage>{`Finalized sessions cannot be edited.`}</ErrorMessage>
                  )}
                </>
              )}
            </SidebarContent>
            {existingSessionId && isEditable && !isSm && (
              <div className="px-6 py-4">
                <Button
                  preset="warning"
                  size="sm"
                  className={clsx(!formData.cancellationReasonId && 'w-full')}
                  onClick={onDeleteSession.bind(null, existingSessionId)}
                >
                  Delete
                </Button>
              </div>
            )}
            <SidebarFooter error={errors?._ || errorReschedule}>
              {isConfirmed && !isEditingConfirmedSession ? (
                <>
                  <Button preset="quaternary" disabled Icon={IconCheck}>
                    Session confirmed
                  </Button>
                  {isEditable && (
                    <Button preset="tertiary" onClick={() => setIsEditingConfirmedSession(true)}>
                      Edit Session
                    </Button>
                  )}
                </>
              ) : existingSession?.cancellationReasonId ? (
                isReschedulingCancelledSession || isRescheduled ? (
                  <>
                    {!formData.date ? (
                      <Tooltip content="You have to select the date to be able to confirm the session">
                        <Button disabled={!!errorReschedule} preset="secondary">
                          Confirm Session
                        </Button>
                      </Tooltip>
                    ) : selectedDateIsInFuture ? (
                      <Button
                        disabled={!!errorReschedule}
                        preset="secondary"
                        isLoading={isLoadingReschedule}
                        onClick={rescheduleSession}
                      >
                        Reschedule Session
                      </Button>
                    ) : (
                      <Tooltip
                        side="bottom"
                        content={
                          isMissingGoals
                            ? `Session cannot be confirmed without goal progress.`
                            : undefined
                        }
                        delayDuration={0}
                        className="w-72"
                      >
                        <Button
                          preset="secondary"
                          isLoading={isLoadingConfirm}
                          onClick={handleConfirmSession}
                          disabled={isMissingGoals || !!errorReschedule}
                        >
                          Confirm Session
                        </Button>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Button
                    preset="secondary"
                    isLoading={isLoadingReschedule}
                    onClick={() => setIsReschedulingCancelledSession(true)}
                  >
                    Reschedule Session
                  </Button>
                )
              ) : (
                <div className="grid w-full grid-cols-2 gap-4">
                  <Tooltip
                    side="bottom"
                    content={confirmSessionValidationTooltip}
                    delayDuration={0}
                  >
                    <Button
                      preset="secondary"
                      onClick={handleConfirmSession}
                      isLoading={isLoadingConfirm || isLoadingCreate}
                      disabled={selectedDateIsInFuture || isMissingGoals}
                    >
                      Confirm Session
                    </Button>
                  </Tooltip>

                  {isEdit && (
                    <Button preset="tertiary" onClick={handleCancelSession}>
                      Cancel Session
                    </Button>
                  )}
                </div>
              )}

              {existingSessionId && isEditable && isSm && (
                <Button
                  preset="warning"
                  className={clsx(!formData.cancellationReasonId && 'ml-auto')}
                  onClick={onDeleteSession.bind(null, existingSessionId)}
                >
                  Delete
                </Button>
              )}
            </SidebarFooter>
          </Sidebar>
        </SidebarContainer>
        <CancelSessionModal
          onAbort={onAbortSessionCancellation}
          onSuccessCb={closeForm}
          cancelSessionData={sessionData}
        />

        <DeleteSessionAlertDialog
          sessionId={sessionIdToDelete}
          onCancel={onCancelDeleteSession}
          onSuccessCb={closeForm}
        />
      </>
    );
  },
);

const RecordSessionForm = forwardRef<RecordSessionSidebarRefProps, RecordSessionFormProps>(
  ({ onSuccessCb, ...props }, ref) => {
    return (
      <RecordSessionContextProvider {...props}>
        <RecordSessionFormBase ref={ref} onSuccessCb={onSuccessCb}></RecordSessionFormBase>
      </RecordSessionContextProvider>
    );
  },
);
RecordSessionForm.displayName = 'RecordSessionFormWrapper';

export default RecordSessionForm;
