export const pasteAsPlainText = (event: React.ClipboardEvent<HTMLDivElement>) => {
  event.preventDefault();
  const text = event.clipboardData.getData('text/plain');
  const selection = document.getSelection();
  let anchorNode = selection?.anchorNode;
  const anchorOffset = selection?.anchorOffset || 0;
  const isContentEditable = !anchorNode?.childNodes.length;
  if (anchorNode && selection) {
    const content = anchorNode.textContent || '';
    anchorNode.textContent = content.slice(0, anchorOffset) + text + content.slice(anchorOffset);
    if (isContentEditable) anchorNode = anchorNode.firstChild || anchorNode;
    const range = document.createRange();
    range.setStart(anchorNode, anchorOffset + text.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
  event.currentTarget.dispatchEvent(new Event('input', { bubbles: true }));
};

export const setCaretOnContentEditableEnd = (node: HTMLDivElement, delay = 100) => {
  setTimeout(() => {
    const selection = document.getSelection();
    if (selection) {
      const range = document.createRange();
      const endPosition = node?.textContent?.length;
      if (endPosition && node.firstChild) {
        range.setStart(node.firstChild, endPosition || 0);
        range.collapse(true);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  }, delay);
};
