import type { SVGProps } from 'react';
const SvgCube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="m8.5.5 7 3v9l-7 3-7-3v-9l7-3Z" />
      <path d="m3.5 4.5 5 2 5-2M8.5 6.5v7" />
    </g>
  </svg>
);
export default SvgCube;
