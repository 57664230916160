import { InvoiceBase } from './billing.types';

export const getFinalizeTooltipMessage = (
  invoice:
    | Pick<
        InvoiceBase,
        'hasUnconfirmedSessions' | 'previousInvoiceSubmitted' | 'noSessions' | 'noSignature'
      >
    | undefined,
) => {
  if (!invoice) return undefined;

  const { hasUnconfirmedSessions, previousInvoiceSubmitted, noSessions, noSignature } = invoice;

  return hasUnconfirmedSessions
    ? 'Billing cannot be finalized prior to confirming scheduled sessions.'
    : previousInvoiceSubmitted === false
    ? 'Billing must first be finalized for previous months'
    : noSessions
    ? 'There is no sessions'
    : noSignature
    ? 'Invoices cannot be finalized prior to signature approval'
    : undefined;
};
