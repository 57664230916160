import { Tooltip } from 'src/components/Tooltip';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconInfo } from 'src/icons';

import { InvoiceOnList } from './billing.types';

type BillingSignerTooltipProps = {
  signer: NonNullable<InvoiceOnList['signers']>[0];
};

const BillingSignerRequestInfo = ({ signer, ...props }: BillingSignerTooltipProps) => {
  const displayInfo = !!signer.requestedAt || !!signer.requestedAgainAt;
  const { format } = useTimeFormat();
  if (!displayInfo) {
    return null;
  }

  return (
    <Tooltip
      side="left"
      trigger="hover"
      content={
        <div className="flex flex-col gap-3">
          {signer.requestedAt && (
            <div>
              <span className="text-neutral-700">Request sent:</span>{' '}
              {format(signer.requestedAt, 'MM/dd/yyyy')}
            </div>
          )}
          {signer.requestedAgainAt && (
            <div>
              <span className="text-neutral-700">
                Request resent
                {signer?.numberOfRequests && signer?.numberOfRequests > 1
                  ? `(${signer.numberOfRequests} times)`
                  : ''}
                :
              </span>{' '}
              {format(signer.requestedAgainAt, 'MM/dd/yyyy')}
            </div>
          )}
        </div>
      }
    >
      <div>
        <IconInfo className="ml-1" />
      </div>
    </Tooltip>
  );
};

export default BillingSignerRequestInfo;
