import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import DistributionBar from 'src/components/DistributionBar';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconInfo } from 'src/icons';
import { getMonthName } from 'src/logic/getMonthName';
import { twMerge } from 'tailwind-merge';

import { BillingMonthSummary } from './billing.types';

type BillingMonthSummaryCardProps = BillingMonthSummary & {
  isActive?: boolean;
  className?: string;
  showCurrentMonth?: boolean;
  size?: 'md' | 'lg';
  disabled?: boolean;
};

const BillingMonthSummaryCard = ({
  disabled,
  size = 'md',
  ...props
}: BillingMonthSummaryCardProps) => {
  const { isSm } = useBreakpoint('sm');
  const isCurrentMonth =
    props.showCurrentMonth &&
    props.month === new Date().getMonth() + 1 &&
    props.year === new Date().getFullYear();

  const { pathname } = useLocation();
  const isBillingMonthPath = pathname.startsWith('/billing/month');

  const distribution = [
    { label: 'Incomplete invoices', value: props.incompleteCount },
    { label: 'Pending invoices', value: props.pendingCount },
    { label: 'Submitted invoices', value: props.submittedCount },
    { label: 'Paid invoices', value: props.paidCount },
  ];

  const tooltipContent = distribution.map((item) => `${item.label}: ${item.value}`).join('\n');

  return (
    <Card
      className={twMerge(
        'box-border w-full flex-shrink-0 flex-grow-0 border-1.5 border-transparent px-6 py-4 text-left ',
        size === 'lg' ? 'h-36 w-full' : 'h-30',
        props.isActive && 'border-secondary-400',
        props.className,
        disabled && 'cursor-default',
      )}
      tagText={isCurrentMonth ? 'Current month' : undefined}
    >
      <div
        className={clsx(
          'flex h-6 items-center justify-between typography-heading-md',
          size === 'lg' ? 'mb-8' : 'mb-6',
          disabled ? 'text-neutral-700' : 'text-neutral-100',
        )}
      >
        {getMonthName(props.month)} {props.year}
        {!isSm && isBillingMonthPath && (
          <div>
            <Tooltip content={tooltipContent} side="top" trigger="hover">
              <Button preset={null} className="p-0">
                <IconInfo className="h-6 w-6 text-neutral-400" />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <DistributionBar items={distribution} inactive={disabled} />
      <div
        className={clsx(
          'whitespace-nowrap typography-body-sm',
          size === 'lg' ? 'mt-6' : 'mt-4',
          disabled ? 'text-neutral-700' : 'text-neutral-300',
        )}
      >
        Incomplete invoices: {props.incompleteCount} / {props.totalCount}
      </div>
    </Card>
  );
};

export default BillingMonthSummaryCard;
