import { ChangeEventHandler } from 'react';
import { useState } from 'react';
import Button from 'src/components/Button';
import Search from 'src/components/Search';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconArrowLeft, IconPlus, IconSearch } from 'src/icons';
import { routes } from 'src/routes';
import { useAppDispatch, useAppSelector } from 'src/store';

import BillingListWithAppliedFilters from './BillingListWithAppliedFilters';
import BillingMonthDefault from './BillingMonthDefault';
import BillingMonthFilters from './BillingMonthFilters';
import BillingMonthFiltersList from './BillingMonthFiltersList';
import BillingSchoolYearPicker from './BillingSchoolYearPicker';
import { selectBillingActiveSchoolYear } from './billingSelectors';
import { selectBillingMonthFiltersCount, selectBillingQueryString } from './billingSelectors';
import { setBillingMonthQuery } from './billingSlice';

type BillingMonthProps = {
  hideTabs: () => void;
};

const BillingMonth: React.FC<BillingMonthProps> = ({ hideTabs }) => {
  const dispatch = useAppDispatch();
  const queryString = useAppSelector(selectBillingQueryString);
  const filtersCount = useAppSelector(selectBillingMonthFiltersCount);
  const onChangeQuery: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(setBillingMonthQuery(e.target.value));
  };
  const { isSm } = useBreakpoint('sm');
  const { isMd } = useBreakpoint('md');
  const { isXl } = useBreakpoint('xl');
  const activeSchoolYear = useAppSelector(selectBillingActiveSchoolYear);

  const [isSearchOpen, setSearchOpen] = useState(false);

  const handleSearchClick = (e) => {
    setSearchOpen(!isSearchOpen);
    dispatch(setBillingMonthQuery(e.target.value));
    hideTabs();
  };

  return (
    <div>
      {!isSearchOpen ? (
        <>
          {!isXl && (
            <div className="px-4 md:px-0">
              <div className="mb-2 mt-4 typography-heading-lg md:ml-4">
                {!isXl &&
                  `School Year ${activeSchoolYear?.schoolYearStart || '2023'}/${
                    activeSchoolYear?.schoolYearEnd || '2024'
                  }`}
              </div>
            </div>
          )}
          <div className="flex items-end justify-between gap-2 px-4 py-4 md:py-6 xl:items-center xl:justify-center">
            <BillingSchoolYearPicker />
            <div className="flex gap-2 md:ml-auto md:gap-4">
              {isSm && (
                <Button
                  Icon={IconPlus}
                  size="md"
                  to={`${routes.billing.root}/${routes.billing.finalizeAll}`}
                >
                  Finalize all invoices
                </Button>
              )}
              {isMd ? (
                <Search
                  size={isMd ? 'md' : 'sm'}
                  className="w-auto xl:w-96"
                  value={queryString}
                  onChange={onChangeQuery}
                />
              ) : (
                <Button
                  Icon={IconSearch}
                  preset="tertiary"
                  size="md"
                  onClick={handleSearchClick}
                  className="h-10 w-10 gap-0 sm:h-12 sm:w-12 md:gap-2"
                />
              )}

              <BillingMonthFilters />
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center bg-neutral-white p-4">
          <div className="px-3">
            <IconArrowLeft className="h-5 w-5 text-neutral-400" onClick={handleSearchClick} />
          </div>
          <Search size="md" className="w-full" value={queryString} onChange={onChangeQuery} />
        </div>
      )}
      {!isSm && !isSearchOpen && (
        <div className="mb-4 px-4">
          <Button
            Icon={IconPlus}
            size="md"
            to={`${routes.billing.root}/${routes.billing.finalizeAll}`}
          >
            Finalize all invoices
          </Button>
        </div>
      )}
      {!isSearchOpen && <BillingMonthFiltersList />}

      {queryString || filtersCount || isSearchOpen ? (
        <BillingListWithAppliedFilters />
      ) : (
        <BillingMonthDefault />
      )}
    </div>
  );
};

export default BillingMonth;
