import EmptyState from 'src/components/EmptyState';

type StudentsListEmptyProps = { areFiltersApplied: boolean };

const StudentsListEmpty = ({ areFiltersApplied }: StudentsListEmptyProps) => (
  <EmptyState
    title={areFiltersApplied ? 'All students filtered out' : 'Hang on.'}
    description={
      areFiltersApplied
        ? 'Please check your filtering criteria'
        : 'Your student(s) will be here shortly!'
    }
  />
);

export default StudentsListEmpty;
