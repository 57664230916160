import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authStorage } from './authStorage';

type AuthProviderType = 'aws' | 'backend';

export type AuthState = {
  provider: AuthProviderType;
};

const initialState: AuthState = {
  provider: 'aws',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: () => authStorage.load('authSlice') ?? initialState,
  reducers: {
    setProvider(state, action: PayloadAction<AuthProviderType>) {
      state.provider = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { reset, setProvider } = authSlice.actions;

export default authSlice;
