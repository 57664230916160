import { Hub } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from 'src/store';

import { AuthProvider, BackdoorToken, UserSession } from '../auth.types';
import { setProvider } from '../authSlice';
import { reset as resetAuth } from '../authSlice';
import { selectAccessToken, selectCurrentUser } from './backendAuthSelectors';
import { reset, setBackdoorToken } from './backendAuthSlice';

export const useBackendAuth = (): AuthProvider => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken) ?? '';
  const currentUser = useAppSelector(selectCurrentUser);

  const currentSession = (): Promise<UserSession> =>
    Promise.resolve({
      getIdToken: () => ({
        getJwtToken: () => accessToken,
      }),
    });

  const signOut = () => {
    dispatch(reset());
    dispatch(resetAuth());
    Hub.dispatch('auth', { event: 'signOut' });
    return Promise.resolve();
  };

  const currentAuthenticatedUser = () =>
    Promise.resolve({
      attributes: {
        email: currentUser?.email ?? '',
        family_name: currentUser?.lastName ?? '',
        given_name: currentUser?.firstName ?? '',
      },
    });

  const backdoorSignIn = (backdoorToken: BackdoorToken) => {
    dispatch(setProvider('backend'));
    dispatch(setBackdoorToken(backdoorToken));
    Hub.dispatch('auth', { event: 'signIn' });
    return Promise.resolve();
  };

  return {
    currentSession,
    signOut,
    currentAuthenticatedUser,
    backdoorSignIn,
    signIn: () => Promise.reject('Not implemented'),
    forgotPassword: () => Promise.reject('Not implemented'),
    completeNewPassword: () => Promise.reject('Not implemented'),
    forgotPasswordSubmit: () => Promise.reject('Not implemented'),
    changePassword: () => Promise.reject('Not implemented'),
  };
};
