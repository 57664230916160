import { CalendarApi } from '@fullcalendar/core';
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconExport, IconLock } from 'src/icons';
import { OutOfOfficeFormRefProps } from 'src/pages/schedule/out-of-office/OutOfOfficeForm';
import { routes } from 'src/routes';
import { twMerge } from 'tailwind-merge';

import { VIEW_OPTIONS } from '../utils/viewOptions.const';
import ContextMenu from './ContextMenu';
import HorizontalTabsButtons from './HorizontalTabsButtons';
import LeftRightButtons from './LeftRightButtons';
import Switch from './Switch';

export type ScheduleHeaderProps = {
  className?: string;
  calendarRef: RefObject<CalendarApi>;
  onExport?: () => void;
  currentView?: string;
  setCurrentView?: Dispatch<SetStateAction<string | undefined>>;
  onChange: () => void;
  hasMyPlans?: boolean;
  hasOutOfOffice?: boolean;
  hasViewOptions?: boolean;
  hasShowToday?: boolean;
  hasShowOnlyMyClasses?: boolean;
  showOnlyMyClassesRef?: MutableRefObject<boolean>;
  outOfOfficeFormRef?: RefObject<OutOfOfficeFormRefProps>;
  initialDate?: string;
};

const ScheduleHeader = ({
  className,
  calendarRef,
  onExport,
  currentView,
  setCurrentView,
  onChange,
  hasMyPlans,
  hasOutOfOffice,
  hasViewOptions = true,
  hasShowToday = true,
  hasShowOnlyMyClasses = false,
  showOnlyMyClassesRef,
  outOfOfficeFormRef,
  initialDate,
}: ScheduleHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSm } = useBreakpoint('sm');
  const { format } = useTimeFormat();
  const [currentTitle, setCurrentTitle] = useState<string | undefined>(
    isSm ? format(Date.now(), 'MMMM yyyy') : format(Date.now(), 'MMM yyyy'),
  );
  const isMyPlansView = location.pathname.endsWith('my-plans');

  useEffect(() => {
    if (initialDate && calendarRef?.current?.view?.title) {
      setCurrentTitle(calendarRef?.current?.view?.title);
    }
  }, [initialDate, calendarRef]);

  const handleGoToToday = useCallback(() => {
    const calendarApi = calendarRef?.current;
    calendarApi?.today();
    setCurrentTitle(calendarApi?.view?.title);
    onChange();
  }, [calendarRef, onChange]);

  const handleSelectView = useCallback(
    (view: string) => {
      const update = () => {
        const calendarApi = calendarRef?.current;
        calendarApi?.changeView(view);
        calendarApi?.today();
        setCurrentTitle(calendarApi?.view?.title);
        setCurrentView && setCurrentView(view);
        onChange();
      };
      if (hasMyPlans) {
        navigate(routes.schedule.root);
        setTimeout(update, 0);
        return;
      }
      update();
    },
    [calendarRef, navigate, setCurrentView, onChange, hasMyPlans],
  );

  const handlePrevious = useCallback(() => {
    const calendarApi = calendarRef?.current;
    calendarApi?.prev();
    setCurrentTitle(calendarApi?.view?.title);
    onChange();
  }, [calendarRef, onChange]);

  const handleNext = useCallback(() => {
    const calendarApi = calendarRef?.current;
    calendarApi?.next();
    setCurrentTitle(calendarApi?.view?.title);
    onChange();
  }, [calendarRef, onChange]);

  const toggleShowOnlyMyClasses = useCallback(() => {
    if (showOnlyMyClassesRef?.current !== undefined) {
      showOnlyMyClassesRef.current = !showOnlyMyClassesRef.current;
      onChange();
    }
  }, [onChange, showOnlyMyClassesRef]);

  return (
    <header className={twMerge('flex justify-between px-4 py-6 md:px-10', className)}>
      {isMyPlansView ? (
        <div className="flex items-center whitespace-nowrap typography-heading-lg">
          My Schedules
        </div>
      ) : (
        <>
          <div className="flex items-center gap-4">
            {hasShowToday && (
              <Button
                className="hidden xl:block"
                preset="tertiary"
                size="md"
                onClick={handleGoToToday}
              >
                Show today
              </Button>
            )}
            <LeftRightButtons
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              size={isSm ? 'md' : 'sm'}
            />
            <div className="text-neutral-200 typography-heading-lg">{currentTitle}</div>
          </div>
          <div className="xl:hidden">
            <ContextMenu
              calendarRef={calendarRef}
              currentView={currentView}
              setCurrentView={setCurrentView}
              hasMyPlans={hasMyPlans}
              onExport={onExport}
              showOnlyMyClassesRef={showOnlyMyClassesRef}
              setCurrentTitle={setCurrentTitle}
              onChange={onChange}
            />
          </div>
        </>
      )}
      <div className="hidden items-center gap-4 xl:flex">
        <div className="hidden gap-4 xl:flex">
          {hasShowOnlyMyClasses && (
            <Switch
              label="Show my sessions only"
              checked={showOnlyMyClassesRef?.current}
              onCheckedChange={toggleShowOnlyMyClasses}
              withFrame
            />
          )}
          {hasViewOptions && (
            <HorizontalTabsButtons
              tabs={VIEW_OPTIONS}
              activeTab={isMyPlansView ? undefined : currentView}
              onSelect={handleSelectView}
            />
          )}
        </div>
        {hasMyPlans && (
          <Link to={routes.schedule.myPlans}>
            <Button
              preset="tertiary"
              size="md"
              className={isMyPlansView ? 'border-secondary-300 text-secondary-300' : ''}
            >
              My Schedules
            </Button>
          </Link>
        )}
        {hasOutOfOffice && (
          <Button
            preset="tertiary"
            onClick={() => {
              outOfOfficeFormRef?.current?.open();
            }}
            Icon={IconLock}
            size="md"
          >
            Block Dates
          </Button>
        )}
        {onExport &&
          !isMyPlansView &&
          VIEW_OPTIONS.some(
            ({ value, exportAvailable }) => value === currentView && exportAvailable,
          ) && (
            <Button
              preset="tertiary"
              onClick={onExport}
              Icon={IconExport}
              iconClassName="color: text-neutral-500"
              size={isSm ? 'md' : 'sm'}
            >
              Export Page
            </Button>
          )}
      </div>
    </header>
  );
};

export default ScheduleHeader;
