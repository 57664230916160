import { CalendarApi } from '@fullcalendar/core';
import { forwardRef, RefObject } from 'react';
import Calendar from 'src/components/Calendar';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { OutOfOfficeFormRefProps } from 'src/pages/schedule/out-of-office/OutOfOfficeForm';
import { RecordSessionSidebarRefProps } from 'src/pages/schedule/record-session/RecordSessionForm';

type Props = {
  export;
  currentView;
  events;
  recordSessionFormRef: RefObject<RecordSessionSidebarRefProps>;
  outOfOfficeFormRef?: RefObject<OutOfOfficeFormRefProps>;
};

const ScheduleCalendar = forwardRef<CalendarApi, Props>((props, calendarRef) => {
  const { outOfOfficeFormRef, recordSessionFormRef } = props;
  const { isSm } = useBreakpoint('sm');

  return (
    <>
      <Calendar
        ref={calendarRef}
        initialView={props.currentView}
        events={props.events}
        height={'auto'}
        dayMaxEventRows={false}
        headerToolbar={false}
        dayMaxEvents={isSm ? 3 : 2}
        moreLinkContent={function (args) {
          return args.num + ' more...';
        }}
        allDaySlot={false}
        editable={true}
        export={props.export}
        onRecordSessionClick={(selectedDate) => {
          recordSessionFormRef.current?.openWithSelectedDate(selectedDate);
        }}
        onEventClick={(event) => {
          if (event.eventType === 'Session') {
            recordSessionFormRef.current?.openWithSelectedSession(event);
          } else if (event.eventType === 'OutOfOffice' && outOfOfficeFormRef) {
            outOfOfficeFormRef?.current?.open(event);
          }
        }}
      ></Calendar>
    </>
  );
});

ScheduleCalendar.displayName = 'ScheduleCalendar';

export default ScheduleCalendar;
