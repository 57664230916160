import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';

import { SupportTicket } from './contactSupport.types';

export const contactSupportApi = createApi({
  reducerPath: 'contactSupportApi',
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    submitTicket: build.mutation<void, SupportTicket>({
      query: (body) => ({
        method: 'POST',
        url: '/support/ticket',
        body,
      }),
    }),
  }),
});

export const { useSubmitTicketMutation } = contactSupportApi;
