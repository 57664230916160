import { Amplify, Hub } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/auth/useAuth';

import { useLazyMeQuery } from './authApi';
import { awsConfig } from './awsConfig';

export const useIsLoggedIn = () => {
  const { currentAuthenticatedUser } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [fetch] = useLazyMeQuery();

  const checkUser = useCallback(async () => {
    try {
      await currentAuthenticatedUser();
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
    }
  }, [currentAuthenticatedUser]);

  useEffect(() => {
    Amplify.configure(awsConfig);
    checkUser();

    const authChangedListener = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          fetch();
          setIsLoggedIn(true);
          break;
        case 'signOut':
          setIsLoggedIn(false);
          break;
      }
    });

    return () => {
      authChangedListener();
    };
  }, [checkUser, fetch]);

  return isLoggedIn;
};
