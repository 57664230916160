import Card from 'src/components/Card';
import Separator from 'src/components/Separator';

const SingleStudentLoadingCard = () => {
  return (
    <Card className="relative flex flex-col px-4">
      <div className="flex gap-4">
        <div className="h-14 w-14 animate-pulse rounded-full bg-gradient-to-r from-neutral-900 to-neutral-900/30"></div>
        <div className="overflow-hidden">
          <div className="mb-1 h-5 w-32 animate-pulse truncate rounded bg-gradient-to-r from-neutral-900 to-neutral-900/30"></div>
          <div className="flex items-center gap-4">
            <span className="h-8 w-64 animate-pulse truncate rounded bg-gradient-to-r from-neutral-900 to-neutral-900/30"></span>
          </div>
        </div>
      </div>
      <Separator className="mb-4 mt-4.5" />
      <div className="flex gap-2">
        <div className="h-8 w-24 animate-pulse truncate rounded bg-gradient-to-r from-neutral-900 to-neutral-900/30"></div>
        <div className="h-8 w-24 animate-pulse truncate rounded bg-gradient-to-r from-neutral-900 to-neutral-900/30"></div>
        <div className="h-8 w-32 animate-pulse truncate rounded bg-gradient-to-r from-neutral-900 to-neutral-900/30"></div>
      </div>
    </Card>
  );
};

export default SingleStudentLoadingCard;
