import clsx from 'clsx';
import Avatar from 'src/components/Avatar';
import { useBreakpoint } from 'src/hooks/useBreakpoint';

const StudentHeaderLoading = () => {
  const { isSm } = useBreakpoint('sm');
  const skeletonClass =
    'animate-pulse truncate rounded bg-gradient-to-r from-neutral-900 to-neutral-900/30';
  return (
    <>
      {isSm ? (
        <div className="flex ">
          <div className={'flex gap-x-8'}>
            <Avatar firstName="" lastName="" url="" size="3xl" />
            <div className="flex flex-col">
              <div className={clsx(skeletonClass, 'mb-2 flex h-7 w-40')}></div>
              <div className={clsx(skeletonClass, 'mb-1 h-7 w-52')}></div>
              <div className={clsx(skeletonClass, 'h-4 w-32')}></div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className=" flex items-center gap-x-2">
                <div className={clsx(skeletonClass, 'h-8 w-44')}></div>
                <div className={clsx(skeletonClass, 'h-8 w-14')}></div>
                <div className={clsx(skeletonClass, 'h-8 w-14')}></div>
              </div>
              <div className=" flex items-center gap-x-2">
                <div className={clsx(skeletonClass, 'h-8 w-40')}></div>
                <div className={clsx(skeletonClass, 'h-8 w-40')}></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className={'mb-4 flex gap-x-8'}>
            <Avatar firstName="" lastName="" url="" size="3xl" />
            <div className="flex flex-col">
              <div className={clsx(skeletonClass, 'mb-2 flex h-7 w-40')}></div>
              <div className={clsx(skeletonClass, 'mb-1 h-7 w-52')}></div>
              <div className={clsx(skeletonClass, 'h-4 w-32')}></div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className=" flex items-center gap-x-2">
                <div className={clsx(skeletonClass, 'h-6 w-40')}></div>
                <div className={clsx(skeletonClass, 'h-6 w-14')}></div>
                <div className={clsx(skeletonClass, 'h-6 w-14')}></div>
              </div>
              <div className="mt-4 flex flex-col gap-x-2 gap-y-2">
                <div className={clsx(skeletonClass, 'h-6 w-32')}></div>
                <div className={clsx(skeletonClass, 'h-6 w-36')}></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentHeaderLoading;
