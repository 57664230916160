import { useMemo, useState } from 'react';
import { useNotifications } from 'src/hooks/useNotifications';
import { IconBell } from 'src/icons';
import NavigationButton from 'src/layout/NavigationButton';
import { NotificationGroup } from 'src/notification/notification.types';
import { useReadAllNotificationsMutation } from 'src/notification/notificationApi';

import Button from './Button';
import MobileFullScreenDialog from './MobileFullScreenDialog';
import NotificationItem from './NotificationItem';
import Tabs from './Tabs';

const MobileNotifications = () => {
  const [submit] = useReadAllNotificationsMutation();
  const { notifications, unreadCount, isOpen, setIsOpen } = useNotifications();
  const [selectedTab, setSelectedTab] = useState(NotificationGroup.ACTIONABLE.toString());
  const items = useMemo(
    () =>
      Object.values(NotificationGroup).map((value) => ({
        id: value,
        label: value,
        count: notifications[value].length,
      })),
    [notifications],
  );
  const notificationsForSelectedGroup = notifications[selectedTab as NotificationGroup];
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <NavigationButton
        isActive={isOpen}
        Icon={IconBell}
        label={`Notifications (${unreadCount || 0})`}
        isCollapsed={true}
        count={unreadCount || undefined}
        onClick={toggleDropdown}
        className="!mb-0"
        iconClassName="text-neutral-500"
      />
      <MobileFullScreenDialog
        dialogTitle="Notifications"
        open={isOpen}
        onOpenChange={toggleDropdown}
      >
        <>
          <div className="border-b border-b-neutral-800 px-4 py-6">
            <Button
              className="w-full"
              preset="tertiary"
              size="sm"
              onClick={() => submit(null)}
              disabled={unreadCount === 0}
            >
              Mark all as read
            </Button>
          </div>
          <Tabs
            className="my-2 px-4"
            onSelect={setSelectedTab}
            active={selectedTab}
            items={items}
            size="md"
            underline
          />
          <div className="mx-auto h-screen overflow-scroll">
            {notificationsForSelectedGroup.length > 0 ? (
              notificationsForSelectedGroup.map((notification) => (
                <NotificationItem setIsOpen={setIsOpen} key={notification.id} {...notification} />
              ))
            ) : (
              <p className="py-10 text-center text-neutral-100 typography-heading-xs">
                Empty notification list
              </p>
            )}
          </div>
        </>
      </MobileFullScreenDialog>
    </div>
  );
};

export default MobileNotifications;
