import AppliedFiltersList from 'src/filters/AppliedFiltersList';
import { useAppDispatch, useAppSelector } from 'src/store';

import { StudentFilters } from './students.types';
import { selectStudentsFilters, selectStudentsFiltersCount } from './studentsSelectors';
import { setStudentsFilters } from './studentsSlice';

const StudentsFiltersList = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectStudentsFilters);
  const filtersCount = useAppSelector(selectStudentsFiltersCount);

  const onChangeFilters = (filters: StudentFilters) => {
    dispatch(setStudentsFilters(filters));
  };

  return (
    <AppliedFiltersList filters={filters} onChange={onChangeFilters} filtersCount={filtersCount} />
  );
};

export default StudentsFiltersList;
