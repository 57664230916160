import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SingleStudentAllGoalsFilters } from './singleStudent.types';
// Goals
type StudentState = {
  page: number;
  query?: string;
  filters: SingleStudentAllGoalsFilters;
  showInactive: boolean;
};

const initialState: StudentState = {
  page: 1,
  filters: {
    showMastered: true,
  },
  showInactive: false,
};

const studentPageSlice = createSlice({
  name: 'studentPage',
  initialState,
  reducers: {
    setStudentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setStudentQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
      state.page = initialState.page;
    },
    setStudentFilters(state, action: PayloadAction<StudentState['filters']>) {
      state.filters = action.payload;
      state.page = initialState.page;
    },
    setShowInactiveStudent(state, action: PayloadAction<boolean>) {
      state.showInactive = action.payload;
      state.page = initialState.page;
    },
    resetStudent() {
      return initialState;
    },
  },
});

export const {
  setStudentQuery,
  setStudentPage,
  setStudentFilters,
  resetStudent,
  setShowInactiveStudent,
} = studentPageSlice.actions;

export default studentPageSlice;
