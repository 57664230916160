import logo from '../assets/logo.svg';
import logoCollapsed from '../assets/logo_collapsed.svg';

type LogoProps = {
  className?: string;
  isCollapsed?: boolean;
};

const Logo = ({ className, isCollapsed }: LogoProps) => (
  <img
    src={isCollapsed ? logoCollapsed : logo}
    alt="Enhanced services logo"
    className={className}
  />
);

export default Logo;
