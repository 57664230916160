import type { SVGProps } from 'react';
const SvgBasketball = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
      <path d="M13.591 3.706A6.964 6.964 0 0 0 11.682 8.5c0 1.858.729 3.541 1.909 4.794M3.409 3.706A6.964 6.964 0 0 1 5.318 8.5a6.969 6.969 0 0 1-1.909 4.794M8.5 1.5v14M15.5 8.5h-14" />
      <path d="M8.5 15.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z" />
    </g>
  </svg>
);
export default SvgBasketball;
