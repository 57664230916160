import { Dispatch, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import ExtraLabel from 'src/components/ExtraLabel';
import ProgressIndicator from 'src/components/ProgressIndicator';
import Select from 'src/components/Select';
import { IconPlus, IconTrash } from 'src/icons';
import { StudentGoal, StudentGoalBase } from 'src/pages/single-student/singleStudent.types';

import { useLazyGetStudentGoalsQuery } from '../scheduleApi';
import { FormActions } from './RecordSessionContext';

type GoalsFormProps = {
  dispatch: Dispatch<FormActions>;
  selectedStudentId?: string;
  goals: StudentGoalBase[];
  error?: string;
  disabled?: boolean;
};

const GoalsForm = ({
  selectedStudentId,
  goals: sessionGoals,
  dispatch,
  error,
  disabled,
}: GoalsFormProps) => {
  const [fetchGoals, { data: goals = [] as StudentGoal[], isFetching }] =
    useLazyGetStudentGoalsQuery();
  const [addNewGoalSelect, setAddNewGoalSelect] = useState(true);
  useEffect(() => {
    if (selectedStudentId && !disabled) {
      fetchGoals(selectedStudentId);
    }
  }, [selectedStudentId, disabled, fetchGoals]);
  const sessionGoalsIds = sessionGoals.map((goal) => goal.id);
  const leftGoals = goals.filter((goal) => !sessionGoalsIds.includes(goal.id));

  return (
    <div className="flex flex-col gap-4">
      <ExtraLabel>Goals</ExtraLabel>
      {isFetching && <div>Loading</div>}
      <ErrorMessage>{error}</ErrorMessage>
      {sessionGoals.map((goal) => (
        <div
          key={goal.id}
          className="flex flex-col gap-4 border border-solid border-neutral-800  bg-neutral-950 p-4"
        >
          <div className="break-all typography-body-sm">{goal.name}</div>
          <div className="flex items-center justify-between">
            <ProgressIndicator
              value={goal.progress}
              max={5}
              onValueChange={(value) => {
                dispatch({
                  type: 'setGoalProgress',
                  payload: { goalId: goal.id, progress: value },
                });
              }}
              disabled={disabled}
            />
            {!disabled && (
              <Button
                preset="ghost"
                size="xxs"
                onClick={() => {
                  dispatch({ type: 'removeGoal', payload: goal });
                }}
                Icon={IconTrash}
              />
            )}
          </div>
        </div>
      ))}
      {leftGoals.length > 0 && !disabled ? (
        addNewGoalSelect || disabled ? (
          <Select
            size="md"
            options={leftGoals.map((goal) => ({ value: goal.id, label: goal.definition.name }))}
            onValueChange={(selectedGoalId) => {
              const goal = goals.find((goal) => goal.id === selectedGoalId);
              if (goal) {
                dispatch({
                  type: 'addNewGoal',
                  payload: { id: selectedGoalId, name: goal.definition.name, progress: 0 },
                });
                setAddNewGoalSelect(false);
              }
            }}
            disabled={disabled}
          />
        ) : (
          <Button
            preset="tertiary"
            size="sm"
            onClick={() => {
              setAddNewGoalSelect(true);
            }}
            Icon={IconPlus}
          >
            Add {sessionGoals.length > 0 ? 'another' : ''} goal
          </Button>
        )
      ) : null}
    </div>
  );
};
export default GoalsForm;
