import { DayHeaderContentArg } from '@fullcalendar/core';
import { format } from 'date-fns';

const DayHeaderContent = (props: DayHeaderContentArg) => {
  const formattedDay = format(props.date, 'eeee');
  const formattedDayMobile = format(props.date, 'eee');
  const firstLetter = formattedDayMobile.charAt(0);
  const formattedNumber = format(props.date, 'd');

  return (
    <>
      <div className="hidden text-neutral-100 md:inline">{formattedDay}</div>
      <div className="inline text-neutral-100 md:hidden">{firstLetter}</div>
      <div className="mt-2 text-neutral-300 typography-heading-sm md:typography-heading-lg">
        {formattedNumber}
      </div>
    </>
  );
};

export default DayHeaderContent;
