import * as SelectPrimitive from '@radix-ui/react-select';
import { forwardRef, ReactNode } from 'react';
import { IconCheck } from 'src/icons';
import { twMerge } from 'tailwind-merge';

type SelectItemProps = SelectPrimitive.SelectItemProps & {
  children: ReactNode;
  className?: string;
  selected?: boolean;
  multiple?: boolean;
  onAddValue?: (value: string) => void;
  onRemoveValue?: (value: string) => void;
};

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  (
    { children, className, selected, multiple, onAddValue, onRemoveValue, ...props },
    forwardedRef,
  ) => {
    return (
      <SelectPrimitive.Item
        className={twMerge(
          'relative mb-1 flex select-none items-center overflow-auto rounded-lg px-2 py-2 typography-body-sm data-[disabled]:pointer-events-none data-[highlighted]:bg-secondary-800 data-[disabled]:text-neutral-700 data-[highlighted]:outline-none sm:overflow-visible sm:px-4',
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        <div className="min-w-0 break-words">
          <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
        </div>

        {multiple && selected && (
          <IconCheck className="absolute right-2.5 inline-flex w-6 items-center justify-center" />
        )}
      </SelectPrimitive.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';

export default SelectItem;
