import type { SVGProps } from 'react';
const SvgMoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2a1 1 0 0 1 1 1v18a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.137 6.182A3.876 3.876 0 0 1 9.917 5h6.25a1 1 0 1 1 0 2h-6.25c-.5 0-.984.204-1.346.576A2.044 2.044 0 0 0 8 9c0 .54.209 1.051.571 1.424.362.372.847.576 1.346.576h4.166c1.048 0 2.048.428 2.78 1.182A4.044 4.044 0 0 1 18 15a4.044 4.044 0 0 1-1.137 2.818A3.876 3.876 0 0 1 14.083 19H7a1 1 0 1 1 0-2h7.083c.5 0 .984-.204 1.346-.576.362-.373.571-.885.571-1.424 0-.54-.209-1.051-.571-1.424A1.876 1.876 0 0 0 14.083 13H9.917a3.876 3.876 0 0 1-2.78-1.182A4.044 4.044 0 0 1 6 9c0-1.052.406-2.066 1.137-2.818Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMoney;
