import Card from 'src/components/Card';
import SkeletonPiece from 'src/components/SkeletonPiece';
import { twMerge } from 'tailwind-merge';

type BillingInvoicesSingleItemLoadingProps = {
  className?: string;
};

const BillingInvoicesSingleItemLoading = ({ className }: BillingInvoicesSingleItemLoadingProps) => (
  <Card className={twMerge('flex items-center justify-between', className)}>
    <SkeletonPiece className="h-6" />
    <div className="flex items-center text-neutral-300 typography-body-sm">
      <SkeletonPiece className="mr-4 h-6 w-6 rounded-full" />
      <SkeletonPiece className="h-5" />
    </div>
    <SkeletonPiece className="h-6 w-24" />
    <SkeletonPiece className="h-6 w-12" />
    <SkeletonPiece className="h-6 w-24" />
    <SkeletonPiece className="h-12 w-36" />
  </Card>
);

export default BillingInvoicesSingleItemLoading;
