import type { SVGProps } from 'react';
const SvgTimer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.636 8.72c0-.917.733-1.66 1.637-1.66.535 0 1.01.26 1.309.663.436-.333.91-.617 1.414-.846A3.258 3.258 0 0 0 6.273 5.4C4.465 5.4 3 6.886 3 8.72c0 1.335.776 2.485 1.895 3.013.145-.543.35-1.062.606-1.548a1.663 1.663 0 0 1-.865-1.465ZM16.418 7.723a1.626 1.626 0 0 1 1.31-.664c.903 0 1.636.744 1.636 1.66 0 .634-.35 1.185-.865 1.465.256.486.46 1.005.606 1.548A3.324 3.324 0 0 0 21 8.719c0-1.833-1.465-3.32-3.273-3.32a3.258 3.258 0 0 0-2.723 1.478c.504.229.978.513 1.414.846Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 7.06c-3.615 0-6.545 2.972-6.545 6.64 0 3.667 2.93 6.64 6.545 6.64 3.615 0 6.546-2.973 6.546-6.64 0-3.668-2.931-6.64-6.546-6.64ZM3.818 13.7c0-4.585 3.663-8.3 8.182-8.3s8.182 3.715 8.182 8.3c0 4.584-3.663 8.3-8.182 8.3s-8.182-3.716-8.182-8.3Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 8.54c.452 0 .818.372.818.83v4.015l2.523 1.28a.836.836 0 0 1 .366 1.113.813.813 0 0 1-1.098.371l-2.975-1.509a.831.831 0 0 1-.452-.742V9.37c0-.458.366-.83.818-.83Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M13.636 3.66a.824.824 0 0 0 .818-.83.824.824 0 0 0-.818-.83h-3.272a.824.824 0 0 0-.819.83c0 .458.367.83.819.83h.818v2.57c0 .458.366.83.818.83a.824.824 0 0 0 .818-.83V3.66h.818Z"
    />
  </svg>
);
export default SvgTimer;
