import Button from './Button';

export type RetryProps = {
  message?: string;
  btnLabel?: string;
  retry: () => void;
};

const Retry = ({ retry, message = 'Failed to load', btnLabel = 'Retry' }: RetryProps) => {
  return (
    <>
      <div>
        {message}
        <Button className="inline-block" size="sm" preset="warning" onClick={retry}>
          {btnLabel}
        </Button>
      </div>
    </>
  );
};

export default Retry;
