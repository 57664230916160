import { createSelector } from '@reduxjs/toolkit';
import { countAllFilters } from 'src/filters/countAllFilters';
import { RootState } from 'src/store';

import { billingApi } from './billingApi';

export const selectBillingQueryString = (state: RootState) => state.billingPage.query;
export const selectBillingMonthFilters = (state: RootState) => state.billingPage.monthFilters;
export const selectBillingMonthFiltersCount = (state: RootState) =>
  countAllFilters(state.billingPage.monthFilters);

export const selectBillingInvoicesFilters = (state: RootState) => state.billingPage.invoicesFilters;
export const selectBillingInvoicesFiltersCount = (state: RootState) =>
  countAllFilters(state.billingPage.invoicesFilters);

export const selectBillingActiveSchoolYearIndex = (state: RootState) =>
  state.billingPage.activeSchoolYearIndex;

const billingSummaryQuery = billingApi.endpoints.billingSummary.select({ query: '' });

export const selectBillingActiveSchoolYear = createSelector(
  billingSummaryQuery,
  selectBillingActiveSchoolYearIndex,
  (query, activeYearIndex) =>
    query.data?.length && activeYearIndex !== undefined ? query.data[activeYearIndex] : null,
);

export const selectBillingActiveMonthIndex = (state: RootState) =>
  state.billingPage.activeMonthIndex;

export const selectInvoicesIdsForSignature = (state: RootState) =>
  state.billingPage.invoicesIdsForSignature;
