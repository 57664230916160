import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { ComponentProps, ComponentType, SVGProps } from 'react';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { twMerge } from 'tailwind-merge';
export const textAreaStyles = cva(
  [
    'w-full px-4 py-2.5 rounded-lg text-neutral-black focus:outline-none typography-body-sm bg-neutral-white',
    'border border-neutral-800',
    'disabled:bg-neutral-950 disabled:text-neutral-500',
    'placeholder:text-neutral-500',
  ],
  {
    variants: {
      hasError: {
        true: 'border-accent-tomato-500 focus:shadow-focus-danger',
        false: 'focus:border-secondary-300 focus:shadow-focus-primary',
      },
    },
    defaultVariants: {
      hasError: false,
    },
  },
);

export const textInputIconStyles = cva('pointer-events-none absolute flex h-5 w-5', {
  variants: {
    size: {
      lg: 'top-4.5',
      md: 'top-3.5',
      sm: 'top-2.5',
      xs: 'top-1.5',
    },
    hasError: {
      true: 'text-accent-tomato-500',
      false: 'text-neutral-200',
    },
    position: {
      prefix: 'left-3',
      sufix: 'right-3',
    },
  },
  compoundVariants: [
    {
      size: 'xs',
      position: 'prefix',
      className: 'left-3',
    },
    {
      size: 'sm',
      position: 'prefix',
      className: 'left-3',
    },
    {
      size: 'md',
      position: 'prefix',
      className: 'left-3.5',
    },
    {
      size: 'lg',
      position: 'prefix',
      className: 'left-3.5',
    },
    {
      size: 'xs',
      position: 'sufix',
      className: 'right-3',
    },
    {
      size: 'sm',
      position: 'sufix',
      className: 'right-3',
    },
    {
      size: 'md',
      position: 'sufix',
      className: 'right-3.5',
    },
    {
      size: 'lg',
      position: 'sufix',
      className: 'right-3.5',
    },
  ],
  defaultVariants: {
    size: 'sm',
    hasError: false,
  },
});

export type TextAreaProps = VariantProps<typeof textAreaStyles> &
  Omit<ComponentProps<'textarea'>, 'size'> & {
    PrefixIcon?: ComponentType<SVGProps<SVGSVGElement>>;
    SufixIcon?: ComponentType<SVGProps<SVGSVGElement>>;
    onSuffixIconClick?: () => void;
    inputClassName?: string;
    active?: boolean;
  };

const TextArea = ({
  hasError,
  className,
  inputClassName,
  PrefixIcon,
  SufixIcon,
  active = true,
  onSuffixIconClick,
  ...props
}: TextAreaProps) => {
  const { isSm } = useBreakpoint('sm');
  return (
    <div className={clsx('relative', className)}>
      {PrefixIcon && (
        <PrefixIcon className={clsx(textInputIconStyles({ hasError, position: 'prefix' }))} />
      )}
      <textarea
        rows={3}
        className={twMerge(
          clsx(
            textAreaStyles({ hasError }),
            PrefixIcon ? 'pl-10' : 'pl-4',
            SufixIcon ? 'pr-10' : 'pr-4',
            !isSm
              ? active
                ? PrefixIcon
                  ? 'pl-10'
                  : 'pl-4'
                : 'cursor-pointer px-4 text-transparent'
              : 'cursor-auto	',
            !isSm
              ? active
                ? SufixIcon
                  ? 'pr-10'
                  : 'pr-4'
                : 'cursor-pointer px-4 text-transparent'
              : 'cursor-auto	',
            inputClassName,
          ),
        )}
        {...props}
        placeholder={!isSm ? (active ? props.placeholder : '') : props.placeholder}
      />
      {SufixIcon && (
        <SufixIcon
          className={twMerge(
            clsx(textInputIconStyles({ hasError, position: 'sufix' }), {
              'pointer-events-auto cursor-pointer': !!onSuffixIconClick,
            }),
          )}
          onClick={onSuffixIconClick}
        />
      )}
    </div>
  );
};

export default TextArea;
