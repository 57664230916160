import { assocPath, uniq } from 'ramda';
import { useEffect, useState } from 'react';

import { DayOfWeek } from '../../components/DaysPicker';
import { useLocationQuery } from '../../dictionaries/dictionariesApi';
import { OnChangeScheduleItem, ScheduleItem, UseScheduleForm } from './schedule.types';
import {} from './schedule.types';
import { useLazyGetAvailableHoursForWeekQuery } from './scheduleApi';

const daysOrder: DayOfWeek[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const sortByDayOfWeek = (items: ScheduleItem[]) => {
  return daysOrder.map((d) => items.filter((i) => i.dayOfWeek === d)).flat();
};

type UseScheduleFormProps = {
  lockOnStudentId?: string;
};

export const useScheduleForm = (props: UseScheduleFormProps = {}): UseScheduleForm => {
  const { data: locations = [] } = useLocationQuery();
  const [studentId, setStudentId] = useState<string | undefined>(props.lockOnStudentId);
  const [start, setStart] = useState<string>();
  const [finish, setFinish] = useState<string>();
  const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>([]);
  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);
  const [excludeDates, setExcludeDates] = useState<string[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>();
  const [numberOfStudents, setNumberOfStudents] = useState<number | undefined>(undefined);

  const [getAvailableHours, { data: availableHours }] = useLazyGetAvailableHoursForWeekQuery();

  useEffect(() => {
    if (studentId) {
      getAvailableHours({ studentId });
    }
  }, [studentId, getAvailableHours]);

  const onSelectStudent = (studentId: string | undefined) => {
    setStudentId(studentId);
    setDaysOfWeek([]);
    setScheduleItems([]);
  };

  const onAddScheduleForDay = (day: DayOfWeek) => {
    const newSchedule = { dayOfWeek: day } as ScheduleItem;
    setScheduleItems((prev) => sortByDayOfWeek([...prev, newSchedule]));
  };

  const onRemoveScheduleForDay = (index: number) => {
    setScheduleItems((prev) => prev.filter((_, i) => i !== index));
  };

  const onDaysOfWeekChange = (newDaysOfWeek: DayOfWeek[]) => {
    setDaysOfWeek(newDaysOfWeek);

    newDaysOfWeek.forEach((day) => {
      const isScheduleForDay = scheduleItems.find((s) => s.dayOfWeek === day);
      if (!isScheduleForDay) {
        onAddScheduleForDay(day);
      }
    });

    setScheduleItems((prev) =>
      prev.filter((s) => newDaysOfWeek?.includes(s.dayOfWeek as DayOfWeek)),
    );
  };

  const onChangeScheduleItem: OnChangeScheduleItem<ScheduleItem, keyof ScheduleItem> = (
    index,
    key,
    value,
  ) => {
    setScheduleItems((prev) => assocPath([index, key as string], value, prev));
  };

  const onSelectDateToExclude = (d: string) => {
    setExcludeDates((prev) => uniq([...prev, d]).sort());
  };

  const onRemoveDateToExclude = (d: string) => {
    setExcludeDates((prev) => prev.filter((prevDate) => prevDate !== d));
  };

  const validate = () => {
    setErrors(undefined);
    const tmpErrors: Record<string, string> = {};
    if (!studentId) {
      tmpErrors.studentId = 'Student has to be selected';
    }
    if (!start) {
      tmpErrors.start = 'Start date is missing';
    }
    if (!finish) {
      tmpErrors.finish = 'End date is missing';
    }
    if (!daysOfWeek?.length) {
      tmpErrors.daysOfWeek = 'At least one day has to be selected';
    }

    scheduleItems.forEach((item, index) => {
      if (!item.location) {
        tmpErrors[`scheduleItems.${index}`] = 'Missing location';
      } else if (!item.start) {
        tmpErrors[`scheduleItems.${index}`] = 'Missing start';
      } else if (!item.finish) {
        tmpErrors[`scheduleItems.${index}`] = 'Missing end';
      }
    });

    if (Object.keys(tmpErrors).length) {
      setErrors(tmpErrors);
      return true;
    }

    return false;
  };

  const clearForm = () => {
    setStudentId(undefined);
    setStart(undefined);
    setFinish(undefined);
    setDaysOfWeek([]);
    setScheduleItems([]);
    setExcludeDates([]);
  };

  return {
    locations,
    excludeDates,
    setExcludeDates,
    studentId,
    setStudentId,
    onSelectStudent,
    scheduleItems,
    setScheduleItems,
    onAddScheduleForDay,
    onRemoveScheduleForDay,
    daysOfWeek,
    setDaysOfWeek,
    start,
    finish,
    setStart,
    setFinish,
    onRemoveDateToExclude,
    onSelectDateToExclude,
    onChangeScheduleItem,
    onDaysOfWeekChange,
    clearForm,
    validate,
    setErrors,
    errors,
    availableHours,
    numberOfStudents,
    setNumberOfStudents,
  };
};
