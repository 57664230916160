import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Tabs from 'src/components/Tabs';
import { routes } from 'src/routes';

import {
  useSingleStudentGoalsListQuery,
  useSingleStudentGoalsYearsFiltersQuery,
} from './singleStudentApi';
import StudentHeader from './student-header/StudentHeader';
import StudentAbout from './StudentAbout';
import StudentContact from './StudentContact';
import StudentGoals from './StudentGoals';
import StudentNotes from './StudentNotes';
import StudentSchedule from './StudentSchedule';

const SingleStudent = () => {
  const { id } = useParams();
  const { data: studentYears } = useSingleStudentGoalsYearsFiltersQuery(id!);
  const { data: goals } = useSingleStudentGoalsListQuery({
    page: 1,
    id: id!,
    showMastered: true,
    schoolYearId: studentYears?.[studentYears.length - 1]?.id ?? undefined,
  });

  const goalsTitle = (): string => {
    return goals?.total ? `Goals (${goals?.total})` : 'Goals';
  };

  const tabs = [
    {
      label: 'Schedule',
      id: 'schedule',
      to: routes.singleStudent.schedule,
    },
    {
      label: goalsTitle(),
      id: 'goals',
      to: routes.singleStudent.goals,
    },
    {
      label: 'About',
      id: 'about',
      to: routes.singleStudent.about,
    },
    {
      label: 'Contacts',
      id: 'contact',
      to: routes.singleStudent.contact,
    },
    {
      label: 'Notes',
      id: 'notes',
      to: routes.singleStudent.notes,
    },
  ];

  return (
    <div className="bg-neutral-white">
      <StudentHeader />
      <Tabs items={tabs} underline className="px-4 sm:px-10" />
      <div className="bg-neutral-950 px-4 py-6 sm:p-10">
        <Routes>
          <Route
            path={routes.singleStudent.schedule}
            element={
              <div className="-m-10 h-[calc(100vh-200px)]">
                <div className="relative h-full">
                  <StudentSchedule studentId={id!} />
                </div>
              </div>
            }
          />
          <Route path={routes.singleStudent.goals} element={<StudentGoals />} />
          <Route path={routes.singleStudent.about} element={<StudentAbout />} />
          <Route path={routes.singleStudent.contact} element={<StudentContact />} />
          <Route path={routes.singleStudent.notes} element={<StudentNotes />} />
          <Route index element={<Navigate to={routes.singleStudent.schedule} replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default SingleStudent;
