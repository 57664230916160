import { useMemo, useState } from 'react';
import { useNotifications } from 'src/hooks/useNotifications';
import { NotificationGroup } from 'src/notification/notification.types';
import { useReadAllNotificationsMutation } from 'src/notification/notificationApi';

import Button from './Button';
import NotificationItem from './NotificationItem';
import Tabs from './Tabs';

const Notifications = (props: { setIsOpen: (isOpen: boolean) => void }) => {
  const [submit] = useReadAllNotificationsMutation();
  const { unreadCount, notifications } = useNotifications();

  const [selectedTab, setSelectedTab] = useState(NotificationGroup.ACTIONABLE.toString());

  const notificationsForSelectedGroup = notifications[selectedTab as NotificationGroup];
  const items = useMemo(
    () =>
      Object.values(NotificationGroup).map((value) => ({
        id: value,
        label: value,
        count: notifications[value].length,
      })),
    [notifications],
  );
  return (
    <div className="scroll flex w-notifications-dropdown flex-col items-center justify-center rounded-lg border border-neutral-800 text-neutral-100">
      <div className="flex w-full items-center justify-between border-b border-b-neutral-800 p-4">
        <span className="text-neutral-100 typography-heading-xs">Notifications</span>
        <Button
          preset="tertiary"
          size="xs"
          onClick={() => submit(null)}
          disabled={unreadCount === 0}
        >
          Mark all as read
        </Button>
      </div>
      <Tabs
        className="mt-2"
        onSelect={setSelectedTab}
        active={selectedTab}
        items={items}
        size="sm"
        underline
      />
      <div className="max-h-[600px] w-full overflow-y-auto">
        {notificationsForSelectedGroup.length > 0 ? (
          notificationsForSelectedGroup.map((notification) => (
            <NotificationItem setIsOpen={props.setIsOpen} key={notification.id} {...notification} />
          ))
        ) : (
          <p className="py-10 text-center text-neutral-100 typography-heading-xs">
            Empty notification list
          </p>
        )}
      </div>
    </div>
  );
};

export default Notifications;
