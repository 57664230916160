import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import PasswordField from 'src/components/PasswordField';
import TextField from 'src/components/TextField';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconEnvlope } from 'src/icons';
import { routes } from 'src/routes';
import { twMerge } from 'tailwind-merge';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Login = () => {
  const [params] = useSearchParams();
  const predefinedUsername = params.get('username');

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState<Record<string, string>>();
  const { signIn, init } = useAuth();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await signIn(predefinedUsername || username, password);
    } catch (e: any) {
      const message = e?.message as string;
      if (!message) {
        setErrors({ _: 'Something went wrong. Try again later.' });
        return;
      }
      if (message.startsWith('CUSTOM_AUTH')) {
        setErrors({ password: 'Password cannot be empty' });
        return;
      }
      if (message.toLowerCase().startsWith('username')) {
        setErrors({ username: e?.message });
        return;
      }
      setErrors({ _: e?.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };
  const { isSm } = useBreakpoint('sm');

  useEffect(() => {
    init();
  });

  useEffect(() => {
    const atPosition = username.indexOf('@');
    const hasAtLeastOneCharAfterAT = atPosition > -1 && atPosition + 1 < username.length;
    if (hasAtLeastOneCharAfterAT && !EMAIL_REGEX.test(username)) {
      setErrors({ username: 'Invalid email' });
    } else {
      setErrors({ username: '' });
    }
  }, [username]);

  return (
    <>
      <div
        className={twMerge(
          clsx(
            'mb-2 text-secondary-300 md:mb-4',
            !isSm ? 'typography-heading-lg' : 'typography-heading-2xl',
          ),
        )}
      >
        {predefinedUsername ? 'Log in with new password' : `Look who's back!`}
      </div>
      <div
        className={twMerge(
          clsx(
            'mb-8 text-neutral-100 md:mb-12',
            !isSm ? 'typography-body-sm' : 'typography-body-lg',
          ),
        )}
      >
        Your credentials, please.
      </div>
      <TextField
        label="Email"
        id="username"
        size={isSm ? 'lg' : 'md'}
        PrefixIcon={IconEnvlope}
        className="mb-4 md:mb-6"
        placeholder="Email"
        value={predefinedUsername || username}
        disabled={!!predefinedUsername}
        onChange={(event) => setUsername(event.target.value.trim())}
        error={errors?.username}
        onKeyDown={handleKeyPress}
        autoFocus // eslint-disable-line
      />
      <PasswordField
        label="Password"
        size={isSm ? 'lg' : 'md'}
        className="mb-8 md:mb-6"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyDown={handleKeyPress}
        error={errors?.password}
      />
      <ErrorMessage className="mb-6">{errors?._}</ErrorMessage>
      <Button
        size={isSm ? 'lg' : 'sm'}
        className="mb-2 md:mb-4"
        onClick={onSubmit}
        isLoading={isLoading}
      >
        Log in
      </Button>
      <Button size="sm" preset="ghost" to={routes.auth.forgotPassword}>
        Forgot password?
      </Button>
    </>
  );
};

export default Login;
