import Button from 'src/components/Button';
import { SidebarHeader, SidebarTitle } from 'src/components/Sidebar';

type FiltersSidebarHeaderProps = {
  initialFiltersCount?: number;
  filtersCount?: number;
  onReset: () => void;
};

const FiltersSidebarHeader = ({
  initialFiltersCount,
  filtersCount,
  onReset,
}: FiltersSidebarHeaderProps) => (
  <SidebarHeader className="flex items-center gap-6">
    <SidebarTitle>{`Filters${
      initialFiltersCount ? ` (${initialFiltersCount})` : ''
    }`}</SidebarTitle>
    <Button preset="warning" onClick={onReset} size="xs" disabled={!filtersCount}>
      Reset All
    </Button>
  </SidebarHeader>
);

export default FiltersSidebarHeader;
