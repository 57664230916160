import type { SVGProps } from 'react';
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.784 3.818v9.008a.9.9 0 0 1-.892.91.9.9 0 0 1-.892-.91v-9.09c0-.46.18-.902.499-1.228C3.818 2.183 4.25 2 4.703 2h9.73c.236 0 .463.096.63.266l5.676 5.785c.167.17.261.402.261.643v4.132a.9.9 0 0 1-.892.91.9.9 0 0 1-.892-.91V9.071l-5.153-5.253h-9.28Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.433 2a.9.9 0 0 1 .892.91v4.875h4.784a.9.9 0 0 1 .891.91.9.9 0 0 1-.891.908h-5.676a.9.9 0 0 1-.892-.909V2.91A.9.9 0 0 1 14.433 2ZM3 16.132a.9.9 0 0 1 .893-.909h1.621c.667 0 1.306.27 1.777.75.472.481.737 1.133.737 1.812 0 .68-.265 1.331-.737 1.812a2.49 2.49 0 0 1-1.777.75h-.73v.744a.9.9 0 0 1-.891.909.9.9 0 0 1-.892-.91v-4.958Zm1.784 2.397h.73c.194 0 .38-.078.516-.218a.751.751 0 0 0 0-1.052.723.723 0 0 0-.516-.218h-.73v1.488ZM9.487 16.132a.9.9 0 0 1 .892-.909h1.216c.881 0 1.727.357 2.35.993.624.635.974 1.497.974 2.396 0 .898-.35 1.76-.974 2.396a3.293 3.293 0 0 1-2.35.992h-1.216a.9.9 0 0 1-.892-.91v-4.958Zm1.784.91v3.14h.324c.408 0 .8-.166 1.09-.46.288-.295.45-.694.45-1.11 0-.417-.162-.816-.45-1.11a1.526 1.526 0 0 0-1.09-.46h-.324ZM16.378 16.132a.9.9 0 0 1 .892-.909h2.838a.9.9 0 0 1 .892.91.9.9 0 0 1-.892.908h-1.946v4.05a.9.9 0 0 1-.892.909.9.9 0 0 1-.892-.91v-4.958Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.378 19.025a.9.9 0 0 1 .892-.91h2.433a.9.9 0 0 1 .892.91.9.9 0 0 1-.892.909H17.27a.9.9 0 0 1-.892-.91Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPdf;
