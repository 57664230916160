import domtoimage from 'dom-to-image';
import jsPDF, { jsPDFOptions } from 'jspdf';
import { RefObject } from 'react';

type ExportToPDFFunction = (
  contentRef: RefObject<HTMLDivElement>,
  filename: string,
  pdfProps: jsPDFOptions & { imgWidth?: number },
) => Promise<void>;

const useExportToPDF = (): ExportToPDFFunction => {
  const exportToPDF: ExportToPDFFunction = async (contentRef, filename, pdfProps) => {
    if (!contentRef.current) {
      console.error('Content reference is not available.');
      return;
    }

    const content = contentRef.current;

    try {
      const dataUrl = await domtoimage.toPng(content);
      const img = new Image();
      img.src = dataUrl;

      await new Promise((resolve) => {
        img.onload = resolve;
      });

      const pdf = new jsPDF(pdfProps);

      const imgWidth = pdfProps.imgWidth || 210;
      const imgHeight = (img.height * imgWidth) / img.width;

      pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(filename);
    } catch (error) {
      console.error('Error exporting content:', error);
    }
  };

  return exportToPDF;
};

export default useExportToPDF;
