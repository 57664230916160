import { Auth } from 'aws-amplify';

import { AuthenticatedUser, AuthProvider } from './auth.types';

export const currentSessionHookless = () => Auth.currentSession();

export const useAwsAuth = (): AuthProvider => {
  return {
    currentSession: () => currentSessionHookless(),

    signOut: async () => {
      await Auth.signOut();
    },

    currentAuthenticatedUser: () => Auth.currentAuthenticatedUser(),

    forgotPassword: (username: string) => Auth.forgotPassword(username),

    signIn: (username: string, password: string) => Auth.signIn(username, password),

    completeNewPassword: (user: AuthenticatedUser, password: string) =>
      Auth.completeNewPassword(user, password),

    forgotPasswordSubmit: async (username: string, code: string, password: string) => {
      await Auth.forgotPasswordSubmit(username, code, password);
    },

    changePassword: async (user: AuthenticatedUser, oldPassword: string, newPassword: string) => {
      await Auth.changePassword(user, oldPassword, newPassword);
    },

    backdoorSignIn: () => Promise.reject('backdoorSignIn not supported for aws auth'),
  };
};
