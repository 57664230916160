import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from './Tooltip';

export type ProgressIndicatorProps = {
  className?: string;
  value: number;
  max: number;
  onValueChange?: (newValue: number) => void;
  disabled?: boolean;
};

const ProgressIndicator = ({
  value,
  max,
  className,
  onValueChange = () => undefined,
  disabled,
}: ProgressIndicatorProps) => {
  return (
    <div className={twMerge('flex', className)}>
      {new Array(max).fill(0).map((_, idx) => (
        <div
          key={idx}
          onClick={() => (disabled ? null : onValueChange(idx + 1))}
          onKeyDown={() => (disabled ? null : onValueChange(idx + 1))}
          tabIndex={0}
          role="button"
          className={clsx('ml-1 py-2 first:ml-0', disabled && 'cursor-default')}
        >
          <div
            className={clsx(
              'relative h-1 w-9 rounded-lg',
              idx < value ? 'bg-secondary-400' : 'bg-neutral-700',
            )}
          >
            {idx === value - 1 && (
              <Tooltip content="Guided practice" side="top" trigger="hover">
                <div className="absolute right-0 top-1/2 h-3.5 w-3.5 -translate-y-1/2 rounded-full border-4  border-neutral-white bg-secondary-400 shadow-elevation-2" />
              </Tooltip>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressIndicator;
