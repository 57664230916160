import { equals } from 'ramda';
import { useCallback, useContext, useEffect } from 'react';
import useSelectedStudent from 'src/hooks/useSelectedStudent';
import { invalidateSingleStudentTags } from 'src/pages/single-student/singleStudentApi';
import { invalidateStudentsListTags } from 'src/pages/students/studentsApi';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { apiErrorsToDict } from 'src/types/api-error-response';

import {
  useCancelSessionMutation,
  useConfirmSessionMutation,
  useCreateRecordSessionMutation,
  useDeleteSessionMutation,
  useLazyConfirmAvailabilityHoursQuery,
  useLazyGetAvailableHoursQuery,
  useRescheduleSessionMutation,
} from '../scheduleApi';
import { EditionForm, FormData, FormOptions, RecordSessionContext } from './RecordSessionContext';

type UseRecordSessionProps = {
  onSuccessCb?: () => void;
  isPara?: boolean;
};

export const useRecordSession = ({ onSuccessCb }: UseRecordSessionProps) => {
  const appDispatch = useAppDispatch();
  const { state, dispatch, features } = useContext(RecordSessionContext);

  const [
    createRecordSession,
    { isSuccess: isCreateSuccess, error: createError, isLoading: isLoadingCreate },
  ] = useCreateRecordSessionMutation();
  const [
    confirmRecordSession,
    { isSuccess: isConfirmSuccess, error: confirmError, isLoading: isLoadingConfirm },
  ] = useConfirmSessionMutation();
  const [
    rescheduleRecordSession,
    { isSuccess: isRescheduleSuccess, error: rescheduleError, isLoading: isLoadingReschedule },
  ] = useRescheduleSessionMutation();
  const [deleteRecordSession, { isSuccess: isDeleteSuccess, error: deleteError }] =
    useDeleteSessionMutation();
  const [cancelRecordSession, { isSuccess: isCancelSuccess, error: cancelError }] =
    useCancelSessionMutation();

  const isEdit = state.isInitialized && 'parentEvent' in state && state.parentEvent;

  const { selectedStudent } = useSelectedStudent(true, state?.formData?.studentId);

  useEffect(() => {
    if (
      isCreateSuccess ||
      isConfirmSuccess ||
      isDeleteSuccess ||
      isCancelSuccess ||
      isRescheduleSuccess
    ) {
      dispatch({ type: 'reset' });

      const actionLabel = isCreateSuccess
        ? 'recorded'
        : isConfirmSuccess
        ? 'confirmed'
        : isDeleteSuccess
        ? 'deleted'
        : isCancelSuccess
        ? 'cancelled'
        : 'was rescheduled and moved to the "awaiting confirmation" tab';

      const toastText = `Session ${actionLabel}.`;

      appDispatch(
        addToast({
          type: 'positive',
          text: toastText,
        }),
      );
      appDispatch(invalidateSingleStudentTags(['StudentEvents']));
      appDispatch(invalidateStudentsListTags(['StudentsList']));

      onSuccessCb?.();
    }
  }, [
    isConfirmSuccess,
    isCreateSuccess,
    isDeleteSuccess,
    isCancelSuccess,
    isRescheduleSuccess,
    appDispatch,
    dispatch,
    onSuccessCb,
  ]);

  const [
    getAvailability,
    {
      currentData: availability,
      error: availabilityError,
      originalArgs: availabilityArgs,
      isFetching: isFetchingAvailability,
    },
  ] = useLazyGetAvailableHoursQuery();

  const [
    confirmSelectedAvailability,
    {
      currentData: confirmationResponse,
      error: confirmationError,
      originalArgs: confirmationRequest,
    },
  ] = useLazyConfirmAvailabilityHoursQuery();

  useEffect(() => {
    const { formData } = state;
    if (formData && formData.studentId && formData.date) {
      const args = {
        studentId: formData.studentId,
        date: formData.date,
      };

      if (
        !equals(args, availabilityArgs) ||
        (!availability && !isFetchingAvailability && !availabilityError)
      ) {
        getAvailability(args);
      }
    }
  }, [
    getAvailability,
    state,
    availabilityArgs,
    availability,
    isFetchingAvailability,
    availabilityError,
  ]);

  useEffect(() => {
    if (availability?.canSchedule) {
      dispatch({ type: 'setErrors', payload: { _: undefined } });
    }
    if (availability?.canSchedule === false) {
      dispatch({ type: 'setErrors', payload: { _: availability.message } });
    }
    const error = confirmationError || availabilityError;
    if (error) {
      dispatch({ type: 'setErrors', payload: apiErrorsToDict(error) });
    }
    if (confirmationResponse?.canSchedule === false) {
      dispatch({ type: 'setErrors', payload: { _: confirmationResponse?.message } });
    }
  }, [confirmationResponse, confirmationError, availabilityError, availability, dispatch]);

  useEffect(() => {
    const { formData } = state;
    if (formData && formData?.startTime && formData?.finishTime) {
      if (
        formData.date &&
        formData.studentId &&
        availability &&
        availability &&
        availability.canSchedule
      ) {
        const body = {
          date: formData.date,
          finishTime: formData.finishTime,
          startTime: formData.startTime,
          studentId: formData.studentId,
        };
        if (!equals(body, confirmationRequest)) {
          confirmSelectedAvailability(body);
        }
      }
    }
  }, [availability, state, confirmationRequest, confirmSelectedAvailability]);

  const validate = useCallback(
    ({ validateGoals }: { validateGoals?: boolean } = {}) => {
      const tmpErrors: Record<string, string> = {};

      if (!state.formData?.studentId) {
        tmpErrors.studentId = 'Student has to be selected';
      }
      if (!state.formData?.startTime) {
        tmpErrors.startTime = 'Start time is missing';
      }
      if (!state.formData?.finishTime) {
        tmpErrors.finishTime = 'End time is missing';
      }
      if (!state.formData?.location) {
        tmpErrors.location = 'This field is required';
      }

      if (validateGoals && !state.formData?.goals?.length && !selectedStudent?.isPara) {
        tmpErrors.goals = `Goals' must not be empty`;
      }

      if (
        validateGoals &&
        state.formData?.goals?.find((goal) => goal.progress === 0) &&
        !selectedStudent?.isPara
      ) {
        tmpErrors.goals = `Goals' must have rating`;
      }

      if (Object.keys(tmpErrors).length) {
        dispatch({ type: 'setErrors', payload: tmpErrors });
        return true;
      }

      return false;
    },
    [state.formData, selectedStudent, dispatch],
  );

  const confirmSession = useCallback(
    ({ isReschedule }: { isReschedule?: boolean }) => {
      dispatch({ type: 'setErrors', payload: undefined });
      if (!('formData' in state) || !state.isInitialized) {
        return;
      }

      const hasErrors = validate({ validateGoals: !isReschedule });
      if (hasErrors) return;

      let editingId: string | undefined;

      const { goals, date, finishTime, startTime, location, note, studentId, numberOfStudents } =
        state.formData;

      if (!goals || !date || !finishTime || !startTime || !location || !studentId) {
        return;
      }

      if (state.isInitialized && 'existingSession' in state) {
        editingId = state.existingSession?.id;
      }

      const finalNumberOfStudents =
        numberOfStudents ?? (selectedStudent?.groupSize ? 1 : undefined);

      const baseData = {
        date,
        startTime,
        finishTime,
        location,
        note,
        goals: goals.map(({ id, progress }) => ({ id, progress })),
        ...(finalNumberOfStudents !== undefined ? { numberOfStudents: finalNumberOfStudents } : {}),
      };

      if (isReschedule) {
        rescheduleRecordSession({ ...baseData, id: editingId! });
        return;
      }

      editingId
        ? confirmRecordSession({ ...baseData, id: editingId })
        : createRecordSession({ ...baseData, studentId });
    },
    [
      createRecordSession,
      confirmRecordSession,
      rescheduleRecordSession,
      state,
      validate,
      dispatch,
      selectedStudent?.groupSize,
    ],
  );

  const rescheduleSession = useCallback(() => {
    confirmSession({ isReschedule: true });
  }, [confirmSession]);

  const deleteSession = useCallback(() => {
    if (state.isInitialized && 'existingSession' in state && state.existingSession) {
      deleteRecordSession(state.existingSession.id);
    }
  }, [state, deleteRecordSession]);

  const cancelSession = useCallback(() => {
    if (
      'formData' in state &&
      'existingSession' in state &&
      state.existingSession?.id &&
      state.formData.cancellationReasonId
    ) {
      const data = {
        cancellationReason: state.formData.cancellationReason,
        cancellationReasonId: state.formData.cancellationReasonId,
        replacementSession: '',
        id: state.existingSession.id,
      };
      cancelRecordSession(data);
    }
    return state;
  }, [state, cancelRecordSession]);

  useEffect(() => {
    const handleIfError = (err, text) => {
      if (err) {
        dispatch({ type: 'setErrors', payload: apiErrorsToDict(err) });

        if (err.status !== 400) {
          appDispatch(
            addToast({
              type: 'negative',
              text: `Error: session not ${text}. Reload the page.`,
            }),
          );
        }
      }
    };
    handleIfError(confirmError, 'confirmed');
    handleIfError(createError, 'recorded');
    handleIfError(deleteError, 'deleted');
    handleIfError(cancelError, 'canceled');
    handleIfError(rescheduleError, 'rescheduled');
  }, [confirmError, createError, deleteError, cancelError, rescheduleError, dispatch, appDispatch]);

  const formData = (state.isInitialized && state.formData) || ({} as FormData);
  const isLoading = 'isLoading' in state && (state as EditionForm).isLoading;
  const formOptions = ('options' in state && state.options) || ({} as FormOptions);
  const errors = state.isInitialized ? state.errors : undefined;

  return {
    data: {
      state,
      formData,
      isLoading,
      errors,
      isLoadingCreate,
      isLoadingConfirm,
      isLoadingReschedule,
      availability: state.formData?.studentId && state.formData?.date ? availability : undefined,
      options: formOptions,
    },
    dispatch,
    actions: { confirmSession, cancelSession, deleteSession, rescheduleSession },
    isEdit,
    features: { isStudentLocked: !!features.lockOnStudentId },
  };
};
