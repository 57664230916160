import clsx from 'clsx';
import Avatar from 'src/components/Avatar';
import Logo from 'src/components/Logo';
import Tag from 'src/components/Tag';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { buildName } from 'src/logic/buildName';
import { getMonthName } from 'src/logic/getMonthName';

import { SingleStudentDetails } from '../single-student/singleStudent.types';
import { InvoiceDetailedStatement } from './billing.types';

export type BillingInvoiceHeaderForPdfExportProps = {
  student: Pick<SingleStudentDetails, 'schoolName' | 'firstName' | 'lastName' | 'englishGrade'>;
  size: 'sm' | 'md';
  statement: InvoiceDetailedStatement;
};

const BillingInvoiceHeaderForPdfExport = ({
  student,
  size,
  statement,
}: BillingInvoiceHeaderForPdfExportProps) => {
  const { formatDate } = useTimeFormat();

  return (
    <div className="flex flex-col gap-6 border-b border-neutral-900 px-4 pb-6 sm:px-10 sm:pb-10 sm:pt-10">
      <div className="flex justify-between">
        <Avatar
          firstName={student?.firstName || ''}
          lastName={student?.lastName || ''}
          url={''} // TODO: should be photo here
          size={size === 'md' ? '3xl' : 'xl'}
          className={clsx({
            'mr-10': size === 'md',
            'mr-6': size === 'sm',
          })}
        />
        <div className="mb-18 md:mb-12">
          <Logo />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div
          className={clsx({
            'mb-2 flex h-7 truncate typography-heading-lg': size === 'md',
            'mb-1 flex  truncate text-button-sm-desktop': size === 'sm',
          })}
        >
          {buildName({
            firstName: student?.firstName,
            lastName: student?.lastName,
          })}
        </div>
        <div
          className={clsx({
            'mb-1 h-7 truncate text-neutral-100 typography-body-md': size === 'md',
            'text-export-to-pdf-2xs text-neutral-100': size === 'sm',
          })}
        >
          {`${student?.schoolName || ''} | ${student?.englishGrade || ''}`}
        </div>
        <div className="flex gap-4">
          <Tag size="md" preset="grayStroke">
            {getMonthName(statement.month)} {statement.year}
          </Tag>
          <Tag size="md" preset="lightBlue">
            {statement.hours} hours
          </Tag>
          <Tag size="md" preset="gray">
            Paid: {formatDate(statement.dueDate)}
          </Tag>
          <Tag size="md" preset="grayStroke">
            Amount: {statement?.amount?.value || 0} {statement?.amount?.currency || ''}
          </Tag>
        </div>
      </div>
    </div>
  );
};

export default BillingInvoiceHeaderForPdfExport;
