import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type DropdownTriggerProps = DropdownMenu.DropdownMenuTriggerProps;

export const DropdownTrigger = ({ children, ...props }: DropdownTriggerProps) => (
  <DropdownMenu.Trigger asChild {...props}>
    {children}
  </DropdownMenu.Trigger>
);

export default DropdownTrigger;
