import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { ComponentType, forwardRef, SVGProps } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Loader from './Loader';

const buttonStyles = cva(
  'flex gap-2 shrink-0 items-center justify-center rounded-lg focus:outline-none cursor-pointer disabled:cursor-default relative',
  {
    variants: {
      preset: {
        primary: [
          'bg-primary-500 text-neutral-white',
          'hover:bg-primary-400',
          'disabled:bg-neutral-800 disabled:text-neutral-600',
        ],
        secondary: [
          'bg-secondary-300 text-neutral-white',
          'hover:bg-secondary-200',
          'disabled:bg-neutral-800 disabled:text-neutral-600',
        ],
        tertiary: [
          'bg-neutral-white border border-neutral-800 text-neutral-200 shadow-elevation-1',
          'hover:border-secondary-300 hover:text-secondary-300 hover:shadow-none',
          'disabled:border-neutral-900 disabled:text-neutral-600',
        ],
        quaternary: [
          'bg-neutral-950 border border-neutral-800 text-neutral-200',
          'hover:text-secondary-300',
          'disabled:border-neutral-900 disabled:text-neutral-600',
        ],
        ghost: 'text-neutral-200 hover:text-neutral-500 disabled:text-neutral-600',
        warning: [
          'bg-neutral-white text-accent-tomato-500 border border-accent-tomato-500',
          'hover:bg-accent-tomato-900',
          'disabled:border-neutral-900 disabled:text-neutral-700 disabled:hover:bg-neutral-white',
        ],
      },
      size: {
        lg: 'h-14 px-6',
        md: 'h-12 px-4',
        sm: 'h-10 px-4',
        xs: 'h-8 px-4',
        xxs: 'h-7 px-4',
      },
      fullWidth: {
        true: 'w-full',
      },
      hasChildren: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        size: 'lg',
        hasChildren: true,
        className: 'w-14 px-0',
      },
      {
        size: 'md',
        hasChildren: true,
        className: 'w-12 px-0',
      },
      {
        size: 'sm',
        hasChildren: true,
        className: 'w-10 px-0',
      },
      {
        size: 'xs',
        hasChildren: true,
        className: 'w-8 px-0',
      },
    ],
    defaultVariants: {
      preset: 'primary',
      size: 'md',
    },
  },
);

export type ButtonProps = Omit<React.ComponentProps<'button'>, 'ref'> &
  Omit<LinkProps, 'to'> &
  Omit<VariantProps<typeof buttonStyles>, 'hasChildren'> & {
    Icon?: ComponentType<SVGProps<SVGSVGElement>>;
    iconClassName?: string;
    textClassName?: string;
    to?: string;
    isLoading?: boolean;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      preset,
      size = 'md',
      fullWidth,
      Icon,
      iconClassName,
      textClassName,
      to,
      isLoading,
      disabled,
      ...props
    },
    ref,
  ) => {
    const btn = (
      <>
        {Icon && !isLoading && (
          <Icon
            className={twMerge(
              'relative h-4 w-4 shrink-0',
              size === 'xs' && 'h-3 w-3',
              iconClassName,
            )}
          />
        )}
        {children && (
          <span
            className={clsx(
              'truncate',
              {
                'typography-button-md': size === 'lg',
                'typography-button-sm': size === 'md' || size === 'sm',
                'typography-button-xs': size === 'xs' || size === 'xxs',
                invisible: isLoading,
              },
              textClassName,
            )}
          >
            {children}
          </span>
        )}
        {isLoading && (
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader />
          </span>
        )}
      </>
    );

    const mergedClassNames = twMerge(
      buttonStyles({ preset, size, fullWidth, hasChildren: !children }),
      className,
    );

    if (to && !disabled) {
      return (
        <Link to={to} className={mergedClassNames} {...props}>
          {btn}
        </Link>
      );
    }

    return (
      <button className={mergedClassNames} ref={ref} disabled={disabled || isLoading} {...props}>
        {btn}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
