import Button from 'src/components/Button';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconPlus } from 'src/icons';

import { SessionData } from '../schedule/record-session/CancelSessionModal';
import { InvoiceSession } from './billing.types';
import BillingSingleSession, { BillingSingleSessionProps } from './BillingSingleSession';

type BillingSessionsListProps = {
  sessions?: InvoiceSession[];
  onRecordSession: () => void;
  onDeleteSession: (sessionId: string) => void;
  onCancelSession: (sessionId: SessionData) => void;
  onConfirmSession: (sessionId: string) => void;
  onFinalize?: () => void;
  type: BillingSingleSessionProps['type'];
  canFinalize?: boolean;
};

const BillingSessionsList = ({
  sessions,
  onRecordSession,
  onDeleteSession,
  onCancelSession,
  onConfirmSession,
  type,
  canFinalize,
  onFinalize,
}: BillingSessionsListProps) => {
  const { isSm } = useBreakpoint('sm');
  if (!sessions?.length) {
    return (
      <div className="flex h-1/2 flex-col items-center justify-center md:h-full">
        <div className="mb-6 text-neutral-100 typography-heading-md">
          {type === 'awaiting-confirmation'
            ? 'No sessions are awaiting confirmation'
            : type === 'confirmed'
            ? 'There is no confirmed sessions'
            : 'There is no cancelled sessions'}
        </div>
        <div className="flex gap-4">
          <Button preset="tertiary" Icon={IconPlus} size="md" onClick={onRecordSession}>
            Add Session
          </Button>
          <Button preset="secondary" size="md" disabled={!canFinalize} onClick={onFinalize}>
            Finalize Invoice
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 pb-20 md:pb-0">
      {type === 'awaiting-confirmation' && isSm && (
        <Button Icon={IconPlus} onClick={onRecordSession}>
          Add session
        </Button>
      )}
      {sessions.map((s) => (
        <BillingSingleSession
          key={s.id}
          type={type}
          onDelete={onDeleteSession}
          onCancel={onCancelSession}
          onConfirm={onConfirmSession}
          {...s}
        />
      ))}
      {type === 'awaiting-confirmation' && !isSm && (
        <div className="fixed bottom-0 left-0 w-full border-t border-neutral-800 bg-neutral-white p-6">
          <Button className="w-full" Icon={IconPlus} onClick={onRecordSession}>
            Add session
          </Button>
        </div>
      )}
    </div>
  );
};

export default BillingSessionsList;
