import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import Button from 'src/components/Button';
import DateRangePicker from 'src/components/date-picker/DateRangePicker';
import Separator from 'src/components/Separator';
import {
  Sidebar,
  SidebarContainer,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarTitle,
} from 'src/components/Sidebar';
import useTimeFormat from 'src/hooks/useTimeFormat';
import useUser from 'src/hooks/useUser';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';

import { ScheduleEvent } from '../schedule.types';
import {
  useCreateScheduleEventMutation,
  useDeleteScheduleEventMutation,
  useUpdateScheduleEventMutation,
} from '../scheduleApi';

export type OutOfOfficeFormRefProps = {
  open: (selectedEvent?: ScheduleEvent) => void;
};

const OutOfOfficeForm = forwardRef<OutOfOfficeFormRefProps>(function _OutOfOfficeForm(
  _: Record<never, never>,
  ref,
) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const { firstName, lastName } = useUser();
  const dispatch = useAppDispatch();
  const [existingEvent, setExistingEvent] = useState<ScheduleEvent | undefined>();
  const [createEvent, { isLoading: isCreating }] = useCreateScheduleEventMutation();
  const [updateEvent, { isLoading: isUpdating }] = useUpdateScheduleEventMutation();
  const [deleteEvent, { isLoading: isDeleting }] = useDeleteScheduleEventMutation();
  const { formatDateISO } = useTimeFormat();
  useImperativeHandle(
    ref,
    () => ({
      open: (selectedEvent) => {
        setStartDate(selectedEvent?.start ? formatDateISO(new Date(selectedEvent.start)) : '');
        setEndDate(selectedEvent?.finish ? formatDateISO(new Date(selectedEvent.finish)) : '');
        setIsSidebarOpen(true);
        setExistingEvent(selectedEvent);
      },
    }),
    [formatDateISO],
  );

  const applyChanges = useCallback(async () => {
    if (startDate && endDate) {
      const baseBody = {
        eventType: 'OutOfOffice' as const,
        start: startDate,
        finish: endDate,
        name: 'Out of office',
        isAllDayEvent: true,
      };
      try {
        await (existingEvent
          ? updateEvent({ id: existingEvent.id, ...baseBody })
          : createEvent(baseBody)
        ).unwrap();
        setIsSidebarOpen(false);
      } catch (err) {
        console.error(err);
      }
    }
  }, [createEvent, startDate, endDate, existingEvent, updateEvent]);

  const onDeleteClick = useCallback(async () => {
    if (existingEvent?.id) {
      try {
        await deleteEvent(existingEvent.id).unwrap();
        dispatch(
          addToast({
            text: 'Absence has been deleted.',
            type: 'positive',
          }),
        );
        setIsSidebarOpen(false);
      } catch (err) {
        const message =
          typeof err === 'object' && err && 'message' in err
            ? (err?.message as string)
            : 'Something went wrong. Try again later.';
        dispatch(
          addToast({
            text: message,
            type: 'negative',
          }),
        );
      }
    }
  }, [existingEvent, deleteEvent, dispatch]);

  const reset = useCallback(() => {
    setStartDate(existingEvent?.start);
    setEndDate(existingEvent?.finish);
  }, [existingEvent]);
  return (
    <>
      <SidebarContainer
        open={isSidebarOpen}
        onOpenChange={(open) => !open && setIsSidebarOpen(false)}
      >
        <Sidebar open={isSidebarOpen}>
          <SidebarHeader>
            <SidebarTitle>
              <div className="flex gap-6">
                Block Dates
                <Button size={'xxs'} onClick={reset} preset="warning">
                  Reset All
                </Button>
              </div>
            </SidebarTitle>
          </SidebarHeader>
          <SidebarContent className="flex flex-col gap-8">
            <div className="flex flex-col">
              <span className="typography-body-sm">Provider</span>
              <span className="typography-heading-lg">
                {firstName} {lastName}
              </span>
            </div>
            <Separator />
            <div>
              <DateRangePicker {...{ startDate, setStartDate, endDate, setEndDate }} />
            </div>
          </SidebarContent>
          <SidebarFooter>
            {existingEvent?.id ? (
              <div className="flex w-full gap-4">
                <Button
                  isLoading={isUpdating}
                  preset="secondary"
                  className="grow basis-0"
                  onClick={() => applyChanges()}
                >
                  Edit
                </Button>
                <Button
                  isLoading={isDeleting}
                  preset="warning"
                  className="grow basis-0"
                  onClick={() => onDeleteClick()}
                >
                  Delete
                </Button>
              </div>
            ) : (
              <Button
                isLoading={isCreating}
                preset="secondary"
                className="w-full"
                onClick={() => applyChanges()}
              >
                Apply
              </Button>
            )}
          </SidebarFooter>
        </Sidebar>
      </SidebarContainer>
    </>
  );
});
export default OutOfOfficeForm;
