import Check from 'src/icons/Check';
import InfoEvent from 'src/icons/InfoEvent';
import { ScheduleEvent } from 'src/pages/schedule/schedule.types';

import { ScheduleType } from '../../hooks/useScheduleType';
import { EEventDisplayType, EEventDisplayTypeWithDot, EventDotType } from './calendar-events.types';

export const getEventDisplayType = (event: ScheduleEvent, type: ScheduleType) => {
  if (event.eventType === 'Holiday') {
    return EEventDisplayType.PUBLIC_HOLIDAYS;
  } else if (!event.isMyEvent) {
    return EEventDisplayType.ANOTHER_PROVIDER;
  } else if (event.status === 'Cancelled') {
    return EEventDisplayType.MY_CANCELLED;
  } else if (event.eventType === 'OutOfOffice') {
    return EEventDisplayType.MY_HOLIDAYS;
  } else if (event.status === 'Confirmed' || event.status === 'Signed') {
    return EEventDisplayType.MY_PAST_RECORDED;
  } else if (new Date(event.start) > new Date()) {
    return type === ScheduleType.PROVIDER_SCHEDULE
      ? EEventDisplayType.MY_FUTURE
      : EEventDisplayType.MY_FUTURE_ON_STUDENTS_SCHEDULE;
  } else {
    return EEventDisplayType.MY_PAST_NOT_RECORDED;
  }
};

export const EVENT_TYPE_TO_BACKGROUND_COLOR_MAP: Record<EEventDisplayType, string> = {
  [EEventDisplayType.MY_FUTURE]: 'bg-neutral-white',
  [EEventDisplayType.MY_FUTURE_ON_STUDENTS_SCHEDULE]: 'bg-secondary-800',
  [EEventDisplayType.MY_PAST_RECORDED]: 'bg-schedule-green-500',
  [EEventDisplayType.MY_PAST_NOT_RECORDED]: 'bg-schedule-red-500',
  [EEventDisplayType.MY_CANCELLED]: 'bg-neutral-white',
  [EEventDisplayType.ANOTHER_PROVIDER]: 'bg-neutral-white',
  [EEventDisplayType.PUBLIC_HOLIDAYS]: 'bg-secondary-700',
  [EEventDisplayType.MY_HOLIDAYS]: 'bg-neutral-950 bg-calendar-pattern',
};

export const EVENT_TYPE_TO_TEXT_COLOR_MAP: Record<EEventDisplayType, string> = {
  [EEventDisplayType.MY_FUTURE]: 'text-neutral-black',
  [EEventDisplayType.MY_FUTURE_ON_STUDENTS_SCHEDULE]: 'text-secondary-100',
  [EEventDisplayType.MY_PAST_RECORDED]: 'text-schedule-green-800',
  [EEventDisplayType.MY_PAST_NOT_RECORDED]: 'text-schedule-red-800',
  [EEventDisplayType.MY_CANCELLED]: 'text-neutral-black line-through',
  [EEventDisplayType.ANOTHER_PROVIDER]: 'text-schedule-gray',
  [EEventDisplayType.PUBLIC_HOLIDAYS]: 'text-secondary-200',
  [EEventDisplayType.MY_HOLIDAYS]: 'text-neutral-200',
};

export const EVENT_TYPE_TO_DOT_PRESET_MAP: Record<EEventDisplayTypeWithDot, EventDotType> = {
  [EEventDisplayType.MY_FUTURE]: 'pacific',
  [EEventDisplayType.MY_PAST_RECORDED]: 'schedule-green',
  [EEventDisplayType.MY_PAST_NOT_RECORDED]: 'schedule-red',
  [EEventDisplayType.MY_CANCELLED]: 'pacific',
  [EEventDisplayType.MY_FUTURE_ON_STUDENTS_SCHEDULE]: 'secondary',
  [EEventDisplayType.ANOTHER_PROVIDER]: 'pacific',
};

export const getEventDisplayName = (
  event: ScheduleEvent,
  eventTime: string,
  isSmallScreen: boolean,
  allowDoubleLines: boolean,
) => {
  const isSingleLine =
    event.isAllDayEvent ||
    !allowDoubleLines ||
    new Date(event.finish).getTime() - new Date(event.start).getTime() < 1750000;

  if (isSingleLine) {
    const text = event.isAllDayEvent
      ? event.name
      : isSmallScreen
      ? `${event.name} ${eventTime}`
      : `${eventTime} ${event.name}`;
    return <span className="truncate">{text}</span>;
  } else {
    return (
      <div className="truncate">
        <div className="truncate">{eventTime}</div>
        <div className="truncate">{event.name}</div>
      </div>
    );
  }
};

export const getEventIcon = (type: EEventDisplayType) => {
  if (type === EEventDisplayType.MY_PAST_RECORDED) {
    return (
      <span className="block h-4 w-4 rounded-full bg-schedule-green-800">
        <Check className="h-4 w-4 text-schedule-green-500" />
      </span>
    );
  } else if (type === EEventDisplayType.MY_PAST_NOT_RECORDED) {
    return (
      <span className="block h-4 w-4 rounded-full bg-schedule-red-800">
        <InfoEvent className="w-4 text-schedule-red-500" />
      </span>
    );
  }
  return null;
};
