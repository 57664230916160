import { selectAccessToken } from './backendAuthSelectors';

export const currentSessionHookless = (state) => {
  const accessToken = selectAccessToken(state) ?? '';

  return Promise.resolve({
    getIdToken: () => ({
      getJwtToken: () => accessToken,
    }),
  });
};
