import Avatar from 'src/components/Avatar';
import Separator from 'src/components/Separator';

interface MobileUserDetailsProps {
  firstName: string;
  lastName: string;
  email: string;
  photo?: string;
}

const MobileUserDetails = ({ firstName, lastName, email, photo = '' }: MobileUserDetailsProps) => {
  return (
    <>
      <div className="mb-4 flex items-center">
        <Avatar firstName={firstName} lastName={lastName} url={photo} />
        <div className="ml-2">
          <div className="truncate text-neutral-100 typography-heading-sm">
            {firstName} {lastName}
          </div>
          <div className="flex items-center gap-4">
            <span className="truncate text-neutral-300 typography-body-xs">{email}</span>
          </div>
        </div>
      </div>
      <Separator className="mb-4" />
    </>
  );
};

export default MobileUserDetails;
