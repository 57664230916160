import { getDayOfWeek, isSameDay, isSameMonth } from '@internationalized/date';
import { CalendarDate } from '@internationalized/date';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { useCalendarCell } from '@react-aria/calendar';
import { useFocusRing } from '@react-aria/focus';
import { useLocale } from '@react-aria/i18n';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import { useRef } from 'react';
import { CalendarState, RangeCalendarState } from 'react-stately';
import { twMerge } from 'tailwind-merge';

type CalendarCellProps = {
  state: CalendarState | RangeCalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
};

const CalendarCell = ({ state, date, currentMonth }: CalendarCellProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { cellProps, buttonProps, isSelected, isDisabled, formattedDate } = useCalendarCell(
    { date },
    state,
    ref,
  );

  const disabled = isDisabled || state.isCellDisabled(date) || state.isCellUnavailable(date);

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  const isSelectionStart =
    'highlightedRange' in state && state.highlightedRange
      ? isSameDay(date, state.highlightedRange.start)
      : isSelected;
  const isSelectionEnd =
    'highlightedRange' in state && state.highlightedRange
      ? isSameDay(date, state.highlightedRange.end)
      : isSelected;

  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td {...cellProps} className={clsx(`relative py-0.5`, isFocusVisible ? 'z-10' : 'z-0')}>
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideMonth}
        className="outline-none"
      >
        <DropdownMenuItem
          className={clsx('group h-10 w-10 outline-none', {
            'rounded-l-full': isRoundedLeft,
            'rounded-r-full': isRoundedRight,
            'bg-primary-700': isSelected,
          })}
          disabled={disabled}
        >
          <div
            className={twMerge(
              clsx(
                `flex h-full w-full items-center justify-center rounded-full typography-body-sm`,
                {
                  'text-neutral-700': disabled,
                  'group-focus:z-2 ring-2 ring-secondary-400 ring-offset-2': isFocusVisible,
                  'bg-secondary-400 text-neutral-white hover:bg-secondary-300':
                    isSelectionStart || isSelectionEnd,
                  'hover:bg-secondary-400 hover:text-neutral-white':
                    isSelected && !(isSelectionStart || isSelectionEnd),
                  'hover:bg-secondary-700': !isSelected && !disabled,
                },
              ),
            )}
          >
            {formattedDate}
          </div>
        </DropdownMenuItem>
      </div>
    </td>
  );
};

export default CalendarCell;
