import type { SVGProps } from 'react';
const SvgInfoEventEvent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.85706 3.40908C6.85706 2.90701 7.26406 2.5 7.76613 2.5H7.77522C8.27729 2.5 8.6843 2.90701 8.6843 3.40908C8.6843 3.91114 8.27729 4.31815 7.77522 4.31815H7.76613C7.26406 4.31815 6.85706 3.91114 6.85706 3.40908Z"
      fill="white"
    />
    <path
      d="M7.76613 6.1363C8.2682 6.1363 8.67521 6.54331 8.67521 7.04538V10.6817C8.67521 11.1837 8.2682 11.5908 7.76613 11.5908C7.26406 11.5908 6.85706 11.1837 6.85706 10.6817V7.04538C6.85706 6.54331 7.26406 6.1363 7.76613 6.1363Z"
      fill="white"
    />
  </svg>
);
export default SvgInfoEventEvent;
