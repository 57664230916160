import type { SVGProps } from 'react';
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.439 3.709a.672.672 0 0 1 0 1.008l-5.445 4.99a.829.829 0 0 1-1.1 0L2.562 7.57a.672.672 0 0 1 0-1.008.829.829 0 0 1 1.1 0l1.783 1.634L10.34 3.71a.829.829 0 0 1 1.1 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheck;
