import { MutableRefObject, useRef } from 'react';

const useCalendarToPdf = () => {
  const generatePDF: MutableRefObject<(withStudentHeader: boolean, fileName?: string) => void> =
    useRef(() => console.log('Export function is not assigned!'));

  return generatePDF;
};

export default useCalendarToPdf;
