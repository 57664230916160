import Collapsible from 'src/components/Collapsible';
import DateRangePicker from 'src/components/date-picker/DateRangePicker';

import { selectedCountLabel } from './selectedCountLabel';

type FilterByDateRangeProps = {
  startDate?: string;
  endDate?: string;
  setStartDate: (v?: string) => void;
  setEndDate: (v?: string) => void;
  separator?: boolean;
};

const FilterByDateRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  separator = true,
}: FilterByDateRangeProps) => {
  return (
    <Collapsible
      title={`Date${selectedCountLabel([startDate, endDate].filter(Boolean) as string[])}`}
      separator={separator}
      defaultOpen={false}
    >
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </Collapsible>
  );
};

export default FilterByDateRange;
