import type { SVGProps } from 'react';
const SvgTripleDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <g fill="currentColor">
      <path d="M2 10.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM8 10.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM16 8.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
    </g>
  </svg>
);
export default SvgTripleDots;
