import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import CenteredLoader from 'src/components/CenteredLoader';
import Pagination from 'src/components/Pagination';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import {
  IconApple2,
  IconArrowLeftGoals,
  IconArrowRightGoals,
  IconBasketball,
  IconBook2,
  IconCube,
  IconEmojiEmotions1,
  IconLanguage,
  IconMath,
  IconOt,
  IconPlus,
  IconWriting,
} from 'src/icons';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getPageCount } from 'src/utils/pagination.utils';

import AddNewGoalModal from './AddNewGoalModal';
import AddNewGoalModalMobile from './AddNewGoalModalMobile';
import {
  invalidateSingleStudentTags,
  useSingleStudentDetailsQuery,
  useSingleStudentGoalDeleteMutation,
  useSingleStudentGoalsListQuery,
  useSingleStudentGoalsYearsFiltersQuery,
  useSingleStudentGoalUndoPatchMutation,
} from './singleStudentApi';
import SingleStudentGoal from './SingleStudentGoal';
import StudentsGoalsFilters from './StudentGoalsFilters';
import StudentNoConfirmedGoals from './StudentNoConfirmedGoals';
import StudentNoGoals from './StudentNoGoals';
import {
  selectIsMasteredFilterApplyied,
  selectStudentGoalsFilters,
  selectStudentGoalsFiltersCount,
} from './StudentSelectors';
import { setStudentFilters } from './StudentSlice';

const StudentGoals = () => {
  const dispatch = useAppDispatch();
  const { isSm } = useBreakpoint('sm');
  const { isMd } = useBreakpoint('md');
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const filters = useAppSelector(selectStudentGoalsFilters);
  const filtersCount = useAppSelector(selectStudentGoalsFiltersCount);
  const isFilterMasteredApplyied = useAppSelector(selectIsMasteredFilterApplyied);
  const { data: studentYears, isSuccess: isSuccessStudentYears } =
    useSingleStudentGoalsYearsFiltersQuery(id!);

  const { data: currentStudent } = useSingleStudentDetailsQuery(id!);
  const [addNewGoalVisible, setAddNewGoalVisible] = useState(false);
  const [filtersTriggerOpen, setFiltersTriggerOpen] = useState(false);
  const [isDefaultYear, setIsDefaultYear] = useState(true);
  const [currentYearIndex, setCurrentYearIndex] = useState(0);

  const { data, refetch } = useSingleStudentGoalsListQuery({
    page,
    id: id!,
    schoolYearId:
      studentYears?.[isDefaultYear ? studentYears.length - 1 : currentYearIndex]?.id ?? undefined,
    ...filters,
  });
  const [deleteGoal] = useSingleStudentGoalDeleteMutation();
  const [changeStatusGoal] = useSingleStudentGoalUndoPatchMutation();

  const pagesCount = getPageCount(data!);
  const [studentYearFilterId, setStudentYearFilterId] = useState<string | undefined>(undefined);

  const { data: staticDefaultAmountOfGoals } = useSingleStudentGoalsListQuery({
    page: 1,
    id: id!,
    showMastered: true,
    schoolYearId: studentYears?.[studentYears.length - 1]?.id ?? undefined,
  });

  const getDomainIcon = (domainName) => {
    const classNameForIcons =
      'w-8 h-8 bg-accent-verdigris-800 rounded-lg text-accent-verdigris-500 p-2';
    switch (domainName) {
      case 'Math':
        return <IconMath className={classNameForIcons} />;
      case 'Social-Emotional':
        return <IconEmojiEmotions1 className={classNameForIcons} />;
      case 'Cognition':
        return <IconWriting className={classNameForIcons} />;
      case 'Literacy':
        return <IconBook2 className={classNameForIcons} />;
      case 'Language':
        return <IconLanguage className={classNameForIcons} />;
      case 'ADL':
        return <IconApple2 className={classNameForIcons} />;
      case 'Fine motor':
        return <IconCube className={classNameForIcons} />;
      case 'Gross motor':
        return <IconBasketball className={classNameForIcons} />;
      case 'OT':
        return <IconOt className={classNameForIcons} />;
      default:
        console.log('Unknown element');
        return null;
    }
  };

  const addYear = () => {
    if (studentYears && currentYearIndex + 1 < studentYears?.length) {
      setIsDefaultYear(false);
      setStudentYearFilterId(studentYears[currentYearIndex + 1].id);
      dispatch(
        setStudentFilters({ ...filters, schoolYearId: studentYears[currentYearIndex + 1].id }),
      );
      setCurrentYearIndex(currentYearIndex + 1);
    }
  };

  const subtractYear = () => {
    if (studentYears && currentYearIndex - 1 >= 0) {
      setIsDefaultYear(false);
      setStudentYearFilterId(studentYears[currentYearIndex - 1].id);
      dispatch(
        setStudentFilters({ ...filters, schoolYearId: studentYears[currentYearIndex - 1].id }),
      );
      setCurrentYearIndex(currentYearIndex - 1);
    }
  };

  const handleCloseAddNewGoalModal = (value = false) => {
    setAddNewGoalVisible(false);
    if (value) {
      refetch();
    }
  };

  const handleUndoGoal = ({ goalId, status }: { goalId: string; status: string }) => {
    changeStatusGoal({ studentId: id!, goalId, status });
  };

  const handleMasteredGoal = ({ goalId }: { goalId: string }) => {
    changeStatusGoal({ studentId: id!, goalId, status: 'Mastered' });
    setTimeout(() => {
      dispatch(invalidateSingleStudentTags(['StudentGoals']));
    }, 5000);
  };

  const handleDeleteGoal = ({ goalId }: { goalId: string }) => {
    deleteGoal({ studentId: id!, goalId });

    setTimeout(() => {
      dispatch(invalidateSingleStudentTags(['StudentGoals']));
    }, 5000);
  };

  useEffect(() => {
    if (studentYears && isDefaultYear) {
      setCurrentYearIndex(studentYears?.length - 1 ?? 0);
      setStudentYearFilterId(studentYears[currentYearIndex].id);
    } else if (!isDefaultYear) {
      refetch();
    }
  }, [studentYears, currentYearIndex, isDefaultYear, refetch]);

  return (
    <>
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <Button
            disabled={currentYearIndex < 1}
            Icon={IconArrowLeftGoals}
            preset="tertiary"
            size="sm"
            className="rounded-e-none"
            onClick={subtractYear}
          ></Button>
          <Button
            disabled={studentYears && currentYearIndex >= studentYears.length - 1}
            Icon={IconArrowRightGoals}
            preset="tertiary"
            size="sm"
            className="rounded-s-none"
            onClick={addYear}
          ></Button>
          {isSm && (
            <div className="ml-4 flex gap-x-2 typography-heading-lg">
              <div className="hidden xl:block">School Year </div>
              {studentYears && (
                <>
                  {studentYears[currentYearIndex].startYear} /{' '}
                  {studentYears[currentYearIndex].endYear}
                </>
              )}
            </div>
          )}
        </div>
        {!isSm && (
          <div className="flex gap-x-2 typography-heading-lg">
            {studentYears && (
              <>
                {studentYears[currentYearIndex].startYear} /{' '}
                {studentYears[currentYearIndex].endYear}
              </>
            )}
          </div>
        )}
        <div className="flex">
          {data?.hasConfirmedContactWithParent && (
            <>
              <StudentsGoalsFilters
                studentYearFilterId={studentYearFilterId}
                forceOpen={filtersTriggerOpen}
                onFiltersClosed={() => setFiltersTriggerOpen(false)}
              />
              <Button
                Icon={IconPlus}
                preset="secondary"
                size={isSm ? 'md' : 'sm'}
                iconClassName="text-neutral-white absolute md:relative"
                className="ml-2 w-10 p-0 md:ml-4 md:w-auto md:p-4"
                onClick={() => setAddNewGoalVisible(true)}
              >
                <div className="hidden md:block">Add Goals</div>
              </Button>
              {addNewGoalVisible && isSm && (
                <AddNewGoalModal
                  studentId={id!}
                  totalGoals={staticDefaultAmountOfGoals?.total}
                  handleClose={handleCloseAddNewGoalModal}
                />
              )}
              {addNewGoalVisible && !isSm && (
                <AddNewGoalModalMobile studentId={id!} handleClose={handleCloseAddNewGoalModal} />
              )}
            </>
          )}
        </div>
      </div>
      {data?.hasConfirmedContactWithParent && data?.items?.length === 0 ? (
        <StudentNoGoals
          totalGoals={staticDefaultAmountOfGoals?.total}
          isFilterMasteredApplyied={isFilterMasteredApplyied}
          filtersCount={filtersCount}
          studentName={currentStudent?.firstName}
          onAddGoalsClicked={() => setAddNewGoalVisible(true)}
          onChangeGoalsFiltersClicked={() => {
            setFiltersTriggerOpen(true);
          }}
        />
      ) : data?.items?.length === 0 ? (
        <StudentNoConfirmedGoals />
      ) : null}
      {data?.items?.length != 0 ? (
        data?.items ? (
          <div className="bg-neutral-950">
            {data.items.map((group) => (
              <div
                key={group.domain.id}
                className="mb-4 rounded-lg sm:p-6 sm:pb-2 md:border md:border-neutral-800 md:bg-neutral-white"
              >
                <div className="flex items-center pb-6 typography-heading-md">
                  <span className="mr-4"> {getDomainIcon(group.domain.name)}</span>{' '}
                  {`${group.domain.name} (${group.goals.length})`}{' '}
                </div>
                {group.goals.map((goal) => (
                  <SingleStudentGoal
                    statusDeleted={goal.status === 'Deleted'}
                    statusMastered={goal.status === 'Mastered'}
                    onDeleteClick={() => handleDeleteGoal({ goalId: goal.id })}
                    onUndoClick={() => handleUndoGoal({ goalId: goal.id, status: 'Learning' })}
                    onUndoMasteredClick={() =>
                      handleUndoGoal({ goalId: goal.id, status: 'Learning' })
                    }
                    onMasteredClick={() => handleMasteredGoal({ goalId: goal.id })}
                    className="mb-4"
                    key={goal.id}
                    goal={goal}
                  />
                ))}
              </div>
            ))}
            <div className="flex items-center justify-between sm:mt-8">
              <Pagination
                itemsPerPage={data.pageSize}
                totalSize={data.total}
                pagesCount={pagesCount}
                activePage={page}
                onChangeActivePage={setPage}
                searchingItemsPlaceholder="goals"
              />
            </div>
          </div>
        ) : (
          <CenteredLoader />
        )
      ) : null}
    </>
  );
};

export default StudentGoals;
