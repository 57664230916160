export const routes = {
  home: '/',
  schedule: {
    root: '/schedule',
    myPlans: 'my-plans',
  },
  students: '/students',
  singleStudent: {
    root: '/students/:id',
    schedule: 'schedule',
    goals: 'goals',
    about: 'about',
    contact: 'contact',
    notes: 'notes',
  },
  billing: {
    root: '/billing',
    month: {
      root: 'month',
      incompleteInvoices: 'incomplete-invoices',
      pendingInvoices: 'pending-invoices',
      submittedInvoices: 'submitted-invoices',
      paidInvoices: 'paid-invoices',
    },
    year: 'year',
    invoices: 'invoices',
    singleInvoice: 'invoices/:id',
    downloadInvoice: 'invoices/:id/download/:type?',
    reopenInvoice: 'invoices/:id/reopen',
    finalizeAll: 'finalize-all',
    confirmSessions: {
      root: 'confirm-sessions/:id',
      awaitingConfirmation: 'awaiting-confirmation',
      confirmed: 'confirmed',
      cancelled: 'cancelled',
    },
  },
  principal: {
    root: '/billing',
    invoices: {
      root: 'invoices',
      invoice: 'invoices/:id',
      submitAll: 'submit-all',
    },
  },
  settings: {
    root: '/settings',
  },
  auth: {
    login: '/login',
    authorize: '/authorize',
    forgotPassword: '/forgot-password',
    setPassword: '/set-password',
  },
  users: {
    root: '/users',
  },
  singleUser: {
    root: '/users/:id',
  },
  wildcard: '*',
};
