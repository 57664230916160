import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import CardInfoItem from 'src/components/CardInfoItem';
import Loader from 'src/components/Loader';
import ScrollMenu from 'src/components/ScrollMenu';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { getBirthDateWithAge } from 'src/logic/getBirthDateWithAge';

import {
  useSingleStudentDetailsQuery,
  useSingleStudentReportMutation,
  useSingleStudentReportsListQuery,
} from './singleStudentApi';

const StudentAbout = () => {
  const { id } = useParams();
  const { data } = useSingleStudentDetailsQuery(id!);
  const { data: reports, isLoading: areReportsLoading } = useSingleStudentReportsListQuery(id!);
  const [downloadReportMutation] = useSingleStudentReportMutation();
  const { formatDate } = useTimeFormat();

  const menu = [
    { label: 'Basic Info', name: 'details' },
    { label: 'School Info', name: 'education' },
    { label: 'IEP Info', name: 'services' },
  ];

  const onReportClick = (id: string, code: string, fileId: string, fileName: string) => {
    downloadReportMutation({ id, code, fileId, fileName });
  };
  return (
    <div className="flex gap-6">
      <div className="hidden w-48 md:block">
        <ScrollMenu items={menu} className="top-10" />
      </div>
      <div className="flex flex-grow flex-col">
        <Element name={menu[0].name} className="pb-4 ">
          <Card titleClassName="pb-4 mb-6 border-b border-b-neutral-900 " title="Basic Info">
            <div className="flex flex-col gap-6">
              <CardInfoItem
                label="Birth date"
                value={getBirthDateWithAge(formatDate, data?.birthDate)}
              />
              <CardInfoItem label="NYC ID" value={data?.nycId} />
              <CardInfoItem label="Home address" value={data?.fullHomeAddress} />
              <CardInfoItem label="Primary Phone" value={data?.primaryNumber} />
            </div>
          </Card>
        </Element>
        <Element name={menu[1].name} className="pb-4">
          <Card titleClassName="pb-4 mb-6 border-b border-b-neutral-900" title="School Info">
            <div className="flex flex-col gap-6">
              <CardInfoItem
                label="School"
                value={`${data?.schoolName || ''}${
                  data?.fullSchoolAddress ? `, ${data?.fullSchoolAddress}` : ''
                }`}
              />
              <CardInfoItem label="English grade" value={data?.englishGrade} />
              <CardInfoItem label="Yiddish grade" value={data?.yiddishGrade} />
            </div>
          </Card>
        </Element>
        <Element name={menu[2].name} className="pb-2 md:pb-4">
          <Card titleClassName="pb-4 mb-6 border-b border-b-neutral-900" title="IEP info">
            <div className="flex flex-col gap-6">
              {reports?.length ? (
                reports.map((report) => (
                  <div key={report.id}>
                    <div className="flex justify-between">
                      <div className=" ">
                        <div className="typography-body-sm">{report.name}</div>{' '}
                        <div className="typography-loud-sm"> {report.code}</div>
                      </div>
                      <div className=" ">
                        <Button
                          preset="tertiary"
                          size="md"
                          onClick={() =>
                            onReportClick(id!, report.code, report.id, report.fileName)
                          }
                        >
                          Download IEP
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              ) : areReportsLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <div className="text-neutral-300 typography-body-sm">No reports yet</div>
              )}
            </div>
          </Card>
        </Element>
      </div>
    </div>
  );
};

export default StudentAbout;
