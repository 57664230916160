import clsx from 'clsx';
import { Link, Outlet } from 'react-router-dom';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { twMerge } from 'tailwind-merge';

import apple from '../assets/apple_es.svg';
import shape1 from '../assets/auth_shape_1.svg';
import shape2 from '../assets/auth_shape_2.svg';
import shape3 from '../assets/auth_shape_3.svg';
import shape4 from '../assets/auth_shape_4.svg';
import emailLogo from '../assets/email_logo.png';
import Logo from './Logo';

const AuthLayout = () => {
  const { isSm } = useBreakpoint('sm');

  return (
    <div className="relative m-0 flex min-h-screen w-full overflow-auto">
      <div className=" w-full md:w-1/2">
        <div className="mx-auto flex h-full w-full flex-col px-4 pb-8 pt-12 md:py-32 xl:w-[438px] xl:px-0">
          <div className="mb-18 md:mb-12">
            <Logo />
          </div>
          <Outlet />
          <div
            className={twMerge(
              clsx(
                'mt-auto text-center text-neutral-500	 md:text-left',
                isSm ? 'typography-body-sm' : 'typography-body-xs',
              ),
            )}
          >
            ©️2023 Enhanced Support Services Inc. All rights reserved. <br />
            By signing in you agree to{' '}
            <Link className="text-secondary-300" to="/privacy-policy">
              ESS Privacy Policy
            </Link>{' '}
            and{' '}
            <Link className="text-secondary-300" to="/terms-of-use">
              Terms of Use
            </Link>
            .
          </div>
        </div>
      </div>
      <div className=" m-4 hidden w-1/2 items-center justify-center overflow-hidden rounded-2xl bg-neutral-800 md:flex ">
        <div className="relative left-5 w-4/5">
          <img src={apple} alt="login_apple" />{' '}
        </div>
      </div>
      <div className="absolute inset-0 -z-background bg-auth-pattern opacity-10" />
      <img src={shape1} alt="shape 1" className="-z-10 absolute right-0 top-60 hidden md:block" />
      <img src={shape2} alt="shape 2" className="absolute bottom-14  hidden md:block" />
      <img
        src={shape3}
        alt="shape 3"
        className="absolute left-1/2 hidden  -translate-x-48 md:block"
      />
      <img
        src={shape4}
        alt="shape 4"
        className="absolute bottom-0 left-1/2 hidden  translate-x-16 md:block"
      />
      <div className="hidden">
        <img src={emailLogo} alt="login_apple" />
      </div>
    </div>
  );
};

export default AuthLayout;
