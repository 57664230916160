import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ErrorMessageProps = {
  children: ReactNode;
  className?: string;
};

const ErrorMessage = ({ children, className }: ErrorMessageProps) =>
  children ? (
    <div className={twMerge('text-accent-tomato-500 typography-body-sm', className)}>
      {children}
    </div>
  ) : null;

export default ErrorMessage;
