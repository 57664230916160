import { format } from 'date-fns';
import { useMemo } from 'react';

import { ScheduleSessionAvailableHoursResponse } from '../pages/schedule/schedule.types';

const defaultDuration = {
  startOffset: 0,
  finishOffset: 24 * 60,
  incrementInMinutes: 15,
  minLengthInMinutes: 15,
  maxLengthInMinutes: 90,
  stepInMinutes: 15,
  isRelatedService: false,
};

const MIN_PAUSE = 15;

export type Step = { label: string; offset: number };

function offsetInMinutesToStep(offset: number): Step {
  const date = new Date();
  date.setHours(Math.floor(offset / 60));
  date.setMinutes(offset % 60);
  const label = format(date, 'h:mmaaaaa');
  return { label, offset };
}

function convertHourMinuteStringToOffset(value: string): number {
  const [hours, minutes] = value.split(':').map(Number);
  return hours * 60 + minutes;
}

export const convertHourMinuteToStep = (value: string): Step => {
  const offset = convertHourMinuteStringToOffset(value);
  return offsetInMinutesToStep(offset);
};

const useTimeSlots = (
  availability?: ScheduleSessionAvailableHoursResponse,
  startOffset = 0,
  excludedSlots?: { start?: string; finish?: string }[],
  isRelatedService?: boolean,
) => {
  const durationConfig = useMemo(() => {
    if (availability && availability.canSchedule) {
      const { parameters } = availability;
      const [startOffset, finishOffset] = [parameters.startTime, parameters.finishTime].map(
        convertHourMinuteStringToOffset,
      );
      return {
        minLengthInMinutes: parameters.sessionLengthIncrementInMinutes,
        maxLengthInMinutes: parameters.maxSessionLengthInMinutes,
        incrementInMinutes: parameters.sessionLengthIncrementInMinutes,
        stepInMinutes: parameters.step,
        startOffset,
        finishOffset,
        isRelatedService: !!isRelatedService,
      };
    }
    return defaultDuration;
  }, [availability, isRelatedService]);

  const excludedSlotOffsets = useMemo(() => {
    if (!excludedSlots) return undefined;

    return excludedSlots.map((slot) => ({
      start: slot.start && convertHourMinuteStringToOffset(slot.start),
      finish: slot.finish && convertHourMinuteStringToOffset(slot.finish),
    }));
  }, [excludedSlots]);

  const startSteps = useMemo(() => {
    const stepsInDay = (60 / durationConfig.stepInMinutes) * 24;
    return Array(stepsInDay)
      .fill(0)
      .map((_, index) => index * durationConfig.stepInMinutes)
      .filter(
        (offset) => offset >= durationConfig.startOffset && offset < durationConfig.finishOffset,
      )
      .filter((offset) => {
        if (!excludedSlotOffsets || !excludedSlotOffsets.length) return true;
        const shouldExclude = !!excludedSlotOffsets.find((slot) => {
          if (!slot.start) return false;

          if (slot.finish) {
            return slot.start - MIN_PAUSE <= offset && offset < slot.finish + MIN_PAUSE;
          }

          return slot.start - MIN_PAUSE <= offset && offset <= slot.start + MIN_PAUSE;
        });
        return !shouldExclude;
      })
      .map(offsetInMinutesToStep);
  }, [durationConfig, excludedSlotOffsets]);

  const finishSteps = useMemo(() => {
    return Array(1 + Math.floor(durationConfig.maxLengthInMinutes / durationConfig.stepInMinutes))
      .fill(0)
      .map((_, index) => (startOffset || 0) + index * durationConfig.incrementInMinutes)
      .map(offsetInMinutesToStep)
      .filter(
        ({ offset }) =>
          offset >= durationConfig.startOffset && offset <= durationConfig.finishOffset,
      )
      .filter(({ offset }) => offset > durationConfig.minLengthInMinutes)
      .filter(({ offset }) => {
        if (!excludedSlotOffsets || !excludedSlotOffsets.length) return true;

        const shouldExclude = !!excludedSlotOffsets.find((slot) => {
          if (!slot.start) return false;

          if (slot.finish) {
            return (
              (slot.start < offset && offset < slot.finish + MIN_PAUSE) ||
              (startOffset <= slot.start && slot.start < offset + MIN_PAUSE)
            );
          }

          return startOffset <= slot.start && slot.start < offset + MIN_PAUSE;
        });

        return !shouldExclude;
      })
      .splice(1)
      .map(({ offset, label }, index) => {
        const newLabel = durationConfig.isRelatedService
          ? `${index + 1} Session${index > 0 ? 's' : ''} (${offset - startOffset}min)`
          : `${label} (${offset - startOffset}min)`;
        return { offset, label: newLabel, oldLabel: label };
      });
  }, [startOffset, durationConfig, excludedSlotOffsets]);

  return { startSteps, finishSteps };
};

export default useTimeSlots;
