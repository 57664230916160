import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PdfHowTo from 'src/assets/pdf/EnhancedServicesXPortalHowTo.pdf';
import { useAuth } from 'src/auth/useAuth';
import BetaIndycator from 'src/components/BetaIndycator';
import Button from 'src/components/Button';
import Logo from 'src/components/Logo';
import MobileNotifications from 'src/components/MobileNotifications';
import MobileSidebarMenu from 'src/components/MobileSidebarMenu';
import MobileUserDetails from 'src/components/MobileUserDetails';
import ContactSupport from 'src/contact-support/ContactSupport';
import useUser from 'src/hooks/useUser';
import { IconChevronLeft, IconHamburgerMenu, IconLogOut, IconPdf, IconSettings } from 'src/icons';
import { routes } from 'src/routes';
import { getAllAPIs, useAppDispatch } from 'src/store';
import { twMerge } from 'tailwind-merge';

import NavigationButton from './NavigationButton';
import NavigationLinks from './NavigationLinks';

type MobileNavigationProps = {
  className?: string;
};

const HEADER_HEIGHT = 65; // TODO: import from tailwind config

const MobileNavigation = ({ className }: MobileNavigationProps) => {
  const { firstName, lastName, email, avatar } = useUser();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const { signOut } = useAuth();
  const dispatch = useAppDispatch();

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    const isScrollingUp = prevScrollPos > currentScrollPos;

    setIsHidden(!isScrollingUp && currentScrollPos > HEADER_HEIGHT);
    setPrevScrollPos(currentScrollPos);
  }, [setIsHidden, setPrevScrollPos, prevScrollPos]);

  const handleOpenPDF = () => {
    window.open(PdfHowTo, '_blank');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, isHidden, handleScroll]);

  const handleLogout = () => {
    getAllAPIs.forEach((api) => dispatch(api.util.resetApiState()));
    setTimeout(() => signOut());
  };

  const handleMenuClose = () => setIsMenuOpen(false);

  return (
    <>
      {!isMenuOpen && (
        <div
          className={twMerge(
            'border-b border-b-neutral-800 bg-neutral-white',
            'box-content flex h-8 items-center justify-between py-4 pr-4',
            'fixed left-0 right-0 top-0 z-mobileHeader transition-transform duration-500',
            isHidden ? '-translate-y-mobile-header' : '',
            className,
          )}
        >
          <Button
            preset="ghost"
            Icon={IconHamburgerMenu}
            onClick={() => setIsMenuOpen(true)}
            iconClassName="text-secondary-300"
            textClassName="text-secondary-300 !font-medium"
          >
            Menu
          </Button>
          <div className="flex items-center">
            <MobileNotifications />
          </div>
        </div>
      )}

      <MobileSidebarMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
        <div className="mb-6 ml-3.5">
          <Logo className="h-6" />
          <BetaIndycator isCollapsed isMobile />
        </div>
        <MobileUserDetails firstName={firstName} lastName={lastName} email={email} photo={avatar} />
        <NavigationLinks
          isCollapsed={false}
          showNotifications={false}
          clickLinkCb={handleMenuClose}
        />
        <div className="mt-auto">
          <NavigationButton
            Icon={IconSettings}
            label="Profile"
            isCollapsed={false}
            onClick={() => {
              navigate(routes.settings.root);
              handleMenuClose();
            }}
          />
          <NavigationButton
            Icon={IconLogOut}
            label="Log out"
            isCollapsed={false}
            onClick={handleLogout}
          />
          {/* TODO: handle mobile version */}
          <ContactSupport isCollapsed={false} onMenuClose={handleMenuClose} />
          <NavigationButton
            Icon={IconPdf}
            label={`"How-To" guide`}
            isCollapsed={false}
            onClick={handleOpenPDF}
          />
          <NavigationButton
            Icon={IconChevronLeft}
            label="Hide menu"
            isCollapsed={false}
            rotateIcon
            title="Toggle navigation"
            onClick={handleMenuClose}
          />
        </div>
      </MobileSidebarMenu>
    </>
  );
};

export default MobileNavigation;
