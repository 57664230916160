import * as Ably from 'ably';
import { useEffect, useRef } from 'react';
import { useNotifications } from 'src/hooks/useNotifications';
import { invalidateStudentsListTags } from 'src/pages/students/studentsApi';
import { useAppDispatch } from 'src/store';

import { setAblyEvent } from './ablyNotificationsSlice';
import { AblyEvent, EAblyEventTypes } from './notification.types';
import { useGetRealTimeEventsQuery } from './notificationApi';

const VITE_ABLY_API_KEY = import.meta.env.VITE_ABLY_API_KEY;

export const useAblyNotifications = () => {
  const { data } = useGetRealTimeEventsQuery();
  const dispatch = useAppDispatch();
  const channel = useRef<Ably.Types.RealtimeChannelCallbacks>();
  const { invalidate } = useNotifications();
  useEffect(() => {
    if (data?.notifications && VITE_ABLY_API_KEY) {
      const client = new Ably.Realtime(VITE_ABLY_API_KEY);
      channel.current = client.channels.get(data?.notifications);
      channel.current.subscribe((event) => {
        const eventType = event.data.Payload.Type;
        if (eventType) {
          dispatch(setAblyEvent(event.data.Payload as AblyEvent));
        }
        const group = event.data.Payload.Group;
        if (group) {
          dispatch(invalidate(group));
        }
        if (
          eventType === EAblyEventTypes.SETUP_STUDENT_GOALS ||
          eventType === EAblyEventTypes.NEW_STUDENT_ASSIGNED
        ) {
          dispatch(invalidateStudentsListTags(['StudentsList']));
        }
      });
      return () => {
        channel.current?.unsubscribe();
      };
    }
  }, [data, dispatch, invalidate]);
};
