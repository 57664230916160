import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconChevronDown } from '../icons';
import Separator from './Separator';

type CollapsibleProps = {
  title?: string | ReactNode;
  defaultOpen?: boolean;
  separator?: boolean;
  children?: ReactNode;
  clasName?: string;
};

const Collapsible = ({
  title = 'Header title',
  defaultOpen = true,
  separator = true,
  children,
  clasName,
}: CollapsibleProps) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  let separatorelement;
  if (separator) {
    separatorelement = <Separator />;
  }
  return (
    <CollapsiblePrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
      <CollapsiblePrimitive.Trigger
        className={twMerge(
          'group flex h-16 w-full items-center justify-between  text-left typography-loud-sm md:h-18 ',
          clasName,
        )}
      >
        <div className="text-neutral-100">{title}</div>
        <IconChevronDown className="h-6 w-6 transform text-neutral-400 duration-300 ease-in-out group-hover:text-neutral-600 group-radix-state-open:rotate-180" />
      </CollapsiblePrimitive.Trigger>
      <CollapsiblePrimitive.Content className={twMerge('flex flex-col justify-between ', clasName)}>
        <div className="pb-5">{children}</div>
      </CollapsiblePrimitive.Content>
      {separatorelement}
    </CollapsiblePrimitive.Root>
  );
};

export default Collapsible;
