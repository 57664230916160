import { useEffect, useState } from 'react';
import { Sidebar, SidebarContainer, SidebarContent } from 'src/components/Sidebar';
import { countAllFilters } from 'src/filters/countAllFilters';
import FilterByGrades from 'src/filters/FilterByGrades';
import FilterByMonths from 'src/filters/FilterByMonths';
import FilterBySchools from 'src/filters/FilterBySchools';
import FilterByStudents from 'src/filters/FilterByStudents';
import FilterBySupervisor from 'src/filters/FilterBySupervisor';
import FiltersSidebarFooter from 'src/filters/FiltersSidebarFooter';
import FiltersSidebarHeader from 'src/filters/FiltersSidebarHeader';
import FiltersTriggerButton from 'src/filters/FiltersTriggerButton';
import { useAppDispatch, useAppSelector } from 'src/store';

import { selectBillingMonthFilters, selectBillingMonthFiltersCount } from './billingSelectors';
import { setBillingMonthFilters } from './billingSlice';

const BillingMonthFilters = () => {
  const dispatch = useAppDispatch();
  const initialFilters = useAppSelector(selectBillingMonthFilters);
  const initialFiltersCount = useAppSelector(selectBillingMonthFiltersCount);

  const [studentIds, setStudentsIds] = useState<string[]>();
  const [schoolIds, setSchoolIds] = useState<string[]>();
  const [gradeIds, setGradeIds] = useState<string[]>();
  const [supervisorIds, setSupervisorIds] = useState<string[]>();
  const [months, setMonths] = useState<number[]>();

  const [open, setOpen] = useState(false);

  const filters = {
    studentIds,
    schoolIds,
    grades: gradeIds,
    supervisorIds,
    months,
  };

  useEffect(() => {
    setStudentsIds(initialFilters.studentIds);
    setSchoolIds(initialFilters.schoolIds);
    setGradeIds(initialFilters.grades);
    setSupervisorIds(initialFilters.supervisorIds);
    setMonths(initialFilters.months);
  }, [initialFilters, open]);

  const onApply = () => {
    dispatch(setBillingMonthFilters(filters));
  };

  const onReset = () => {
    setStudentsIds(undefined);
    setSchoolIds(undefined);
    setGradeIds(undefined);
    setSupervisorIds(undefined);
    setMonths(undefined);
  };

  const filtersCount = countAllFilters(filters);

  return (
    <SidebarContainer onOpenChange={setOpen} open={open}>
      <FiltersTriggerButton initialFiltersCount={initialFiltersCount} />
      <Sidebar open={open}>
        <FiltersSidebarHeader
          initialFiltersCount={initialFiltersCount}
          onReset={onReset}
          filtersCount={filtersCount}
        />
        <SidebarContent className="pt-1">
          <FilterByStudents value={studentIds} onChange={setStudentsIds} />
          <FilterBySchools value={schoolIds} onChange={setSchoolIds} />
          <FilterByGrades value={gradeIds} onChange={setGradeIds} />
          <FilterBySupervisor value={supervisorIds} onChange={setSupervisorIds} />
          <FilterByMonths value={months} onChange={setMonths} separator={false} />
        </SidebarContent>
        <FiltersSidebarFooter onApply={onApply} filtersCount={filtersCount} />
      </Sidebar>
    </SidebarContainer>
  );
};

export default BillingMonthFilters;
