export const removeStringAfterColon = (inputString?: string) => {
  if (inputString === undefined) {
    return undefined;
  }

  const parts = inputString.split(':');
  const result = parts[0];
  const cleanedResult = result.replace(/:/g, '');

  return cleanedResult;
};
