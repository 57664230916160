import type { SVGProps } from 'react';
const SvgApple2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.44 6.261A4.464 4.464 0 0 0 8 5.913a4.464 4.464 0 0 0-4.44.348C2.109 7.332.3 11.191 5.225 15.175 6.389 16.116 8 14.713 8 14.713s1.611 1.4 2.775.462c4.925-3.984 3.116-7.843 1.665-8.914Z"
    />
    <path
      fill="currentColor"
      d="M9.158 1.514a3.969 3.969 0 0 1 2.634-.731 3.973 3.973 0 0 1-1.158 2.476A3.976 3.976 0 0 1 8 3.99a3.971 3.971 0 0 1 1.158-2.476Z"
    />
  </svg>
);
export default SvgApple2;
