import { DateValue, parseDate } from '@internationalized/date';
import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useDatePicker } from 'react-aria';
import { useDatePickerState } from 'react-stately';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconCalendar } from 'src/icons';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import Dropdown from '../Dropdown';
import DropdownContent from '../DropdownContent';
import DropdownTrigger from '../DropdownTrigger';
import FormField, { FormFieldProps } from '../FormField';
import { textInputIconStyles, textInputStyles } from '../TextInput';
import Calendar from './Calendar';
import { DateField } from './DateField';

type DatePickerProps = VariantProps<typeof textInputStyles> &
  Omit<FormFieldProps, 'children'> & {
    value?: string;
    onChange?: (v: string) => void;
    isDateDisabled?: (d: DateValue) => boolean;
  };

const DatePicker = ({
  size,
  hasError,
  label,
  hint,
  error,
  id,
  disabled,
  className,
  value,
  onChange,
  isDateDisabled,
  ...props
}: DatePickerProps) => {
  const { timeZone, formatDateISO, format, FORMAT_ISO } = useTimeFormat();
  const handleChange = (newValue: DateValue) => {
    if (newValue && 'toDate' in newValue) {
      const isoDate = formatDateISO(newValue.toDate(timeZone));
      onChange && onChange(isoDate);
    }
  };

  const state = useDatePickerState({
    value: value ? parseDate(value.slice(0, 10)) : undefined,
    onChange: handleChange,
    ...props,
  });

  useEffect(() => {
    if (value && value.length > 10) {
      state.setDateValue(parseDate(format(value, FORMAT_ISO).slice(0, 10)));
    }
  }, [value, state, format, FORMAT_ISO]);

  const ref = useRef<Element>(null);
  const { fieldProps } = useDatePicker(
    {
      ...props,
      label,
    },
    state,
    ref,
  );

  const selectedDateIsDisabledError =
    state.value && isDateDisabled?.(state.value) ? 'Selected date is unavailable' : undefined;

  return (
    <>
      <FormField
        label={label}
        hint={hint}
        error={error || selectedDateIsDisabledError}
        id={id}
        className={clsx('group', className)}
        disabled={disabled}
      >
        <div
          className={twMerge(
            clsx(
              textInputStyles({ size, hasError }),
              'relative group-focus-within:border-secondary-300 group-focus-within:shadow-focus-primary group-focus-within:outline-none',
            ),
          )}
          aria-label="Date Field container"
        >
          <DateField {...fieldProps} isDateUnavailable={isDateDisabled} />
          <Dropdown>
            <DropdownTrigger disabled={disabled}>
              <Button
                Icon={IconCalendar}
                preset="ghost"
                className={twMerge(
                  textInputIconStyles({ size, position: 'sufix' }),
                  'pointer-events-auto cursor-pointer text-neutral-400',
                )}
              />
            </DropdownTrigger>
            <DropdownContent
              className="z-portal"
              side="bottom"
              align="end"
              sideOffset={12}
              alignOffset={-13}
            >
              <Calendar
                value={state.dateValue}
                onChange={handleChange}
                isDateUnavailable={isDateDisabled}
              />
            </DropdownContent>
          </Dropdown>
        </div>
      </FormField>
    </>
  );
};

export default DatePicker;
