import type { SVGProps } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.962 12a16.675 16.675 0 0 0 2.259 2.948c1.642 1.698 3.937 3.274 6.78 3.274 2.841 0 5.137-1.576 6.778-3.274A16.677 16.677 0 0 0 21.038 12a16.678 16.678 0 0 0-2.259-2.948C17.138 7.354 14.842 5.778 12 5.778S6.863 7.354 5.22 9.052A16.676 16.676 0 0 0 2.963 12Zm19.122 0 .82-.398-.002-.003-.004-.006-.01-.02a16.375 16.375 0 0 0-.708-1.167 18.464 18.464 0 0 0-2.063-2.57C18.32 5.98 15.575 4 12 4 8.426 4 5.68 5.98 3.883 7.837a18.463 18.463 0 0 0-2.595 3.422 10.967 10.967 0 0 0-.176.314l-.01.02-.003.006-.001.002s-.001.001.819.399l-.82-.398a.865.865 0 0 0 0 .796l.82-.398-.82.398.002.003.003.006.01.02.037.069a16.333 16.333 0 0 0 .67 1.098 18.463 18.463 0 0 0 2.064 2.57C5.68 18.02 8.426 20 12 20c3.575 0 6.321-1.98 8.117-3.837a18.464 18.464 0 0 0 2.595-3.422 11.198 11.198 0 0 0 .176-.314l.01-.02.004-.006v-.002s.001-.001-.819-.399Zm0 0 .82.398a.866.866 0 0 0 0-.796l-.82.398Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 10.222c-1.012 0-1.833.796-1.833 1.778s.82 1.778 1.833 1.778 1.833-.796 1.833-1.778-.82-1.778-1.833-1.778ZM8.333 12c0-1.964 1.642-3.556 3.667-3.556s3.667 1.592 3.667 3.556-1.642 3.556-3.667 3.556S8.333 13.964 8.333 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEye;
