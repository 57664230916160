import type { SVGProps } from 'react';
const SvgExport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 12.667a.333.333 0 0 0 .333.333h9.334a.333.333 0 0 0 .333-.333V10a1 1 0 1 1 2 0v2.667A2.333 2.333 0 0 1 12.667 15H3.333A2.333 2.333 0 0 1 1 12.667V10a1 1 0 1 1 2 0v2.667ZM3.96 6.04a1 1 0 0 1 0-1.414l3.333-3.333a1 1 0 0 1 1.414 0l3.333 3.333a1 1 0 1 1-1.414 1.414L8 3.414 5.374 6.04a1 1 0 0 1-1.414 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 11a1 1 0 0 1-1-1V2a1 1 0 1 1 2 0v8a1 1 0 0 1-1 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExport;
