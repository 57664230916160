import { addHours, addMinutes, addSeconds, format, parseISO } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useCallback } from 'react';
import { useMeQuery } from 'src/pages/auth/authApi';

const BASE_TIME_ZONE = 'America/New_York';
type DateArg = Date | string | number | undefined;
const calcZonedDate = (date, tz, fn, options = null) => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};
const FORMAT_ISO = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

const useTimeFormat = () => {
  const { data: user } = useMeQuery();

  const timeZone = user?.timeZone || BASE_TIME_ZONE;
  const formatUsingTimeZone = useCallback(
    (date: DateArg, formatString: string) => {
      if (!date) return '';
      try {
        const isIsoDateWithoutTime = typeof date === 'string' && date.length === 10; // yyyy-MM-dd
        if (isIsoDateWithoutTime) {
          const parsedDate = parseISO(date);
          return format(parsedDate, formatString);
        }
        return formatInTimeZone(date, timeZone, formatString);
      } catch (err) {
        return formatString;
      }
    },
    [timeZone],
  );
  const formatDateOnly = (date: string, format = 'MMM dd, yyyy') => {
    if (!date) return '';
    try {
      const targetOffsetInMinutes = getTimezoneOffset(timeZone, new Date(date)) / 1000 / 60;
      const targetDate = addMinutes(new Date(date), -targetOffsetInMinutes);
      return formatUsingTimeZone(targetDate, format);
    } catch (err) {
      return date;
    }
  };

  return {
    timeZone,
    FORMAT_ISO,
    formatHourMinute: (date: DateArg) => formatUsingTimeZone(date, 'HH:mm'),
    monthName: (date: DateArg) => formatUsingTimeZone(date, 'MMMM'),
    formatDateOnly: formatDateOnly,
    formatDate: (date: DateArg) => formatUsingTimeZone(date, 'MMM dd, yyyy'),
    formatDateISO: (date: DateArg) => formatUsingTimeZone(date, 'yyyy-MM-dd'),
    formatLongHourMinute: (date: DateArg) => formatUsingTimeZone(date, 'hh:mmaaa'),
    formatShortHourMinute: (date: DateArg) => formatUsingTimeZone(date, 'h:mmaaaaa'),
    formatShortDateLongHourMinute: (date: DateArg) => formatUsingTimeZone(date, 'MM/dd/yyyy h:mma'),
    format: formatUsingTimeZone,
    zonedEndOfDay: (date) => addSeconds(new Date(formatDateOnly(date, FORMAT_ISO)), 86399),
    zonedStartOfDay: (date) => formatDateOnly(date, FORMAT_ISO),
    zonedMiddleOfDay: (date) => addHours(new Date(formatDateOnly(date, FORMAT_ISO)), 12),
    formatDateTime: (date: DateArg): string => formatUsingTimeZone(date, 'MMM d, yyyy, h:mm aaa'),
  };
};

export default useTimeFormat;
