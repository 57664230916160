import './polyfills';

import { useEffect } from 'react';
import { I18nProvider } from 'react-aria';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Smartlook from 'smartlook-client';

import AuthLayout from './components/AuthLayout';
import { useUserType } from './hooks/useUserType';
import Layout from './layout/Layout';
import NotificationProvider from './notification/NotificationProvider';
import { useMeQuery } from './pages/auth/authApi';
import CodeLogin from './pages/auth/CodeLogin';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import SetPassword from './pages/auth/SetPassword';
import { useIsLoggedIn } from './pages/auth/useIsLoggedIn';
import Billing from './pages/billing/Billing';
import BillingConfirmSessions from './pages/billing/BillingConfirmSessions';
import BillingFinalizeAll from './pages/billing/BillingFinalizeAll';
import BillingInvoicesDownload from './pages/billing/BillingInvoicesDownload';
import BillingInvoicesReopen from './pages/billing/BillingInvoicesReopen';
import BillingInvoiceSummaryDetails from './pages/billing/BillingInvoiceSummaryDetails';
import Principal from './pages/principal/Principal';
import Schedule from './pages/schedule/Schedule';
import ProfileSettings from './pages/settings/ProfileSettings';
import SingleStudent from './pages/single-student/SingleStudent';
import SingleUser from './pages/single-user/SingleUser';
import Students from './pages/students/Students';
import Users from './pages/users/Users';
import { routes } from './routes';
import { store } from './store';
import ToastsProvider from './toasts/ToastsProvider';

export type PrincipalOrParentRoutesProps = {
  variant: 'parent' | 'principal';
};

const PrincipalOrParentRoutes = ({ variant }: PrincipalOrParentRoutesProps) => {
  return (
    <Routes>
      <Route element={<Layout withSimpleHeader withoutProfile />}>
        <Route path={routes.home} index element={<Navigate to={routes.principal.root} replace />} />
        <Route path={`${routes.principal.root}/*`} element={<Principal variant={variant} />} />
        <Route path={routes.settings.root} element={<ProfileSettings />} />
        <Route path={routes.wildcard} element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

const DefaultLoggedInUserRoutes = ({ isAdmin }: { isAdmin?: boolean }) => {
  return (
    <Routes>
      <Route element={<Layout withNavigation />}>
        <Route path={routes.home} element={<Navigate to={routes.students} replace />} />
        <Route path={`${routes.schedule.root}/*`} element={<Schedule />} />
        <Route path={routes.students} element={<Students />} />
        <Route path={`${routes.singleStudent.root}/*`} element={<SingleStudent />} />
        <Route
          path={`${routes.billing.root}/${routes.billing.finalizeAll}`}
          element={<BillingFinalizeAll />}
        />
        <Route
          path={`${routes.billing.root}/${routes.billing.confirmSessions.root}/*`}
          element={<BillingConfirmSessions />}
        />
        <Route
          path={`${routes.billing.root}/${routes.billing.singleInvoice}`}
          element={
            <BillingInvoiceSummaryDetails
              onDownload={() => {
                alert('To be implemented');
              }}
              showGoals
              backTo={'Back to Invoices'}
            />
          }
        />
        <Route path={`${routes.billing.root}/*`} element={<Billing />} />
        {isAdmin && <Route path={routes.users.root} element={<Users />} />}
        {isAdmin && <Route path={routes.singleUser.root} element={<SingleUser />} />}
        {isAdmin && (
          <Route
            path={`${routes.billing.root}/${routes.billing.downloadInvoice}`}
            element={<BillingInvoicesDownload />}
          />
        )}
        {isAdmin && (
          <Route
            path={`${routes.billing.root}/${routes.billing.reopenInvoice}`}
            element={<BillingInvoicesReopen />}
          />
        )}
        <Route path={routes.settings.root} element={<ProfileSettings />} />
        <Route path={routes.wildcard} element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.authorize} element={<CodeLogin />} />
        <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.auth.setPassword} element={<SetPassword />} />
        <Route
          path={`${routes.billing.root}/${routes.billing.downloadInvoice}`}
          element={<Login />}
        />
        <Route
          path={`${routes.billing.root}/${routes.billing.reopenInvoice}`}
          element={<Login />}
        />
        <Route path={routes.wildcard} element={<Navigate to="/login" replace />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  const isLoggedIn = useIsLoggedIn();
  const { userType } = useUserType();
  const isAdmin = userType === 'Admin';
  const { data: user } = useMeQuery();

  useEffect(() => {
    if (!isLoggedIn) {
      Smartlook.anonymize();
    } else if (user) {
      Smartlook.identify(user.id, {
        email: user.email,
        externalId: user.externalId,
        firstName: user.firstName,
        lastName: user.lastName,
        signatureStatus: user.signature?.status || 'no-signature',
        timezone: user.timeZone,
        type: user.userType,
      });
    }
  }, [isLoggedIn, user]);

  if (typeof isLoggedIn !== 'boolean') return null;
  if (!isLoggedIn) return <NotLoggedInRoutes />;
  if (typeof userType === 'undefined') return null;

  const userRoutes = {
    Principal: <PrincipalOrParentRoutes variant="principal" />,
    Parent: <PrincipalOrParentRoutes variant="parent" />,
    default: <DefaultLoggedInUserRoutes {...{ isAdmin }} />,
  };

  return <NotificationProvider>{userRoutes[userType] || userRoutes.default}</NotificationProvider>;
};

const ContextWrappedApp = () => {
  return (
    <I18nProvider locale="en-US">
      <Provider store={store}>
        <App />
        <ToastsProvider />
      </Provider>
    </I18nProvider>
  );
};
export default ContextWrappedApp;
