import clsx from 'clsx';
import Avatar from 'src/components/Avatar';
import { buildName } from 'src/logic/buildName';

import { SingleStudentDetails } from '../singleStudent.types';

export type StudentHeaderProfileProps = {
  student: Pick<SingleStudentDetails, 'schoolName' | 'firstName' | 'lastName' | 'englishGrade'>;
  className?: string;
  size: 'sm' | 'md';
};

const StudentHeaderProfile = ({ student, className, size }: StudentHeaderProfileProps) => {
  return (
    <div className={clsx('flex', className)}>
      <Avatar
        firstName={student?.firstName || ''}
        lastName={student?.lastName || ''}
        url={''} // TODO: should be photo here
        size={size === 'md' ? '3xl' : 'xl'}
        className={clsx({
          'mr-3 md:mr-10': size === 'md',
          'mr-6': size === 'sm',
        })}
      />
      <div className="flex flex-col overflow-hidden">
        <div
          className={clsx({
            'mb-1 flex truncate typography-heading-lg md:mb-2 md:h-7': size === 'md',
            'mb-1 flex  truncate text-button-sm-desktop': size === 'sm',
          })}
        >
          {buildName({
            firstName: student?.firstName,
            lastName: student?.lastName,
          })}
        </div>
        <span
          className={clsx({
            'mb-1 h-7 truncate text-neutral-100 typography-body-md': size === 'md',
            'text-export-to-pdf-2xs text-neutral-100': size === 'sm',
          })}
        >
          {student?.schoolName || ''}
        </span>
        <span
          className={clsx({
            'h-4 truncate text-neutral-400 typography-loud-xs': size === 'md',
            'truncate pt-[6px] text-export-to-pdf-2xs text-neutral-400': size === 'sm',
          })}
        >
          {' '}
          {student?.englishGrade}
        </span>
      </div>
    </div>
  );
};

export default StudentHeaderProfile;
