import { useEffect, useState } from 'react';
import EmptyState from 'src/components/EmptyState';

import Pagination from '../../components/Pagination';
import { getPageCount } from '../../utils/pagination.utils';
import { InvoiceStatus } from './billing.types';
import { useInvoicesListQuery } from './billingApi';
import BillingMonthSingleInvoice from './BillingMonthSingleInvoice';
import BillingMonthSingleInvoiceLoading from './BillingMonthSingleInvoiceLoading';

export type BillingMonthInvoicesProps = {
  month: number;
  schoolYearId: string;
  status: InvoiceStatus;
};

const INIT_PAGE_NUMBER = 1;

const BillingMonthInvoices = ({ month, schoolYearId, status }: BillingMonthInvoicesProps) => {
  const [page, setPage] = useState(INIT_PAGE_NUMBER);

  const { data, isFetching } = useInvoicesListQuery({
    months: [month],
    schoolYearId,
    status,
    page,
  });

  const pagesCount = getPageCount(data!);

  useEffect(() => {
    setPage(INIT_PAGE_NUMBER);
  }, [status, month, schoolYearId]);

  if (isFetching) {
    return (
      <div className="flex flex-col gap-6">
        {Array.from({ length: 3 }, (_, index) => (
          <BillingMonthSingleInvoiceLoading
            key={index}
            className={index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : ''}
          />
        ))}
      </div>
    );
  }

  if (!data?.items?.length) {
    return <EmptyState title={`No ${status.toLowerCase()} invoices.`} description={``} />;
  }

  return (
    <div className="flex flex-col gap-6">
      {data.items?.map((i) => (
        <BillingMonthSingleInvoice key={i.id} {...i} />
      ))}
      <div className="flex items-center justify-between sm:mt-8">
        <Pagination
          itemsPerPage={data.pageSize}
          totalSize={data.total}
          pagesCount={pagesCount}
          activePage={page}
          onChangeActivePage={setPage}
          searchingItemsPlaceholder="goals"
        />
      </div>
    </div>
  );
};

export default BillingMonthInvoices;
