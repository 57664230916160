import { AppStorage } from './storage.type';

const expireKey = 'expireAt';

export type ExpirableStorage = AppStorage & {
  enableFor: (expireTimeMs: number) => void;
  invalidate: () => void;
};

export const expirableStorage = (underlyingStorage: AppStorage): ExpirableStorage => ({
  load: <T>(k: string) => {
    const expireAt = underlyingStorage.load<number>(expireKey) ?? 0;
    const item = underlyingStorage.load<T>(k);

    const isExpired = expireAt < Date.now();
    return item && !isExpired ? item : undefined;
  },
  save: (k, v) => {
    underlyingStorage.save(k, v);
  },
  remove: (k) => {
    underlyingStorage.remove(k);
  },
  invalidate: () => {
    underlyingStorage.remove(expireKey);
  },
  enableFor: (expireTimeMs) => {
    underlyingStorage.save(expireKey, Date.now() + expireTimeMs);
  },
});
