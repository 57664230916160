import { Link } from 'react-router-dom';
import { routes } from 'src/routes';

import SingleStudentCard from './SingleStudentCard';
import { Student } from './students.types';

type StudentsListProps = { students: Student[] };

const StudentsList = ({ students }: StudentsListProps) => {
  return (
    <div className="mb-8 grid grid-cols-1 gap-3  sm:gap-6 md:grid-cols-2 xl:grid-cols-3 2.5xl:grid-cols-4 4xl:grid-cols-5 4.5xl:grid-cols-6 5xl:grid-cols-7">
      {students.map((s) => (
        <Link key={s.id} to={`${routes.singleStudent.root.replace(':id', s.id)}`}>
          <SingleStudentCard {...s} />
        </Link>
      ))}
    </div>
  );
};

export default StudentsList;
