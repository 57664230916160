import { countAllFilters } from 'src/filters/countAllFilters';
import { RootState } from 'src/store';
// goals
export const selectStudentPage = (state: RootState) => state.studentPage.page;
export const selectStudentQueryString = (state: RootState) => state.studentPage.query;
export const selectStudentGoalsFilters = (state: RootState) => state.studentPage.filters;
export const selectStudentGoalsFiltersCount = (state: RootState) =>
  countAllFilters(state.studentPage.filters);
export const selectIsMasteredFilterApplyied = (state: RootState) =>
  !!state.studentPage.filters?.showMastered;
