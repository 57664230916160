import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';

import { ReviewSignatureQuery, UpdateUserSettingsQuery, User } from './singleUser.types';

export const singleUserApi = createApi({
  reducerPath: 'singleUserApi',
  tagTypes: ['User'],
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    singleUser: build.query<User, string>({
      query: (id) => `/user/${id}`,
      forceRefetch: () => true,
      providesTags: ['User'],
    }),
    updateUserSettings: build.mutation<User, UpdateUserSettingsQuery>({
      query: ({ body, id }) => ({
        method: 'PUT',
        url: `/user/${id}/settings`,
        body,
      }),
      invalidatesTags: ['User'],
    }),
    reviewSignature: build.mutation<User, ReviewSignatureQuery>({
      query: ({ body, id }) => ({
        method: 'PUT',
        url: `/user/${id}/signature/review`,
        body,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const { useSingleUserQuery, useUpdateUserSettingsMutation, useReviewSignatureMutation } =
  singleUserApi;
