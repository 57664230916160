import clsx from 'clsx';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Switch from 'src/components/Switch';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { routes } from 'src/routes';
import { useAppDispatch, useAppSelector } from 'src/store';

import { useBillingSummaryQuery } from './billingApi';
import BillingMonthSummaryCard from './BillingMonthSummaryCard';
import BillingMonthSummaryCardLoading from './BillingMonthSummaryCardLoading';
import BillingSchoolYearPicker from './BillingSchoolYearPicker';
import { selectBillingActiveSchoolYear } from './billingSelectors';
import { setActiveMonthIndex } from './billingSlice';

const BillingYear = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isFetching } = useBillingSummaryQuery({ query: '' });
  const [showOnlyIncomplete, setShowOnlyIncomplete] = useState(false);
  const activeSchoolYear = useAppSelector(selectBillingActiveSchoolYear);
  const { isSm } = useBreakpoint('sm');

  const onToggleShowOnlyIncomplete = () => {
    setShowOnlyIncomplete((prev) => !prev);
  };

  const handleClick = (invoice, index) => {
    dispatch(setActiveMonthIndex(index));
    navigate(`${routes.billing.root}/${routes.billing.month.root}`);
  };

  return (
    <div className="px-4 md:px-0">
      {!isSm && (
        <div className="mb-2 mt-4 typography-heading-lg md:ml-4">
          {!isSm &&
            `School Year ${activeSchoolYear?.schoolYearStart || '2023'}/${
              activeSchoolYear?.schoolYearEnd || '2024'
            }`}
        </div>
      )}
      <div className="flex items-end justify-between gap-2 py-6 md:items-center">
        <BillingSchoolYearPicker />
        <Switch
          label="Show only incomplete"
          checked={showOnlyIncomplete}
          onCheckedChange={onToggleShowOnlyIncomplete}
          withFrame
          size="sm"
        />
      </div>
      {isFetching || !activeSchoolYear ? (
        <div className="grid grid-cols-3 gap-6">
          {Array.from({ length: 3 }, (_, index) => (
            <div key={index}>
              <BillingMonthSummaryCardLoading
                size="lg"
                className={clsx(
                  'w-full',
                  index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : '',
                )}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {activeSchoolYear?.invoices.map((invoice, index) =>
            !showOnlyIncomplete || !!invoice.incompleteCount ? (
              <button
                key={`${invoice.year}-${invoice.month}`}
                onClick={handleClick.bind(null, invoice, index)}
              >
                <BillingMonthSummaryCard showCurrentMonth size="lg" {...invoice} />
              </button>
            ) : null,
          )}
        </div>
      )}
    </div>
  );
};

export default BillingYear;
