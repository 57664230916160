import { useState } from 'react';
import Dropdown from 'src/components/Dropdown';
import DropdownContent from 'src/components/DropdownContent';
import DropdownTrigger from 'src/components/DropdownTrigger';
import MobileFullScreenDialog from 'src/components/MobileFullScreenDialog';
import useWindowSize from 'src/hooks/useWindowSize';
import { IconQuestion } from 'src/icons';
import NavigationButton from 'src/layout/NavigationButton';

import ContactSupportForm from './ContactSupportForm';

type ContactSupportProps = {
  isCollapsed: boolean;
  onMenuClose?: () => void;
};

const ContactSupport = ({ isCollapsed, onMenuClose }: ContactSupportProps) => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();

  const isMobileView = width < 640;

  const toggleOpen = () => {
    setOpen(!open);
  };

  if (isMobileView) {
    return (
      <div>
        <NavigationButton
          Icon={IconQuestion}
          label="Contact support"
          className="my-2"
          isCollapsed={isCollapsed}
          onClick={toggleOpen}
        />
        <MobileFullScreenDialog dialogTitle="Submit a ticket" open={open} onOpenChange={toggleOpen}>
          <div className="mt-4 h-screen border-t border-t-neutral-800">
            <ContactSupportForm onClose={toggleOpen} onMenuClose={onMenuClose} />
          </div>
        </MobileFullScreenDialog>
      </div>
    );
  }

  return (
    <Dropdown open={open} onOpenChange={toggleOpen}>
      <DropdownTrigger>
        <NavigationButton
          Icon={IconQuestion}
          label="Contact support"
          className="my-2"
          isCollapsed={isCollapsed}
        />
      </DropdownTrigger>
      <DropdownContent className=" ml-4 w-auto" align="end" side="left">
        <ContactSupportForm onClose={toggleOpen} />
      </DropdownContent>
    </Dropdown>
  );
};

export default ContactSupport;
