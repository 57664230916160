import clsx from 'clsx';
import Label from 'src/components/Label';
import SelectField from 'src/components/SelectField';
import { ScheduleSessionAvailableHoursResponse } from 'src/pages/schedule/schedule.types';

import useTimeSlots, { convertHourMinuteToStep } from '../hooks/useTimeSlots';

type TimeRangePickerProps = {
  onSelectTime?: (startTime: string, finishTime: string) => void;
  separator?: boolean;
  size?: 'sm' | 'xs';
  label?: boolean;
  errors?: { startTime?: string; finishTime?: string };
  startTime?: string;
  finishTime?: string;
  availability?: ScheduleSessionAvailableHoursResponse;
  expectAvailability?: boolean;
  disabled?: boolean;
  excludedSlots?: { start?: string; finish?: string }[];
  isRelatedService: boolean;
};

const minutesToHHMM = (minutes: number) => {
  const hours = Math.floor(minutes / 60).toString();
  const minutesLeft = (minutes % 60).toString();
  return `${hours.padStart(2, '0')}:${minutesLeft.padStart(2, '0')}`;
};

const TimeRangePicker = ({
  onSelectTime,
  label = false,
  separator = false,
  expectAvailability = false,
  size = 'sm',
  errors = {},
  startTime,
  finishTime,
  availability,
  disabled,
  excludedSlots,
  isRelatedService,
}: TimeRangePickerProps) => {
  const onSelectStart = (minutes: string) => {
    if (!onSelectTime) return;

    const start = minutesToHHMM(+minutes);
    onSelectTime(start, '');
  };

  const onSelectFinish = (minutes: string) => {
    if (!onSelectTime) return;

    const finish = minutesToHHMM(+minutes);
    onSelectTime(startTime || '', finish);
  };

  const start = startTime ? convertHourMinuteToStep(startTime) : undefined;
  const finish = finishTime ? convertHourMinuteToStep(finishTime) : undefined;
  const { finishSteps, startSteps } = useTimeSlots(
    availability,
    start?.offset,
    excludedSlots,
    isRelatedService,
  );

  return (
    <div className="flex flex-col">
      {label && <Label>Time</Label>}
      <div
        className={clsx('relative grid items-center gap-1 sm:gap-2', {
          'grid-cols-[auto_auto_auto] gap-1 sm:gap-2': separator,
          'grid-flow-dense grid-cols-2 gap-1 sm:gap-2': !separator,
        })}
      >
        <SelectField
          className="w-20 sm:w-24"
          error={errors?.startTime}
          hideArrow
          options={startSteps.map((s) => ({
            label: s.label,
            value: `${s.offset}`,
          }))}
          size={size}
          onValueChange={onSelectStart}
          value={`${start?.offset}`}
          disabled={disabled || (expectAvailability && !availability)}
        />
        {separator && <div className="text-neutral-300 typography-button-sm">-</div>}
        <SelectField
          className="w-20 sm:w-24"
          error={errors?.finishTime}
          hideArrow
          allowWiderDropdown
          formatSelectedLabel={(value) =>
            !value || !finishSteps?.length
              ? ''
              : finishSteps.find((o) => o.offset === +value)?.oldLabel || ''
          }
          align="end"
          options={finishSteps.map(({ label, offset }) => ({
            label: label,
            value: offset.toString(),
          }))}
          size={size}
          onValueChange={onSelectFinish}
          value={`${finish?.offset}`}
          disabled={disabled || (expectAvailability && !availability) || !start}
        />
      </div>
    </div>
  );
};

export default TimeRangePicker;
