import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import Avatar from 'src/components/Avatar';
import Card from 'src/components/Card';
import { OverflowTooltipText } from 'src/components/OverflowTooltipText';
import Separator from 'src/components/Separator';
import Tag from 'src/components/Tag';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconTimer } from 'src/icons';

import { Student } from './students.types';

export type SingleStudentCardProps = Student;

const SingleStudentCard = ({
  firstName,
  lastName,
  photo,
  schoolName,
  grade,
  startDate,
  finishDate,
  hoursPerWeek,
  hoursPerDay,
  isNew,
  serviceType,
  isActive,
  groupSize,
}: SingleStudentCardProps) => {
  const daysLeft = differenceInDays(Date.parse(finishDate), Date.now());
  const isTimeEnding = daysLeft <= 30;
  const { formatDate } = useTimeFormat();
  const { isSm } = useBreakpoint('sm');
  return (
    <Card
      className={clsx(
        'relative flex  flex-col gap-5 px-4 py-4 sm:gap-0 sm:py-5',
        isActive ? 'hover:shadow-elevation-4' : 'hover:shadow-elevation-1',
      )}
      preset={isActive ? 'default' : 'inactive'}
      tagText={isNew ? 'New' : undefined}
    >
      <div className="flex gap-4 pr-24 md:pr-0">
        <Avatar
          firstName={firstName}
          lastName={lastName}
          url={photo}
          size="xl"
          isActive={isActive}
        />
        <div className="overflow-hidden">
          <OverflowTooltipText side="top">
            <div
              className={clsx(
                'typography-heading-md sm:mb-1',
                isActive ? 'text-neutral-100' : 'text-neutral-400',
              )}
            >
              {firstName} {lastName}
            </div>
          </OverflowTooltipText>

          <div className="flex items-center gap-4">
            <span
              className={clsx(
                'truncate typography-body-sm',
                isActive ? 'text-neutral-300' : 'text-neutral-400',
              )}
            >
              {schoolName}
            </span>
            {grade && (
              <Tag
                preset={isActive ? 'lightBlue' : 'inactive'}
                size="md"
                className="absolute right-2 top-4 h-6	sm:relative sm:right-0 sm:top-0 md:h-8"
              >
                {grade}
              </Tag>
            )}
            {groupSize && isSm && (
              <Tag
                preset={isActive ? 'lightBlue' : 'inactive'}
                size="md"
                className="absolute right-2 top-4 h-6	sm:relative sm:right-0 sm:top-0 md:h-8"
              >
                Group size: {groupSize}
              </Tag>
            )}
          </div>
        </div>
      </div>
      <Separator className="my-4 hidden sm:block" />
      <div className=" flex flex-wrap gap-2 overflow-hidden xl:flex-nowrap">
        {serviceType ? (
          <Tag size="md" preset={isActive ? 'grayStroke' : 'inactive'}>
            {serviceType}
          </Tag>
        ) : null}
        <Tag size="md" preset={isActive ? 'grayStroke' : 'inactive'}>
          {hoursPerWeek}h / week
        </Tag>
        {typeof hoursPerDay === 'number' && hoursPerDay >= 0 && (
          <Tag size="md" preset={isActive ? 'grayStroke' : 'inactive'}>
            {hoursPerDay}h / day
          </Tag>
        )}
        <Tag
          preset={isActive ? 'lightBlue' : 'inactive'}
          size="md"
          className={clsx('relative overflow-hidden', isTimeEnding ? 'pr-9' : '')}
        >
          <OverflowTooltipText>
            <span className="leading-none">
              {startDate && finishDate
                ? `${formatDate(startDate)} - ${formatDate(finishDate)}`
                : ''}
            </span>
          </OverflowTooltipText>

          {isTimeEnding && (
            <Tooltip
              side="bottom"
              content={isActive ? `Service end date approaching.` : ''}
              className="absolute -left-40 top-2 w-50"
              classNameArrow="mr-36"
            >
              <span>
                <IconTimer
                  className={clsx(
                    'absolute right-3 top-2 z-buttons h-4 w-4 shrink-0 text-accent-tomato-500 typography-body-sm ',
                    isActive ? 'text-accent-tomato-500' : 'text-neutral-500',
                  )}
                />
              </span>
            </Tooltip>
          )}
        </Tag>
        {groupSize && !isSm && (
          <Tag preset={isActive ? 'lightBlue' : 'inactive'} size="md">
            Group size: {groupSize}
          </Tag>
        )}
      </div>
    </Card>
  );
};

export default SingleStudentCard;
