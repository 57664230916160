import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AblyEvent, EAblyEventTypes } from './notification.types';

type AblyNotificationsState = {
  events: Record<EAblyEventTypes, AblyEvent | null>;
};

const initialState: AblyNotificationsState = {
  events: {
    [EAblyEventTypes.NEW_STUDENT_ASSIGNED]: null,
    [EAblyEventTypes.SETUP_STUDENT_GOALS]: null,
    [EAblyEventTypes.SIGNATURE_REJECTED]: null,
    [EAblyEventTypes.SIGNATURE_ACCEPTED]: null,
    [EAblyEventTypes.PRINCIPAL_ADDED_AS_SIGNER]: null,
    [EAblyEventTypes.APPROVER_SIGNED_INVOICES]: null,
  },
};

const ablyNotificationsSlice = createSlice({
  name: 'ablyNotifications',
  initialState,
  reducers: {
    setAblyEvent(state, action: PayloadAction<AblyEvent>) {
      state.events = {
        ...state.events,
        [action.payload.Type]: action.payload,
      };
    },
    clearAblyEvent(state, action: PayloadAction<EAblyEventTypes>) {
      state.events = {
        ...state.events,
        [action.payload]: null,
      };
    },
  },
});

export const { setAblyEvent, clearAblyEvent } = ablyNotificationsSlice.actions;

export default ablyNotificationsSlice;
