import * as Dialog from '@radix-ui/react-dialog';
import { eachDayOfInterval, format, isAfter, isBefore, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateValue } from 'react-aria';
import { useParams } from 'react-router-dom';
import ErrorMessage from 'src/components/ErrorMessage';
import Select from 'src/components/Select';
import TimeRangePicker from 'src/form-partials/TimeRangePicker';
import useSelectedStudent from 'src/hooks/useSelectedStudent';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { apiErrorsToDict } from 'src/types/api-error-response';
import { twMerge } from 'tailwind-merge';

import Button from '../../components/Button';
import DateRangePicker from '../../components/date-picker/DateRangePicker';
import DaysPicker, { DayOfWeek } from '../../components/DaysPicker';
import ExtraLabel from '../../components/ExtraLabel';
import {
  Sidebar,
  SidebarContainer,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarTitle,
} from '../../components/Sidebar';
import Tag from '../../components/Tag';
import { IconPlus, IconTrash } from '../../icons';
import { invalidateBillingTags } from '../billing/billingApi';
import {
  invalidateSingleStudentTags,
  useSingleStudentDetailsQuery,
} from '../single-student/singleStudentApi';
import { invalidateStudentsListTags } from '../students/studentsApi';
import ExcludeDates from './ExcludeDates';
import StudentForm from './record-session/StudentForm';
import {
  useCreateSchedulePlanMutation,
  useLazyCheckSessionsBeforeCreateQuery,
  useLazyCheckSessionsBeforeUpdateQuery,
  useSingleSchedulePlanQuery,
  useStudentDisabledDaysQuery,
  useUpdateSchedulePlanMutation,
} from './scheduleApi';
import ScheduleSessionsFormSummary from './ScheduleSessionsFormSummary';
import { useScheduleForm } from './useScheduleForm';

type ScheduleSessionsFormProps = {
  handleClose: () => void;
  scheduleId?: string;
  studentId?: string;
};

const ScheduleSessionsForm = ({
  handleClose,
  scheduleId,
  studentId: selectedStudentId,
}: ScheduleSessionsFormProps) => {
  const dispatch = useAppDispatch();
  const isEdit = !!scheduleId;
  const { formatDate } = useTimeFormat();
  const { id } = useParams();
  const { refetch, isUninitialized } = useSingleStudentDetailsQuery(id!, { skip: !id });
  const { data, error: scheduledError } = useSingleSchedulePlanQuery(scheduleId || '', {
    skip: !scheduleId,
  });
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isErrorFormShown, setIsErrorFormShown] = useState(false);
  const [hasConflictingSessions, setHasConflictingSessions] = useState(false);

  const [create, createInfo] = useCreateSchedulePlanMutation();
  const [update, updateInfo] = useUpdateSchedulePlanMutation();

  const [checkCreate, checkCreateInfo] = useLazyCheckSessionsBeforeCreateQuery();
  const [checkUpdate, checkUpdateInfo] = useLazyCheckSessionsBeforeUpdateQuery();
  const submit = useMemo(() => (isEdit ? update : create), [isEdit, update, create]);
  const isLoading =
    createInfo.isLoading ||
    updateInfo.isLoading ||
    checkCreateInfo.isFetching ||
    checkUpdateInfo.isFetching;
  const error = createInfo.error || updateInfo.error;
  const isSuccess = createInfo.isSuccess || updateInfo.isSuccess;

  const checkSessionsResponse = checkCreateInfo.data || checkUpdateInfo.data;
  const isCheckSessionsFetching = checkCreateInfo.isFetching || checkUpdateInfo.isFetching;

  const checkSessions = isEdit ? checkUpdate : checkCreate;

  useEffect(() => {
    const message = apiErrorsToDict(scheduledError);
    if (message && !isErrorFormShown) {
      setIsErrorFormShown(true);
      dispatch(
        addToast({
          type: 'negative',
          text: message._,
        }),
      );
    }
  }, [scheduledError, isErrorFormShown, dispatch]);

  useEffect(() => {
    if (createInfo.isSuccess) {
      dispatch(
        addToast({
          type: 'positive',
          text: 'Schedule created.',
        }),
      );
      dispatch(invalidateBillingTags(['InvoiceDetails']));
      dispatch(invalidateSingleStudentTags(['StudentEvents']));
      dispatch(invalidateStudentsListTags(['StudentsList']));
    }
  }, [createInfo.isSuccess, dispatch]);

  useEffect(() => {
    if (updateInfo.isSuccess) {
      dispatch(
        addToast({
          type: 'positive',
          text: 'Schedule updated.',
        }),
      );
      dispatch(invalidateBillingTags(['InvoiceDetails']));
      dispatch(invalidateSingleStudentTags(['StudentEvents']));
      dispatch(invalidateStudentsListTags(['StudentsList']));
    }
  }, [updateInfo.isSuccess, dispatch]);

  useEffect(() => {
    if (createInfo.error) {
      dispatch(
        addToast({
          type: 'negative',
          text: 'Schedule not created. Reload the page.',
        }),
      );
      dispatch({ type: 'setErrors', payload: apiErrorsToDict(createInfo.error) });
    }
  }, [createInfo.error, dispatch]);

  useEffect(() => {
    if (updateInfo.error) {
      dispatch(
        addToast({
          type: 'negative',
          text: 'Schedule not updated. Reload the page.',
        }),
      );
      dispatch({ type: 'setErrors', payload: apiErrorsToDict(updateInfo.error) });
    }
  }, [updateInfo.error, dispatch]);

  const {
    numberOfStudents,
    setNumberOfStudents,
    excludeDates,
    studentId,
    setStudentId,
    onSelectStudent,
    scheduleItems,
    onAddScheduleForDay,
    onRemoveScheduleForDay,
    daysOfWeek,
    start,
    finish,
    setStart,
    setFinish,
    onRemoveDateToExclude,
    onSelectDateToExclude,
    setExcludeDates,
    onChangeScheduleItem,
    setScheduleItems,
    onDaysOfWeekChange,
    setDaysOfWeek,
    clearForm,
    validate,
    errors,
    setErrors,
    availableHours = [],
  } = useScheduleForm({ lockOnStudentId: selectedStudentId });

  const { data: disabledDays } = useStudentDisabledDaysQuery(studentId!, {
    skip: !studentId,
  });

  useEffect(() => {
    if (data && !isFormFilled) {
      setDaysOfWeek(data.daysOfWeek);
      setStart(format(parseISO(data.start), 'yyyy-MM-dd'));
      setFinish(format(parseISO(data.finish), 'yyyy-MM-dd'));
      setScheduleItems(data.schedule);
      setExcludeDates(data.exclude || []);
      setStudentId(data.student.id);
      setIsFormFilled(true);
    }
  }, [
    data,
    isFormFilled,
    setDaysOfWeek,
    setStart,
    setFinish,
    setScheduleItems,
    setExcludeDates,
    setStudentId,
  ]);

  const onHandleClose = useCallback(() => {
    clearForm();
    setIsFormFilled(false);
    handleClose();
    setIsErrorFormShown(false);
  }, [clearForm, handleClose]);

  const payload = useMemo(() => {
    const tmpPayload = {
      studentId: studentId!,
      start: start!,
      finish: finish!,
      daysOfWeek,
      schedule: scheduleItems,
      exclude: excludeDates,
      numberOfStudents,
    };
    return scheduleId
      ? {
          id: scheduleId,
          ...tmpPayload,
        }
      : tmpPayload;
  }, [
    studentId,
    start,
    finish,
    daysOfWeek,
    scheduleItems,
    excludeDates,
    scheduleId,
    numberOfStudents,
  ]);

  const { selectedStudent } = useSelectedStudent(true, studentId);
  const onSubmit = async () => {
    if (hasConflictingSessions) {
      await submit(payload);
      return;
    }

    const hasErrors = validate();
    if (hasErrors) return;

    await checkSessions(payload);
  };

  useEffect(() => {
    if (selectedStudent?.groupSize) {
      setNumberOfStudents(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudent?.groupSize, studentId]);

  useEffect(() => {
    async function fetchData() {
      if (checkSessionsResponse?.canSchedule === true) {
        const result = await submit(payload);
        const isError = !!result['error'];
        if (isError) {
          dispatch(
            addToast({
              type: 'negative',
              text: `Error: session can not be ${
                isEdit ? 'edited' : 'scheduled'
              }. Reload the page.`,
            }),
          );
        }
      }
    }
    fetchData();
  }, [checkSessionsResponse, submit, payload, dispatch, isEdit]);

  useEffect(() => {
    if (isCheckSessionsFetching) return;

    if (checkSessionsResponse?.canSchedule === false) {
      dispatch(
        addToast({
          type: 'negative',
          text: `Error: session can not be ${isEdit ? 'edited' : 'scheduled'}. Reload the page.`,
        }),
      );
      if (checkSessionsResponse.conflictedSessions) {
        setHasConflictingSessions(true);
      } else if (checkSessionsResponse.message) {
        setErrors({ _: checkSessionsResponse.message });
      }
    }
  }, [checkSessionsResponse, setErrors, isCheckSessionsFetching, dispatch, isEdit]);

  useEffect(() => {
    if (isSuccess && !isUninitialized) {
      refetch();
      onHandleClose();
    }
  }, [isSuccess, isUninitialized, onHandleClose, handleClose, refetch]);

  useEffect(() => {
    if (error) {
      setErrors(apiErrorsToDict(error));
    }
  }, [error, setErrors]);

  useEffect(() => {
    if (updateInfo.isLoading && !error) {
      onHandleClose();
    }
  }, [updateInfo.isLoading, error, onHandleClose]);
  let prevScheduleDay = '';

  const availableWeekDaysInRange: DayOfWeek[] = useMemo(() => {
    try {
      if (start && finish) {
        return eachDayOfInterval({
          start: parseISO(start),
          end: parseISO(finish),
        })
          .slice(0, 7)
          .map((f) => format(f, 'iiii') as DayOfWeek);
      }
      return [];
    } catch (err) {
      return [];
    }
  }, [start, finish]);

  const isDateDisabled = useCallback(
    (d: DateValue) => {
      const dDate = new Date(d.year, d.month - 1, d.day);

      const isOutOfPlacementRange =
        disabledDays?.startDate && disabledDays.finishDate
          ? isBefore(dDate, parseISO(disabledDays.startDate)) ||
            isAfter(dDate, parseISO(disabledDays.finishDate))
          : false;

      return isOutOfPlacementRange;
    },
    [disabledDays],
  );

  return (
    <SidebarContainer open>
      <Sidebar size="xl" open>
        <SidebarHeader onClose={onHandleClose}>
          <SidebarTitle>{isEdit ? 'Edit schedule' : 'Create schedule'}</SidebarTitle>
        </SidebarHeader>
        <SidebarContent className="flex max-w-[100vw] flex-col gap-8">
          <StudentForm
            onStudentClear={() => onSelectStudent(undefined)}
            onStudentSelect={(student) => onSelectStudent(student.id)}
            isStudentLocked={isEdit || !!selectedStudentId}
            selectedStudentId={studentId}
            savedStudent={data?.student}
            error={errors?.studentId}
            disabled={hasConflictingSessions}
          />
          {hasConflictingSessions && checkSessionsResponse?.conflictedSessions ? (
            <ScheduleSessionsFormSummary
              start={start!}
              finish={finish!}
              schedule={scheduleItems}
              conflictedSessions={checkSessionsResponse.conflictedSessions}
              onEdit={() => setHasConflictingSessions(false)}
              message={checkSessionsResponse.message}
            />
          ) : (
            <>
              <div>
                <ExtraLabel className="mb-6">Start - End Date</ExtraLabel>
                <DateRangePicker
                  startDate={start}
                  setStartDate={setStart}
                  isStartDateDisabled={isDateDisabled}
                  endDate={finish}
                  setEndDate={setFinish}
                  isEndDateDisabled={isDateDisabled}
                  startError={errors?.start}
                  endError={errors?.finish}
                />
              </div>

              <div>
                <ExtraLabel className="mb-4 ">Days</ExtraLabel>
                <DaysPicker
                  options={availableHours?.map((day) => ({
                    label: day.dayOfWeek.slice(0, 2),
                    value: day.dayOfWeek,
                    disabled: !day.canSchedule,
                    message: day.message,
                  }))}
                  availableWeekDaysInRange={availableWeekDaysInRange}
                  value={daysOfWeek}
                  onChange={onDaysOfWeekChange}
                />
                <ErrorMessage className="pt-4">{errors?.daysOfWeek}</ErrorMessage>
              </div>

              <div>
                <ExtraLabel className="mb-4">Schedule</ExtraLabel>
                <div className="flex flex-col gap-4">
                  {!scheduleItems?.length && (
                    <div className="typography-loud-sm">Pick days first...</div>
                  )}
                  {scheduleItems?.map((session, index) => {
                    const canRemove = prevScheduleDay === session.dayOfWeek;
                    prevScheduleDay = session.dayOfWeek;

                    const availabilityForDay = availableHours.find(
                      (day) => day.dayOfWeek === session.dayOfWeek,
                    );

                    const availabillityLocations: { label: string; value: string }[] | undefined =
                      availabilityForDay?.parameters?.locations.map((location) => {
                        return { label: location, value: location };
                      });

                    const maxSessions = availabilityForDay?.canSchedule
                      ? availabilityForDay.parameters.maxSessions
                      : 0;

                    const schedulesForThisDay = scheduleItems.filter(
                      (item) => item.dayOfWeek === session.dayOfWeek,
                    ).length;

                    const canAddAnotherSessionForThisDay =
                      !maxSessions || maxSessions > schedulesForThisDay;

                    const excludedSlots = scheduleItems
                      .filter((_, i) => i !== index)
                      .filter((item) => item.dayOfWeek === session.dayOfWeek)
                      .map((item) => ({ start: item.start, finish: item.finish }));

                    return (
                      <div key={index} className="flex flex-col gap-2">
                        <div
                          className={twMerge(
                            'flex flex-col gap-3 rounded-lg border border-neutral-900 bg-neutral-950 px-4 pb-1 pt-3',
                            errors?.[`scheduleItems.${index}`] && 'border-accent-tomato-500',
                          )}
                        >
                          <div className="text-neutral-100 typography-loud-sm">
                            {session.dayOfWeek}
                          </div>
                          <div className="grow">
                            <div className="flex content-stretch items-center gap-1 sm:gap-4">
                              <Select
                                placeholder="Location"
                                options={availabillityLocations}
                                size="xs"
                                className="w-32 sm:w-48"
                                value={session.location || ''}
                                onValueChange={onChangeScheduleItem.bind(null, index, 'location')}
                              />
                              <div className="hidden h-6 w-0.25 border-r border-r-neutral-800 sm:block" />
                              <div className="grow">
                                <TimeRangePicker
                                  separator
                                  size="xs"
                                  startTime={session.start}
                                  finishTime={session.finish}
                                  onSelectTime={(start, finish) => {
                                    onChangeScheduleItem(index, 'start', start);
                                    onChangeScheduleItem(index, 'finish', finish);
                                  }}
                                  expectAvailability
                                  availability={availabilityForDay}
                                  excludedSlots={excludedSlots}
                                  isRelatedService={
                                    !!(
                                      selectedStudent?.isRelatedService ??
                                      data?.student.isRelatedService
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex min-h-4 items-center justify-between">
                              {canAddAnotherSessionForThisDay && (
                                <Button
                                  preset="ghost"
                                  Icon={IconPlus}
                                  size="xs"
                                  onClick={onAddScheduleForDay.bind(null, session.dayOfWeek)}
                                >
                                  Add a new session for {session.dayOfWeek}
                                </Button>
                              )}
                              {canRemove && (
                                <Button
                                  className="ml-auto"
                                  Icon={IconTrash}
                                  preset="ghost"
                                  size="sm"
                                  onClick={onRemoveScheduleForDay.bind(null, index)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <ErrorMessage>{errors?.[`scheduleItems.${index}`]}</ErrorMessage>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                <ExtraLabel className="mb-4">Exclude dates</ExtraLabel>
                <div className="flex flex-wrap items-center gap-2">
                  {excludeDates.map((d) => (
                    <Tag key={d} preset="gray" onRemove={onRemoveDateToExclude.bind(null, d)}>
                      {formatDate(d)}
                    </Tag>
                  ))}
                  <div className="ml-2">
                    <ExcludeDates onChange={onSelectDateToExclude} />
                  </div>
                </div>
              </div>
            </>
          )}
        </SidebarContent>
        <SidebarFooter error={errors?._}>
          <Button preset="secondary" size="md" onClick={onSubmit} isLoading={isLoading}>
            {isEdit ? 'Edit Schedule' : 'Confirm Schedule'}
          </Button>
          <Dialog.Close asChild>
            <Button preset="tertiary" onClick={onHandleClose}>
              Cancel
            </Button>
          </Dialog.Close>
        </SidebarFooter>
      </Sidebar>
    </SidebarContainer>
  );
};

export default ScheduleSessionsForm;
