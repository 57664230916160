import { formatDistanceToNow } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification, NotificationGroup } from 'src/notification/notification.types';
import { useReadNotificationMutation } from 'src/notification/notificationApi';
import { routes } from 'src/routes';

import Button from './Button';

type NotificationProps = Notification & {
  setIsOpen: (isOpen: boolean) => void;
};

const NotificationItem = ({
  isRead,
  title,
  createdAt,
  content,
  id,
  data,
  group,
  setIsOpen,
  type,
}: NotificationProps) => {
  const [read] = useReadNotificationMutation();
  const formattedTime = formatDistanceToNow(new Date(createdAt));
  const navigate = useNavigate();
  const toggleCollapse = () => {
    if (!isRead) {
      read({ id });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      toggleCollapse();
    }
  };
  const action = useMemo(() => {
    if (group !== NotificationGroup.ACTIONABLE) {
      return;
    }
    switch (type) {
      case 'NewStudentAssigned':
        if ('studentId' in data) {
          return {
            label: 'Add Goals',
            action: () => {
              navigate(
                [routes.singleStudent.root, routes.singleStudent.goals]
                  .join('/')
                  .replace(':id', data.studentId),
              );
              setIsOpen(false);
            },
          };
        }
        return;
      default:
        return;
    }
  }, [group, type, data, navigate, setIsOpen]);
  return (
    <div
      className={`border-b border-b-neutral-800 p-3 px-4 ${
        isRead ? 'bg-neutral-white' : 'bg-neutral-950'
      }`}
    >
      <div role="button" onClick={toggleCollapse} onKeyPress={handleKeyPress} tabIndex={0}>
        <div className="flex w-full gap-2">
          <div>{/* ICON HAS TO BE IMPLEMENTED */}</div>
          <div className="flex grow flex-col">
            <div className="flex justify-between">
              <div className="text-neutral-100 typography-loud-sm">{title}</div>
              <div className="text-neutral-400 typography-body-xs">{formattedTime}</div>
            </div>
            <div className="mt-0.5 flex justify-between py-1">
              <div className={`text-neutral-300 typography-loud-sm `}>{content}</div>
            </div>
          </div>
        </div>
      </div>

      {action && (
        <div className="my-2">
          <Button onClick={action.action} preset={'secondary'} size="sm">
            {action.label}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
