interface IProps {
  [key: string]: string | Blob;
}

export const createFormData = (data: IProps) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return formData;
};
