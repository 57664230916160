import { UserType } from 'src/pages/auth/auth.types';

import { useMeQuery } from '../pages/auth/authApi';

export const useUserType = (): {
  userType: UserType | undefined;
  invalidate: () => void;
} => {
  const { data, isFetching, refetch } = useMeQuery();
  if (isFetching || !data) {
    return { userType: undefined, invalidate: refetch };
  }

  return { userType: data.userType, invalidate: refetch };
};
