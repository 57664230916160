import { DayCellContentArg } from '@fullcalendar/core';

import { RecordSessionButton } from '../../pages/schedule/record-session/RecordSessionButton';
import { isRestrictedHolidayOrOutOfOffice } from '../../utils/isRestrictedHolidayOrOutOfOffice';

type DayCellRecordSessionProps = DayCellContentArg & {
  onClick: (selectedDate: Date) => void;
  disableSessionRecordHover?: (date: Date) => boolean;
};

const DayCellRecordSession = (props: DayCellRecordSessionProps) => {
  const { date, dayNumberText, view } = props;
  const isWorkingDay = !isRestrictedHolidayOrOutOfOffice(date, view.calendar);
  if (!props.onClick) {
    return <span className="fc-daygrid-day-number-container">{dayNumberText}</span>;
  }
  return (
    <div className="relative flex gap-1">
      <span className="fc-daygrid-day-number-container">{dayNumberText}</span>
      {isWorkingDay && !props.disableSessionRecordHover?.(date) && (
        <div className="absolute -right-4 top-0 w-8 overflow-hidden rounded-full md:relative md:right-auto md:w-auto ">
          <RecordSessionButton onClick={() => props.onClick(date)} />
        </div>
      )}
    </div>
  );
};

export default DayCellRecordSession;
