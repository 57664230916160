import './index.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Smartlook from 'smartlook-client';

import App from './App';

if (import.meta.env.VITE_SENTRY_KEY && import.meta.env.VITE_SENTRY_KEY !== 'false') {
  Sentry.init({
    dsn: `https://${import.meta.env.VITE_SENTRY_KEY}`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'https://dev.enhancedservices.org/',
          'https://stg.enhancedservices.org/',
          'https://enhancedservices.org/',
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // 1.0 // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.log('Sentry disabled');
}

if (
  import.meta.env.VITE_SMARTLOOK_PROJECT_KEY &&
  import.meta.env.VITE_SMARTLOOK_PROJECT_KEY !== 'false'
) {
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_PROJECT_KEY, {
    advancedNetwork: { allowedUrls: [/\.*/] },
  });
  Smartlook.record({ emails: true, numbers: true });
} else {
  console.log('Smartlook disabled');
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
