import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Separator from 'src/components/Separator';
import Tag from 'src/components/Tag';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { buildName } from 'src/logic/buildName';

import { InvoiceStatementBase } from './billing.types';

type BillingInvoicesSingleItemProps = InvoiceStatementBase;

const BillingInvoicesSingleItem = ({
  id,
  invoiceName,
  student,
  hours,
  amount,
  status,
  lastStatusChangedAt,
}: BillingInvoicesSingleItemProps) => {
  const { formatDate } = useTimeFormat();
  const { isXl } = useBreakpoint('xl');
  return (
    <Card className="flex flex-col items-start justify-between gap-y-4 xl:flex-row xl:items-center xl:gap-x-4">
      <div className="w-full max-w-143 text-neutral-100 typography-heading-xs">
        {invoiceName || 'No invoice number yet'}
      </div>
      <div className="flex w-full max-w-143 items-center text-neutral-300 typography-body-sm">
        <Avatar
          firstName={student.firstName}
          lastName={student.lastName}
          size="xs"
          className="mr-3"
        />
        {buildName(student)}
      </div>
      {!isXl && <Separator className="w-full" />}
      {!isXl ? (
        <div className="flex w-full gap-x-4">
          <div>
            <Tag preset="lightBlue" size="sm">
              {hours} hours
            </Tag>
          </div>
          {amount && (
            <div className="text-neutral-300 typography-body-sm">
              {amount?.currency} {amount?.value}
            </div>
          )}
          <div>
            {status === 'Paid' && (
              <Tag size="sm" preset="pacific">
                Paid: {formatDate(lastStatusChangedAt)}
              </Tag>
            )}
            {status === 'Submitted' && (
              <Tag size="sm" preset="lightGreen">
                Submitted: {formatDate(lastStatusChangedAt)}
              </Tag>
            )}
          </div>
        </div>
      ) : (
        <>
          <div>
            <Tag preset="lightBlue" size="sm">
              {hours} hours
            </Tag>
          </div>
          <div className="w-full max-w-143 text-center text-neutral-300 typography-body-sm">
            {amount?.currency} {amount?.value}
          </div>
          <div className="flex w-full max-w-143 flex-col items-start">
            {status === 'Paid' && (
              <Tag size="sm" preset="pacific">
                Paid: {formatDate(lastStatusChangedAt)}
              </Tag>
            )}
            {status === 'Submitted' && (
              <Tag size="sm" preset="lightGreen">
                Submitted: {formatDate(lastStatusChangedAt)}
              </Tag>
            )}
          </div>
        </>
      )}

      <Button size="md" preset="secondary" to={id}>
        View Invoice
      </Button>
    </Card>
  );
};

export default BillingInvoicesSingleItem;
