import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';

import Button from './Button';

type AlertDialogProps = RadixAlertDialog.AlertDialogProps & {
  title?: string;
  description?: string;
  canBeDeleted?: boolean;
  onActionClick?: React.MouseEventHandler<HTMLButtonElement> &
    React.MouseEventHandler<HTMLAnchorElement>;
  actionButtonLabel?: string;
  onCancelClick?: React.MouseEventHandler<HTMLButtonElement> &
    React.MouseEventHandler<HTMLAnchorElement>;
  isActionLoading?: boolean;
};

const AlertDialog = ({
  open,
  onOpenChange,
  onActionClick,
  onCancelClick,
  canBeDeleted,
  title,
  description,
  actionButtonLabel,
  isActionLoading,
}: AlertDialogProps) => {
  return (
    <RadixAlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <RadixAlertDialog.Portal>
        <RadixAlertDialog.Overlay className="fixed inset-0 top-0 z-modal bg-neutral-100/25" />
        <RadixAlertDialog.Content
          className={clsx(
            'data-[state=open]:animate-contentShow fixed left-1/2 top-1/2 max-h-[85vh] w-[90vw] max-w-[424px] -translate-x-1/2 -translate-y-1/2 rounded-lg px-4 py-3',
            'z-modal content-center border border-neutral-800 bg-neutral-white shadow-elevation-1',
          )}
        >
          <RadixAlertDialog.Title className="md:md-0-md mb-0 text-neutral-100 typography-heading-md md:typography-loud-lg">
            {title}
          </RadixAlertDialog.Title>
          <RadixAlertDialog.Description className="text-neutral-300 typography-loud-sm md:typography-loud-md">
            {description}
          </RadixAlertDialog.Description>
          <div className="mt-6 grid grid-cols-2 justify-end  gap-4 md:flex md:gap-2">
            <RadixAlertDialog.Cancel asChild>
              <Button preset="tertiary" size="md" className="max-w-20" onClick={onCancelClick}>
                Cancel
              </Button>
            </RadixAlertDialog.Cancel>
            <RadixAlertDialog.Action asChild>
              <Button
                onClick={onActionClick}
                disabled={canBeDeleted || false}
                preset="warning"
                size="md"
                className="max-w-20"
                isLoading={isActionLoading}
              >
                {actionButtonLabel || 'Delete'}
              </Button>
            </RadixAlertDialog.Action>
          </div>
        </RadixAlertDialog.Content>
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );
};

export default AlertDialog;
