import { isAnyOf } from '@reduxjs/toolkit';
import { AppStartListening } from 'src/listenerMiddleware';

import AuthSlice, { AuthState } from './authSlice';
import BackendAuthSlice, { BackendAuthState } from './backend/backendAuthSlice';
import { ExpirableStorage, expirableStorage } from './storage/expirableStorage';
import { lStorage } from './storage/lStorage';

const storage: ExpirableStorage = expirableStorage(lStorage('auth'));

type AuthStorage = {
  authSlice: AuthState;
  backendAuthSlice: BackendAuthState;
};

export const authStorage = {
  load: <K extends keyof AuthStorage>(k: K): AuthStorage[K] | undefined => storage.load(k),
  save: <K extends keyof AuthStorage>(k: K, v: AuthStorage[K]): void => storage.save(k, v),
  remove: <K extends keyof AuthStorage>(k: K): void => storage.remove(k),
  invalidate: (): void => storage.invalidate(),
  enableFor: (expireTimeMs: number): void => storage.enableFor(expireTimeMs),
};

export const addEnableBackdoorAuthStorageListener = (startListening: AppStartListening) => {
  startListening({
    actionCreator: BackendAuthSlice.actions.setBackdoorToken,
    effect: (action) => {
      const expireTimeMs = action.payload.expiresIn * 1000;
      authStorage.enableFor(expireTimeMs);
    },
  });
};

export const addBackendAuthSliceStorageListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(...Object.values(BackendAuthSlice.actions)),
    effect: (_, api) => {
      authStorage.save('backendAuthSlice', api.getState().backendAuth);
    },
  });
};

export const addAuthSliceStorageListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(...Object.values(AuthSlice.actions)),
    effect: (_, api) => {
      authStorage.save('authSlice', api.getState().auth);
    },
  });
};

export const addLogoutStorageListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(AuthSlice.actions.reset, BackendAuthSlice.actions.reset),
    effect: () => {
      authStorage.invalidate();
    },
  });
};
