import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import authSlice from './auth/authSlice';
import { backendAuthApi } from './auth/backend/backendAuthApi';
import backendAuthSlice from './auth/backend/backendAuthSlice';
import { contactSupportApi } from './contact-support/contactSupportApi';
import { dictionariesApi } from './dictionaries/dictionariesApi';
import { filtersApi } from './filters/filtersApi';
import { listenerMiddleware } from './listenerMiddleware';
import ablyNotificationsSlice from './notification/ablyNotificationsSlice';
import { notificationApi } from './notification/notificationApi';
import { anotherPageApi } from './pages/another-page/anotherPageApi';
import anotherPageSlice from './pages/another-page/anotherPageSlice';
import { authApi } from './pages/auth/authApi';
import { unauthorizedApi } from './pages/auth/unauthorizedApi';
import { billingApi } from './pages/billing/billingApi';
import billingSlice from './pages/billing/billingSlice';
import { principalApi } from './pages/principal/principalApi';
import principalSlice from './pages/principal/principalSlice';
import { scheduleApi } from './pages/schedule/scheduleApi';
import schedulePageSlice from './pages/schedule/scheduleSlice';
import { settingsApi } from './pages/settings/settingsApi';
import { singleStudentApi } from './pages/single-student/singleStudentApi';
import studentPageSlice from './pages/single-student/StudentSlice';
import { singleUserApi } from './pages/single-user/singleUserApi';
import { studentsListApi } from './pages/students/studentsApi';
import studentsPageSlice from './pages/students/studentsSlice';
import { usersApi } from './pages/users/usersApi';
import toastsSlice from './toasts/ToastsSlice';

const appReducer = combineReducers({
  [anotherPageApi.reducerPath]: anotherPageApi.reducer,
  [anotherPageSlice.name]: anotherPageSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [backendAuthApi.reducerPath]: backendAuthApi.reducer,
  [backendAuthSlice.name]: backendAuthSlice.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [billingSlice.name]: billingSlice.reducer,
  [dictionariesApi.reducerPath]: dictionariesApi.reducer,
  [filtersApi.reducerPath]: filtersApi.reducer,
  [principalApi.reducerPath]: principalApi.reducer,
  [principalSlice.name]: principalSlice.reducer,
  [scheduleApi.reducerPath]: scheduleApi.reducer,
  [schedulePageSlice.name]: schedulePageSlice.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [singleStudentApi.reducerPath]: singleStudentApi.reducer,
  [singleUserApi.reducerPath]: singleUserApi.reducer,
  [studentPageSlice.name]: studentPageSlice.reducer,
  [studentsListApi.reducerPath]: studentsListApi.reducer,
  [studentsPageSlice.name]: studentsPageSlice.reducer,
  [toastsSlice.name]: toastsSlice.reducer,
  [unauthorizedApi.reducerPath]: unauthorizedApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [ablyNotificationsSlice.name]: ablyNotificationsSlice.reducer,
  [contactSupportApi.reducerPath]: contactSupportApi.reducer,
});

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(
        anotherPageApi.middleware,
        authApi.middleware,
        backendAuthApi.middleware,
        billingApi.middleware,
        dictionariesApi.middleware,
        filtersApi.middleware,
        principalApi.middleware,
        scheduleApi.middleware,
        settingsApi.middleware,
        singleStudentApi.middleware,
        singleUserApi.middleware,
        studentsListApi.middleware,
        unauthorizedApi.middleware,
        usersApi.middleware,
        notificationApi.middleware,
        contactSupportApi.middleware,
      ),
});

export const getAllAPIs = [
  anotherPageApi,
  authApi,
  backendAuthApi,
  billingApi,
  dictionariesApi,
  filtersApi,
  principalApi,
  scheduleApi,
  settingsApi,
  singleStudentApi,
  singleUserApi,
  studentsListApi,
  unauthorizedApi,
  usersApi,
  notificationApi,
  contactSupportApi,
];

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
