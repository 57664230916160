import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Tag, { TagProps } from './Tag';

const cardStyles = cva('grow rounded-lg p-4 md:px-4 md:py-6 md:px-6 md:py-5 ', {
  variants: {
    preset: {
      default: 'bg-neutral-white border-1 border-neutral-800',
      inactive: 'bg-neutral-950 border border-solid border-neutral-800 text-neutral-400',
    },
  },
  defaultVariants: {
    preset: 'default',
  },
});

type CardProps = VariantProps<typeof cardStyles> & {
  children: ReactNode;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  title?: string | ReactNode;
  tagText?: string;
  tagPreset?: TagProps['preset'];
};

const Card = ({
  preset,
  className,
  titleClassName,
  contentClassName,
  title,
  children,
  tagText,
  tagPreset = 'red',
}: CardProps) => (
  <div className={twMerge(cardStyles({ preset }), tagText && 'relative', className)}>
    {title && (
      <div className={clsx('-mx-4 px-4 typography-heading-xs  sm:-mx-6 sm:px-6', titleClassName)}>
        {title}
      </div>
    )}
    {tagText && (
      <>
        <div
          className={clsx(
            'absolute right-0 top-0  h-8 w-12 -translate-y-1/2 rounded shadow-elevation-2 sm:block',
            preset === 'inactive' ? 'bg-neutral-950' : 'bg-neutral-white',
            contentClassName,
          )}
        ></div>
        <div
          className={clsx(
            'absolute right-0 top-0  h-5 w-14 sm:block',
            preset === 'inactive' ? 'bg-neutral-950' : 'bg-neutral-white',
            contentClassName,
          )}
        ></div>
        <div className="absolute right-0 top-0 m-0 -translate-y-1/2 rounded px-1 sm:block">
          <Tag preset={preset === 'inactive' ? 'inactive' : tagPreset} size="sm">
            {tagText}
          </Tag>
        </div>
      </>
    )}
    {children}
  </div>
);

export default Card;
