import { SignatureStatus } from 'src/pages/auth/auth.types';

import { useMeQuery } from '../pages/auth/authApi';

export const useSignatureStatus = (): {
  status: SignatureStatus | undefined;
} => {
  const { data } = useMeQuery();
  return { status: data?.signature?.status };
};
