import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProps,
  DropResult,
} from '@hello-pangea/dnd';
import { assocPath, move, pluck } from 'ramda';
import { insert } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Button from 'src/components/Button';
import CenteredLoader from 'src/components/CenteredLoader';
import Search from 'src/components/Search';
import Select from 'src/components/Select';
import { Tooltip } from 'src/components/Tooltip';
import { IconClose, IconDrag, IconDragCart, IconInfoWarning } from 'src/icons';
import { useAppDispatch } from 'src/store';

import Card from '../../components/Card';
import Modal, { ModalFooter } from '../../components/Modal';
import Tag from '../../components/Tag';
import { invalidateStudentsListTags } from '../students/studentsApi';
import { SingleStudentGoalsDefinitionItem } from './singleStudent.types';
import {
  invalidateSingleStudentTags,
  useCreateSingleStudentGoalsMutation,
  useSingleStudentAllGoalsFiltersQuery,
  useSingleStudentAvailableGradesQuery,
  useSingleStudentAvailableSubdomainsQuery,
  useSingleStudentGoalsDefinitionsQuery,
} from './singleStudentApi';

type Column = {
  id: string;
  list: SingleStudentGoalsDefinitionItem[];
};

type Columns = {
  library: Column;
  selected: Column;
};

const initialColumns: Columns = {
  library: {
    id: 'library',
    list: [],
  },
  selected: {
    id: 'selected',
    list: [],
  },
};

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

interface SelfType {
  elt: HTMLDivElement | null;
}

const useDraggableInPortal = () => {
  const self = useRef<SelfType>({ elt: null }).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
        return self.elt ? createPortal(element, self.elt) : element;
      }
      return element;
    };
};

const handleArrayUpdate = (prev, value, setSelected) => {
  const updatedArray = [...prev];
  const index = updatedArray.indexOf(value);
  if (index !== -1) {
    updatedArray.splice(index, 1);
  } else {
    updatedArray.push(value);
  }
  setSelected(updatedArray);
};

const Column = ({
  col: { list, id },
  filtersApplied: allFiltersApplied,
  isFetching,
  onLoadMore,
}: {
  col: Column;
  filtersApplied?: boolean;
  isFetching?: boolean;
  onLoadMore?: () => void;
}) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      onLoadMore?.();
    }
  }, [onLoadMore, inView]);

  return (
    <StrictModeDroppable droppableId={id}>
      {(provided) => (
        <div style={{ height: '100%' }}>
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="h-full"
            style={{ height: '100%' }}
          >
            {id === 'selected' && !list.length && (
              <div className="flex h-full flex-col items-center justify-center gap-2">
                <IconDrag className="h-6 w-6" />
                <div className="text-neutral-200 typography-body-sm">Drag and drop goals here</div>
              </div>
            )}

            {id === 'library' && !isFetching && (!list.length || !allFiltersApplied) && (
              <div className="flex h-full flex-col items-center justify-center gap-2 rounded-lg border-1 border-neutral-800">
                <IconInfoWarning className="h-6 w-6" />
                <div className="!leading-none text-neutral-200 typography-body-sm ">
                  {allFiltersApplied
                    ? 'No matching records found. Please select different parameters.'
                    : 'Select a domain and grade or subdomain to view goals.'}
                </div>
              </div>
            )}

            {(id !== 'library' || allFiltersApplied) &&
              list.map((element, index) => (
                <Item key={element.id} element={element} index={index} />
              ))}
            {provided.placeholder}

            {!isFetching && !!list.length && onLoadMore && <div ref={ref} className="h-10" />}
            {!!list.length && isFetching && <CenteredLoader />}
          </div>
        </div>
      )}
    </StrictModeDroppable>
  );
};

const Item = ({ element, index }) => {
  const renderDraggable = useDraggableInPortal();

  return (
    <Draggable draggableId={String(element.id)} index={index}>
      {renderDraggable((provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="pb-1 pt-1"
        >
          <Card className="md:px-2">
            <div className="flex items-center gap-x-2">
              <div className="w-auto">
                <IconDragCart />
              </div>
              <div className="overflow-x-hidden">
                <div className="break-words">{element.name}</div>
                <div className="flex gap-2 pt-2">
                  <Tag preset="grayStroke">{element.domain.name}</Tag>
                  <Tag preset="grayStroke">{element.grade.name}</Tag>
                  <Tag preset="grayStroke">{element.subdomain.name}</Tag>
                </div>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </Draggable>
  );
};

const generateFilterOptions = (filtersOptions) =>
  filtersOptions.map((filterElement) => ({ value: filterElement.id, label: filterElement.name }));

const generateFilterName = (filters, filterId) => {
  const selectedFilter = filters?.find((filterElement) => filterElement.id === filterId);
  return selectedFilter ? selectedFilter.name : '';
};

type AddNewGoalModalProps = {
  handleClose: (value?: boolean) => void;
  studentId: string;
  totalGoals?: number;
};

const AddNewGoalModal = ({ handleClose, studentId, totalGoals }: AddNewGoalModalProps) => {
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [columns, setColumns] = useState(initialColumns);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
  const [selectedSubdomains, setSelectedSubdomains] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const [createGoals, { isLoading, isSuccess }] = useCreateSingleStudentGoalsMutation();
  const [isDivVisible, setIsDivVisible] = useState(true);

  const { data: availableGrades = [] } = useSingleStudentAvailableGradesQuery({
    studentId,
    domainIds: selectedDomains,
  });

  const { data: availableSubdomains = [] } = useSingleStudentAvailableSubdomainsQuery({
    studentId,
    domainIds: selectedDomains,
    gradeIds: selectedGrades,
  });

  const { id } = useParams();

  const selectedIds = useMemo(() => {
    return pluck('id', columns.selected.list);
  }, [columns.selected]);

  const libraryColumnFiltered = useMemo(
    () =>
      columns.library && {
        ...columns.library,
        list: columns.library.list.filter((item) => !selectedIds.includes(item.id)),
      },
    [columns.library, selectedIds],
  );

  useEffect(() => {
    if (
      selectedSubdomains.length > 0 &&
      availableSubdomains.filter((domain) => selectedSubdomains.includes(domain.id)).length === 0
    ) {
      setSelectedSubdomains([]);
      return;
    }
    if (
      selectedGrades.length > 0 &&
      availableGrades.filter((domain) => selectedGrades.includes(domain.id)).length === 0
    ) {
      setSelectedGrades([]);
    }
  }, [availableGrades, availableSubdomains, selectedSubdomains, selectedGrades]);

  const filtersApplied =
    !!selectedDomains.length ||
    (!!selectedDomains.length && !!selectedSubdomains.length) ||
    (!!selectedDomains.length && !!selectedGrades.length && !!selectedSubdomains.length) ||
    (!!selectedGrades.length && !!selectedSubdomains.length) ||
    !!selectedSubdomains.length;

  const filters = useSingleStudentAllGoalsFiltersQuery(id || '');
  const { data, isFetching } = useSingleStudentGoalsDefinitionsQuery(
    {
      studentId: id || '',
      domainIds: selectedDomains,
      gradeIds: selectedGrades,
      subdomainIds: selectedSubdomains,
      pageSize: pageSize,
    },
    { skip: !filtersApplied },
  );

  useEffect(() => {
    if (data) {
      setColumns((prev) => ({ ...prev, library: { ...prev.library, list: data.items } }));
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(invalidateSingleStudentTags(['StudentEvents']));
      dispatch(invalidateStudentsListTags(['StudentsList']));
    }
  }, [isSuccess, dispatch]);

  const handleChangeSelectedDomains = (value) => {
    handleArrayUpdate(selectedDomains, value, setSelectedDomains);
  };
  const handleChangeSelectedGrades = (value) => {
    handleArrayUpdate(selectedGrades, value, setSelectedGrades);
  };
  const handleChangeSelectedSubdomains = (value) => {
    handleArrayUpdate(selectedSubdomains, value, setSelectedSubdomains);
  };

  const onSubmitGoals = async () => {
    if (id) {
      const goalsToAdd: string[] = [];
      columns.selected.list.map((item) => goalsToAdd.push(item.id));
      await createGoals({
        id: id,
        goalDefinitionIds: goalsToAdd,
      });
      handleClose(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(invalidateStudentsListTags(['StudentsList']));
      dispatch(invalidateSingleStudentTags(['GoalsDefinitions']));
    }
  }, [isSuccess, dispatch]);

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (destination === undefined || destination === null) return null;

    if (source.droppableId === destination.droppableId && destination.index === source.index)
      return null;

    const start =
      source.droppableId === 'library' ? libraryColumnFiltered : columns[source.droppableId];
    const end =
      destination.droppableId === 'library'
        ? libraryColumnFiltered
        : columns[destination.droppableId];

    if (start === end) {
      setColumns((state) =>
        assocPath([start.id, 'list'], move(source.index, destination.index, start.list), state),
      );
      return null;
    } else {
      const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

      const newStartCol = {
        id: start.id,
        list: newStartList,
      };

      const newEndList = insert(destination.index, start.list[source.index], end.list);

      const newEndCol = {
        id: end.id,
        list: newEndList,
      };

      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };

  const onLoadMore = useCallback(() => setPageSize((prev) => prev + 5), [setPageSize]);

  useEffect(() => {
    return () => {
      setIsAlertDialogOpen(false);
      setColumns(initialColumns);
    };
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allSubdomains = useMemo(() => {
    return (filters?.data?.subdomains || []).filter((subdomain) => {
      return (
        selectedSubdomains.includes(subdomain.id) ||
        availableSubdomains.some((availaibleSubdomain) => availaibleSubdomain.id === subdomain.id)
      );
    });
  }, [filters?.data?.subdomains, selectedSubdomains, availableSubdomains]);

  const allGrades = useMemo(() => {
    return (filters?.data?.grades || []).filter((grade) => {
      return (
        selectedGrades.includes(grade.id) ||
        availableGrades.some((availaibleGrade) => availaibleGrade.id === grade.id)
      );
    });
  }, [filters?.data?.grades, selectedGrades, availableGrades]);
  const selectedGoalsCount = columns.selected.list.length;
  return (
    <Modal
      open={true}
      onOpenChange={() => {
        if (columns.selected.list.length > 0) {
          setIsAlertDialogOpen(true);
        } else {
          handleClose();
        }
      }}
      title="Add goals"
      className="flex h-full w-full rounded-md"
      contentClassName="grid grow grid-cols-2"
      Footer={
        <ModalFooter className="mx-0 mt-0 pb-6">
          <div className="flex gap-4">
            <Tooltip
              content={totalGoals === 0 && selectedGoalsCount < 6 ? 'Add minimum 6 goals' : ''}
              asChild={false}
              trigger="hover"
              side="top"
            >
              <Button
                preset="secondary"
                disabled={selectedGoalsCount < 1 || (totalGoals === 0 && selectedGoalsCount < 6)}
                onClick={() => onSubmitGoals()}
                isLoading={isLoading}
              >
                Add Goals {(selectedGoalsCount && `(${selectedGoalsCount})`) || ''}
              </Button>
            </Tooltip>
            {columns.selected.list.length > 0 ? (
              <Button
                preset="tertiary"
                onClick={() => {
                  setIsAlertDialogOpen(true);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                preset="tertiary"
                onClick={() => {
                  setColumns({
                    library: {
                      id: 'library',
                      list: [],
                    },
                    selected: {
                      id: 'selected',
                      list: [],
                    },
                  });
                  handleClose(true);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </ModalFooter>
      }
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="mt-4 flex flex-col overflow-hidden">
          <span className="text-neutral-200 typography-heading-xs">Library</span>
          {isDivVisible ? (
            <div className="grid grid-cols-3 gap-4 pr-6 pt-4 lg:flex">
              {/* <Button
                preset="tertiary"
                onClick={() => setIsDivVisible(!isDivVisible)}
                className="hidden lg:block"
              >
                <IconSearch className="w-5" />
              </Button> */}
              <Select
                placeholder="Domain"
                className="max-w-90"
                options={filters.data ? generateFilterOptions(filters.data.domains) : []}
                size="md"
                onValueChange={handleChangeSelectedDomains}
                multiple
                value={selectedDomains}
              />
              <Select
                placeholder="Grade"
                options={generateFilterOptions(allGrades)}
                className="max-w-90"
                size="md"
                onValueChange={handleChangeSelectedGrades}
                multiple
                value={selectedGrades}
              />
              <Select
                placeholder="Subdomain"
                options={generateFilterOptions(allSubdomains)}
                className="max-w-90"
                size="md"
                onValueChange={handleChangeSelectedSubdomains}
                multiple
                value={selectedSubdomains}
              />
            </div>
          ) : (
            <div className="relative flex w-full gap-4 pr-6 pt-4">
              <Search className="w-full" size="md" />
              <IconClose
                className="absolute right-9 top-8 text-neutral-500"
                onClick={() => setIsDivVisible(!isDivVisible)}
              />
            </div>
          )}
          <div className="flex w-full flex-wrap gap-2 py-3 pr-6">
            {selectedDomains.map((domain) => (
              <Tag
                preset="lightBlue"
                key={domain}
                onRemove={handleChangeSelectedDomains.bind(null, domain)}
              >
                Domain:{' '}
                <span className="pl-1 typography-loud-xs-bolder">
                  {filters.data && generateFilterName(filters?.data?.domains, domain)}
                </span>
              </Tag>
            ))}
            {selectedGrades.map((grade) => (
              <Tag
                key={grade}
                preset="lightBlue"
                onRemove={handleChangeSelectedGrades.bind(null, grade)}
              >
                Grade:{' '}
                <span className="pl-1 typography-loud-xs-bolder">
                  {filters.data && generateFilterName(filters?.data?.grades, grade)}
                </span>
              </Tag>
            ))}
            {selectedSubdomains.map((subdomain) => (
              <Tag
                key={subdomain}
                preset="lightBlue"
                onRemove={handleChangeSelectedSubdomains.bind(null, subdomain)}
              >
                Subdomain:{' '}
                <span className="pl-1 typography-loud-xs-bolder">
                  {filters.data && generateFilterName(filters?.data?.subdomains, subdomain)}
                </span>
              </Tag>
            ))}
          </div>
          <div className="mb-6 grow overflow-y-auto pr-6">
            <Column
              col={libraryColumnFiltered}
              key={libraryColumnFiltered.id}
              isFetching={isFetching}
              filtersApplied={filtersApplied}
              onLoadMore={(data?.total || 0) < pageSize ? undefined : onLoadMore}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col overflow-hidden pl-6">
          <div className="mb-4 text-neutral-200 typography-heading-xs">Selected goals</div>
          <div className="mb-6 grow overflow-y-auto overflow-x-hidden rounded-md border-2 border-dashed border-neutral-800 bg-neutral-950 p-2 px-4 py-3">
            <Column col={columns.selected} key={columns.selected.id} />
          </div>
        </div>
      </DragDropContext>

      <AlertDialog
        open={isAlertDialogOpen && columns.selected.list.length > 0}
        onActionClick={() => {
          setColumns({
            library: {
              id: 'library',
              list: [],
            },
            selected: {
              id: 'selected',
              list: [],
            },
          });
          handleClose(true);
        }}
        onCancelClick={() => setIsAlertDialogOpen(false)}
        actionButtonLabel="Leave Page"
        title="Wait a minute"
        description="Are you sure you want to leave this page? All selected goals will be lost."
      />
    </Modal>
  );
};

export default AddNewGoalModal;
