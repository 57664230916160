import { useCallback, useState } from 'react';
import { IconSearch } from 'src/icons';
import { debounce } from 'throttle-debounce';

import TextInput, { TextInputProps } from './TextInput';

type SearchProps = Omit<TextInputProps, 'ref'> & {
  debounceDelay?: number;
  active?: boolean;
  inputClassName?: string;
};

const Search = ({
  placeholder = 'Search',
  onChange,
  value,
  debounceDelay = 300,
  active,
  inputClassName,
  ...rest
}: SearchProps) => {
  const [localValue, setLocalValue] = useState(value || '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(onChange ? debounce(debounceDelay, onChange) : () => null, [
    onChange,
  ]);

  return (
    <TextInput
      inputClassName={inputClassName}
      PrefixIcon={IconSearch}
      placeholder={placeholder}
      value={localValue}
      active={active}
      onChange={(event) => {
        setLocalValue(event.target.value);
        debouncedOnChange(event);
      }}
      {...rest}
    />
  );
};

export default Search;
