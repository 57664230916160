import { Navigate, Route, Routes } from 'react-router-dom';
import { PrincipalOrParentRoutesProps } from 'src/App';
import { routes } from 'src/routes';

import BillingInvoiceSummaryDetails from '../billing/BillingInvoiceSummaryDetails';
import InvoicesSubmitAll from './InvoicesSubmitAll';
import InvoicesToSign from './InvoicesToSign';

type PrincipalProps = PrincipalOrParentRoutesProps;

const Principal = ({ variant }: PrincipalProps) => {
  return (
    <div className="bg-neutral-950">
      <Routes>
        <Route
          index
          element={
            <Navigate to={`${routes.principal.root}/${routes.principal.invoices.root}`} replace />
          }
        />
        <Route
          path={`${routes.principal.invoices.root}`}
          element={<InvoicesToSign hideFilters={variant === 'parent'} />}
        />
        <Route
          path={`${routes.principal.invoices.root}/${routes.principal.invoices.submitAll}`}
          element={<InvoicesSubmitAll />}
        />
        <Route
          path={`${routes.principal.invoices.invoice}`}
          element={
            <BillingInvoiceSummaryDetails
              backTo="Back to invoices"
              showSignatureRequestedAndDueDate
              showProvider
              showDownloadBtn={false}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Principal;
