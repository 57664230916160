import { CalendarApi } from '@fullcalendar/core';
import clsx from 'clsx';
import { isNil, reject } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Button from 'src/components/Button';
import ScheduleHeader from 'src/components/ScheduleHeader';
import TextInput from 'src/components/TextInput';
import useCalendarToPdf from 'src/hooks/calendarToPdf';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useCalendarRange from 'src/hooks/useCalendarRange';
import { useDebounce } from 'src/hooks/useDebounce';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconPlus, IconSearch } from 'src/icons';
import { IconChevronLeft } from 'src/icons';
import { routes } from 'src/routes';
import { useAppSelector } from 'src/store';

import { mapScheduleEventToFullCalendarEvent } from './mapScheduleEventToFullCalendarEvent';
import OutOfOfficeForm, { OutOfOfficeFormRefProps } from './out-of-office/OutOfOfficeForm';
import RecordSessionForm, {
  RecordSessionSidebarRefProps,
} from './record-session/RecordSessionForm';
import { useLazyScheduleEventsQuery } from './scheduleApi';
import ScheduleCalendar from './ScheduleCalendar';
import ScheduleFilters from './ScheduleFilters';
import ScheduleMyPlans from './ScheduleMyPlans';
import { selectScheduleFilters } from './scheduleSelectors';
import ScheduleSessionsForm from './ScheduleSessionsForm';

const Schedule = () => {
  const [isAddSessionOpen, setIsAddSessionOpen] = useState(false);
  const [isMyPlans, setIsMyPlans] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm: string = useDebounce(searchTerm, 500);
  const calendarRef = useRef<CalendarApi>(null);
  const [currentView, setCurrentView] = useState<string | undefined>('dayGridMonth');
  const filters = useAppSelector(selectScheduleFilters);
  const [getEvents, { data: events = [], isUninitialized }] = useLazyScheduleEventsQuery();
  const generatePDF = useCalendarToPdf();
  const location = useLocation();
  const getCalendarRange = useCalendarRange(calendarRef);
  const onExportRequest = () => {
    generatePDF.current(false);
  };
  const recordSessionFormRef = useRef<RecordSessionSidebarRefProps>(null);
  const outOfOfficeFormRef = useRef<OutOfOfficeFormRefProps>(null);
  const { format, zonedEndOfDay, zonedStartOfDay, FORMAT_ISO } = useTimeFormat();

  const { isSm } = useBreakpoint('sm');

  const handleCloseAddSession = () => {
    setIsAddSessionOpen(false);
  };

  const handleOpenAddSession = () => {
    setIsAddSessionOpen(true);
  };

  const getEventsLazily = useCallback(() => {
    const range = getCalendarRange();
    if (range) {
      const appliedDateFilters: typeof filters = {
        ...filters,
        start: filters.start && format(zonedStartOfDay(filters.start), FORMAT_ISO),
        finish: filters.finish && format(zonedEndOfDay(filters.finish), FORMAT_ISO),
      };
      getEvents({
        ...range,
        ...reject(isNil, { ...appliedDateFilters, queryString: debouncedSearchTerm }),
      });
    }
  }, [
    getCalendarRange,
    getEvents,
    filters,
    debouncedSearchTerm,
    format,
    zonedStartOfDay,
    FORMAT_ISO,
    zonedEndOfDay,
  ]);

  useEffect(() => {
    getEventsLazily();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, debouncedSearchTerm]);

  useEffect(() => {
    setIsMyPlans(location.pathname.includes(routes.schedule.myPlans));
  }, [location]);

  useEffect(() => {
    if (isUninitialized && calendarRef) {
      getEventsLazily();
    }
  }, [isUninitialized, calendarRef, getEventsLazily, filters]);

  const fullCalendarEvents = useMemo(
    () => events.map(mapScheduleEventToFullCalendarEvent),
    [events],
  );

  return (
    <div className="h-full ">
      <div className="mb-6 border-b-1 border-neutral-800 bg-neutral-white pt-4 md:mb-0 md:bg-transparent md:pt-8">
        {isMyPlans && !isSm && (
          <Link to={routes.schedule.root}>
            <div className="mb-7 flex items-center gap-x-2 px-4">
              <IconChevronLeft />
              <div className="text-neutral-200 typography-button-sm">Back to Schedule</div>
            </div>
          </Link>
        )}
        <div className="flex w-full items-center justify-between gap-x-4 px-4 pb-4 md:mb-6 md:px-10 md:pb-0">
          <div className="whitespace-nowrap typography-heading-xl">Schedule</div>
          <div className="flex gap-2">
            <Button
              Icon={IconPlus}
              preset="primary"
              size="md"
              onClick={handleOpenAddSession}
              className="hidden md:flex"
            >
              Create Schedule
            </Button>
            <TextInput
              size={'md'}
              PrefixIcon={IconSearch}
              placeholder="Search"
              className="hidden md:block"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
            <ScheduleFilters />
          </div>
        </div>
      </div>
      <RecordSessionForm ref={recordSessionFormRef} />
      <OutOfOfficeForm ref={outOfOfficeFormRef} />
      {!isSm && (
        <div className="px-4">
          <Button
            Icon={IconPlus}
            preset="primary"
            size="sm"
            onClick={handleOpenAddSession}
            className={clsx('w-full', {
              'mb-6': isMyPlans,
            })}
          >
            Confirm Session
          </Button>
        </div>
      )}
      <div>
        {(!isMyPlans || isSm) && (
          <ScheduleHeader
            calendarRef={calendarRef}
            onExport={onExportRequest}
            currentView={currentView}
            setCurrentView={setCurrentView}
            onChange={getEventsLazily}
            hasMyPlans
            hasOutOfOffice
            outOfOfficeFormRef={outOfOfficeFormRef}
          />
        )}
      </div>
      {isAddSessionOpen && <ScheduleSessionsForm handleClose={handleCloseAddSession} />}
      <Routes>
        <Route path={routes.schedule.myPlans} element={<ScheduleMyPlans />} />
        <Route
          index
          element={
            <ScheduleCalendar
              ref={calendarRef}
              events={fullCalendarEvents}
              export={generatePDF}
              currentView={currentView}
              outOfOfficeFormRef={outOfOfficeFormRef}
              recordSessionFormRef={recordSessionFormRef}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Schedule;
