import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type BillingState = {
  query?: string;
};

const initialState: BillingState = {};

const studentsPageSlice = createSlice({
  name: 'principalPage',
  initialState,
  reducers: {
    setPrincipalQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { reset, setPrincipalQuery } = studentsPageSlice.actions;

export default studentsPageSlice;
