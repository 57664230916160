import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Sidebar, SidebarContainer, SidebarContent } from 'src/components/Sidebar';
import { countAllFilters } from 'src/filters/countAllFilters';
import FilterByGoalDomains from 'src/filters/FilterByGoalDomains';
import FilterByGoalGrades from 'src/filters/FilterByGoalGrades';
import FilterByGoalSubdomains from 'src/filters/FilterByGoalSubdomains';
import FilterByGoalToggles from 'src/filters/FilterByGoalToggles';
import FiltersSidebarFooter from 'src/filters/FiltersSidebarFooter';
import FiltersSidebarHeader from 'src/filters/FiltersSidebarHeader';
import FiltersTriggerButton from 'src/filters/FiltersTriggerButton';
import { useAppDispatch, useAppSelector } from 'src/store';

import { selectStudentGoalsFilters, selectStudentGoalsFiltersCount } from './StudentSelectors';
import { setStudentFilters } from './StudentSlice';

type StudentGoalsFiltersProps = {
  studentYearFilterId?: string;
  forceOpen?: boolean;
  onFiltersClosed?: () => void;
};
const StudentsGoalsFilters = ({
  studentYearFilterId,
  forceOpen,
  onFiltersClosed,
}: StudentGoalsFiltersProps) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const initialFilters = useAppSelector(selectStudentGoalsFilters);
  const initialFiltersCount = useAppSelector(selectStudentGoalsFiltersCount);

  const [subdomainIds, setSubDomains] = useState<string[]>();
  const [domainIds, setDomains] = useState<string[]>();
  const [gradeIds, setGradeIds] = useState<string[]>();
  const [showMastered, setShowMastered] = useState<boolean>();
  const [showDeleted, setShowDeleted] = useState<boolean>();

  const [open, setOpen] = useState(forceOpen ?? false);

  const filters = {
    subdomainIds,
    domainIds,
    gradeIds,
    showMastered,
    showDeleted,
    schoolYearId: studentYearFilterId,
  };

  useEffect(() => {
    setGradeIds(initialFilters.gradeIds);
    setSubDomains(initialFilters.subdomainIds);
    setDomains(initialFilters.domainIds);
    setShowMastered(initialFilters.showMastered);
    setShowDeleted(initialFilters.showDeleted);
  }, [initialFilters, open]);

  const onApply = () => {
    dispatch(setStudentFilters(filters));
  };

  const onReset = () => {
    setGradeIds(undefined);
    setSubDomains(undefined);
    setDomains(undefined);
    setShowMastered(true);
    setShowDeleted(false);
  };

  const filtersCount = countAllFilters(filters);

  if (forceOpen && !open) {
    setOpen(true);
  }
  return (
    <SidebarContainer
      onOpenChange={(val) => {
        !val && onFiltersClosed && onFiltersClosed();
        setOpen(val);
      }}
      open={open}
    >
      <FiltersTriggerButton
        initialFiltersCount={initialFiltersCount}
        iconClassName="text-neutral-500"
      />
      <Sidebar open={open}>
        <FiltersSidebarHeader
          initialFiltersCount={initialFiltersCount}
          onReset={onReset}
          filtersCount={filtersCount}
        />
        <SidebarContent className="p-4 md:p-6">
          <FilterByGoalToggles
            valueMastered={showMastered}
            valueDeleted={showDeleted}
            onChangeMastered={(v) => setShowMastered(v)}
            onChangeDeleted={(v) => setShowDeleted(v)}
          />
          <FilterByGoalDomains value={domainIds} onChange={setDomains} studentId={id} />
          <FilterByGoalGrades value={gradeIds} onChange={setGradeIds} studentId={id} />
          <FilterByGoalSubdomains value={subdomainIds} onChange={setSubDomains} studentId={id} />
        </SidebarContent>
        <FiltersSidebarFooter onApply={onApply} filtersCount={filtersCount} />
      </Sidebar>
    </SidebarContainer>
  );
};

export default StudentsGoalsFilters;
