import type { SVGProps } from 'react';
const SvgBook2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#37AAA1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M11.75 1.5C9.679 1.5 8 2.395 8 3.5c0-1.105-1.679-2-3.75-2-2.071 0-3.75.895-3.75 2v11c0-1.105 1.679-2 3.75-2 2.071 0 3.75.895 3.75 2 0-1.105 1.679-2 3.75-2 2.071 0 3.75.895 3.75 2v-11c0-1.105-1.679-2-3.75-2Z"
    />
  </svg>
);
export default SvgBook2;
