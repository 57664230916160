import clsx from 'clsx';
import ScrollArea from 'src/components/ScrollArea';
import Tag from 'src/components/Tag';
import { useGradesQuery } from 'src/dictionaries/dictionariesApi';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { buildName } from 'src/logic/buildName';
import { twMerge } from 'tailwind-merge';

import { MONTHS } from './FilterByMonths';
import {
  useSchoolsForFiltersQuery,
  useStudentsForFiltersQuery,
  useSupervisorForFiltersQuery,
} from './filtersApi';

type AppliedFiltersListProps = {
  filtersCount?: number;
  filters: Record<string, any>;
  onChange: (v: any) => void;
  className?: string;
};

const AppliedFiltersList = ({
  filtersCount,
  filters,
  onChange,
  className,
}: AppliedFiltersListProps) => {
  const { data: students = [] } = useStudentsForFiltersQuery();
  const { data: schools = [] } = useSchoolsForFiltersQuery();
  const { data: grades = [] } = useGradesQuery();
  const { data: supervisors = [] } = useSupervisorForFiltersQuery();

  const buildLabel = (key: string, id: string | number) => {
    if (key === 'studentIds') {
      return buildName(students.find((student) => id === student.studentId) || {});
    }

    if (key === 'supervisorIds') {
      return buildName(supervisors.find((supervisor) => id === supervisor.id) || {});
    }

    if (key === 'schoolIds') {
      return schools.find((school) => id === school.id)?.name;
    }

    if (key === 'grades') {
      return grades.find((grade) => id === grade.key)?.value;
    }

    if (key === 'months') {
      return MONTHS.find((grade) => id === grade.value)?.label;
    }

    return '';
  };

  const onRemove = (filterGroup: string, value?: string) => {
    const updatedFilters = Array.isArray(filters[filterGroup])
      ? {
          ...filters,
          [filterGroup]: filters[filterGroup]?.filter((id: string) => id !== value),
        }
      : {
          ...filters,
          [filterGroup]: undefined,
        };
    onChange(updatedFilters);
  };

  const filterKeys = Object.keys(filters);
  const { isSm } = useBreakpoint('sm');
  if (!filtersCount) return null;

  return (
    <div className={twMerge('mb-6 block', className)}>
      <ScrollArea orientation="horizontal" indicatorHidden className="block">
        <div className="flex flex-col items-start gap-2 md:flex-row md:items-center md:gap-4">
          <div
            className={twMerge(
              clsx(
                'shrink-0 text-neutral-300',
                !isSm ? 'typography-body-xs' : 'typography-body-sm',
              ),
            )}
          >
            Selected filters
          </div>
          <div className="flex w-screen gap-2 sm:w-auto">
            {filterKeys.map((key) => {
              const singleFilter = filters[key];

              if (!singleFilter) return null;

              if (Array.isArray(singleFilter)) {
                return singleFilter?.map((id) => (
                  <Tag onRemove={() => onRemove(key, id)} key={id} preset="white" size="md">
                    {buildLabel(key, id)}
                  </Tag>
                ));
              }

              return (
                <Tag onRemove={() => onRemove(key)} preset="white" size="md" key={key}>
                  {singleFilter}
                </Tag>
              );
            })}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default AppliedFiltersList;
