import { isAfter, isSameDay, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { DateValue } from 'react-aria';

import DatePicker from './DatePicker';

type DateRangePickerProps = {
  startDate?: string;
  setStartDate?: (d?: string) => void;
  isStartDateDisabled?: (d: DateValue) => boolean;
  endDate?: string;
  setEndDate?: (d?: string) => void;
  isEndDateDisabled?: (d: DateValue) => boolean;
  startError?: string;
  endError?: string;
};

const DateRangePicker = ({
  startDate,
  setStartDate,
  isStartDateDisabled,
  endDate,
  setEndDate,
  isEndDateDisabled: isEndDateDisabledTmp,
  startError,
  endError,
}: DateRangePickerProps) => {
  const isEndDateDisabled = useCallback(
    (d: DateValue) => {
      if (!startDate) false;

      const dDate = new Date(d.year, d.month - 1, d.day);
      const start = parseISO(startDate!);

      const disabledBecauseOfExternalRule = isEndDateDisabledTmp && isEndDateDisabledTmp(d);

      if (disabledBecauseOfExternalRule) return true;

      if (isSameDay(dDate, start) || isAfter(dDate, start)) return false;

      return true;
    },
    [startDate, isEndDateDisabledTmp],
  );

  return (
    <div className="flex w-full">
      <DatePicker
        label="From"
        className="flex-1"
        value={startDate}
        onChange={setStartDate}
        error={startError}
        hasError={!!startError}
        isDateDisabled={isStartDateDisabled}
      />
      <div className="mt-8 px-3 text-neutral-300 typography-button-sm">-</div>
      <DatePicker
        label="To"
        className="flex-1"
        value={endDate}
        onChange={setEndDate}
        error={endError}
        hasError={!!endError}
        isDateDisabled={isEndDateDisabled}
      />
    </div>
  );
};

export default DateRangePicker;
