import type { SVGProps } from 'react';
const SvgEnvlope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 6c-.677 0-1 .461-1 .75v10.5c0 .289.323.75 1 .75h16c.677 0 1-.461 1-.75V6.75c0-.289-.323-.75-1-.75H4Zm-3 .75C1 5.114 2.477 4 4 4h16c1.523 0 3 1.114 3 2.75v10.5c0 1.636-1.477 2.75-3 2.75H4c-1.523 0-3-1.114-3-2.75V6.75Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.18 6.427a1 1 0 0 1 1.393-.246L12 12.779l9.427-6.598a1 1 0 1 1 1.146 1.638l-10 7a1 1 0 0 1-1.146 0l-10-7a1 1 0 0 1-.246-1.392Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEnvlope;
