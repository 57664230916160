import * as RadixSeparator from '@radix-ui/react-separator';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type SeparatorProps = RadixSeparator.SeparatorProps & {
  vertical?: boolean;
};

const Separator = ({ className, vertical, ...props }: SeparatorProps) => (
  <RadixSeparator.Root
    className={twMerge(
      clsx('h-0.25 bg-neutral-800', vertical ? 'h-4 w-0.25' : 'w-full', className),
    )}
    {...props}
  />
);

export default Separator;
