import { Fragment } from 'react';
import EmptyState from 'src/components/EmptyState';
import { getMonthName } from 'src/logic/getMonthName';

import { useInvoiceStatementsQuery } from './billingApi';
import BillingInvoicesSingleItem from './BillingInvoicesSingleItem';
import BillingInvoicesSingleItemLoading from './BillingInvoicesSingleItemLoading';

type BillingInvoicesForSchoolYearProps = {
  schoolYearId: string;
  query: string;
};

const BillingInvoicesForSchoolYear = ({
  schoolYearId,
  query,
}: BillingInvoicesForSchoolYearProps) => {
  const { data, isFetching } = useInvoiceStatementsQuery({ schoolYearId, query: query });

  if (isFetching || !data) {
    return (
      <div className="flex flex-col gap-4">
        {Array.from({ length: 3 }, (_, index) => (
          <BillingInvoicesSingleItemLoading
            key={index}
            className={index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : ''}
          />
        ))}
      </div>
    );
  }

  let currentMonth: number;

  return (
    <div>
      <div className="mb-2 text-neutral-300 typography-loud-lg">Total: {data.total}</div>
      {!data.items?.length ? (
        <EmptyState title="No invoices" description="Looks like you don’t have any invoices" />
      ) : (
        <div className="flex flex-col gap-4">
          {data.items.map((item) => {
            const isNewMonth = item.month !== currentMonth;
            if (isNewMonth) {
              currentMonth = item.month;
            }
            return (
              <Fragment key={item.id}>
                {isNewMonth && (
                  <div className="mt-2 text-neutral-100 typography-heading-sm md:mb-2 md:mt-8">
                    {getMonthName(item.month)}
                  </div>
                )}
                <BillingInvoicesSingleItem {...item} />
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BillingInvoicesForSchoolYear;
