import clsx from 'clsx';
import { useState } from 'react';
import PdfHowTo from 'src/assets/pdf/EnhancedServicesXPortalHowTo.pdf';
import BetaIndycator from 'src/components/BetaIndycator';
import Logo from 'src/components/Logo';
import NotificationsDropdown from 'src/components/NotificationsDropdown';
// Import the notifications component
import UserDropdown from 'src/components/UserDropdown';
import ContactSupport from 'src/contact-support/ContactSupport';
import { IconChevronLeft, IconPdf } from 'src/icons';

import MobileNavigation from './MobileNavigation';
import NavigationButton from './NavigationButton';
import NavigationLinks from './NavigationLinks';

const Navigation = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleOpenPDF = () => {
    window.open(PdfHowTo, '_blank');
  };

  return (
    <>
      <MobileNavigation className="sm:hidden" />
      <div
        className={clsx(
          'shrink-0 py-3 transition-all',
          'hidden sm:block',
          isCollapsed ? 'w-20 px-3' : 'w-50 px-3',
        )}
      />
      <div
        className={clsx(
          'border-r border-neutral-900 py-8 transition-all',
          'fixed bottom-0 left-0 top-0',
          'hidden flex-col sm:flex',
          isCollapsed ? 'w-20 px-3' : 'w-50 px-3',
        )}
      >
        <div
          className={clsx(
            'flex shrink-0 overflow-hidden transition-transform',
            isCollapsed ? '' : '-translate-x-14',
          )}
        >
          <Logo className="ml-2.5 mr-4 mt-3 h-8" isCollapsed />
          <Logo className="mb-10 ml-3.5 mt-3 h-6 transition-all" />
        </div>
        <BetaIndycator isCollapsed={isCollapsed} isMobile={false} />
        <div className="sm:mb-2">
          <NotificationsDropdown isCollapsed={isCollapsed} />
        </div>

        <div className="flex flex-col gap-2">
          <NavigationLinks isCollapsed={isCollapsed} />
        </div>

        <div className="mt-auto sm:mb-4">
          <UserDropdown isCollapsed={isCollapsed} />
        </div>
        <ContactSupport isCollapsed={isCollapsed} />
        <NavigationButton
          Icon={IconPdf}
          label={`"How-To" guide`}
          isCollapsed={isCollapsed}
          onClick={handleOpenPDF}
        />
        <NavigationButton
          Icon={IconChevronLeft}
          label="Hide menu"
          isCollapsed={isCollapsed}
          rotateIcon
          title="Toggle navigation"
          onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}
        />
      </div>
    </>
  );
};

export default Navigation;
