import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'src/auth/useAuth';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import TextField from 'src/components/TextField';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconArrowLeft, IconEnvlope } from 'src/icons';
import { routes } from 'src/routes';
import { twMerge } from 'tailwind-merge';
const SECONDS_TO_WAIT = 60;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [username, setUsername] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>();
  const secondsIntervalRef = useRef<NodeJS.Timeout>();

  const [secondsLeft, setSecondsLeft] = useState(SECONDS_TO_WAIT);
  const { forgotPassword } = useAuth();

  useEffect(() => {
    if (secondsLeft <= 0 && secondsIntervalRef.current) {
      clearInterval(secondsIntervalRef.current);
    }
  }, [secondsLeft]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await forgotPassword(username);
      setSent(true);
      setSecondsLeft(SECONDS_TO_WAIT);
      secondsIntervalRef.current = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);
    } catch (e: any) {
      const message = e?.message as string;
      if (!message) {
        setErrors({ _: 'Something went wrong. Try again later.' });
        return;
      }
      if (message.toLowerCase().startsWith('username')) {
        setErrors({ username: e?.message });
        return;
      }
      setErrors({ _: e?.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };
  const { isSm } = useBreakpoint('sm');

  return (
    <>
      {sent ? (
        <>
          <div
            className={twMerge(
              clsx(
                'mb-2 text-secondary-300 md:mb-4',
                !isSm ? 'typography-heading-lg' : 'typography-heading-2xl',
              ),
            )}
          >
            Check your inbox
          </div>
          <div
            className={twMerge(
              clsx(
                'mb-8 text-neutral-100 md:mb-12',
                !isSm ? 'typography-body-sm' : 'typography-body-lg',
              ),
            )}
          >
            We marched some assistance over to you at {username}.
          </div>
          <div
            className={twMerge(
              clsx(
                'mb-8 text-neutral-100 md:mb-12',
                !isSm ? 'typography-body-sm' : 'typography-body-lg',
              ),
            )}
          >
            {secondsLeft > 0 && (
              <span className="typography-body-lg">
                Wait {secondsLeft} seconds before resending.
              </span>
            )}
          </div>
          <Button
            size={isSm ? 'lg' : 'md'}
            className="mb-4"
            preset="tertiary"
            disabled={secondsLeft > 0}
            onClick={onSubmit}
            isLoading={isLoading}
          >
            📩 Didn’t receive the email? Click to resend
          </Button>
        </>
      ) : (
        <>
          <div
            className={twMerge(
              clsx(
                'mb-2 text-secondary-300 md:mb-4',
                !isSm ? 'typography-heading-lg' : 'typography-heading-2xl',
              ),
            )}
          >
            Forgot password?
          </div>
          <div
            className={twMerge(
              clsx(
                'mb-8 text-neutral-100 md:mb-12',
                !isSm ? 'typography-body-sm' : 'typography-body-lg',
              ),
            )}
          >
            That&apos;s absolutely human. Let&apos;s undo the oops.
          </div>
          <TextField
            label="Email"
            id="username"
            size={isSm ? 'lg' : 'md'}
            PrefixIcon={IconEnvlope}
            className="mb-6"
            placeholder="Email"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            onKeyDown={handleKeyPress}
            error={errors?.username}
          />
          <ErrorMessage className="mb-6">{errors?._}</ErrorMessage>
          <Button
            size={isSm ? 'lg' : 'sm'}
            className="mb-2 md:mb-4"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            Reset password
          </Button>
        </>
      )}
      <Button size="sm" preset="ghost" Icon={IconArrowLeft} to={routes.auth.login}>
        Back to log in
      </Button>
    </>
  );
};

export default ForgotPassword;
