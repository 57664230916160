import { IconElipseDocks } from 'src/icons';

const ElipsedIconDocument = () => {
  return (
    <div className="h-12 w-12">
      {' '}
      <IconElipseDocks className="top-2 mt-7 h-12 w-12 text-secondary-400"></IconElipseDocks>
    </div>
  );
};

const StudentNoGoalsByFilter = () => {
  return (
    <div>
      <div className="mx-auto  flex h-28 w-28 justify-center rounded-full bg-neutral-white text-center ">
        <ElipsedIconDocument></ElipsedIconDocument>
      </div>
      <div className="mx-auto mt-6 flex w-max justify-center  text-center text-neutral-300 typography-body-sm">
        No matching records found.
      </div>
    </div>
  );
};

export default StudentNoGoalsByFilter;
