import type { SVGProps } from 'react';
const SvgDraw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <mask
      id="draw_svg__a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="currentColor" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#draw_svg__a)">
      <path
        fill="currentColor"
        d="M9.333 14a.672.672 0 0 1-.492-.208.672.672 0 0 1-.208-.492c0-.189.07-.353.208-.492a.672.672 0 0 1 .492-.208c.511 0 .967-.092 1.367-.275.4-.183.6-.403.6-.658a.545.545 0 0 0-.209-.417 2.079 2.079 0 0 0-.491-.317l1.016-1.016a2.4 2.4 0 0 1 .775.7c.206.289.309.639.309 1.05 0 .744-.353 1.32-1.059 1.725A4.552 4.552 0 0 1 9.333 14ZM2.816 8.8a1.941 1.941 0 0 1-.616-.583c-.156-.234-.234-.528-.234-.884 0-.466.18-.864.542-1.191.361-.328.975-.686 1.842-1.075.666-.3 1.102-.509 1.308-.625.206-.117.308-.253.308-.409 0-.166-.105-.314-.316-.441-.211-.128-.54-.192-.984-.192-.266 0-.497.03-.691.092a1.16 1.16 0 0 0-.509.325.742.742 0 0 1-.466.225.722.722 0 0 1-.5-.142.67.67 0 0 1-.117-.967c.211-.266.52-.489.925-.666A3.348 3.348 0 0 1 4.666 2c.811 0 1.464.183 1.959.55.494.367.741.861.741 1.483 0 .445-.16.834-.483 1.167-.322.333-.978.717-1.967 1.15-.633.267-1.05.467-1.25.6-.2.133-.3.261-.3.383 0 .09.042.17.125.242.084.072.203.136.359.192L2.816 8.8Zm9.834-1.9L9.766 4.017l.584-.584c.266-.266.588-.397.966-.391.378.005.7.141.967.408l.933.933c.267.267.4.592.4.975 0 .384-.133.709-.4.975l-.566.567Zm-8.584 5.7h.884l4.7-4.7-.884-.883-4.7 4.7v.883Zm-1.4 1.4v-2.883l6.1-6.1L11.65 7.9 5.55 14H2.666Z"
      />
    </g>
  </svg>
);
export default SvgDraw;
