import Button from 'src/components/Button';
import { SidebarTrigger } from 'src/components/Sidebar';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconFilters } from 'src/icons';

type FiltersTriggerButtonProps = {
  initialFiltersCount?: number;
  iconClassName?: string;
};

const FiltersTriggerButton = ({
  initialFiltersCount,
  iconClassName,
}: FiltersTriggerButtonProps) => {
  const { isLg } = useBreakpoint('lg');
  return (
    <SidebarTrigger>
      <Button
        Icon={IconFilters}
        iconClassName={iconClassName}
        preset="tertiary"
        size="md"
        className="h-10 w-10 gap-0 sm:h-12 sm:w-12 sm:gap-2 lg:w-auto"
      >
        {isLg && (
          <div className="flex items-center gap-2">
            <span className="hidden md:block">Filter</span>
            {!!initialFiltersCount && (
              <div className="absolute left-7 top-3 h-2 w-2 rounded-full bg-primary-500 text-neutral-white transition-all typography-body-xs md:relative md:left-0 md:top-0 md:h-4 md:w-4">
                <span className="hidden md:block">{initialFiltersCount}</span>
              </div>
            )}
          </div>
        )}
      </Button>
    </SidebarTrigger>
  );
};

export default FiltersTriggerButton;
