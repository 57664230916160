import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export type DropdownContentProps = DropdownMenu.DropdownMenuContentProps;

const DropdownContent = ({
  children,
  className,
  sideOffset = 5,
  ...props
}: DropdownContentProps) => (
  <DropdownMenu.Portal>
    <DropdownMenu.Content
      className={twMerge(
        clsx(
          'z-portal overflow-hidden rounded-lg border border-neutral-800 bg-neutral-white shadow-elevation-3',
          className,
        ),
      )}
      sideOffset={sideOffset}
      {...props}
    >
      {children}
    </DropdownMenu.Content>
  </DropdownMenu.Portal>
);

export default DropdownContent;
