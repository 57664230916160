import type { SVGProps } from 'react';
const SvgMath = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#math_svg__a)"
    >
      <path d="m3 9 1.5 1.5M5 7l1.5 1.5M7 5l1.5 1.5M9 3l1.5 1.5M11.5.5l-11 11 4 4 11-11-4-4Z" />
    </g>
    <defs>
      <clipPath id="math_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMath;
