import clsx from 'clsx';
import Button, { ButtonProps } from 'src/components/Button';
import { IconPlus } from 'src/icons';

export type RecordSessionProps = {
  onClick: ButtonProps['onClick'];
  className?: string;
};
export const RecordSessionButton = (props: RecordSessionProps) => {
  return (
    <Button
      onClick={props.onClick}
      Icon={IconPlus}
      size={'xxs'}
      preset="secondary"
      className={clsx(
        'caption-sm-desktop h-6 p-2 opacity-0 hover:bg-secondary-400 hover:opacity-100',
        props.className,
      )}
    >
      <div className="hidden md:block">Add session</div>
    </Button>
  );
};
