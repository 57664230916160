import { useNotifications } from 'src/hooks/useNotifications';
import { IconBell } from 'src/icons';
import NavigationButton from 'src/layout/NavigationButton';

import Dropdown, { DropdownProps } from './Dropdown';
import DropdownContent from './DropdownContent';
import DropdownTrigger from './DropdownTrigger';
import Notifications from './Notifications';

type NotificationDropdownProps = DropdownProps & {
  isCollapsed: boolean;
};

const NotificationsDropdown = (props: NotificationDropdownProps) => {
  const { unreadCount, isOpen, setIsOpen } = useNotifications();

  return (
    <div className="flex">
      <Dropdown open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <DropdownTrigger>
          <NavigationButton
            isActive={isOpen}
            Icon={IconBell}
            label={`Notifications (${unreadCount})`}
            isCollapsed={props.isCollapsed}
            count={unreadCount || undefined}
            title={`Notifications (${unreadCount})`}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </DropdownTrigger>
        <DropdownContent className="ml-4" align="start" side="left">
          <Notifications setIsOpen={setIsOpen} />
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

export default NotificationsDropdown;
