import clsx from 'clsx';

export type BetaIndycatorProps = {
  isCollapsed: boolean;
  isMobile: boolean;
};

const BetaIndycator = (props: BetaIndycatorProps) => {
  const pcCollipsed = (
    <div
      className={clsx(
        'mx-auto -mt-7.5 mb-10 w-11 rounded-lg border border-secondary-300 bg-secondary-300 px-1.5 py-0.5 text-center text-neutral-white typography-loud-xs',
        props.isCollapsed ? '' : 'hidden',
      )}
    >
      BETA
    </div>
  );

  const pcNotCollipsed = (
    <div
      className={clsx(
        '-mt-15.5 mb-10 ml-auto mr-2 w-11 rounded-lg border border-secondary-300 bg-secondary-300 px-1.5 py-0.5 text-center text-neutral-white typography-loud-xs',
        props.isCollapsed ? 'hidden' : '',
      )}
    >
      BETA
    </div>
  );

  const mobile = (
    <div
      className={clsx(
        '-mt-6  ml-28  w-11 rounded-lg border border-secondary-300 bg-secondary-300 px-1.5 py-0.5 text-center text-neutral-white typography-loud-xs',
      )}
    >
      BETA
    </div>
  );

  const showBetaIndicator = () => {
    if (props.isMobile) return mobile;
    else return props.isCollapsed ? pcCollipsed : pcNotCollipsed;
  };
  return <> {showBetaIndicator()}</>;
};

export default BetaIndycator;
