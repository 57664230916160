import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleToast } from 'src/components/Toast';

type ToastsState = {
  toasts: SingleToast[];
};

const initialState: ToastsState = {
  toasts: [],
};

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<Omit<SingleToast, 'id'>>) {
      const timestamp = new Date().getTime();
      const newToast = { ...action.payload, id: timestamp, isOpen: true };
      state.toasts = [newToast, ...state.toasts];
    },
    removeToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload);
    },
    dismissToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.map((t) =>
        t.id === action.payload ? { ...t, isOpen: false } : t,
      );
    },
  },
});

export const { addToast, removeToast, dismissToast } = toastsSlice.actions;

export default toastsSlice;
