import { IconPlus } from 'src/icons';

import Button from '../../components/Button';
import Calendar from '../../components/date-picker/Calendar';
import Dropdown from '../../components/Dropdown';
import DropdownContent from '../../components/DropdownContent';
import DropdownTrigger from '../../components/DropdownTrigger';

type ExcludeDatesProps = {
  onChange?: (v: string) => void;
};

const ExcludeDates = ({ onChange }: ExcludeDatesProps) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue && `${newValue}`);
  };

  return (
    <div>
      <Dropdown>
        <DropdownTrigger>
          <Button Icon={IconPlus} preset="ghost" size="xs">
            Add a date
          </Button>
        </DropdownTrigger>
        <DropdownContent
          className="z-portal"
          side="bottom"
          align="end"
          sideOffset={12}
          alignOffset={-13}
        >
          <Calendar onChange={handleChange} />
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

export default ExcludeDates;
