import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { ReactNode } from 'react';

type DropdownItemProps = {
  children: ReactNode;
  className?: string;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
  handleKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
};

export const DropdownItem = (props: DropdownItemProps) => (
  <DropdownMenuItem
    onClick={props?.handleClick}
    onKeyDown={props?.handleKeyDown}
    className={clsx(
      `cursor-pointer border-b border-neutral-800 bg-neutral-white px-4 py-2 last:border-b-0 hover:bg-neutral-950 focus:outline-none`,
      props.className,
    )}
  >
    {props.children}
  </DropdownMenuItem>
);
