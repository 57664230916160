import { EventInput } from '@fullcalendar/core';

import { ScheduleEvent } from './schedule.types';

export const mapScheduleEventToFullCalendarEvent = (event: ScheduleEvent): EventInput => ({
  start: event.start,
  end: event.finish,
  title: event.name,
  extendedProps: event,
});
