import type { SVGProps } from 'react';
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.05 4.1A6.916 6.916 0 0 1 12 2c1.857 0 3.637.756 4.95 2.1A7.262 7.262 0 0 1 19 9.173c0 3.45.723 5.128 1.3 5.917.288.393.555.587.717.68a1.024 1.024 0 0 0 .183.083c.494.104.838.57.797 1.089a1.01 1.01 0 0 1-.997.94H3a1.01 1.01 0 0 1-.997-.94 1.021 1.021 0 0 1 .81-1.093c.027-.01.087-.032.17-.08.162-.092.429-.286.717-.68C4.277 14.3 5 12.624 5 9.173c0-1.902.738-3.726 2.05-5.071Zm11.33 11.732c-.776-1.308-1.38-3.38-1.38-6.66a5.187 5.187 0 0 0-1.464-3.622A4.94 4.94 0 0 0 12 4.05a4.94 4.94 0 0 0-3.536 1.5A5.187 5.187 0 0 0 7 9.172c0 3.28-.604 5.352-1.38 6.66h12.76ZM9.768 20.068a.987.987 0 0 1 1.367.372c.088.156.214.284.366.374a.981.981 0 0 0 .998 0c.152-.09.278-.218.366-.374a.987.987 0 0 1 1.367-.372c.478.284.64.911.363 1.4a3.037 3.037 0 0 1-1.098 1.122 2.945 2.945 0 0 1-2.994 0 3.037 3.037 0 0 1-1.098-1.121 1.039 1.039 0 0 1 .363-1.4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBell;
