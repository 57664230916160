import { forwardRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { IconUndo } from 'src/icons';
import { twMerge } from 'tailwind-merge';

import Button from './Button';

type SignatureProps = {
  className?: string;
  onHasSignatureChange?: (hasSignature: boolean) => void;
};

const Signature = forwardRef<any, SignatureProps>(
  ({ className, onHasSignatureChange }, canvasRef) => {
    const [showSignHere, setShowSignHere] = useState(true);

    const onChangeShowSignHere = (show: boolean) => {
      setShowSignHere(show);
      onHasSignatureChange?.(!show);
    };

    const reset = () => {
      const signature = (canvasRef as any)?.current;
      signature?.clear();
      onChangeShowSignHere(true);
    };

    return (
      <div
        className={twMerge('relative h-56 border-y border-y-neutral-800 bg-neutral-950', className)}
      >
        {showSignHere && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-neutral-600 typography-heading-md">
            Sign here
          </div>
        )}
        {!showSignHere && (
          <Button
            preset="ghost"
            className="absolute bottom-3 right-3"
            size="xs"
            Icon={IconUndo}
            onClick={reset}
          >
            Redo
          </Button>
        )}
        <SignatureCanvas
          ref={canvasRef}
          canvasProps={{
            className: 'h-full w-full',
          }}
          onBegin={() => onChangeShowSignHere(false)}
          clearOnResize={false}
        />
      </div>
    );
  },
);

Signature.displayName = 'Signature';

export default Signature;
