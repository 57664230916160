import type { SVGProps } from 'react';
const SvgLogOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#log_out_svg__a)"
    >
      <path d="M6.875 10h12.5M14.375 5l5 5-5 5M10.625 19.375H2.5A1.875 1.875 0 0 1 .625 17.5v-15A1.875 1.875 0 0 1 2.5.625h8.125" />
    </g>
    <defs>
      <clipPath id="log_out_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogOut;
