import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from './Tooltip';

export type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

type Option = {
  label: string;
  value: DayOfWeek;
  disabled?: boolean;
  message?: string;
};

type DaysPickerProps = {
  options?: Option[];
  value?: DayOfWeek[];
  onChange?: (v: DayOfWeek[]) => void;
  availableWeekDaysInRange: DayOfWeek[];
};

type DaySelectorProps = {
  option: Option;
  onDayClick: (day: DayOfWeek) => void;
  isSelected?: boolean;
};

const DaySelector = ({ option, onDayClick, isSelected }: DaySelectorProps) => {
  const handleClick = () => {
    !option.disabled && onDayClick(option.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || (event.key === ' ' && !option.disabled)) {
      handleClick();
    }
  };

  return (
    <Tooltip content={option.message}>
      <div
        className={twMerge(
          'mr-4 h-10 w-10 rounded-full border border-neutral-800 pt-2 text-center text-neutral-100 typography-loud-sm',
          isSelected ? 'border-secondary-800 bg-secondary-800 text-secondary-300' : '',
          option.disabled && 'cursor-default text-neutral-700',
        )}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        aria-pressed={isSelected}
      >
        <span>{option.label}</span>
      </div>
    </Tooltip>
  );
};

const ALL_WEEK_DAYS: DaysPickerProps['options'] = [
  { label: 'Su', value: 'Sunday' },
  { label: 'Mo', value: 'Monday' },
  { label: 'Tu', value: 'Tuesday' },
  { label: 'We', value: 'Wednesday' },
  { label: 'Th', value: 'Thursday' },
  { label: 'Fr', value: 'Friday' },
  { label: 'Sa', value: 'Saturday' },
];

const DaysPicker = ({
  options = ALL_WEEK_DAYS,
  value = [],
  onChange,
  availableWeekDaysInRange,
}: DaysPickerProps) => {
  const handleDayClick = (day: DayOfWeek) => {
    if (!value?.includes(day)) {
      onChange && onChange([...value, day]);
    } else {
      onChange && onChange(value.filter((d) => d !== day));
    }
  };

  return (
    <div className="flex flex-wrap gap-y-2">
      {options.map((opt) => {
        return (
          <DaySelector
            key={opt.value + 'DaySelector'}
            option={{
              ...opt,
              disabled: opt.disabled || !availableWeekDaysInRange.includes(opt.value),
            }}
            onDayClick={handleDayClick}
            isSelected={value?.includes(opt.value)}
          />
        );
      })}
    </div>
  );
};

export default DaysPicker;
