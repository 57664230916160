import { useState } from 'react';
import Separator from 'src/components/Separator';
import Switch from 'src/components/Switch';

type FilterByGoalTogglesProps = {
  valueMastered?: boolean;
  valueDeleted?: boolean;
  onChangeMastered: (v: boolean) => void;
  onChangeDeleted: (v: boolean) => void;
  separator?: boolean;
};

const FilterByGoalToggles = ({
  valueMastered,
  valueDeleted,
  onChangeMastered,
  onChangeDeleted,
}: FilterByGoalTogglesProps) => {
  const [isMasteredFilter, setMasteredFilter] = useState(false);
  return (
    <div className="">
      <Switch
        onCheckedChange={(v) => {
          setMasteredFilter(v);
          console.log(isMasteredFilter);
          onChangeMastered(v);
        }}
        checked={valueMastered}
        className="mb-5 "
        labelClassName="typography-loud-sm"
        label="Show mastered goals"
      ></Switch>
      <Switch
        onCheckedChange={(v) => {
          setMasteredFilter(v);
          console.log(isMasteredFilter);
          onChangeDeleted(v);
        }}
        checked={valueDeleted}
        labelClassName="typography-loud-sm"
        label="Show removed goals"
      ></Switch>
      <Separator className="mt-5" />
    </div>
  );
};

export default FilterByGoalToggles;
