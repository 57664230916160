import { CalendarApi } from '@fullcalendar/core';
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import CalendarOptionsHeader from 'src/components/CalendarOptionsHeader';
import HorizontalTabsButtons from 'src/components/HorizontalTabsButtons';
import { Sidebar, SidebarContainer, SidebarContent } from 'src/components/Sidebar';
import FiltersSidebarFooter from 'src/filters/FiltersSidebarFooter';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconTripleDots } from 'src/icons';
import { IconExport } from 'src/icons';
import { routes } from 'src/routes';
import { VIEW_OPTIONS } from 'src/utils/viewOptions.const';

import Switch from './Switch';

export type ContextMenuProps = {
  calendarRef: RefObject<CalendarApi>;
  currentView?: string;
  setCurrentView?: Dispatch<SetStateAction<string | undefined>>;
  onExport?: () => void;
  hasMyPlans?: boolean;
  showOnlyMyClassesRef?: MutableRefObject<boolean>;
  setCurrentTitle?: Dispatch<SetStateAction<string | undefined>>;
  onChange: () => void;
};

const ContextMenu = ({
  calendarRef,
  currentView,
  setCurrentView,
  onExport,
  hasMyPlans,
  showOnlyMyClassesRef,
  setCurrentTitle,
  onChange,
}: ContextMenuProps) => {
  const navigate = useNavigate();
  const { isSm } = useBreakpoint('sm');
  const [showOnlyMyClasses, setShowOnlyMyClasses] = useState(
    showOnlyMyClassesRef?.current || false,
  );
  const [localView, setLocalView] = useState(currentView);

  const handleSelectView = (view: string) => {
    setLocalView(view);
    if (hasMyPlans) {
      navigate(routes.schedule.root);
    }
  };

  const onApply = () => {
    if (showOnlyMyClassesRef?.current !== undefined) {
      showOnlyMyClassesRef.current = showOnlyMyClasses;
    }
    const calendarApi = calendarRef?.current;
    if (calendarApi && localView !== undefined) {
      calendarApi?.changeView(localView);
      calendarApi?.today();
      setCurrentTitle?.(calendarApi?.view?.title);
      setCurrentView?.(localView);
      onChange();
    }
  };

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  return (
    <SidebarContainer onOpenChange={setOpen} open={open}>
      <Button
        preset="tertiary"
        className="h-10 w-10 rounded-lg md:h-12 md:w-12"
        Icon={IconTripleDots}
        onClick={handleButtonClick}
      />
      <Sidebar open={open}>
        <CalendarOptionsHeader />

        <SidebarContent className="p-4 pt-6 md:p-6">
          <>
            <div className="mb-4 text-neutral-200 typography-heading-xs">Classes</div>
            <Switch
              label="Show my sessions only"
              withFrame
              checked={showOnlyMyClasses}
              onCheckedChange={() => setShowOnlyMyClasses(!showOnlyMyClasses)}
            />
            <div className="mb-4 mt-8 text-neutral-200 typography-heading-xs">View options</div>
            <HorizontalTabsButtons
              tabs={VIEW_OPTIONS}
              activeTab={localView}
              onSelect={handleSelectView}
            />
            {hasMyPlans && (
              <>
                <div className="mb-4 mt-8 text-neutral-200 typography-heading-xs">My plans</div>
                <Link to={routes.schedule.myPlans}>
                  <Button
                    preset="secondary"
                    onClick={handleButtonClick}
                    size="md"
                    className="w-full"
                  >
                    View All Schedules
                  </Button>
                </Link>
              </>
            )}
            {onExport &&
              VIEW_OPTIONS.some(
                ({ value, exportAvailable }) => value === currentView && exportAvailable,
              ) && (
                <>
                  <div className="mb-4 mt-8 text-neutral-200 typography-heading-xs">
                    PDF options
                  </div>
                  <Button
                    preset="tertiary"
                    onClick={onExport}
                    Icon={IconExport}
                    size="md"
                    className="w-full"
                  >
                    Export to PDF
                  </Button>
                </>
              )}
          </>
        </SidebarContent>
        <FiltersSidebarFooter onApply={onApply} />
      </Sidebar>
    </SidebarContainer>
  );
};

export default ContextMenu;
