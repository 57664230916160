import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { IconClose } from 'src/icons';

import Button from './Button';

type MobileFullScreenDialogProps = Dialog.DialogProps & {
  children?: ReactNode;
  dialogTitle?: string;
};

const MobileFullScreenDialog = ({
  open,
  onOpenChange,
  dialogTitle,
  children,
}: MobileFullScreenDialogProps) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0" />
      <Dialog.Content className="fixed inset-0 z-modal bg-neutral-white">
        <div className={clsx(' flex px-4 pt-4', dialogTitle ? 'justify-between' : 'justify-end')}>
          {dialogTitle && (
            <Dialog.Title className="text-neutral-100 typography-heading-xl  md:text-neutral-200 md:typography-heading-lg">
              {dialogTitle}
            </Dialog.Title>
          )}
          <Dialog.Close asChild>
            <Button
              Icon={IconClose}
              preset="ghost"
              size="xs"
              className=""
              iconClassName="w-6 h-6 text-neutral-400 hover:text-neutral-600"
            />
          </Dialog.Close>
        </div>
        <div>{children}</div>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default MobileFullScreenDialog;
