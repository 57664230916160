import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';

import { Grade, Location, SessionCancellationReason } from './dictionaries.types';

export const dictionariesApi = createApi({
  reducerPath: 'dictionariesApi',
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    grades: build.query<Grade[], void>({
      query: () => '/Dictionary/grade',
    }),
    location: build.query<Location[], void>({
      query: () => '/Dictionary/session-location',
    }),
    sessionCancellationReason: build.query<SessionCancellationReason[], void>({
      query: () => '/Dictionary/session-cancellation-reason',
    }),
  }),
});

export const { useGradesQuery, useLocationQuery, useSessionCancellationReasonQuery } =
  dictionariesApi;
