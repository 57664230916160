import { useMemo, useState } from 'react';
import { Notification, NotificationGroup } from 'src/notification/notification.types';
import {
  invalidateNotificationsListTags,
  useGetNotificationsQuery,
} from 'src/notification/notificationApi';
import { useAppDispatch } from 'src/store';

export function useNotifications() {
  const [isOpen, setIsOpen] = useState(false);
  const { data: actionable } = useGetNotificationsQuery({ group: NotificationGroup.ACTIONABLE });
  const { data: informational } = useGetNotificationsQuery({
    group: NotificationGroup.INFORMATIONAL,
  });

  const unreadCount = useMemo(
    () => (actionable?.unreadCount || 0) + (informational?.unreadCount || 0),
    [actionable, informational],
  );
  const dispatch = useAppDispatch();
  const invalidateNotifications = (group: NotificationGroup) =>
    dispatch(invalidateNotificationsListTags([{ type: 'Notifications', id: group }]));
  const notifications: Record<NotificationGroup, Notification[]> = {
    [NotificationGroup.ACTIONABLE]: actionable?.items || [],
    [NotificationGroup.INFORMATIONAL]: informational?.items || [],
  };
  return {
    unreadCount,
    notifications,
    invalidate: invalidateNotifications,
    isOpen,
    setIsOpen,
  };
}
