import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';
import { buildName } from 'src/logic/buildName';

import { useSupervisorForFiltersQuery } from './filtersApi';
import { selectedCountLabel } from './selectedCountLabel';

type FilterBySupervisorProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
};

const FilterBySupervisor = ({ value, onChange, separator = true }: FilterBySupervisorProps) => {
  const { data: supervisors } = useSupervisorForFiltersQuery();

  return (
    <Collapsible
      title={`Supervisor${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="grid grid-cols-2 gap-x-6 gap-y-2">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={supervisors?.map((s) => ({
            label: buildName(s),
            value: s.id,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterBySupervisor;
