import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import AlertDialog from 'src/components/AlertDialog';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import DropdownContent from 'src/components/DropdownContent';
import { DropdownItem } from 'src/components/DropdownItem';
import DropdownTrigger from 'src/components/DropdownTrigger';
import useTimeFormat from 'src/hooks/useTimeFormat';
import ChevronRight from 'src/icons/ChevronRight';
import SvgTripleDots from 'src/icons/TripleDots';
import { pasteAsPlainText, setCaretOnContentEditableEnd } from 'src/utils/contentEditable.utils';
import { twMerge } from 'tailwind-merge';

import { StudentNote } from './singleStudent.types';

export type SingleStudentNoteProps = {
  note: StudentNote;
  updateNote: (content: string) => void;
  deleteNote: () => void;
  isLoading: boolean;
  className?: string;
};

export const SingleStudentNote = ({
  note,
  className,
  isLoading,
  updateNote,
  deleteNote,
}: SingleStudentNoteProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [content, setContent] = useState('');
  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] = useState(false);
  const contendtEditable = useRef<HTMLDivElement>(null);
  const { formatDateTime } = useTimeFormat();
  useEffect(() => {
    if (isEditMode && contendtEditable.current) {
      setCaretOnContentEditableEnd(contendtEditable.current);
    }
  }, [isEditMode]);

  useEffect(() => {
    if (!isLoading) {
      setIsEditMode(false);
    }
  }, [isLoading]);

  return (
    <div
      className={twMerge(
        clsx(
          'relative rounded-lg border border-neutral-800 bg-neutral-white p-4',
          { 'border-secondary-500': isEditMode },
          className,
        ),
      )}
    >
      <div className="pb-2 text-neutral-300 typography-caption-md">
        {formatDateTime(note.createdAt)}
      </div>
      <div
        className={
          'w-full whitespace-break-spaces break-words text-neutral-100 outline-0 typography-loud-sm'
        }
        contentEditable={isEditMode}
        suppressContentEditableWarning={true}
        ref={contendtEditable}
        onPaste={pasteAsPlainText}
        onInput={(e) => setContent(e.currentTarget.innerText)}
      >
        {note.content}
      </div>
      {isEditMode && (
        <div className="mt-4 flex">
          <Button
            size="xs"
            preset="tertiary"
            className="ml-auto mr-4 self-end"
            onClick={() => {
              setIsEditMode(false);
              if (contendtEditable.current) {
                contendtEditable.current.textContent = note.content;
              }
            }}
          >
            Cancel
          </Button>

          <Button
            size="xs"
            preset="secondary"
            onClick={() => updateNote(content)}
            isLoading={isLoading}
          >
            <div className="flex">
              Save
              <ChevronRight fontSize="16" className="ml-2" />
            </div>
          </Button>
        </div>
      )}
      {isEditMode ? null : (
        <Dropdown>
          <DropdownTrigger>
            <Button preset="ghost" className="absolute right-4 top-0 rounded pr-0">
              <SvgTripleDots />
            </Button>
          </DropdownTrigger>
          <DropdownContent className="w-36" align="end" side="bottom">
            <DropdownItem
              handleClick={() => {
                setIsEditMode(true);
              }}
            >
              Edit
            </DropdownItem>
            <DropdownItem handleClick={() => setIsDeleteConfirmationOpened(true)}>
              Delete
            </DropdownItem>
          </DropdownContent>
        </Dropdown>
      )}
      <AlertDialog
        open={isDeleteConfirmationOpened}
        onActionClick={() => {
          setIsDeleteConfirmationOpened(false);
          deleteNote();
        }}
        onCancelClick={() => setIsDeleteConfirmationOpened(false)}
        title="Are you sure you want to delete this note?"
      />
    </div>
  );
};
