import { assocPath, update } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import Modal from 'src/components/Modal';
import SelectField from 'src/components/SelectField';
import Signature from 'src/components/Signature';
import TextField from 'src/components/TextField';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useUser from 'src/hooks/useUser';
import { IconPlus } from 'src/icons';
import { buildName } from 'src/logic/buildName';
import { routes } from 'src/routes';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { apiErrorsToDict, isFetchBaseQueryError } from 'src/types/api-error-response';
import { validateEmail } from 'src/utils/validateEmail';

import {
  FinalizeInvoicePayload,
  InvoicesToSignDetails,
  Person,
  Principal,
  RequestedPrincipal,
} from './billing.types';
import {
  invalidateBillingTags,
  useFinalizeInvoiceMutation,
  useInvoicesToSignDetailsQuery,
} from './billingApi';
import { selectInvoicesIdsForSignature } from './billingSelectors';
import { setInvoicesIdsForSignature } from './billingSlice';

const BillingSignatureModal = () => {
  const dispatch = useAppDispatch();
  const canvasRef = useRef<any>();
  const navigate = useNavigate();
  const [requestedPrincipalError, setRequestedPrincipalError] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [submit, { isLoading, error: apiError, isSuccess, reset }] = useFinalizeInvoiceMutation();
  const { firstName, lastName } = useUser();
  const [errors, setErrors] = useState<Record<string, string>>();
  const { isSm } = useBreakpoint('sm');

  const invoicesIds = useAppSelector(selectInvoicesIdsForSignature);

  const { data: details, isLoading: isLoadingDetails } = useInvoicesToSignDetailsQuery(
    {
      ids: invoicesIds!,
    },
    { skip: !invoicesIds?.length, refetchOnMountOrArgChange: true },
  );

  const [invoices, setInvoices] = useState<FinalizeInvoicePayload['invoices']>();
  const [requestedPrincipals, setRequestedPrincipals] = useState<Person[]>([]);
  const [showRequestPrincipal, setShowRequestPrincipal] = useState<boolean>(false);

  useEffect(() => {
    if (!invoicesIds?.length) {
      setInvoices(undefined);
    }
  }, [invoicesIds?.length]);

  useEffect(() => {
    if (details) {
      setInvoices(
        details.invoices.map((d) => ({
          id: d.id,
          principalId: d?.principalId,
          parentId: d.parent?.id,
        })),
      );
    }
  }, [details]);

  const filterPrincipalsBySchoolId = (data: InvoicesToSignDetails): Principal[] => {
    const schoolIdsInInvoices = data.invoices.map((invoice) => invoice.schoolId);
    const filteredPrincipals = data.principals.filter((principal) =>
      schoolIdsInInvoices.includes(principal.schoolId),
    );
    return filteredPrincipals;
  };

  const getPrincipalsBySchoolId = (data: InvoicesToSignDetails, schoolId: string): Person[] => {
    const oneSchoolPrincipals = filterPrincipalsBySchoolId(data).filter((p) =>
      p.schoolId.includes(schoolId),
    );

    return oneSchoolPrincipals.flatMap((school) => school.principals);
  };

  const checkIfRequestedPrincipalIsFromTheSameSchool = (
    requestedPrincipals: Person[],
    schoolIdToCompare: string,
  ) => {
    return requestedPrincipals.filter((requestedP) => requestedP.schoolId === schoolIdToCompare);
  };

  const onRequestPrincipal = (index: number) => {
    setShowRequestPrincipal(true);

    setInvoices((prev) => {
      if (!prev) return prev;

      const updatedInvoice = prev[index];
      updatedInvoice.principalId = undefined;
      updatedInvoice.requestedPrincipal = {};

      return update(index, updatedInvoice, prev);
    });
  };

  const onCancelRequestPrincipal = (index: number) => {
    setShowRequestPrincipal(false);

    setInvoices((prev) => {
      if (!prev) return prev;
      setRequestedPrincipals([]);

      return assocPath([index, 'requestedPrincipal'], undefined, prev);
    });
  };

  const isRequestedPrincipalValid = (principal?: RequestedPrincipal) => {
    return (
      principal && !!principal.firstName && !!principal.lastName && validateEmail(principal.email)
    );
  };

  const onAddPrincipal = (index: number) => {
    setShowRequestPrincipal(false);
    setInvoices((prev) => {
      if (!prev) return prev;

      const updatedInvoice = prev[index];
      updatedInvoice.principalId = undefined;

      const newPrincipal: Person = {
        id: 'undefined',
        invalidEmail: undefined,
        ...updatedInvoice.requestedPrincipal,
      };
      setRequestedPrincipals([newPrincipal]);
      return assocPath([index, 'requestedPrincipal'], newPrincipal, prev);
    });
  };

  const onChangePrincipalProp = (index: number, key: string, value: string, schoolId?: string) => {
    setInvoices((prev) => assocPath([index, 'requestedPrincipal', key], value, prev));
    if (schoolId) {
      setInvoices((prev) => assocPath([index, 'requestedPrincipal', 'schoolId'], schoolId, prev));
    }
  };

  const onSelectPrincipal = (index: number, principalId: string) => {
    setInvoices((prev) => assocPath([index, 'principalId'], principalId, prev));
  };

  const onClear = useCallback(() => {
    dispatch(setInvoicesIdsForSignature());
    setRequestedPrincipals([]);
    setErrors(undefined);
    reset();
  }, [dispatch, reset]);

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClear();
    }
  };

  const onSave = () => {
    setErrors(undefined);

    if (!invoicesIds?.length || !canvasRef.current?.toDataURL() || canvasRef.current?.isEmpty()) {
      setErrors({ _: 'Missing signature.' });
      return;
    }

    const hasMissingPrincipal = details?.invoices.some((billing, index) => {
      if (billing.principalSignatureRequired) {
        const invoice = invoices?.[index];

        if (!invoice) {
          return true;
        }

        const { requestedPrincipal } = invoice;
        const { firstName, lastName, email } = requestedPrincipal || requestedPrincipals[0] || {};

        if (!invoice.principalId && (!firstName || !lastName || !validateEmail(email))) {
          setRequestedPrincipalError((prev) => ({
            ...prev,
            firstName: !firstName,
            lastName: !lastName,
            email: !validateEmail(email),
          }));
          return true;
        }
      }
      return false;
    });

    if (hasMissingPrincipal) {
      setErrors({ _: 'Missing principal.' });
      return;
    }
    if (invoices?.length) {
      submit({
        signature: {
          content: canvasRef.current?.toDataURL().split(',')[1],
          contentType: 'image/png',
        },
        invoices: invoices.map((invoice) => {
          if (invoice.principalId !== 'undefined' && invoice.principalId !== undefined) {
            return {
              ...invoice,
              requestedPrincipal: undefined,
            };
          }
          if (invoice.requestedPrincipal?.['id'] === 'undefined') {
            return {
              id: invoice.id,
              parentId: invoice.parentId,
              requestedPrincipal: {
                email: invoice.requestedPrincipal?.email,
                firstName: invoice.requestedPrincipal?.firstName,
                lastName: invoice.requestedPrincipal?.lastName,
              },
            };
          } else return invoice;
        }),
      });
    }
  };

  const findPrincipalById = (principals: Principal[], principalId?: string): Person | undefined => {
    return (
      principals
        .flatMap((principalsArray) => principalsArray.principals)
        .find((p) => p.id === principalId) ?? undefined
    );
  };

  const findRequestedPrincipalById = (
    principals: Person[],
    principalId?: string,
  ): Person | undefined => {
    return principals.find((p) => p.id === principalId) ?? undefined;
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          type: 'positive',
          text: 'Billing has been finalized successfully and automatically send for payment.',
        }),
      );
      onClear();
      dispatch(invalidateBillingTags(['InvoiceDetails', 'InvoiceSummary', 'BillingSummary']));
      navigate(routes.billing.root);
    }
  }, [isSuccess, onClear, dispatch, navigate]);

  useEffect(() => {
    if (apiError) {
      setErrors(apiErrorsToDict(apiError));
      let message = 'Error: invoice not submitted. Reload the page.';
      if (isFetchBaseQueryError(apiError)) {
        const errorDict = apiErrorsToDict(apiError);
        message = errorDict?._ || message;
      }
      dispatch(addToast({ type: 'negative', text: message }));
    }
  }, [apiError, dispatch]);

  const hasMissingAnyParentEmail =
    details &&
    details.invoices.some(
      (billing) => billing.parent && billing.parentSignatureRequired && !billing?.parent?.email,
    );

  return (
    <Modal
      open={!!invoicesIds?.length}
      onOpenChange={onOpenChange}
      title="Add your signature"
      className="h-full max-h-full w-full md:h-auto lg:w-signature-modal"
    >
      <div className="-mx-4 mb-6 px-6 text-neutral-300 typography-loud-sm md:-mx-6">
        Invoice will automatically be sent to parent/principal for signature after clicking
        &quot;Submit Invoice&quot;. Note that invoices cannot be edited after finalization.
      </div>
      <div className="-mx-4 flex border-t border-t-neutral-800 px-6 py-3 text-neutral-100 typography-body-sm md:-mx-6">
        <div>
          <span className="text-neutral-300">Provider:</span> {buildName({ firstName, lastName })}
        </div>
      </div>
      <Signature ref={canvasRef} className="-mx-4 md:-mx-6" />
      {isLoadingDetails && <div>loading details...</div>}
      {!isLoadingDetails && (
        <div>
          {details?.invoices.map((billing, index) => (
            <div
              key={billing.id}
              className="-mx-4 mt-6 border-t border-t-neutral-800 px-6 first-of-type:mt-0 first-of-type:border-t-0 md:-mx-6"
            >
              <div className="flex items-center pt-6 md:flex-row">
                <Avatar size="md" {...billing.student} />
                <div className="flex w-full flex-col items-start justify-start md:flex-row md:justify-between">
                  <div className="ml-4 text-neutral-100 typography-heading-md">
                    {buildName({
                      firstName: billing.student.firstName,
                      lastName: billing.student.lastName,
                    })}
                  </div>
                  <div className="ml-4 text-neutral-100 typography-body-sm md:ml-auto">
                    <span className="text-neutral-300">Session location:</span>{' '}
                    {billing.locations.join(', ')}
                  </div>
                </div>
              </div>
              {billing.principalSignatureRequired && (
                <div className="mt-6 flex items-end justify-between">
                  {invoices?.[index]?.requestedPrincipal && showRequestPrincipal ? (
                    <div className="w-full">
                      <div className="mb-4 flex items-center justify-between md:mb-6">
                        <span className="text-neutral-200 typography-heading-xs">
                          Request a principal
                        </span>
                        {isSm && (
                          <div className="flex">
                            <Button
                              onClick={onAddPrincipal.bind(null, index)}
                              preset="secondary"
                              size="sm"
                              className="mr-2"
                              disabled={
                                !isRequestedPrincipalValid(invoices[index].requestedPrincipal)
                              }
                            >
                              Send Request
                            </Button>
                            <Button
                              onClick={onCancelRequestPrincipal.bind(null, index)}
                              preset="tertiary"
                              size="sm"
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="mb-4 flex grow flex-col gap-4 md:mb-6 md:flex-row md:gap-6">
                        <TextField
                          label="First Name"
                          size="md"
                          placeholder="Enter principal's first name"
                          className="grow"
                          value={invoices[index].requestedPrincipal?.firstName || ''}
                          onChange={(e) =>
                            onChangePrincipalProp(
                              index,
                              'firstName',
                              e.target.value,
                              billing.schoolId,
                            )
                          }
                          error={requestedPrincipalError.firstName ? 'Invalid name' : undefined}
                        />
                        <TextField
                          label="Last Name"
                          size="md"
                          placeholder="Enter principal's last name"
                          className="grow"
                          value={invoices[index].requestedPrincipal?.lastName || ''}
                          onChange={(e) =>
                            onChangePrincipalProp(
                              index,
                              'lastName',
                              e.target.value,
                              billing.schoolId,
                            )
                          }
                          error={requestedPrincipalError.lastName ? 'Invalid surname' : undefined}
                        />
                      </div>
                      <TextField
                        label="Email"
                        size="md"
                        placeholder="Enter principal's email"
                        value={invoices[index].requestedPrincipal?.email || ''}
                        onChange={(e) =>
                          onChangePrincipalProp(index, 'email', e.target.value, billing.schoolId)
                        }
                        error={requestedPrincipalError.email ? 'Invalid email address' : undefined}
                      />
                      {!isSm && (
                        <div className="mt-6 flex gap-4">
                          <Button
                            onClick={onAddPrincipal.bind(null, index)}
                            preset="secondary"
                            size="sm"
                            className="mr-2"
                            disabled={
                              !isRequestedPrincipalValid(invoices[index].requestedPrincipal)
                            }
                          >
                            Send request
                          </Button>
                          <Button
                            onClick={onCancelRequestPrincipal.bind(null, index)}
                            preset="tertiary"
                            size="sm"
                          >
                            Cancel request
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex w-full flex-col gap-6 md:flex-row ">
                      <SelectField
                        label="Principal"
                        className="w-full md:w-80"
                        size="md"
                        placeholder={'-'}
                        disabled={!details.principals.length}
                        options={[
                          ...checkIfRequestedPrincipalIsFromTheSameSchool(
                            requestedPrincipals,
                            billing.schoolId,
                          ),
                          ...getPrincipalsBySchoolId(details, billing.schoolId),
                        ].map((p) => ({
                          label: buildName({ firstName: p?.firstName, lastName: p?.lastName }),
                          value: p?.id,
                        }))}
                        value={invoices?.[index].principalId || requestedPrincipals[0]?.id}
                        onValueChange={onSelectPrincipal.bind(null, index)}
                        error={
                          findPrincipalById(details.principals, invoices?.[index].principalId)
                            ?.invalidEmail ||
                          (!getPrincipalsBySchoolId(details, billing.schoolId).length &&
                            !checkIfRequestedPrincipalIsFromTheSameSchool(
                              requestedPrincipals,
                              billing.schoolId,
                            ).length)
                            ? !checkIfRequestedPrincipalIsFromTheSameSchool(
                                details.principals.flatMap(
                                  (principalsArray) => principalsArray.principals,
                                ),
                                billing.schoolId,
                              ).length
                              ? `No principal detected in system. Please proceed to "Change principal" ${
                                  isSm ? 'at the right' : 'below'
                                }.`
                              : 'Invalid principal email in system. Contact admin.'
                            : undefined
                        }
                        hint={
                          findRequestedPrincipalById(
                            requestedPrincipals,
                            invoices?.[index].principalId,
                          )?.id === 'undefined' ||
                          (requestedPrincipals[0]?.id === 'undefined' &&
                            invoices?.[index]?.principalId === undefined) ||
                          findPrincipalById(details.principals, invoices?.[index].principalId)
                            ?.isPending
                            ? 'Principal pending admin approval. Invoice will be sent for signature once principal has been added to system.'
                            : undefined
                        }
                      />
                      <Button
                        Icon={IconPlus}
                        preset="tertiary"
                        size={isSm ? 'md' : 'sm'}
                        className="w-48 md:top-5"
                        onClick={onRequestPrincipal.bind(null, index)}
                      >
                        Change principal
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {billing.parentSignatureRequired && (
                <div className="mt-6">
                  <TextField
                    label="Parent"
                    size="md"
                    value={buildName(billing.parent)}
                    disabled
                    className="w-80"
                  />
                  {!billing?.parent?.email && (
                    <ErrorMessage className="mt-2">
                      No parent in system. Contact admin.
                    </ErrorMessage>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="-mx-4 mt-6 border-t border-t-neutral-800 p-6 md:-mx-6">
        <ErrorMessage className="mb-6">{errors?._}</ErrorMessage>
        <div className="grid grid-cols-2 gap-4 md:flex ">
          <Tooltip
            content={
              hasMissingAnyParentEmail &&
              'Cannot submit invoice due to missing parent email in system. Contact admin.'
            }
            className="w-64"
            side="bottom"
          >
            <Button
              preset="secondary"
              disabled={hasMissingAnyParentEmail || showRequestPrincipal}
              onClick={onSave}
              isLoading={isLoading}
            >
              Submit Invoice
            </Button>
          </Tooltip>
          <Button preset="tertiary" onClick={onClear}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BillingSignatureModal;
