import { RadioGroupProps as RadioGroupPrimitiveProps, Root } from '@radix-ui/react-radio-group';
import { twMerge } from 'tailwind-merge';

import RadioButton from './RadioButton';

type RadioGroupProps = RadioGroupPrimitiveProps & {
  options: { label: string; value: string }[];
  className?: string;
};

const RadioGroup = ({ options, value, onValueChange, className, ...props }: RadioGroupProps) => (
  <Root
    className={twMerge('flex flex-col gap-4', className)}
    defaultValue="default"
    aria-label="View density"
    value={value}
    onValueChange={onValueChange}
    {...props}
  >
    {options.map((opt) => (
      <RadioButton
        key={opt.value}
        {...opt}
        checked={value === opt.value}
        onClick={onValueChange?.bind(null, opt.value)}
      />
    ))}
  </Root>
);

export default RadioGroup;
