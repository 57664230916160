export enum EEventDisplayType {
  MY_FUTURE,
  MY_FUTURE_ON_STUDENTS_SCHEDULE,
  MY_PAST_RECORDED,
  MY_PAST_NOT_RECORDED,
  MY_CANCELLED,
  ANOTHER_PROVIDER,
  PUBLIC_HOLIDAYS,
  MY_HOLIDAYS,
}

export enum EEventDisplayTypeWithDot {
  MY_FUTURE,
  MY_FUTURE_ON_STUDENTS_SCHEDULE,
  MY_PAST_RECORDED,
  MY_PAST_NOT_RECORDED,
  MY_CANCELLED,
  ANOTHER_PROVIDER,
}

export type EventDotType = 'pacific' | 'schedule-green' | 'schedule-red' | 'secondary';
