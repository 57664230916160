import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenteredLoader from 'src/components/CenteredLoader';
import Pagination from 'src/components/Pagination';
import Retry from 'src/components/Retry';
import { getPageCount } from 'src/utils/pagination.utils';

import {
  useCreateStudentNoteMutation,
  useDeleteStudentNoteMutation,
  useSingleStudentNotesListQuery,
  useUpdateStudentNoteMutation,
} from './singleStudentApi';
import { SingleStudentNote } from './SingleStudentNote';
import StudentAddNote from './StudentAddNote';
import StudentNoNotes from './StudentNoNotes';

const StudentNotes = () => {
  const { id: studentId = '' } = useParams();
  const [createNoteMutation, { isLoading: isCreating }] = useCreateStudentNoteMutation();
  const [deleteNote, { isLoading: isDeleting }] = useDeleteStudentNoteMutation();
  const [updateNote, { isLoading: isUpdating }] = useUpdateStudentNoteMutation();
  const [page, setPage] = useState(1);
  const { data, isError, isFetching, refetch } = useSingleStudentNotesListQuery({
    studentId,
    page,
  });

  const createNote = useCallback(
    (content: string) => {
      if (isCreating || !content) {
        return;
      }
      if (studentId) {
        createNoteMutation({ studentId, content });
      }
    },
    [createNoteMutation, studentId, isCreating],
  );

  if (isError) {
    return <Retry retry={refetch} />;
  }

  if (!data || isDeleting || isFetching) {
    return <CenteredLoader />;
  }

  return (
    <>
      <StudentAddNote saveNote={createNote} isLoading={isCreating} className="mb-4" />
      {data.items.length === 0 && page === 1 ? (
        <StudentNoNotes />
      ) : (
        <>
          <div>
            {data.items.map((note) => (
              <SingleStudentNote
                key={note.id}
                note={note}
                className="mb-4"
                isLoading={isUpdating}
                deleteNote={() => deleteNote({ studentId, id: note.id })}
                updateNote={(content) => updateNote({ studentId, id: note.id, content })}
              />
            ))}
          </div>
          <Pagination
            className="sm:mt-8"
            activePage={data?.pageNumber}
            pagesCount={getPageCount(data) || 1}
            totalSize={data?.total}
            onChangeActivePage={setPage}
          ></Pagination>
        </>
      )}
    </>
  );
};

export default StudentNotes;
