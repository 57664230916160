/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      sans: ['Inter', 'sans-serif'],
    },
    colors: {
      transparent: 'transparent',
      neutral: {
        white: '#FFFFFF',
        950: '#F8F9FB',
        900: '#E9ECF2',
        800: '#E1E4EA',
        700: '#C3C9D5',
        600: '#A5ADC0',
        500: '#8792AB',
        400: '#6D788D',
        300: '#545F78',
        200: '#434C60',
        100: '#262B36',
        black: '#090B11',
      },
      primary: {
        900: '#FDF3F3',
        800: '#FCE4E5',
        700: '#FBCDCE',
        600: '#F6A6A8',
        500: '#E7585B',
        400: '#D23033',
        300: '#B02528',
        200: '#841E20',
        100: '#5F1A1C',
      },
      secondary: {
        900: '#EAEFFA',
        800: '#E2E9F7',
        700: '#CCD9F1',
        600: '#A9C1E7',
        500: '#6282CF',
        400: '#4458B1',
        300: '#3D4A92',
        200: '#354073',
        100: '#242947',
      },
      accent: {
        'pink-800': '#FEF9FC',
        'pink-500': '#D25FA0',
        'tomato-800': '#FFE7E5',
        'tomato-500': '#F25045',
        'tomato-900': '#FFFAFA',
        'pacific-800': '#E9F7FA',
        'pacific-500': '#1286A1',
        'verdigris-800': '#DCF3F1',
        'verdigris-500': '#3CBBB1',
        'wisteria-800': '#FAF7FC',
        'wisteria-500': '#A969D2',
        'selective-yellow-800': '#FFEECB',
        'selective-yellow-500': '#FFAD00',
      },
      schedule: {
        'red-500': '#FBEBEA',
        'red-800': '#C03026',
        'green-500': '#DBF4FA',
        'green-800': '#0F738A',
        gray: '#8792AB',
      },
    },
    fontSize: {
      'heading-3xl-desktop': ['48px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-2xl-desktop': ['40px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-2xl-mobile': ['28px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-xl-desktop': ['32px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-xl-mobile': ['24px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-lg-desktop': ['24px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-lg-mobile': ['20px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-md-desktop': ['18px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-md-mobile': ['16px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-sm-desktop': ['16px', { lineHeight: '120%', fontWeight: '600' }],
      'heading-xs-desktop': ['14px', { lineHeight: '140%', fontWeight: '600' }],
      'caption-md-desktop': ['12px', { lineHeight: '125%', fontWeight: '400' }],
      'caption-sm-desktop': ['10px', { lineHeight: '125%', fontWeight: '400' }],
      'loud-lg-desktop': ['18px', { lineHeight: '160%', fontWeight: '500' }],
      'loud-md-desktop': ['16px', { lineHeight: '160%', fontWeight: '500' }],
      'loud-sm-desktop': ['14px', { lineHeight: '160%', fontWeight: '500' }],
      'loud-xs-desktop': ['12px', { lineHeight: '140%', fontWeight: '500' }],
      'loud-xs-bolder-desktop': ['12px', { lineHeight: '140%', fontWeight: '600' }],
      'body-lg-desktop': ['18px', { lineHeight: '160%', fontWeight: '400' }],
      'body-lg-mobile': ['16px', { lineHeight: '160%', fontWeight: '400' }],
      'body-md-desktop': ['16px', { lineHeight: '160%', fontWeight: '400' }],
      'body-sm-desktop': ['14px', { lineHeight: '160%', fontWeight: '400' }],
      'body-xs-desktop': ['12px', { lineHeight: '140%', fontWeight: '400' }],
      'button-lg-desktop': ['20px', { fontWeight: '600' }],
      'button-md-desktop': ['16px', { fontWeight: '600' }],
      'button-sm-desktop': ['14px', { fontWeight: '600' }],
      'button-xs-desktop': ['12px', { fontWeight: '600' }],
      'export-to-pdf-2xs': ['10px', { fontWeight: '400' }],
    },
    extend: {
      boxShadow: {
        'focus-primary': '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 5px #E2E9F7',
        'focus-danger': '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 5px #FFE7E5',
        'elevation-1': '0px 1px 2px rgba(40, 47, 62, 0.05)',
        'elevation-2': '0px 1px 3px rgba(40, 47, 62, 0.1), 0px 1px 2px rgba(40, 47, 62, 0.06)',
        'elevation-3':
          '0px 4px 8px -2px rgba(40, 47, 62, 0.1), 0px 2px 4px -2px rgba(40, 47, 62, 0.06)',
        'elevation-4':
          '0px 12px 16px -4px rgba(40, 47, 62, 0.08), 0px 4px 6px -2px rgba(40, 47, 62, 0.03)',
        'elevation-5':
          '0px 20px 24px -4px rgba(40, 47, 62, 0.08), 0px 8px 8px -4px rgba(40, 47, 62, 0.03)',
        'elevation-6': '0px 24px 48px -12px rgba(40, 47, 62, 0.18)',
        'elevation-7': '0px 32px 64px -12px rgba(40, 47, 62, 0.14)',
        'switch-thumb': '0px 2px 2px rgba(13, 34, 56, 0.08)',
      },
      borderWidth: {
        1: '1px',
        1.5: '1.5px',
        6: '6px',
      },
      spacing: {
        0.25: '0.0625rem',
        1.5: '0.375rem',
        2.5: '0.625rem',
        3.5: '0.875rem',
        4.5: '1.125rem',
        5.5: '1.375rem',
        18: '4.5rem',
        'mobile-header': '67px',
      },
      margin: {
        '-7.5': '-32px',
        '-15.5': '-66px',
      },
      width: {
        50: '12.75rem',
        120: '30rem',
        135: '33.75rem',
        'notifications-dropdown': '420px',
        'signature-modal': '674px',
        inherit: 'inherit',
        '9/10': '90%',
      },
      height: {
        'd-screen': '100dvh',
      },
      minWidth: {
        12: '3rem',
        8: '2rem',
        4: '0.5rem',
        2: '0.25rem',
      },
      minHeight: {
        4: '0.5rem',
        2: '0.25rem',
        16: '4rem',
      },
      maxWidth: {
        143: '14.312rem',
        101: '10.375rem',
        91: '9.25rem',
      },
      backgroundImage: {
        'auth-pattern': "url('/src/assets/bg_pattern.png')",
        'calendar-pattern': "url('/src/assets/calendar_pattern.png')",
      },
      keyframes: {
        // Tooltip
        'slide-up-fade': {
          '0%': { opacity: 0, transform: 'translateY(2px)' },
          '100%': { opacity: 1, transform: 'translateY(0)' },
        },
        'slide-right-fade': {
          '0%': { opacity: 0, transform: 'translateX(-2px)' },
          '100%': { opacity: 1, transform: 'translateX(0)' },
        },
        'slide-down-fade': {
          '0%': { opacity: 0, transform: 'translateY(-2px)' },
          '100%': { opacity: 1, transform: 'translateY(0)' },
        },
        'slide-left-fade': {
          '0%': { opacity: 0, transform: 'translateX(2px)' },
          '100%': { opacity: 1, transform: 'translateX(0)' },
        },
        // Toast
        'toast-hide': {
          '0%': { opacity: 1 },
          '100%': { opacity: 0 },
        },
        'toast-slide-in-top': {
          '0%': { transform: `translateY(calc(-100% + 1rem))` },
          '100%': { transform: 'translateY(0)' },
        },
      },
      animation: {
        // Tooltip
        'slide-up-fade': 'slide-up-fade 0.4s cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-right-fade': 'slide-right-fade 0.4s cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-down-fade': 'slide-down-fade 0.4s cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-left-fade': 'slide-left-fade 0.4s cubic-bezier(0.16, 1, 0.3, 1)',
        // Toast
        'toast-hide': 'toast-hide 400ms ease-in forwards',
        'toast-slide-in-top': 'toast-slide-in-top 500ms cubic-bezier(0.16, 1, 0.3, 1)',
      },
      borderRadius: {
        xs: '1px',
      },
      screens: {
        sm: '640px',
        md: '767px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
        '2.5xl': '1820px',
        '3xl': '1920px',
        '4xl': '2560px',
        '4.5xl': '3220px',
        '5xl': '3840px',
      },
    },
    zIndex: {
      stickyTableCell: 1,
      background: 1,
      mobileHeader: 1,
      buttons: 5,
      sidebar: 20,
      dropdown: 25,
      modalOverlay: 29,
      modal: 30,
      overlay: 40,
      loader: 50,
      portal: 60,
      toast: 70,
    },
  },
  plugins: [
    require('tailwindcss-radix')(), // eslint-disable-line
    ({ addUtilities }) => {
      addUtilities({
        '.typography-heading-3xl': { '@apply text-heading-3xl-desktop': {} },
        '.typography-heading-2xl': {
          '@apply text-heading-2xl-mobile': {},
          '@screen sm': {
            '@apply text-heading-2xl-desktop': {},
          },
        },
        '.typography-heading-xl': {
          '@apply text-heading-xl-mobile': {},
          '@screen sm': {
            '@apply text-heading-xl-desktop': {},
          },
        },
        '.typography-heading-lg': {
          '@apply text-heading-lg-mobile': {},
          '@screen sm': {
            '@apply text-heading-lg-desktop': {},
          },
        },
        '.typography-heading-md': {
          '@apply text-heading-md-mobile': {},
          '@screen sm': {
            '@apply text-heading-md-desktop': {},
          },
        },
        '.typography-heading-sm': { '@apply text-heading-sm-desktop': {} },
        '.typography-heading-xs': { '@apply text-heading-xs-desktop': {} },
        '.typography-caption-md': { '@apply text-caption-md-desktop': {} },
        '.typography-caption-sm': { '@apply text-caption-sm-desktop': {} },
        '.typography-loud-lg': { '@apply text-loud-lg-desktop': {} },
        '.typography-loud-md': { '@apply text-loud-md-desktop': {} },
        '.typography-loud-sm': { '@apply text-loud-sm-desktop': {} },
        '.typography-loud-xs': { '@apply text-loud-xs-desktop': {} },
        '.typography-loud-xs-bolder': { '@apply text-loud-xs-bolder-desktop': {} },
        '.typography-body-lg': {
          '@apply text-body-lg-mobile': {},
          '@screen sm': {
            '@apply text-body-lg-desktop': {},
          },
        },
        '.typography-body-md': { '@apply text-body-md-desktop': {} },
        '.typography-body-sm': { '@apply text-body-sm-desktop': {} },
        '.typography-body-xs': { '@apply text-body-xs-desktop': {} },
        '.typography-button-lg': { '@apply text-button-lg-desktop': {} },
        '.typography-button-md': { '@apply text-button-md-desktop': {} },
        '.typography-button-sm': { '@apply text-button-sm-desktop': {} },
        '.typography-button-xs': { '@apply text-button-xs-desktop': {} },
      });
    },
  ],
};
