import { useCallback, useRef, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import Tabs from 'src/components/Tabs';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconChevronLeft } from 'src/icons';
import { routes } from 'src/routes';
import { useAppDispatch } from 'src/store';

import CancelSessionModal, {
  useCancelSession,
} from '../schedule/record-session/CancelSessionModal';
import DeleteSessionAlertDialog, {
  useDeleteSession,
} from '../schedule/record-session/DeleteSessionAlertDialog';
import RecordSessionForm, {
  RecordSessionSidebarRefProps,
} from '../schedule/record-session/RecordSessionForm';
import { ScheduleEvent } from '../schedule/schedule.types';
import { useSingleStudentDetailsQuery } from '../single-student/singleStudentApi';
import StudentHeaderLoading from '../single-student/student-header/StudentHeaderLoading';
import StudentHeaderParameters from '../single-student/student-header/StudentHeaderParameters';
import StudentHeaderProfile from '../single-student/student-header/StudentHeaderProfile';
import StudentSchedule from '../single-student/StudentSchedule';
import { invalidateBillingTags, useInvoiceDetailsQuery } from './billingApi';
import BillingSessionsList from './BillingSessionsList';
import BillingSignatureModal from './BillingSignatureModal';
import { setInvoicesIdsForSignature } from './billingSlice';
import { getFinalizeTooltipMessage } from './getFinalizeTooltipMessage';

const tabs = [
  {
    label: 'Awaiting Confirmation',
    id: 'awaiting-confirmation',
    to: routes.billing.confirmSessions.awaitingConfirmation,
  },
  {
    label: 'Confirmed',
    id: 'confirmed',
    to: routes.billing.confirmSessions.confirmed,
  },
  {
    label: 'Cancelled',
    id: 'canceled',
    to: routes.billing.confirmSessions.cancelled,
  },
];

const BillingConfirmSessions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useInvoiceDetailsQuery(id!, {
    // refetchOnMountOrArgChange: true,
  });
  const recordSessionFormRef = useRef<RecordSessionSidebarRefProps>(null);
  const { sessionId, onDeleteSession, onCancelDeleteSession } = useDeleteSession();

  const { session: sessionData, onAbortSessionCancellation, onCancelSession } = useCancelSession();

  const onFormSuccess = useCallback(() => {
    refetch();
    dispatch(invalidateBillingTags(['InvoiceSummary']));
  }, [dispatch, refetch]);

  const onRecordSession = () => {
    if (recordSessionFormRef.current) {
      recordSessionFormRef.current.openWithSelectedDate(new Date());
    }
  };

  const onConfirmSession = (sessionId: string) => {
    if (recordSessionFormRef.current) {
      recordSessionFormRef.current.openWithSelectedSession({ id: sessionId } as ScheduleEvent, {
        limitDatePicker: true,
      });
    }
  };

  const onFinalizeBilling = () => {
    dispatch(setInvoicesIdsForSignature([id!]));
  };

  const { data: student, isFetching: isFetchingStudent } = useSingleStudentDetailsQuery(
    data?.studentId || '',
    {
      skip: !data?.studentId,
    },
  );

  const { isSm } = useBreakpoint('sm');
  const { isXl } = useBreakpoint('xl');
  const [activePreset, setActivePreset] = useState('list');

  const handlePresetChange = () => {
    setActivePreset(activePreset === 'list' ? 'calendar' : 'list');
  };
  const initialMonth = data && `${data.year}-${data.month.toString().padStart(2, '0')}`;

  return (
    <div className="flex min-h-full flex-col bg-neutral-white">
      <div className="h-auto w-full px-4 pb-6 sm:px-10 sm:pb-10 sm:pt-10">
        <Button
          preset="ghost"
          Icon={IconChevronLeft}
          to={`${routes.billing.root}`}
          className="mb-6 inline-flex"
        >
          Back to Invoices
        </Button>
        {!student || isFetchingStudent ? (
          <StudentHeaderLoading />
        ) : (
          <>
            <div className="flex flex-col xl:flex-row">
              <StudentHeaderProfile size="md" student={student} className="mb-4 mr-6 xl:mb-0" />
              <StudentHeaderParameters size="md" student={student} />
            </div>
          </>
        )}
        <Tooltip content={getFinalizeTooltipMessage(data)} delayDuration={0} className="max-w-xs">
          <Button
            className="mt-4 w-full xl:absolute xl:right-10 xl:top-24 xl:w-fit"
            size={isSm ? 'md' : 'xs'}
            disabled={!data?.canBeSigned}
            preset="tertiary"
            onClick={onFinalizeBilling}
          >
            Finalize invoice
          </Button>
        </Tooltip>
      </div>

      <Tabs items={tabs} underline className="px-4 sm:px-10" />
      {!isXl && (
        <div className="bg-neutral-950 px-4 py-4">
          <div className="grid w-full grid-cols-2 gap-4 rounded-lg border border-neutral-800 bg-neutral-white p-2">
            <Button
              preset={activePreset === 'list' ? 'secondary' : 'ghost'}
              size="xs"
              className="typography-loud-sm"
              onClick={handlePresetChange}
            >
              List
            </Button>
            <Button
              preset={activePreset === 'calendar' ? 'secondary' : 'ghost'}
              size="xs"
              className="typography-loud-sm"
              onClick={handlePresetChange}
            >
              Calendar
            </Button>
          </div>
        </div>
      )}
      <div
        className={`flex h-full grow justify-center gap-12 bg-neutral-950  md:p-6 ${
          activePreset === 'list' ? 'p-4 py-8' : '-m-6 py-6 md:m-0'
        }`}
      >
        {!isXl &&
          (activePreset === 'list' ? (
            <div className="w-full shrink-0 xl:w-auto">
              {isLoading ? (
                'loading...'
              ) : (
                <Routes>
                  <Route
                    path={routes.billing.confirmSessions.awaitingConfirmation}
                    element={
                      <BillingSessionsList
                        onRecordSession={onRecordSession}
                        onDeleteSession={onDeleteSession}
                        onCancelSession={onCancelSession}
                        onConfirmSession={onConfirmSession}
                        sessions={data?.awaitingConfirmation}
                        type="awaiting-confirmation"
                        canFinalize={data?.canBeSigned}
                        onFinalize={onFinalizeBilling}
                      />
                    }
                  />
                  <Route
                    path={routes.billing.confirmSessions.confirmed}
                    element={
                      <BillingSessionsList
                        onRecordSession={onRecordSession}
                        onDeleteSession={onDeleteSession}
                        onCancelSession={onCancelSession}
                        onConfirmSession={onConfirmSession}
                        sessions={data?.confirmed}
                        type="confirmed"
                      />
                    }
                  />
                  <Route
                    path={routes.billing.confirmSessions.cancelled}
                    element={
                      <BillingSessionsList
                        onRecordSession={onRecordSession}
                        onDeleteSession={onDeleteSession}
                        onCancelSession={onCancelSession}
                        onConfirmSession={onConfirmSession}
                        sessions={data?.cancelled}
                        type="cancelled"
                      />
                    }
                  />
                  <Route
                    index
                    element={
                      <Navigate to={routes.billing.confirmSessions.awaitingConfirmation} replace />
                    }
                  />
                </Routes>
              )}
            </div>
          ) : (
            <div className="flex w-full flex-col">
              <StudentSchedule
                key={initialMonth}
                studentId={data?.studentId}
                disableInteractions
                initialDate={initialMonth}
                headerProps={{
                  hasViewOptions: false,
                  className: 'md:px-0 pt-0',
                  onExport: undefined,
                  hasShowToday: false,
                  hasShowOnlyMyClasses: false,
                }}
              />
            </div>
          ))}

        {isXl && (
          <>
            <div className="w-full shrink-0 lg:w-135">
              {isLoading ? (
                'loading...'
              ) : (
                <Routes>
                  <Route
                    path={routes.billing.confirmSessions.awaitingConfirmation}
                    element={
                      <BillingSessionsList
                        onRecordSession={onRecordSession}
                        onDeleteSession={onDeleteSession}
                        onCancelSession={onCancelSession}
                        onConfirmSession={onConfirmSession}
                        sessions={data?.awaitingConfirmation}
                        type="awaiting-confirmation"
                        canFinalize={data?.canBeSigned}
                        onFinalize={onFinalizeBilling}
                      />
                    }
                  />
                  <Route
                    path={routes.billing.confirmSessions.confirmed}
                    element={
                      <BillingSessionsList
                        onRecordSession={onRecordSession}
                        onDeleteSession={onDeleteSession}
                        onCancelSession={onCancelSession}
                        onConfirmSession={onConfirmSession}
                        sessions={data?.confirmed}
                        type="confirmed"
                      />
                    }
                  />
                  <Route
                    path={routes.billing.confirmSessions.cancelled}
                    element={
                      <BillingSessionsList
                        onRecordSession={onRecordSession}
                        onDeleteSession={onDeleteSession}
                        onCancelSession={onCancelSession}
                        onConfirmSession={onConfirmSession}
                        sessions={data?.cancelled}
                        type="cancelled"
                      />
                    }
                  />
                  <Route
                    index
                    element={
                      <Navigate to={routes.billing.confirmSessions.awaitingConfirmation} replace />
                    }
                  />
                </Routes>
              )}
            </div>
            <div className="flex flex-col">
              <StudentSchedule
                key={initialMonth}
                studentId={data?.studentId}
                disableInteractions
                initialDate={initialMonth}
                headerProps={{
                  hasViewOptions: false,
                  className: 'md:px-0 pt-0',
                  onExport: undefined,
                  hasShowToday: false,
                  hasShowOnlyMyClasses: false,
                }}
              />
            </div>
          </>
        )}
      </div>
      <RecordSessionForm
        ref={recordSessionFormRef}
        onSuccessCb={onFormSuccess}
        lockOnStudentId={data?.studentId}
      />
      <DeleteSessionAlertDialog
        sessionId={sessionId}
        onCancel={onCancelDeleteSession}
        onSuccessCb={onFormSuccess}
      />
      <CancelSessionModal
        cancelSessionData={sessionData}
        onAbort={onAbortSessionCancellation}
        onSuccessCb={onFormSuccess}
      />
      <BillingSignatureModal />
    </div>
  );
};

export default BillingConfirmSessions;
