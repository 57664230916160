import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { IconUser } from 'src/icons';
import { twMerge } from 'tailwind-merge';

const avatarStyles = cva(
  'inline-flex items-center justify-center rounded-full bg-secondary-900 relative shrink-0',
  {
    variants: {
      size: {
        '3xl': 'w-20 h-20 typography-heading-xs',
        '2xl': 'w-16 h-16 typography-heading-xs',
        xl: 'w-10 h-10 sm:w-14 sm:h-14 typography-heading-xs',
        lg: 'w-12 h-12 typography-heading-xs',
        md: 'w-10 h-10 typography-loud-xs-bolder',
        sm: 'w-8 h-8 typography-loud-xs-bolder',
        xs: 'w-6 h-6 typography-body-xs',
      },
      isActive: {
        true: 'opacity-100',
        false: ' opacity-50',
      },
    },
    defaultVariants: {
      size: 'md',
      isActive: true,
    },
  },
);

const placeholderIconStyles = cva('', {
  variants: {
    size: {
      '3xl': 'w-8 h-8',
      '2xl': 'w-8 h-8',
      xl: 'w-8 h-8',
      lg: 'w-7 h-7',
      md: 'w-6 h-6',
      sm: 'w-5 h-5',
      xs: 'w-4 h-4',
    },
  },
});

const onlineStyles = cva(
  'absolute -bottom-0.5 -right-0.5 rounded-full border-1.5 border-neutral-white box-content bg-accent-verdigris-500',
  {
    variants: {
      size: {
        '3xl': 'w-6 h-6',
        '2xl': 'w-4 h-4',
        xl: 'w-3.5 h-3.5',
        lg: 'w-3 h-3',
        md: 'w-2.5 h-2.5',
        sm: 'w-2 h-2',
        xs: 'w-1.5 h-1.5',
      },
    },
  },
);

type AvatarProps = VariantProps<typeof avatarStyles> &
  AvatarPrimitive.AvatarProps & {
    url?: string;
    firstName?: string;
    lastName?: string;
    isOnline?: boolean;
  };

const getInitials = (firstName?: string, lastName?: string) => {
  let initials = '';

  if (firstName && firstName.length > 0) {
    initials += firstName[0].toUpperCase();
  }

  if (lastName && lastName.length > 0) {
    initials += lastName[0].toUpperCase();
  }

  return initials;
};

const Avatar = ({
  url,
  firstName,
  lastName,
  size,
  isOnline,
  className,
  isActive,
  ...props
}: AvatarProps) => (
  <AvatarPrimitive.Root
    className={twMerge(
      clsx(avatarStyles({ size, isActive }), props.onClick ? 'cursor-pointer' : ''),
      className,
    )}
    {...props}
  >
    <AvatarPrimitive.Image className="h-full w-full rounded-full object-cover" src={url} />
    <AvatarPrimitive.Fallback className="flex h-full w-full items-center justify-center text-secondary-300">
      {getInitials(firstName, lastName) || <IconUser className={placeholderIconStyles({ size })} />}
    </AvatarPrimitive.Fallback>
    {isOnline && <div className={onlineStyles({ size })}></div>}
  </AvatarPrimitive.Root>
);

export default Avatar;
