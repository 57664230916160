import { useEffect } from 'react';
import { useLazyBackdoorTokenQuery } from 'src/auth/backend/backendAuthApi';
import { useAuth } from 'src/auth/useAuth';
import Button from 'src/components/Button';
import Loader from 'src/components/Loader';
import { IconLogOut } from 'src/icons';
import { useAppDispatch } from 'src/store';

import { User } from './singleUser.types';

type SingleUserLoginButtonProps = {
  user: User;
};

const SingleUserLoginButton = ({ user }: SingleUserLoginButtonProps) => {
  const dispatch = useAppDispatch();
  const { signOut, backdoorSignIn } = useAuth();
  const [fetchToken, { isLoading, data }] = useLazyBackdoorTokenQuery();

  useEffect(() => {
    if (data) {
      signOut().then(() => backdoorSignIn(data));
    }
  }, [backdoorSignIn, data, dispatch, signOut]);

  return (
    <div>
      <Button
        preset="ghost"
        disabled={isLoading}
        Icon={isLoading ? Loader : IconLogOut}
        onClick={() => fetchToken(user.id)}
      >
        <span className="text-neutral-200 typography-heading-xs">Login</span>
      </Button>
    </div>
  );
};

export default SingleUserLoginButton;
