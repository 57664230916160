import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { customBaseQuery } from './../../api';
import { User } from './auth.types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery(['forgotPassword']),
  tagTypes: ['Me'],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    me: build.query<User, void>({
      query: () => ({
        method: 'GET',
        url: '/user',
      }),
      providesTags: ['Me'],
    }),
    addSignature: build.mutation<any, FormData>({
      query: (data) => ({
        url: '/user/signature',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Me'],
    }),
    forgotPassword: build.mutation<void, { email: string }>({
      query: (body) => ({
        method: 'POST',
        url: '/Auth/forgot-password',
        body,
      }),
      extraOptions: {
        unauthorized: true,
      },
    }),
  }),
});

export const { useMeQuery, useLazyMeQuery, useAddSignatureMutation, useForgotPasswordMutation } =
  authApi;
