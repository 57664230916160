import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';

import { useGoalGradesForFiltersQuery } from './filtersApi';
import { selectedCountLabel } from './selectedCountLabel';

type FilterByGoalGradesProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
  studentId?: string;
};

const FilterByGoalGrades = ({
  value,
  onChange,
  separator = true,
  studentId,
}: FilterByGoalGradesProps) => {
  const { data: grades } = useGoalGradesForFiltersQuery(studentId ?? '');

  return (
    <Collapsible
      title={`Grade${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="grid grid-cols-2 gap-x-6 gap-y-2">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={grades?.map((s) => ({
            label: s.name,
            value: s.id,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterByGoalGrades;
