import Checkbox from './Checkbox';

type CheckboxesNestedGroupProps<T> = {
  nestedOptions?: Record<string, { label: string; value: T }[]>;
  options?: { label: string; value: T }[];
  value?: T[];
  onChange?: (v?: T[]) => void;
};

const CheckboxesNestedGroup = <T extends string | number>({
  nestedOptions,
  value,
  onChange,
}: CheckboxesNestedGroupProps<T>) => {
  const handleChange = (id: T, isChecked: boolean) => {
    if (isChecked) {
      onChange && onChange([...(value || []), id]);
    } else {
      const newValue = (value || []).filter((v) => v !== id);
      onChange && onChange(newValue);
    }
  };

  return (
    <>
      {Object.entries(nestedOptions || {}).map(([key, items]) => {
        return (
          <div key={`nestedOption` + key} className="flex flex-col gap-y-2">
            <div>{key}</div>
            {items?.map((opt) => (
              <div key={opt.value} className="mb-2">
                <Checkbox
                  label={opt.label}
                  key={opt.value}
                  isChecked={!!value?.includes(opt.value)}
                  onCheckedChange={handleChange.bind(null, opt.value)}
                />
              </div>
            ))}
          </div>
        );
      })}
    </>
  );
};

export default CheckboxesNestedGroup;
