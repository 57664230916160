import { Close } from '@radix-ui/react-dialog';
import Button from 'src/components/Button';
import { SidebarFooter } from 'src/components/Sidebar';

type FiltersSidebarFooterProps = {
  onApply: () => void;
  disabled?: boolean;
  filtersCount?: number;
};

const FiltersSidebarFooter = ({ onApply, disabled, filtersCount }: FiltersSidebarFooterProps) => (
  <SidebarFooter>
    <Close asChild>
      <Button preset="secondary" size="md" fullWidth onClick={onApply} disabled={disabled}>
        {`Apply${filtersCount ? ` (${filtersCount})` : ''}`}
      </Button>
    </Close>
  </SidebarFooter>
);

export default FiltersSidebarFooter;
