import { useState } from 'react';
import Pagination from 'src/components/Pagination';
import Search from 'src/components/Search';
import { getPageCount } from 'src/utils/pagination.utils';

import { useUsersListQuery } from './usersApi';
import UserCard from './UsersCard';

const Users = () => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching } = useUsersListQuery({
    page,
    pageSize: 8,
    email: searchQuery,
    userTypes: ['Provider', 'Principal'],
  });

  const pagesCount = data ? getPageCount(data) : 0;

  const Loader = () => <div>loading...</div>;

  const UsersTable = () =>
    data?.items?.length ? (
      <>
        {data.items.map((user) => (
          <UserCard key={user.id} user={user} />
        ))}
        <Pagination activePage={page} pagesCount={pagesCount} onChangeActivePage={setPage} />
      </>
    ) : (
      <div>empty</div>
    );

  return (
    <div className="bg-neutral-950 p-10">
      <div className="mb-10 flex items-start items-center justify-between gap-4">
        <div>
          <div className="mb-2 text-neutral-100 typography-heading-xl">Users</div>
          <div className="text-neutral-300 typography-body-lg">
            Total: <span className="typography-loud-lg">{isFetching ? '' : data?.total}</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Search
            size="md"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={`h-12 w-12 w-auto min-w-12 rounded-lg border-0 bg-neutral-white shadow-elevation-1 transition duration-1000 xl:w-80`}
          />
        </div>
      </div>
      {isFetching ? <Loader /> : <UsersTable />}
    </div>
  );
};

export default Users;
