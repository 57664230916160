import clsx from 'clsx';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Separator from './Separator';
import TabButton from './TabButton';
import Tag from './Tag';

export type TabItem = {
  label: React.ReactNode;
  to?: string;
  id: string;
  count?: number;
};

export type TabsProps = {
  items: TabItem[];
  underline?: boolean;
  className?: string;
  active?: string;
  onSelect?: (id: string) => void;
  size?: 'sm' | 'md';
};

const TabItemComponent = (props: { name: ReactNode; count?: number }) => (
  <div className="flex items-center gap-2">
    {props.name}
    {props.count !== undefined && (
      <Tag preset="grayStroke" size="sm">
        {props.count}
      </Tag>
    )}
  </div>
);

const Tabs = ({ items, underline, className, active, onSelect, size = 'md' }: TabsProps) => {
  return (
    <div className={twMerge('relative w-full overflow-auto', className)}>
      {underline && <Separator className="absolute left-0 right-0 top-full -mt-0.25 " />}
      <div className={clsx('relative flex whitespace-nowrap', size === 'sm' ? 'gap-2' : 'gap-6')}>
        {items.map((i) => (
          <TabButton
            active={active ? active === i.id : undefined}
            onSelect={onSelect}
            key={i.id}
            id={i.id}
            to={i.to}
            size={size}
          >
            <TabItemComponent name={i.label} count={i.count} />
          </TabButton>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
