import { RootState } from 'src/store';

import { EAblyEventTypes } from './notification.types';

export const selectSignatureRejectedEvent = (state: RootState) =>
  state.ablyNotifications.events[EAblyEventTypes.SIGNATURE_REJECTED];

export const selectSignatureAcceptedEvent = (state: RootState) =>
  state.ablyNotifications.events[EAblyEventTypes.SIGNATURE_ACCEPTED];

export const selectNewStudentAssignedEvent = (state: RootState) =>
  state.ablyNotifications.events[EAblyEventTypes.NEW_STUDENT_ASSIGNED];
