import { useRef, useState } from 'react';
import Button from 'src/components/Button';
import EmptyState from 'src/components/EmptyState';
import Pagination from 'src/components/Pagination';
import { ListApiResponse } from 'src/types/list-api-response';
import { getPageCount } from 'src/utils/pagination.utils';

import BillingMonthSingleInvoice from '../billing/BillingMonthSingleInvoice';
import BillingMonthSingleInvoiceLoading from '../billing/BillingMonthSingleInvoiceLoading';
import InvoicesSignatureModal, { InvoicesSignatureModalRefProps } from './InvoicesSignatureModal';
import { InvoiceOn } from './principal.types';
import { useInvoicesQuery } from './principalApi';

const InvoicesSubmitAll = () => {
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const { isFetching, data: invoices } = useInvoicesQuery({ page });

  const onCheckedChange = (invoiceId: string, checked: boolean) => {
    setSelectedInvoices((prev) => {
      return checked ? [...prev, invoiceId] : prev.filter((i) => i !== invoiceId);
    });
  };

  const onSubmit = () => {
    ref.current?.open({ ids: selectedInvoices });
    setSelectedInvoices([]);
  };

  const ref = useRef<InvoicesSignatureModalRefProps>(null);
  const pagesCount = getPageCount(invoices as ListApiResponse<InvoiceOn>);

  return (
    <div className="p-4 md:p-10">
      <InvoicesSignatureModal ref={ref} />
      <div className="flex flex-col gap-y-5 py-6 md:flex-row md:gap-y-0">
        <div className="flex items-center">Invoices: {invoices?.total || 0}</div>
        <div className="grid grid-cols-2 gap-4 md:ml-auto md:flex">
          <Button preset="secondary" size="md" onClick={onSubmit}>
            Submit signature{' '}
            {selectedInvoices.length
              ? `(${selectedInvoices.length})`
              : invoices?.total
              ? `(${invoices?.total})`
              : ''}
          </Button>
          <Button preset="tertiary" size="md" className="ml-2" to=".." relative="path">
            Cancel
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {isFetching ? (
          <div className="flex flex-col gap-6">
            {Array.from({ length: 3 }, (_, index) => (
              <BillingMonthSingleInvoiceLoading
                key={index}
                className={index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : ''}
              />
            ))}
          </div>
        ) : !invoices?.items?.length ? (
          <EmptyState
            title="No invoices"
            description="Looks like you don’t have any invoices to submit"
          />
        ) : (
          <>
            {invoices.items?.map((b) => (
              <>
                <BillingMonthSingleInvoice
                  key={b.id}
                  {...b}
                  isChecked={selectedInvoices.includes(b.id)}
                  onCheckedChange={onCheckedChange.bind(null, b.id)}
                  canBeSigned
                  showMonth
                  principalView
                />
              </>
            ))}

            <div className="flex items-center justify-between">
              <Pagination
                itemsPerPage={invoices.pageSize}
                totalSize={invoices.total}
                pagesCount={pagesCount}
                activePage={page}
                onChangeActivePage={setPage}
                searchingItemsPlaceholder="Invoices"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InvoicesSubmitAll;
