import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import HorizontalTabsButtons from 'src/components/HorizontalTabsButtons';
import SignatureStatusBar from 'src/components/SignatureStatusBar';
import { routes } from 'src/routes';

import BillingInvoices from './BillingInvoices';
import BillingMonth from './BillingMonth';
import BillingSignatureModal from './BillingSignatureModal';
import BillingYear from './BillingYear';

const BILLING_TABS = [
  { value: 'month', label: 'Month', to: routes.billing.month.root },
  { value: 'year', label: 'Year', to: routes.billing.year },
  { value: 'list', label: 'List', to: routes.billing.invoices },
];

const Billing = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>();

  const [isTabsVisible, setTabsVisible] = useState(true);

  const handleHideTabs = () => {
    setTabsVisible(!isTabsVisible);
  };

  useEffect(() => {
    const activeTab = BILLING_TABS.find((tab) => pathname.includes(tab.to));
    if (activeTab) {
      setActiveTab(activeTab.value);
    }
  }, [pathname]);

  return (
    <>
      <SignatureStatusBar />
      <div className="bg-neutral-950 md:p-10">
        {isTabsVisible && (
          <div className="flex items-center justify-between gap-2 p-4 md:mb-2 md:p-0">
            <div className="typography-heading-xl">Invoices</div>
            <HorizontalTabsButtons
              tabs={BILLING_TABS}
              activeTab={activeTab}
              onSelect={setActiveTab}
            />
          </div>
        )}
        <Routes>
          <Route
            path={`${routes.billing.month.root}/*`}
            element={<BillingMonth hideTabs={handleHideTabs} />}
          />
          <Route path={routes.billing.year} element={<BillingYear />} />
          <Route path={routes.billing.invoices} element={<BillingInvoices />} />
          <Route index element={<Navigate to={routes.billing.month.root} replace />} />
        </Routes>
        <BillingSignatureModal />
      </div>
    </>
  );
};

export default Billing;
