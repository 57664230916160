import image from '../assets/no_data_icon.svg';

type EmptyStateProps = {
  title: string;
  description: string;
};

const EmptyState = ({ title, description }: EmptyStateProps) => (
  <div className="mt-16 text-center md:mt-18">
    <img src={image} alt="no data icon" className="mx-auto" />
    <div className="mt-6 typography-heading-lg">{title}</div>
    <div className="mt-4 text-neutral-400 typography-heading-xs">{description}</div>
  </div>
);

export default EmptyState;
