import { useParams } from 'react-router-dom';

import {
  useReviewSignatureMutation,
  useSingleUserQuery,
  useUpdateUserSettingsMutation,
} from './singleUserApi';
import SingleUserBackButton from './SingleUserBackButton';
import SingleUserLoginButton from './SingleUserLoginButton';
import SingleUserSettings from './SingleUserSettings';
import SingleUserSignature from './SingleUserSignature';
import SingleUserSummary from './SingleUserSummary';

const SingleUser = () => {
  const { id: userId = '' } = useParams();
  const { data: user, isFetching } = useSingleUserQuery(userId);
  const [updateSettings, { isLoading: isSettingsUpdateInProgress }] =
    useUpdateUserSettingsMutation();
  const [reviewSignature, { isLoading: isReviewRequestInProgress }] = useReviewSignatureMutation();

  const isUpdating = isSettingsUpdateInProgress || isReviewRequestInProgress;

  const Loader = () =>
    isUpdating ? (
      <div className="h-auto w-full bg-neutral-white p-10">updating...</div>
    ) : (
      <div className="h-auto w-full bg-neutral-white p-10">loading...</div>
    );

  return (
    <div className="divide-y divide-neutral-800">
      {isFetching || !user || isUpdating ? (
        <Loader />
      ) : (
        <>
          <div className="h-auto w-full bg-neutral-white p-10">
            <SingleUserBackButton />
            <SingleUserSummary user={user} />
            <SingleUserLoginButton user={user} />
          </div>
          <div className="bg-neutral-950 py-10" />
          {user.signature ? (
            <SingleUserSignature
              userId={user.id}
              reviewSignature={reviewSignature}
              signature={user.signature}
            />
          ) : null}
          <SingleUserSettings user={user} updateSettings={updateSettings} />
        </>
      )}
    </div>
  );
};

export default SingleUser;
