import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import Avatar from 'src/components/Avatar';
import useUser from 'src/hooks/useUser';
import { IconChevronDown } from 'src/icons';
import { StudentForDropdown } from 'src/pages/students/students.types';
import { routes } from 'src/routes';
import { getAllAPIs, useAppDispatch } from 'src/store';

import Dropdown, { DropdownProps } from './Dropdown';
import DropdownContent, { DropdownContentProps } from './DropdownContent';
import { DropdownItem } from './DropdownItem';
import DropdownTrigger from './DropdownTrigger';

type UserDropdownProps = DropdownProps &
  StudentForDropdown & {
    onlyAvatar?: boolean;
    side?: DropdownContentProps['side'];
    withoutProfile?: boolean;
  };

const UserDropdown = (props: UserDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { firstName, lastName, email } = useUser();
  const { avatar } = useUser();
  const { side = 'right' } = props;
  const { signOut } = useAuth();
  const dispatch = useAppDispatch();

  const onLogout = () => {
    getAllAPIs.forEach((api) => dispatch(api.util.resetApiState()));
    setTimeout(() => signOut());
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      onLogout;
    }
  };

  return (
    <div className="flex">
      <Dropdown onOpenChange={(open) => setIsOpen(open)}>
        <DropdownTrigger>
          <div
            className={clsx(
              'flex w-full cursor-pointer items-center hover:bg-neutral-950',
              props.isCollapsed ? ' p-0 ' : 'gap-x-2 rounded-lg border border-neutral-800 p-2 ',
            )}
          >
            <div
              className={clsx(
                'flex items-center',
                props.isCollapsed ? 'rounded-lg border border-neutral-800 p-2' : '',
              )}
            >
              <Avatar firstName={firstName} lastName={lastName} url={avatar} />
            </div>
            {!props.onlyAvatar && (
              <>
                <div className={clsx(props.isCollapsed ? 'hidden' : '', 'overflow-hidden')}>
                  <div
                    className={
                      'mb-0.5truncate whitespace-nowrap !font-medium text-neutral-black typography-body-xs	'
                    }
                  >
                    {firstName} {lastName}
                  </div>
                  <div className="flex items-center gap-4">
                    <span className="truncate whitespace-nowrap text-neutral-300 typography-body-xs">
                      {email}
                    </span>
                  </div>
                </div>
                <div className={clsx(props.isCollapsed ? 'hidden' : '', 'ml-auto')}>
                  <IconChevronDown
                    className={`h-5 w-auto text-neutral-400 duration-300 ease-in-out typography-heading-sm ${
                      isOpen ? 'rotate-180' : ''
                    }`}
                  />
                </div>{' '}
              </>
            )}
          </div>
        </DropdownTrigger>
        <DropdownContent className="cursor-pointer" align="end" side={side}>
          <div className="flex flex-col gap-2">
            <div className="w-80 py-2">
              <DropdownItem className="cursor-default rounded-t-lg px-4 py-4">
                <div className={clsx('flex gap-3', props.isCollapsed ? 'ml-1' : '')}>
                  <Avatar firstName={firstName} lastName={lastName} url={avatar} />
                  <div className="overflow-hidden">
                    <div className={'truncate text-neutral-100 typography-loud-md'}>
                      {firstName} {lastName}
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="truncate text-neutral-300 typography-body-xs">{email}</span>
                    </div>
                  </div>
                </div>
              </DropdownItem>
              {!props.withoutProfile && (
                <Link to={routes.settings.root}>
                  <DropdownItem className="rounded-none px-4 py-4 text-neutral-200 typography-body-md">
                    Profile
                  </DropdownItem>
                </Link>
              )}
              <DropdownItem
                className="rounded-none rounded-b-lg px-4 py-4 text-neutral-200 typography-body-md"
                handleClick={onLogout}
                handleKeyDown={handleKeyDown}
              >
                Log out
              </DropdownItem>
            </div>
          </div>
        </DropdownContent>
      </Dropdown>
    </div>
  );
};
export default UserDropdown;
