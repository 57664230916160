import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { ComponentProps, ComponentType, forwardRef, SVGProps } from 'react';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { twMerge } from 'tailwind-merge';
export const textInputStyles = cva(
  [
    'w-full px-4 py-2.5 rounded-lg text-neutral-black focus:outline-none typography-body-sm bg-neutral-white',
    'border border-neutral-800',
    'disabled:bg-neutral-950 disabled:text-neutral-500',
    'placeholder:text-neutral-500',
  ],
  {
    variants: {
      size: {
        lg: 'h-14',
        md: 'h-12',
        sm: 'h-10',
        xs: 'h-8 py-1.5',
      },
      hasError: {
        true: 'border-accent-tomato-500 focus:shadow-focus-danger',
        false: 'focus:border-secondary-300 focus:shadow-focus-primary',
      },
    },
    defaultVariants: {
      size: 'sm',
      hasError: false,
    },
  },
);

export const textInputIconStyles = cva('pointer-events-none absolute flex h-5 w-5', {
  variants: {
    size: {
      lg: 'top-4.5',
      md: 'top-3.5',
      sm: 'top-2.5',
      xs: 'top-1.5',
    },
    hasError: {
      true: 'text-accent-tomato-500',
      false: 'text-neutral-200',
    },
    position: {
      prefix: 'left-3',
      sufix: 'right-3',
    },
  },
  compoundVariants: [
    {
      size: 'xs',
      position: 'prefix',
      className: 'left-3',
    },
    {
      size: 'sm',
      position: 'prefix',
      className: 'left-3',
    },
    {
      size: 'md',
      position: 'prefix',
      className: 'left-3.5',
    },
    {
      size: 'lg',
      position: 'prefix',
      className: 'left-3.5',
    },
    {
      size: 'xs',
      position: 'sufix',
      className: 'right-3',
    },
    {
      size: 'sm',
      position: 'sufix',
      className: 'right-3',
    },
    {
      size: 'md',
      position: 'sufix',
      className: 'right-3.5',
    },
    {
      size: 'lg',
      position: 'sufix',
      className: 'right-3.5',
    },
  ],
  defaultVariants: {
    size: 'sm',
    hasError: false,
  },
});

export type TextInputProps = VariantProps<typeof textInputStyles> &
  Omit<ComponentProps<'input'>, 'size'> & {
    PrefixIcon?: ComponentType<SVGProps<SVGSVGElement>>;
    SufixIcon?: ComponentType<SVGProps<SVGSVGElement>>;
    onSuffixIconClick?: () => void;
    inputClassName?: string;
    active?: boolean;
  };

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      hasError,
      size,
      className,
      inputClassName,
      PrefixIcon,
      SufixIcon,
      active = true,
      onSuffixIconClick,
      ...props
    },
    ref,
  ) => {
    const { isSm } = useBreakpoint('sm');
    return (
      <div className={clsx('relative', className)}>
        {PrefixIcon && (
          <PrefixIcon
            className={clsx(textInputIconStyles({ size, hasError, position: 'prefix' }))}
          />
        )}
        <input
          ref={ref}
          className={twMerge(
            clsx(
              textInputStyles({ size, hasError }),
              PrefixIcon ? 'pl-10' : 'pl-4',
              SufixIcon ? 'pr-10' : 'pr-4',
              !isSm
                ? active
                  ? PrefixIcon
                    ? 'pl-10'
                    : 'pl-4'
                  : 'cursor-pointer px-4 text-transparent'
                : 'cursor-auto	',
              !isSm
                ? active
                  ? SufixIcon
                    ? 'pr-10'
                    : 'pr-4'
                  : 'cursor-pointer px-4 text-transparent'
                : 'cursor-auto	',
              inputClassName,
            ),
          )}
          {...props}
          placeholder={!isSm ? (active ? props.placeholder : '') : props.placeholder}
        />
        {SufixIcon && (
          <SufixIcon
            className={twMerge(
              clsx(textInputIconStyles({ size, hasError, position: 'sufix' }), {
                'pointer-events-auto cursor-pointer': !!onSuffixIconClick,
              }),
            )}
            onClick={onSuffixIconClick}
          />
        )}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
export default TextInput;
