import FormField, { FormFieldProps } from './FormField';
import TextArea, { TextAreaProps } from './TextArea';

type Props = Omit<TextAreaProps, 'hasError'> & Omit<FormFieldProps, 'children'>;

const TextAreaField = ({ label, hint, error, id, className, ...props }: Props) => {
  return (
    <FormField
      label={label}
      hint={hint}
      error={error}
      id={id}
      disabled={props.disabled}
      className={className}
    >
      <TextArea hasError={!!error} id={id} {...props} />
    </FormField>
  );
};

export default TextAreaField;
