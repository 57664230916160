import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import { invalidateBillingTags } from 'src/pages/billing/billingApi';
import {
  invalidateSingleStudentTags,
  useSingleStudentDetailsQuery,
} from 'src/pages/single-student/singleStudentApi';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { removeStringAfterColon } from 'src/utils/removeStringAfterColon';

import { useDeleteSessionMutation } from '../scheduleApi';

export const useDeleteSession = () => {
  const [sessionId, setSessionId] = useState<string>();

  const onDeleteSession = useCallback((id: string) => {
    setSessionId(id);
  }, []);

  const onCancelDeleteSession = useCallback(() => {
    setSessionId(undefined);
  }, []);

  return {
    onDeleteSession,
    onCancelDeleteSession,
    sessionId,
  };
};

type DeleteSessionAlertDialogProps = {
  sessionId?: string;
  onCancel: () => void;
  onSuccessCb?: () => void;
};

const DeleteSessionAlertDialog = ({
  sessionId,
  onCancel,
  onSuccessCb,
}: DeleteSessionAlertDialogProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [deleteSession, { isLoading, isError, isSuccess }] = useDeleteSessionMutation();
  const studentId = removeStringAfterColon(id);
  const { refetch, isUninitialized } = useSingleStudentDetailsQuery(studentId!, {
    skip: !studentId,
  });

  useEffect(() => {
    if (isError) {
      dispatch(addToast({ text: 'Something went wrong. Try again later.', type: 'negative' }));
      onCancel();
    }
  }, [dispatch, isError, onCancel]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addToast({ text: 'Session has been successfully deleted.', type: 'positive' }));
      dispatch(invalidateBillingTags(['InvoiceDetails']));
      dispatch(invalidateSingleStudentTags(['StudentEvents']));
      onCancel();
      onSuccessCb?.();
    }
  }, [dispatch, isSuccess, onCancel, onSuccessCb]);

  const onDelete = async () => {
    if (sessionId) {
      await deleteSession(sessionId);
      if (!isUninitialized) {
        refetch();
      }
    }
  };

  return (
    <AlertDialog
      open={!!sessionId}
      onCancelClick={onCancel}
      onActionClick={onDelete}
      title="Are you sure you want to delete this session?"
      description="Deleted sessions cannot be restored."
      actionButtonLabel="Delete session"
      isActionLoading={isLoading}
    />
  );
};

export default DeleteSessionAlertDialog;
