import { useMemo, useReducer, useState } from 'react';
import Separator from 'src/components/Separator';
import { Sidebar, SidebarContainer, SidebarContent } from 'src/components/Sidebar';
import Switch from 'src/components/Switch';
import FilterByMonths from 'src/filters/FilterByMonths';
import FilterByProviders from 'src/filters/FilterByProviders';
import FilterByStudents from 'src/filters/FilterByStudents';
// import { countAllFilters } from 'src/filters/countAllFilters';
// import FilterByGrades from 'src/filters/FilterByGrades';
// import FilterByMonths from 'src/filters/FilterByMonths';
// import FilterBySchools from 'src/filters/FilterBySchools';
// import FilterByStudents from 'src/filters/FilterByStudents';
// import FilterBySupervisor from 'src/filters/FilterBySupervisor';
import FiltersSidebarFooter from 'src/filters/FiltersSidebarFooter';
import FiltersSidebarHeader from 'src/filters/FiltersSidebarHeader';
import FiltersTriggerButton from 'src/filters/FiltersTriggerButton';

type FiltersAction =
  | { type: 'setMonths'; payload?: number[] }
  | { type: 'setStudents'; payload?: string[] }
  | { type: 'setProviders'; payload?: string[] }
  | { type: 'reset' };

export type FiltersState = {
  months?: number[];
  studentsIds?: string[];
  providerIds?: string[];
  isAll?: boolean;
};

const reducer = (state: FiltersState, action: FiltersAction): FiltersState => {
  switch (action.type) {
    case 'setMonths':
      return { ...state, months: action.payload?.length ? action.payload : undefined };
    case 'setStudents':
      return { ...state, studentsIds: action.payload?.length ? action.payload : undefined };
    case 'setProviders':
      return { ...state, providerIds: action.payload?.length ? action.payload : undefined };
    case 'reset':
      return {
        months: [],
      };
    default:
      return state;
  }
};

type InvoicesFiltersProps = {
  appliedFiltersCount: number;
  onApply: (filters: FiltersState, filtersCount: number) => void;
};

const InvoicesFilters = (props: InvoicesFiltersProps) => {
  const [open, setOpen] = useState(false);
  const [filters, dispatch] = useReducer(reducer, {});

  const filtersCount = useMemo(() => {
    return (
      +Boolean(filters.months?.length || 0) +
      +Boolean(filters.studentsIds?.length || 0) +
      +Boolean(filters.providerIds?.length || 0)
    );
  }, [filters]);

  return (
    <SidebarContainer onOpenChange={setOpen} open={open}>
      <FiltersTriggerButton initialFiltersCount={props.appliedFiltersCount} />
      <Sidebar open={open}>
        <FiltersSidebarHeader
          onReset={() => dispatch({ type: 'reset' })}
          filtersCount={filtersCount}
        />
        <SidebarContent className="pt-1">
          <div className="my-4">
            <Switch label="Show Inactive students" />{' '}
          </div>
          <Separator />
          <FilterByStudents
            onChange={(students) => dispatch({ type: 'setStudents', payload: students })}
            separator
          />
          <FilterByProviders
            onChange={(students) => dispatch({ type: 'setStudents', payload: students })}
          />
          <FilterByMonths
            value={filters.months}
            onChange={(months) => dispatch({ type: 'setMonths', payload: months })}
          />
        </SidebarContent>
        <FiltersSidebarFooter
          onApply={() => props.onApply(filters, filtersCount)}
          filtersCount={filtersCount}
        />
      </Sidebar>
    </SidebarContainer>
  );
};

export default InvoicesFilters;
