import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';
import { buildName } from 'src/logic/buildName';

import { useStudentsForFiltersQuery } from './filtersApi';
import { selectedCountLabel } from './selectedCountLabel';

type FilterByStudentsProps = {
  value?: string[];
  onChange: (v?: string[]) => void;
  separator?: boolean;
};

const FilterByStudents = ({ value, onChange, separator = true }: FilterByStudentsProps) => {
  const { data: students } = useStudentsForFiltersQuery();

  return (
    <Collapsible
      title={`Student${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className=" grid  grid-cols-2 gap-x-6 gap-y-2">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={students?.map((s) => ({
            label: buildName(s),
            value: s.studentId,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterByStudents;
