import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import EmptyState from 'src/components/EmptyState';
import Pagination from 'src/components/Pagination';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { useAppDispatch } from 'src/store';
import { getPageCount } from 'src/utils/pagination.utils';

import { useIssuerWaitingInvoicesQuery } from './billingApi';
import BillingMonthSingleInvoice from './BillingMonthSingleInvoice';
import BillingMonthSingleInvoiceLoading from './BillingMonthSingleInvoiceLoading';
import BillingSignatureModal from './BillingSignatureModal';
import { setInvoicesIdsForSignature } from './billingSlice';

const BillingFinalizeAll = () => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const { data, isFetching } = useIssuerWaitingInvoicesQuery({ page });

  const pagesCount = getPageCount(data!);

  const onCheckedChange = (invoiceId: string, checked: boolean) => {
    setSelectedInvoices((prev) => {
      return checked ? [...prev, invoiceId] : prev.filter((i) => i !== invoiceId);
    });
  };

  const onFinalizeAll = () => {
    dispatch(setInvoicesIdsForSignature(selectedInvoices));
  };

  const { isSm } = useBreakpoint('sm');

  const billings = data?.items;

  return (
    <div className="px-4 md:p-10">
      <div className="mb-10 flex justify-between">
        <div>
          <div className="mb-2 py-4 typography-heading-xl">Invoices</div>
        </div>
        {isSm && (
          <div className="flex">
            <Button
              preset="secondary"
              size="md"
              disabled={!selectedInvoices.length}
              onClick={onFinalizeAll}
            >
              Finalize Invoices
            </Button>
            <Button preset="tertiary" size="md" className="ml-2" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-6">
        {isFetching ? (
          <div className="flex flex-col gap-6">
            {Array.from({ length: 3 }, (_, index) => (
              <BillingMonthSingleInvoiceLoading
                key={index}
                className={index === 1 ? 'opacity-70' : index === 2 ? 'opacity-50' : ''}
              />
            ))}
          </div>
        ) : !data?.items?.length ? (
          <EmptyState
            title="No invoices"
            description="Looks like you don’t have any invoices to finalize"
          />
        ) : (
          billings?.map((b) => (
            <BillingMonthSingleInvoice
              key={b.id}
              {...b}
              isChecked={selectedInvoices.includes(b.id)}
              onCheckedChange={onCheckedChange.bind(null, b.id)}
              hideActions
            />
          ))
        )}
      </div>
      {!isSm && (
        <div className="fixed bottom-0 left-0 grid w-full grid-cols-2 gap-x-2 border-t border-neutral-800 bg-neutral-white p-6">
          <Button
            preset="secondary"
            size="md"
            disabled={!selectedInvoices.length}
            onClick={onFinalizeAll}
          >
            Finalize invoice
          </Button>
          <Button preset="tertiary" size="md" className="ml-2" to=".." relative="path">
            Cancel
          </Button>
        </div>
      )}
      <BillingSignatureModal />
      <div className="flex items-center justify-between sm:mt-8">
        <Pagination
          itemsPerPage={data?.pageSize}
          totalSize={data?.total}
          pagesCount={pagesCount}
          activePage={page}
          onChangeActivePage={setPage}
        />
      </div>
    </div>
  );
};

export default BillingFinalizeAll;
