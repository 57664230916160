import { useEffect } from 'react';
import AlertDialog from 'src/components/AlertDialog';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';

import { useDeleteSchedulePlanMutation } from './scheduleApi';

const ScheduleMyPlansDeleteAlert = ({ planToDeleteId, setPlanToDelete }) => {
  const dispatch = useAppDispatch();
  const [deletePlan, { isSuccess }] = useDeleteSchedulePlanMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          type: 'positive',
          text: 'Plan has been successfully deleted.',
        }),
      );
    }
  }, [isSuccess, dispatch]);

  const onClear = async () => {
    if (planToDeleteId) {
      await deletePlan(planToDeleteId);
      onCancel();
    }
  };

  const onCancel = () => {
    setPlanToDelete('');
  };

  return (
    <AlertDialog
      open={!!planToDeleteId}
      onCancelClick={onCancel}
      onActionClick={onClear}
      title="Are you sure you want to delete this session?"
      description="All unconfirmed sessions from this schedule will be deleted."
    />
  );
};
export default ScheduleMyPlansDeleteAlert;
