import { Item, RadioGroupItemProps } from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type RadioButtonProps = RadioGroupItemProps & {
  label: string;
  value: string;
  id?: string;
};

const RadioButton = ({ label, value, id, checked, onClick }: RadioButtonProps) => {
  const localId = id || value;

  return (
    <button
      className={twMerge(
        'flex items-center gap-4 rounded-lg border border-neutral-800 px-4 py-3',
        checked && 'border-secondary-400 bg-secondary-900',
        'group',
      )}
      onClick={onClick}
    >
      <Item value={value} id={localId} asChild>
        <div
          className={clsx(
            'relative box-border aspect-square h-5 w-5 rounded-full border border-neutral-700 bg-neutral-white',
            'radix-state-checked:border-6 radix-state-checked:border-secondary-300',
            'group-hover:border-secondary-400',
          )}
        />
      </Item>
      <label
        className="cursor-pointer truncate whitespace-nowrap text-neutral-100 typography-loud-sm"
        htmlFor={localId}
      >
        {label}
      </label>
    </button>
  );
};

export default RadioButton;
