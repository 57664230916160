import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { TPagination } from 'src/types/pagination.type';
import { toQueryParams } from 'src/utils/pagination.utils';

import { StudentGoal } from '../single-student/singleStudent.types';
import {
  CancelSessionPayload,
  CheckSessionsApiResponse,
  ConfirmSessionPayload,
  CreateRecordSessionPayload,
  CreateScheduleEvent,
  PlanAvailableHoursForDay,
  RecordSessionFull,
  RecordUnavailableDates,
  RescheduleSessionPayload,
  ScheduleEvent,
  ScheduleEventsQuery,
  ScheduleFilters,
  SchedulePlanApiResponse,
  ScheduleSessionAvailabilityRequest,
  ScheduleSessionAvailabilityResponse,
  ScheduleSessionAvailableHoursResponse,
  ScheduleSinglePlan,
  ScheduleSinglePlanFull,
} from './schedule.types';

export const scheduleApi = createApi({
  reducerPath: 'scheduleApi',
  baseQuery,
  tagTypes: ['ScheduleEvents', 'SchedulePlans', 'SingleSchedulePlan'],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    schedulePlans: build.query<SchedulePlanApiResponse, TPagination & ScheduleFilters>({
      query: ({ queryString, page, pageSize, ...filters }) => ({
        method: 'POST',
        url: `/schedulePlan/plans?${toQueryParams({
          page,
          pageSize: pageSize || 10,
        })}`,
        body: {
          queryString,
          ...filters,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ plans, student }) => ({
                type: 'SchedulePlans' as const,
                plans,
                student,
              })),
              'SchedulePlans',
            ]
          : ['SchedulePlans'],
    }),
    singleSchedulePlan: build.query<ScheduleSinglePlanFull | undefined, string>({
      query: (id) => `/schedulePlan/${id}`,
      providesTags: (result) =>
        result
          ? [{ type: 'SingleSchedulePlan' as const, id: result.id }]
          : [{ type: 'SingleSchedulePlan', id: '' }],
    }),
    scheduleEvents: build.query<ScheduleEvent[], ScheduleEventsQuery>({
      query: (body) => ({
        url: '/schedule/events',
        method: 'POST',
        body,
      }),
      providesTags: (result = []) =>
        result.map(({ id }) => ({ type: 'ScheduleEvents' as const, id })),
    }),
    createSchedulePlan: build.mutation<ScheduleSinglePlan, ScheduleSinglePlan>({
      query: (body) => ({
        url: '/schedulePlan',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
    }),
    updateSchedulePlan: build.mutation<ScheduleSinglePlan, ScheduleSinglePlan>({
      query: (body) => ({
        url: `/schedulePlan/${body.id}`,
        method: 'PUT',
        body: {
          start: body.start,
          finish: body.finish,
          daysOfWeek: body.daysOfWeek,
          schedule: body.schedule,
          exclude: body.exclude,
        },
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans', 'SingleSchedulePlan'],
    }),
    checkSessionsBeforeCreate: build.query<CheckSessionsApiResponse, ScheduleSinglePlan>({
      query: (body) => ({
        method: 'POST',
        url: `/schedulePlan/check`,
        body,
      }),
    }),
    checkSessionsBeforeUpdate: build.query<CheckSessionsApiResponse, ScheduleSinglePlan>({
      query: ({ id, ...body }) => ({
        method: 'POST',
        url: `/schedulePlan/${id}/check`,
        body,
      }),
    }),
    deleteSchedulePlan: build.mutation<string, string>({
      query: (id) => ({
        url: '/SchedulePlan/' + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
    }),

    createRecordSession: build.mutation<RecordSessionFull, CreateRecordSessionPayload>({
      query: (body) => ({
        url: `/ScheduleSession/record`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
    }),

    confirmSession: build.mutation<RecordSessionFull, ConfirmSessionPayload & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/ScheduleSession/${id}/confirm`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
    }),

    cancelSession: build.mutation<RecordSessionFull, CancelSessionPayload & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/ScheduleSession/${id}/cancel`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
    }),

    rescheduleSession: build.mutation<RecordSessionFull, RescheduleSessionPayload & { id: string }>(
      {
        query: ({ id, ...body }) => ({
          url: `/ScheduleSession/${id}/reschedule`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
      },
    ),

    deleteSession: build.mutation<RecordSessionFull, string>({
      query: (id) => ({
        url: `/ScheduleSession/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ScheduleEvents', 'SchedulePlans'],
    }),
    getRecordSession: build.query<RecordSessionFull, string>({
      query: (id) => ({
        url: `/ScheduleSession/${id}`,
        method: 'GET',
      }),
    }),
    getStudentGoals: build.query<Omit<StudentGoal, 'progress'>[], string>({
      query: (studentId: string) => ({
        method: 'GET',
        url: `/ScheduleSession/${studentId}/goals`,
      }),
    }),
    createScheduleEvent: build.mutation<ScheduleEvent, CreateScheduleEvent>({
      query: (body) => ({
        url: `/Schedule/event`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ScheduleEvents'],
    }),
    updateScheduleEvent: build.mutation<
      ScheduleEvent,
      { id: string } & Omit<CreateScheduleEvent, 'eventType'>
    >({
      query: ({ id, ...body }) => ({
        url: `/Schedule/${id}/event`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ScheduleEvents'],
    }),
    deleteScheduleEvent: build.mutation<void, string>({
      query: (id) => ({
        url: `/Schedule/${id}/event`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ScheduleEvents'],
    }),

    getAvailableHours: build.query<
      ScheduleSessionAvailableHoursResponse,
      { studentId: string; date: string }
    >({
      query: ({ studentId, date }) => ({
        url: `/ScheduleSession/${studentId}/hours/${date}`,
        method: 'GET',
      }),
    }),

    getAvailableHoursForWeek: build.query<PlanAvailableHoursForDay[], { studentId: string }>({
      query: ({ studentId }) => ({
        url: `/SchedulePlan/${studentId}/hours`,
        method: 'GET',
      }),
    }),

    studentDisabledDays: build.query<RecordUnavailableDates, string>({
      query: (studentId) => ({
        url: `/ScheduleSession/${studentId}/dates`,
      }),
    }),

    confirmAvailabilityHours: build.query<
      ScheduleSessionAvailabilityResponse,
      ScheduleSessionAvailabilityRequest & { studentId: string; date: string }
    >({
      query: ({ studentId, date, ...body }) => ({
        url: `/ScheduleSession/${studentId}/hours/${date}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetRecordSessionQuery,
  useLazyGetStudentGoalsQuery,
  useLazyScheduleEventsQuery,
  useSchedulePlansQuery,
  useSingleSchedulePlanQuery,
  useCancelSessionMutation,
  useConfirmSessionMutation,
  useRescheduleSessionMutation,
  useCreateSchedulePlanMutation,
  useDeleteSchedulePlanMutation,
  useDeleteSessionMutation,
  useCreateRecordSessionMutation,
  useUpdateSchedulePlanMutation,
  useUpdateScheduleEventMutation,
  useLazyCheckSessionsBeforeCreateQuery,
  useLazyCheckSessionsBeforeUpdateQuery,
  useCreateScheduleEventMutation,
  useDeleteScheduleEventMutation,
  useLazyGetAvailableHoursQuery,
  useLazyGetAvailableHoursForWeekQuery,
  useLazyConfirmAvailabilityHoursQuery,
  useStudentDisabledDaysQuery,
} = scheduleApi;
