import React, { ReactNode } from 'react';

import { useAblyNotifications } from './useAblyNotifications';

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  useAblyNotifications();

  return <>{children}</>;
};

export default NotificationProvider;
