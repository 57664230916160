export const uppercaseFirstLetter = (str: string): string => {
  if (!str) return str;

  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const remainingLetters = word.slice(1);
    return firstLetter + remainingLetters;
  });

  return capitalizedWords.join(' ');
};
