import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';

import { SettingDataResponse } from './settings.types';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery,
  tagTypes: ['Settings'],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    getProfileSettings: build.query<SettingDataResponse, void>({
      query: () => `/User`,
      providesTags: () => [{ type: 'Settings', id: 'DETAILS' }],
    }),
    updateProfileSettings: build.mutation<SettingDataResponse, FormData>({
      query: (data) => ({
        url: '/User',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'Settings', id: 'DETAILS' }],
    }),
  }),
});

export const { useGetProfileSettingsQuery, useUpdateProfileSettingsMutation } = settingsApi;
