import { pluck } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import AlertDialog from 'src/components/AlertDialog';
import Button from 'src/components/Button';
import CenteredLoader from 'src/components/CenteredLoader';
import Checkbox from 'src/components/Checkbox';
import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';
import { selectedCountLabel } from 'src/filters/selectedCountLabel';
import { IconInfoWarning } from 'src/icons';
import { IconFilters } from 'src/icons';
import { useAppDispatch } from 'src/store';
import { twMerge } from 'tailwind-merge';

import Card from '../../components/Card';
import Modal, { ModalFooter } from '../../components/Modal';
import Tag from '../../components/Tag';
import { invalidateStudentsListTags } from '../students/studentsApi';
import { SingleStudentGoalsDefinitionItem } from './singleStudent.types';
import {
  invalidateSingleStudentTags,
  useCreateSingleStudentGoalsMutation,
  useSingleStudentAllGoalsFiltersQuery,
  useSingleStudentAvailableGradesQuery,
  useSingleStudentAvailableSubdomainsQuery,
  useSingleStudentGoalsDefinitionsQuery,
} from './singleStudentApi';

type Column = {
  id: string;
  list: SingleStudentGoalsDefinitionItem[];
};

type Columns = {
  library: Column;
  selected: Column;
};

const initialColumns: Columns = {
  library: {
    id: 'library',
    list: [],
  },
  selected: {
    id: 'selected',
    list: [],
  },
};

const handleArrayUpdate = (prev, value) => {
  console.log({ prev }, { value }, 'dupasa');
  if (prev.includes(value)) {
    return prev.filter((item) => item !== value);
  } else {
    return [...prev, value];
  }
};

const Column = ({
  col: { list, id },
  allFiltersApplied,
  isFetching,
  onLoadMore,
  selectedCheckboxes: propSelectedCheckboxes,
  onCheckedChangeClick,
}: {
  col: Column;
  allFiltersApplied?: boolean;
  isFetching?: boolean;
  onLoadMore?: () => void;
  onCheckedChangeClick?: (selectedCheckboxes: string[]) => void;
  selectedCheckboxes: string[];
}) => {
  const { ref, inView } = useInView();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(propSelectedCheckboxes);

  const handleItemCheckedChange = (elementId, isChecked) => {
    setSelectedCheckboxes((prevSelected) =>
      isChecked ? [...prevSelected, elementId] : prevSelected.filter((id) => id !== elementId),
    );
  };

  useEffect(() => {
    if (onCheckedChangeClick) {
      onCheckedChangeClick(selectedCheckboxes);
    }
  }, [onCheckedChangeClick, selectedCheckboxes]);

  useEffect(() => {
    if (inView) {
      onLoadMore?.();
    }
  }, [onLoadMore, inView]);

  return (
    <div style={{ height: '100%' }}>
      <div className="h-full overflow-x-hidden">
        {id === 'library' && !isFetching && (!list.length || !allFiltersApplied) && (
          <div className="flex h-full flex-col items-center justify-center gap-2">
            <IconInfoWarning className="h-6 w-6" />
            <div className="px-10 text-center text-neutral-200 typography-body-md">
              {allFiltersApplied
                ? 'There is no more goals matching applied filters'
                : 'Select a domain, grade, and\u00A0subdomain to view goals.'}
            </div>
          </div>
        )}

        {(id !== 'library' || allFiltersApplied) &&
          list.map((element) => (
            <Item
              key={element.id}
              element={element}
              isChecked={selectedCheckboxes.includes(element.id)}
              onCheckedChange={(isChecked) => handleItemCheckedChange(element.id, isChecked)}
            />
          ))}

        {!isFetching && !!list.length && onLoadMore && <div ref={ref} className="h-10" />}
        {!!list.length && isFetching && <CenteredLoader />}
      </div>
    </div>
  );
};

const Item = ({ element, isChecked, onCheckedChange }) => {
  const handleCardClick = () => {
    onCheckedChange(!isChecked);
  };

  return (
    <Card
      className={twMerge(
        'mb-4 border border-neutral-800 shadow-none',
        isChecked ? 'bg-neutral-950' : '',
      )}
    >
      <div className="flex w-full gap-x-4 overflow-hidden">
        <div className="flex items-center">
          <Checkbox isChecked={isChecked} onCheckedChange={handleCardClick} />
        </div>
        <div className="grow">
          <div className="typography-loud-sm">{element.name}</div>
          <div className="relative h-8 w-full overflow-x-auto pt-2">
            <div className="absolute flex gap-2">
              <Tag preset="grayStroke">{element.domain.name}</Tag>
              <Tag preset="grayStroke">{element.grade.name}</Tag>
              <Tag preset="grayStroke">{element.subdomain.name}</Tag>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const generateFilterName = (filters, filterId) => {
  const selectedFilter = filters?.find((filterElement) => filterElement.id === filterId);
  return selectedFilter ? selectedFilter.name : '';
};

type AddNewGoalModalMobileProps = {
  handleClose: (value?: boolean) => void;
  studentId: string;
};

const AddNewGoalModalMobile = ({ handleClose, studentId }: AddNewGoalModalMobileProps) => {
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [columns, setColumns] = useState(initialColumns);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
  const [selectedSubdomains, setSelectedSubdomains] = useState<string[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const [hasFilter, setHasFilter] = useState(true);
  const [count, setCount] = useState(0);
  const dispatch = useAppDispatch();
  const [createGoals, { isLoading, isSuccess }] = useCreateSingleStudentGoalsMutation();

  const selectedIds = useMemo(() => {
    return pluck('id', columns.selected.list);
  }, [columns.selected]);

  const libraryColumnFiltered = useMemo(
    () =>
      columns.library && {
        ...columns.library,
        list: columns.library.list.filter((item) => !selectedIds.includes(item.id)),
      },
    [columns.library, selectedIds],
  );

  const loadGoals =
    selectedDomains.length > 0 || selectedGrades.length > 0 || selectedSubdomains.length > 0;

  const filters = useSingleStudentAllGoalsFiltersQuery(studentId || '');

  const { data: availableGrades = [] } = useSingleStudentAvailableGradesQuery({
    studentId: studentId || '',
    domainIds: selectedDomains,
  });

  const { data: availableSubdomains = [] } = useSingleStudentAvailableSubdomainsQuery({
    studentId: studentId || '',
    domainIds: selectedDomains,
    gradeIds: selectedGrades,
  });

  const { data, isFetching } = useSingleStudentGoalsDefinitionsQuery(
    {
      studentId: studentId || '',
      domainIds: selectedDomains,
      gradeIds: selectedGrades,
      subdomainIds: selectedSubdomains,
      pageSize: pageSize,
    },
    { skip: !loadGoals },
  );

  const allSubdomains = useMemo(() => {
    return (filters?.data?.subdomains || []).filter(
      (subdomain) =>
        selectedSubdomains.includes(subdomain.id) ||
        availableSubdomains.some((availableSubdomain) => availableSubdomain.id === subdomain.id),
    );
  }, [filters?.data?.subdomains, selectedSubdomains, availableSubdomains]);

  const allGrades = useMemo(() => {
    return (filters?.data?.grades || []).filter(
      (grade) =>
        selectedGrades.includes(grade.id) ||
        availableGrades.some((availableGrade) => availableGrade.id === grade.id),
    );
  }, [filters?.data?.grades, selectedGrades, availableGrades]);

  const handleChangeSelectedDomains = (value) => {
    setSelectedDomains((prev) => handleArrayUpdate(prev, value));
  };

  const handleChangeSelectedGrades = (value) => {
    setSelectedGrades((prev) => handleArrayUpdate(prev, value));
  };

  const handleChangeSelectedSubdomains = (value) => {
    setSelectedSubdomains((prev) => handleArrayUpdate(prev, value));
  };

  const generateFilterOptions = (filtersOptions) =>
    filtersOptions.map((filterElement) => ({ value: filterElement.id, label: filterElement.name }));

  const onLoadMore = useCallback(() => setPageSize((prev) => prev + 5), [setPageSize]);

  const handleShowGoals = () => {
    setHasFilter((prev) => !prev);
  };

  const handleCheckedChange = (data) => {
    setCount(data.length);
    setSelectedGoals(data);
  };

  const onSubmitGoals = async () => {
    if (studentId) {
      await createGoals({
        id: studentId,
        goalDefinitionIds: selectedGoals,
      });
    }
    handleClose(!!studentId);
  };

  useEffect(() => {
    if (data) {
      setColumns((prev) => ({ ...prev, library: { ...prev.library, list: data.items } }));
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(invalidateSingleStudentTags(['StudentEvents']));
      dispatch(invalidateStudentsListTags(['StudentsList']));
      dispatch(invalidateSingleStudentTags(['GoalsDefinitions']));
    }
  }, [isSuccess, dispatch]);

  const filterAndUpdateSelected = (selectedArray, availableArray, setSelectedArray) => {
    if (
      selectedArray.length > 0 &&
      availableArray.every((domain) => !selectedArray.includes(domain.id))
    ) {
      setSelectedArray([]);
    }
  };

  useEffect(() => {
    filterAndUpdateSelected(selectedSubdomains, availableSubdomains, setSelectedSubdomains);
    filterAndUpdateSelected(selectedGrades, availableGrades, setSelectedGrades);
  }, [availableGrades, availableSubdomains, selectedSubdomains, selectedGrades]);

  return hasFilter ? (
    <Modal
      open={true}
      onOpenChange={() => {
        handleClose(true);
      }}
      title="Filters"
      className="flex h-full max-h-full w-full rounded-md"
      contentClassName="grid grow grid-cols-1"
    >
      <div className="flex flex-col">
        <div className="grow">
          <div className="border-t border-neutral-800 pb-24 md:pb-0">
            <Collapsible
              title={`Domain${selectedCountLabel(selectedDomains)}`}
              separator={false}
              defaultOpen={false}
            >
              <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                <CheckboxesGroup
                  value={selectedDomains}
                  onChange={(value) => setSelectedDomains(value || [])}
                  options={filters.data ? generateFilterOptions(filters.data.domains) : []}
                />
              </div>
            </Collapsible>
            <Collapsible
              title={`Grade${selectedCountLabel(selectedGrades)}`}
              separator={false}
              defaultOpen={false}
            >
              <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                <CheckboxesGroup
                  value={selectedGrades}
                  onChange={(value) => setSelectedGrades(value || [])}
                  options={generateFilterOptions(allGrades)}
                />
              </div>
            </Collapsible>
            <Collapsible
              title={`Subdomain${selectedCountLabel(selectedSubdomains)}`}
              separator={false}
              defaultOpen={false}
            >
              <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                <CheckboxesGroup
                  value={selectedSubdomains}
                  onChange={(value) => setSelectedSubdomains(value || [])}
                  options={generateFilterOptions(allSubdomains)}
                />
              </div>
            </Collapsible>
            <div className="fixed bottom-0 left-0 flex w-full border-t border-neutral-800 bg-neutral-white p-6">
              <Button className="w-full" preset="secondary" onClick={handleShowGoals}>
                Show Goals
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <Modal
      open={true}
      onOpenChange={() =>
        selectedGoals.length > 0 ? setIsAlertDialogOpen(true) : handleClose(true)
      }
      title="Add new goal"
      className="flex h-full max-h-full w-full rounded-md"
      contentClassName="h-full w-full overflow-hidden pb-24"
      Header={
        <Button
          preset="tertiary"
          className="h-10 w-10 rounded-lg md:h-12 md:w-12"
          Icon={IconFilters}
          onClick={() => setHasFilter(!hasFilter)}
        />
      }
      Footer={
        <ModalFooter className="mx-0 mt-0 w-full pb-5">
          <div className="fixed bottom-0 left-0 flex w-full border-t border-neutral-800 bg-neutral-white p-6">
            <Button
              className="w-full"
              preset="secondary"
              onClick={() => onSubmitGoals()}
              isLoading={isLoading}
            >
              Add Selected Goals ({count})
            </Button>
          </div>
        </ModalFooter>
      }
    >
      <div className="mb-4 flex h-6 w-full flex-nowrap gap-2 overflow-x-auto">
        {selectedDomains.map((domain) => (
          <Tag
            preset="lightBlue"
            key={domain}
            onRemove={handleChangeSelectedDomains.bind(null, domain)}
          >
            Domain:{' '}
            <span className="pl-1 typography-loud-xs-bolder">
              {generateFilterName(filters?.data?.domains, domain)}
            </span>
          </Tag>
        ))}

        {selectedGrades.map((grade) => (
          <Tag
            preset="lightBlue"
            key={grade}
            onRemove={handleChangeSelectedGrades.bind(null, grade)}
          >
            Grade:{' '}
            <span className="pl-1 typography-loud-xs-bolder">
              {generateFilterName(filters?.data?.grades, grade)}
            </span>
          </Tag>
        ))}

        {selectedSubdomains.map((subdomain) => (
          <Tag
            preset="lightBlue"
            key={subdomain}
            onRemove={handleChangeSelectedSubdomains.bind(null, subdomain)}
          >
            Subdomain:{' '}
            <span className="pl-1 typography-loud-xs-bolder">
              {generateFilterName(filters?.data?.subdomains, subdomain)}
            </span>
          </Tag>
        ))}
      </div>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="grow overflow-y-auto">
          <Column
            col={libraryColumnFiltered}
            key={libraryColumnFiltered.id}
            isFetching={isFetching}
            allFiltersApplied={loadGoals}
            onLoadMore={(data?.total || 0) < pageSize ? undefined : onLoadMore}
            selectedCheckboxes={[]}
            onCheckedChangeClick={handleCheckedChange}
          />
        </div>
      </div>
      <AlertDialog
        open={isAlertDialogOpen && selectedGoals.length > 0}
        onActionClick={() => handleClose(true)}
        onCancelClick={() => setIsAlertDialogOpen(false)}
        actionButtonLabel="Leave Page"
        title="Wait a minute"
        description="Are you sure you want to leave this page? All selected goals will be lost."
      />
    </Modal>
  );
};

export default AddNewGoalModalMobile;
