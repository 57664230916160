import Loader from './Loader';

const CenteredLoader = () => {
  return (
    <div className="flex w-auto justify-center">
      <Loader />
    </div>
  );
};

export default CenteredLoader;
