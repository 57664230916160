import { SingleStudentDetails } from '../singleStudent.types';
import StudentHeaderParameters from './StudentHeaderParameters';
import StudentHeaderProfile from './StudentHeaderProfile';

type StudentHeaderForPdfExportProps = {
  student: SingleStudentDetails;
  className?: string;
};

const StudentHeaderForPdfExport = ({ student, className }: StudentHeaderForPdfExportProps) => {
  return (
    <div className={className}>
      <div className="flex flex-row pb-6">
        <StudentHeaderProfile size="sm" student={student} className="mb-4 mr-6 sm:mb-0" />
        <StudentHeaderParameters size="sm" student={student} />
      </div>
    </div>
  );
};

export default StudentHeaderForPdfExport;
