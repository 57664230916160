import { useAppSelector } from 'src/store';

import { InvoiceStatus } from './billing.types';
import BillingListGroup from './BillingListGroup';
import { selectBillingActiveSchoolYear } from './billingSelectors';

const Groups: InvoiceStatus[] = ['Incomplete', 'Pending', 'Submitted', 'Paid'];

const BillingListWithAppliedFilters = () => {
  const activeSchoolYear = useAppSelector(selectBillingActiveSchoolYear);

  if (!activeSchoolYear) return null;

  return (
    <div className="p-4 xl:p-0">
      {Groups.map((g) => (
        <BillingListGroup key={g} status={g} schoolYearId={activeSchoolYear.schoolYearId} />
      ))}
    </div>
  );
};

export default BillingListWithAppliedFilters;
