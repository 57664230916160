import clsx from 'clsx';
import { IconChevronLeft, IconChevronRight } from 'src/icons';

import Button, { ButtonProps } from './Button';
import Separator from './Separator';

type LeftRightButtonsProps = {
  handlePrevious: () => void;
  handleNext: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  size?: ButtonProps['size'];
};

const LeftRightButtons = ({
  handlePrevious,
  handleNext,
  prevDisabled,
  nextDisabled,
  size = 'md',
}: LeftRightButtonsProps) => (
  <div className="group flex">
    <Button
      size={size}
      preset="tertiary"
      className="peer rounded-lg rounded-e-none border-r-0"
      onClick={handlePrevious}
      disabled={prevDisabled}
      Icon={IconChevronLeft}
    />
    <Separator
      vertical
      className={clsx(size === 'md' ? 'h-12' : 'h-10', 'peer-hover:bg-secondary-300', {
        'peer-hover:bg-secondary-800': prevDisabled,
        'bg-neutral-900': prevDisabled && nextDisabled,
      })}
    />
    <div className="relative -ml-0.25 flex">
      <Button
        size={size}
        preset="tertiary"
        className="peer order-2 rounded-lg rounded-s-none border-l-0"
        onClick={handleNext}
        disabled={nextDisabled}
        Icon={IconChevronRight}
      />
      <Separator
        vertical
        className={clsx(
          size === 'md' ? 'h-12' : 'h-10',
          'order-1 opacity-0 peer-hover:bg-secondary-300',
          { 'peer-hover:opacity-100': !nextDisabled },
        )}
      />
    </div>
  </div>
);

export default LeftRightButtons;
