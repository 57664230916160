import clsx from 'clsx';
import { isFuture } from 'date-fns';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Checkbox, { CheckboxProps } from 'src/components/Checkbox';
import Separator from 'src/components/Separator';
import Tag from 'src/components/Tag';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconDraw, IconTimer } from 'src/icons';
import { buildName } from 'src/logic/buildName';
import { formatCurrency } from 'src/logic/formatCurrency';
import { getMonthName } from 'src/logic/getMonthName';
import { routes } from 'src/routes';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';

import { apiErrorsToDict } from '../../types/api-error-response';
import { InvoiceOnList } from './billing.types';
import { invalidateBillingTags, useRequestSignatureAgainMutation } from './billingApi';
import BillingSignerRequestInfo from './BillingSignerRequestInfo';
import { setInvoicesIdsForSignature } from './billingSlice';
import { getFinalizeTooltipMessage } from './getFinalizeTooltipMessage';

type BillingMonthSingleInvoiceProps = InvoiceOnList & {
  onFinalize?: (invoice: Omit<InvoiceOnList, 'signers' | 'statusChangedAt' | 'amount'>) => void;
  onRequestAgain?: (id: string) => void;
  showMonth?: boolean;
  isChecked?: boolean;
  onCheckedChange?: CheckboxProps['onCheckedChange'];
  principalView?: boolean;
  hideActions?: boolean;
};

export const BillingMonthSingleInvoiceBase = ({
  onFinalize,
  onRequestAgain,
  canConfirm = true,
  showMonth = false,
  hideActions,
  month,
  year,
  isChecked,
  onCheckedChange,
  amount,
  statusChangedAt,
  signers,
  provider,
  principalView,
  ...props
}: BillingMonthSingleInvoiceProps) => {
  const {
    status,
    canBeSigned,
    hasUnconfirmedSessions,
    previousInvoiceSubmitted,
    noSessions,
    noSignature,
  } = props;
  const isOnTime = isFuture(new Date(props.dueDate));
  const { format, formatDate } = useTimeFormat();

  const showConfirmSessions = !hideActions && status === 'Incomplete';
  const showFinalizeBilling = !hideActions && status === 'Incomplete';
  const showRequestAgain = !hideActions && status === 'Pending';
  const showPreviewSessions =
    (!hideActions && status === 'Pending') || status === 'Submitted' || status === 'Paid';
  const showCheckPayment = !hideActions && status === 'Paid';
  const showSubmitSignature = !hideActions && !status; // principal or parent
  const showSummaryPreview = !hideActions && !status; // principal or parent
  const { isXl } = useBreakpoint('xl');
  const { isLg } = useBreakpoint('lg');
  const { isSm } = useBreakpoint('sm');
  const { pathname } = useLocation();
  const isBillingMonthPath = pathname.startsWith('/billing/finalize-all');
  const isSubmitAllPath = pathname.startsWith('/billing/invoices/submit-all');
  const shouldAddTagMargin =
    !isBillingMonthPath ||
    showSummaryPreview ||
    showRequestAgain ||
    showPreviewSessions ||
    showCheckPayment ||
    showSubmitSignature;
  const onFinalizeBiling = () => {
    onFinalize && onFinalize(props);
  };

  const onCheckPayment = () => {
    window.open('https://idp.empeon.com/signin', '_blank');
  };

  const ConfirmButtonWithWrapper = () => (
    <Tooltip
      content={
        canConfirm
          ? undefined
          : "You cannot finalize billing, because you don't have selected goals for this student."
      }
      delayDuration={0}
      className="max-w-xs"
    >
      <div className="sm:w-auto">
        <Button
          disabled={!canConfirm}
          preset="secondary"
          to={
            canConfirm
              ? `${routes.billing.root}/${routes.billing.confirmSessions.root.replace(
                  ':id',
                  props.id,
                )}`
              : undefined
          }
        >
          Confirm Sessions
        </Button>
      </div>
    </Tooltip>
  );

  const FinalizeButtonWithWrapper = () => (
    <Tooltip
      content={getFinalizeTooltipMessage({
        hasUnconfirmedSessions,
        previousInvoiceSubmitted,
        noSessions,
        noSignature,
      })}
      className="max-w-xs"
    >
      <Button
        className="sm:w-auto"
        preset="tertiary"
        onClick={onFinalizeBiling}
        disabled={!canBeSigned}
      >
        Finalize invoice
      </Button>
    </Tooltip>
  );

  return (
    <Card tagText={props.isNew ? 'New' : undefined}>
      {principalView && provider && (
        <div className="-mx-6 mb-4 flex items-center gap-4 border-b border-b-neutral-800 px-6 pb-4 text-neutral-100 typography-heading-md">
          {buildName(provider)}
          <Tag preset="darkBlue" size="sm">
            Provider
          </Tag>
        </div>
      )}
      <div className="flex flex-col items-start gap-4 xl:flex-row xl:items-center xl:gap-0">
        <div className="flex items-center gap-4 ">
          {canBeSigned && onCheckedChange && (
            <Checkbox onCheckedChange={onCheckedChange} isChecked={isChecked} />
          )}
          <Avatar
            className="h-12 w-12"
            size="xl"
            firstName={props.student.firstName}
            lastName={props.student.lastName}
          />
          <div className="flex flex-col gap-1">
            <div className="flex h-5 items-center text-neutral-100 typography-heading-md md:h-6">
              {buildName(props.student)}
            </div>
            <div className="grid grid-cols-2 gap-4 text-neutral-300 typography-body-sm md:flex md:items-center">
              <div className=" truncate ">{props.student.schoolName}</div>
              <div className="flex">
                <Tag preset="lightBlue" size={isLg ? 'md' : 'sm'}>
                  {props.student.grade}
                </Tag>
              </div>
              {isXl && <div>Location: {props.sessionsLocation.join(', ')}</div>}
            </div>
            {!isXl && (
              <div className="!leading-tight text-neutral-300 typography-body-sm	">
                Location: {props.sessionsLocation.join(', ')}
              </div>
            )}
          </div>
        </div>
        {!principalView && status !== 'Paid' && status !== 'Submitted' && isLg ? (
          <div className="xl:ml-auto">
            <Tag
              size="sm"
              preset={isOnTime ? 'gray' : 'lightCoral'}
              className={clsx(shouldAddTagMargin && 'mx-12')}
            >
              {isOnTime
                ? `Due 
                ${formatDate(props.dueDate)}`
                : `Delayed: Due 
                  ${formatDate(props.dueDate)}`}
            </Tag>
          </div>
        ) : (
          <div className="xl:ml-auto"></div>
        )}

        {((showConfirmSessions || showFinalizeBilling) && !isSm && !isBillingMonthPath) || isSm ? (
          <div className="ml-4 grid w-full grid-cols-2 gap-x-2 md:flex md:w-auto">
            {showConfirmSessions && <ConfirmButtonWithWrapper />}
            {showFinalizeBilling && <FinalizeButtonWithWrapper />}
          </div>
        ) : null}

        {showRequestAgain || showPreviewSessions || showCheckPayment ? (
          <div className="grid w-full grid-cols-2 gap-x-2 md:flex md:w-auto">
            {showRequestAgain && (
              <Button
                preset="secondary"
                disabled={!props.canRequestAgain}
                onClick={onRequestAgain?.bind(null, props.id)}
              >
                Resend Request
              </Button>
            )}
            {showPreviewSessions && (
              <Button
                preset="tertiary"
                to={`${routes.billing.root}/${routes.billing.singleInvoice}`.replace(
                  ':id',
                  props.id,
                )}
              >
                View Invoice
              </Button>
            )}
            {showCheckPayment && (
              <Button preset="ghost" onClick={onCheckPayment}>
                Check payment
              </Button>
            )}
          </div>
        ) : null}

        {!isSubmitAllPath ? (
          <div className="flex gap-x-2">
            {showSubmitSignature && (
              <Tooltip
                content={getFinalizeTooltipMessage({
                  hasUnconfirmedSessions,
                  previousInvoiceSubmitted,
                  noSessions,
                  noSignature,
                })}
              >
                <Button preset="secondary" onClick={onFinalizeBiling} disabled={!props.canBeSigned}>
                  Sign Invoice
                </Button>
              </Tooltip>
            )}
            {showSummaryPreview && (
              <Button
                preset="tertiary"
                to={`${routes.principal.root}/${routes.principal.invoices.root}/${props.id}`}
              >
                View Invoice
              </Button>
            )}
          </div>
        ) : null}
      </div>
      <Separator className="my-4" />
      <div className="flex w-full flex-col items-start justify-start gap-4 lg:flex-row lg:justify-between">
        {/* <div className="text-neutral-500 typography-body-sm">
          {noGoals &&
            'You cannot finalize billing, because you don’t have selected goals for this student.'}
          {noSessions && 'This billing cannot be finalized, because there are no sessions.'}
        </div> */}
        <div className="flex gap-2">
          {month && year && showMonth && (
            <Tag size="sm" preset="grayStroke">
              {getMonthName(month)} {year}
            </Tag>
          )}
          <Tag size="sm" preset="grayStroke" className="truncate pr-4">
            Confirmed sessions: {props.confirmedSessionsCount}
          </Tag>
        </div>
        <div className="flex flex-col items-start gap-2 md:flex-row md:items-center lg:ml-auto lg:gap-4">
          {principalView && (
            <>
              {props.requestedAt && (
                <div className="flex items-center text-neutral-100 typography-body-sm">
                  <IconDraw className="mr-1 text-neutral-400" />
                  <div>
                    Signature requested:{' '}
                    <span className="text-neutral-100">
                      {format(props.requestedAt, 'MM/dd/yyyy')}
                    </span>
                  </div>
                </div>
              )}
              {props.dueDate && (
                <>
                  {isLg && <div className="text-neutral-800">|</div>}
                  <div className="flex items-center text-neutral-100 typography-body-sm">
                    <IconTimer className="mr-1 text-neutral-400" />
                    <div>
                      Invoice due date:{' '}
                      <span className="text-neutral-100">
                        {format(props.dueDate, 'MM/dd/yyyy')}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {status === 'Pending' &&
            signers?.length &&
            signers.map((signer, index) => (
              <div className="flex items-center text-neutral-100 typography-body-sm" key={index}>
                <IconDraw className="mr-1 text-neutral-400" />
                <span className="text-neutral-300">
                  {signer.signed ? 'Signed' : 'Pending'}
                </span>: {buildName({ firstName: signer.firstName, lastName: signer.lastName })}
                {signer.signedAt ? `- ${formatDate(signer.signedAt)}` : ''}
                <BillingSignerRequestInfo signer={signer} />
              </div>
            ))}

          {status === 'Submitted' && (
            <div className="flex items-center text-neutral-100 typography-body-sm">
              <IconDraw className="mr-1 text-neutral-400" />
              <span className="text-neutral-300">Submitted</span>: {formatDate(statusChangedAt)}
            </div>
          )}

          {status === 'Paid' && (
            <>
              {amount && (
                <div className="ml-auto whitespace-nowrap text-neutral-100 typography-body-sm">
                  <span className="text-neutral-300">Amount</span>: {formatCurrency(amount)}
                </div>
              )}
              <div className="whitespace-nowrap text-neutral-100 typography-body-sm">
                <span className="text-neutral-300">Paid</span>: {formatDate(statusChangedAt)}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

const BillingMonthSingleInvoice = (
  props: Omit<BillingMonthSingleInvoiceProps, 'onFinalize' | 'on'>,
) => {
  const dispatch = useAppDispatch();
  const [requestAgain, { isSuccess, error }] = useRequestSignatureAgainMutation();
  const { formatShortDateLongHourMinute } = useTimeFormat();

  const onFinalize = (invoice: Omit<InvoiceOnList, 'signers' | 'statusChangedAt' | 'amount'>) => {
    dispatch(setInvoicesIdsForSignature([invoice.id]));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          text: `Signature request sent: ${formatShortDateLongHourMinute(props.requestedAt)}`,
          type: 'positive',
        }),
      );
      dispatch(invalidateBillingTags([{ type: 'InvoiceSummary', id: props.id }]));
    }
  }, [dispatch, isSuccess, props.id, props.requestedAt, formatShortDateLongHourMinute]);

  useEffect(() => {
    if (error) {
      dispatch({ type: 'setErrors', payload: apiErrorsToDict(error) });

      dispatch(
        addToast({
          text: 'Error: signature request not sent. Reload the page.',
          type: 'negative',
        }),
      );
    }
  }, [dispatch, error, props.id]);

  return (
    <BillingMonthSingleInvoiceBase
      {...props}
      onFinalize={onFinalize}
      onRequestAgain={requestAgain}
    />
  );
};

export default BillingMonthSingleInvoice;
