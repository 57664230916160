import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { IconChevronLeft } from 'src/icons';
import { routes } from 'src/routes';

const SingleUserBackButton = () => (
  <div className="mb-10">
    <Link to={`${routes.users.root}`}>
      <Button preset="ghost" Icon={IconChevronLeft}>
        <span className="text-neutral-200 typography-heading-xs">Back to All Users</span>
      </Button>
    </Link>
  </div>
);

export default SingleUserBackButton;
