import type { SVGProps } from 'react';
const SvgArrowLeftGoals = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.207 3.293a1 1 0 0 1 0 1.414L7.914 8l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0Z"
    />
  </svg>
);
export default SvgArrowLeftGoals;
