import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const dotStyles = cva('rounded-full', {
  variants: {
    preset: {
      primary: 'bg-neutral-700',
      success: 'bg-accent-verdigris-500',
      pacific: 'bg-accent-pacific-500',
      'schedule-green': 'bg-schedule-green-800',
      'schedule-red': 'bg-schedule-red-800',
      secondary: 'bg-secondary-400',
    },
    size: {
      md: 'mr-2 h-2 w-2',
      sm: 'mr-2 h-1 w-1',
      xs: 'h-1 w-1',
    },
  },
  defaultVariants: {
    preset: 'primary',
    size: 'md',
  },
});

export type DotProps = React.ComponentProps<'div'> & VariantProps<typeof dotStyles>;

const Dot = ({ children, className, preset, size, ...props }: DotProps) => (
  <div className={twMerge('flex items-center', className)} {...props}>
    <div className={dotStyles({ preset, size })}></div>
    <div className="text-neutral-300 typography-body-sm">{children}</div>
  </div>
);
export default Dot;
