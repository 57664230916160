import type { SVGProps } from 'react';
const SvgEyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.018 20.25c-6.92 0-10.76-7.516-10.915-7.836a.914.914 0 0 1 .01-.844 17.639 17.639 0 0 1 4.884-5.737.91.91 0 0 1 1.283.174c.302.403.229.98-.174 1.283a15.719 15.719 0 0 0-4.143 4.72c.862 1.476 4.106 6.416 9.036 6.416a8.186 8.186 0 0 0 4.885-1.704.917.917 0 0 1 1.283.174c.302.403.23.98-.174 1.283a10.026 10.026 0 0 1-5.984 2.071h.009Zm8.083-4.408a.92.92 0 0 1-.587-.211.917.917 0 0 1-.11-1.293 15.922 15.922 0 0 0 1.631-2.337C20.174 10.525 16.93 5.585 12 5.585c-.55.028-1.154.064-1.713.193a.91.91 0 0 1-1.1-.688.91.91 0 0 1 .687-1.1c.697-.165 1.393-.275 2.136-.247 6.9 0 10.74 7.516 10.896 7.836a.942.942 0 0 1-.01.844 17.671 17.671 0 0 1-2.089 3.08.907.907 0 0 1-.705.33v.009Zm-8.166-.11c-.898 0-1.796-.321-2.502-.981a3.673 3.673 0 0 1-.183-5.179c.055-.064.12-.119.183-.183a.905.905 0 0 1 1.293.046.905.905 0 0 1-.046 1.292c-.028.028-.064.055-.092.092a1.838 1.838 0 0 0 .092 2.594c.742.687 1.906.65 2.593-.092a.905.905 0 0 1 1.292-.046.914.914 0 0 1 .046 1.293 3.681 3.681 0 0 1-2.685 1.164h.01Z"
    />
    <path
      fill="currentColor"
      d="M22.08 23a.939.939 0 0 1-.65-.266L11.348 12.652 1.269 2.57a.924.924 0 0 1-.001-1.302.924.924 0 0 1 1.301 0l20.162 20.165a.913.913 0 0 1-.65 1.558V23Z"
    />
  </svg>
);
export default SvgEyeOff;
