import { createCalendar } from '@internationalized/date';
import clsx from 'clsx';
import { useRef } from 'react';
import { DateValue, useDateField, useDateSegment, useLocale } from 'react-aria';
import {
  DateFieldState,
  DateFieldStateOptions,
  DateSegment,
  useDateFieldState,
} from 'react-stately';

export function DateField(
  props: Omit<DateFieldStateOptions<DateValue>, 'locale' | 'createCalendar'>,
) {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className="flex">
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}

type DateSegmentProps = {
  segment: DateSegment;
  state: DateFieldState;
};

function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={segmentProps.style}
      className={clsx(
        'group box-content rounded-sm px-0.5 text-right tabular-nums outline-none focus:bg-secondary-300 focus:text-neutral-white',
        !segment.isEditable ? 'text-neutral-500' : 'text-neutral-black',
      )}
    >
      {segment.isPlaceholder && (
        <span
          aria-hidden="true"
          className="block w-full text-center text-neutral-500 group-focus:text-neutral-white"
          style={{
            visibility: segment.isPlaceholder ? undefined : 'hidden',
            height: segment.isPlaceholder ? '' : 0,
            pointerEvents: 'none',
          }}
        >
          {segment.placeholder}
        </span>
      )}
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
}
