import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';

import { BackdoorToken } from '../auth.types';

export const backendAuthApi = createApi({
  reducerPath: 'backendAuthApi',
  baseQuery,
  endpoints: (build) => ({
    backdoorToken: build.query<BackdoorToken, string>({
      query: (userId) => ({
        method: 'POST',
        url: `/auth/${userId}/backdoorToken`,
      }),
    }),
  }),
});

export const { useLazyBackdoorTokenQuery } = backendAuthApi;
