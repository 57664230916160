import Validator from 'password-validator';

const MIN_LENGTH = 8;
const MIN_DIGITS = 1;
const MIN_SYMBOLS = 1;

export const VALIDATORS = [
  {
    name: 'lowercase',
    value: 1,
    description: 'One lowercase character',
  },
  {
    name: 'uppercase',
    value: 1,
    description: 'One uppercase character',
  },
  {
    name: 'symbols',
    value: MIN_SYMBOLS,
    description: 'One special character',
  },
  {
    name: 'digits',
    value: MIN_DIGITS,
    description: 'One number',
  },
  {
    name: 'min',
    value: MIN_LENGTH,
    description: `Eight characters minimum`,
  },
];

const schema = new Validator()
  .is()
  .min(MIN_LENGTH)
  .has()
  .lowercase()
  .has()
  .uppercase()
  .has()
  .digits(MIN_DIGITS)
  .has()
  .symbols(MIN_SYMBOLS);

export const isPasswordValid = (value: string) =>
  schema.validate(value, { details: true }) as {
    validation: 'min' | 'lowercase' | 'uppercase' | 'digits' | 'symbols';
  }[];
