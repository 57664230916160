import { EventContentArg } from '@fullcalendar/core';
import clsx from 'clsx';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';

import { useScheduleType } from '../../hooks/useScheduleType';
import { ScheduleEvent } from '../../pages/schedule/schedule.types';
import Dot from '../Dot';
import { OverflowTooltipText } from '../OverflowTooltipText';
import {
  EVENT_TYPE_TO_BACKGROUND_COLOR_MAP,
  EVENT_TYPE_TO_DOT_PRESET_MAP,
  EVENT_TYPE_TO_TEXT_COLOR_MAP,
  getEventDisplayName,
  getEventDisplayType,
  getEventIcon,
} from './calendar-events.utils';

type SingleLineEventProps = EventContentArg & {
  onClick: (event: ScheduleEvent) => void;
  disableInteractions?: boolean;
};

export const SingleLineEvent = (props: SingleLineEventProps) => {
  const { formatLongHourMinute } = useTimeFormat();
  const event = props.event.extendedProps as ScheduleEvent;
  const { start, end } = props.event;
  const startTime = (start && formatLongHourMinute(start)) || '';
  const endTime = (end && formatLongHourMinute(end)) || '';
  const eventTime = [startTime, endTime].filter((t) => t.length > 0).join('-');
  const { isSm } = useBreakpoint('sm');

  const displayType = getEventDisplayType(event, useScheduleType());

  return (
    <button
      onClick={() => event.canViewDetails && props.onClick(event)}
      className={clsx(
        'flex h-5 w-full items-center justify-between gap-1 overflow-hidden rounded-md p-1 typography-body-xs',
        !event.canViewDetails && 'cursor-default',
        EVENT_TYPE_TO_BACKGROUND_COLOR_MAP[displayType],
        EVENT_TYPE_TO_TEXT_COLOR_MAP[displayType],
      )}
    >
      <div className="flex truncate">
        {EVENT_TYPE_TO_DOT_PRESET_MAP[displayType] && (
          <Dot size="xs" className="pr-1" preset={EVENT_TYPE_TO_DOT_PRESET_MAP[displayType]} />
        )}
        <OverflowTooltipText>
          {getEventDisplayName(event, eventTime, !isSm, false)}
        </OverflowTooltipText>
      </div>
      {isSm && getEventIcon(displayType)}
    </button>
  );
};
