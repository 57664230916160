import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export type MutationStatus = 'Success' | 'Failed' | 'UnhandledException' | 'HttpNonSuccess';

export type ApiErrorResponse = {
  status: MutationStatus;
  isSuccessful: boolean;
  errorList?: {
    message: string;
    isFatal: boolean;
    details: string;
    code: string;
    propertyName: string;
  }[];
  httpStatus: string;
  message: string;
};

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

function isErrorWithMessage(error: unknown): error is { data: ApiErrorResponse } {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data != null &&
    'message' in error.data &&
    typeof (error.data as any).message === 'string'
  );
}

function isErrorWithErrorList(error: unknown): error is { data: ApiErrorResponse } {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data != null &&
    'errorList' in error.data &&
    !!(error.data as any).errorList.length
  );
}

export const apiErrorsToDict = (error: unknown) => {
  const errorsDict: Record<string, string> = {};

  if (isErrorWithErrorList(error)) {
    if (!error.data.errorList) return undefined;

    error.data.errorList.forEach((e) => {
      errorsDict[e.propertyName] = e.message;
    });
  }

  if (isErrorWithMessage(error)) {
    errorsDict._ = error.data.message;
  }

  if (Object.keys(errorsDict).length) return errorsDict;

  return undefined;
};
