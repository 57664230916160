import { useEffect, useState } from 'react';
import { Sidebar, SidebarContainer, SidebarContent } from 'src/components/Sidebar';
import { countAllFilters } from 'src/filters/countAllFilters';
import FilterByDateRange from 'src/filters/FilterByDateRange';
import FilterByGrades from 'src/filters/FilterByGrades';
import FilterBySchools from 'src/filters/FilterBySchools';
import FilterByStudents from 'src/filters/FilterByStudents';
import FilterBySupervisor from 'src/filters/FilterBySupervisor';
import FiltersSidebarFooter from 'src/filters/FiltersSidebarFooter';
import FiltersSidebarHeader from 'src/filters/FiltersSidebarHeader';
import FiltersTriggerButton from 'src/filters/FiltersTriggerButton';
import { useAppDispatch, useAppSelector } from 'src/store';

import { selectScheduleFilters, selectScheduleFiltersCount } from './scheduleSelectors';
import { setScheduleFilters } from './scheduleSlice';

const ScheduleFilters = () => {
  const dispatch = useAppDispatch();
  const initialFilters = useAppSelector(selectScheduleFilters);
  const initialFiltersCount = useAppSelector(selectScheduleFiltersCount);

  const [studentIds, setStudentsIds] = useState<string[]>();
  const [schoolIds, setSchoolIds] = useState<string[]>();
  const [gradeIds, setGradeIds] = useState<string[]>();
  const [supervisorIds, setSupervisorIds] = useState<string[]>();
  const [start, setStartDate] = useState<string>();
  const [finish, setFinishDate] = useState<string>();

  const [open, setOpen] = useState(false);

  const filters = {
    studentIds,
    schoolIds,
    grades: gradeIds,
    supervisorIds,
    start: start,
    finish: finish,
  };

  useEffect(() => {
    setStudentsIds(initialFilters.studentIds);
    setSchoolIds(initialFilters.schoolIds);
    setGradeIds(initialFilters.grades);
    setSupervisorIds(initialFilters.supervisorIds);
    setStartDate(initialFilters.start);
    setFinishDate(initialFilters.finish);
  }, [initialFilters, open]);

  const onApply = () => {
    dispatch(setScheduleFilters(filters));
  };

  const onReset = () => {
    setStudentsIds(undefined);
    setSchoolIds(undefined);
    setGradeIds(undefined);
    setSupervisorIds(undefined);
    setStartDate(undefined);
    setFinishDate(undefined);
  };

  const filtersCount = countAllFilters(filters);

  return (
    <SidebarContainer onOpenChange={setOpen} open={open}>
      <FiltersTriggerButton initialFiltersCount={initialFiltersCount} />
      <Sidebar open={open}>
        <FiltersSidebarHeader
          initialFiltersCount={initialFiltersCount}
          onReset={onReset}
          filtersCount={filtersCount}
        />
        <SidebarContent className="p-4 pt-1 md:p-6">
          <FilterByStudents value={studentIds} onChange={setStudentsIds} />
          <FilterBySchools value={schoolIds} onChange={setSchoolIds} />
          <FilterByGrades value={gradeIds} onChange={setGradeIds} />
          <FilterBySupervisor value={supervisorIds} onChange={setSupervisorIds} />
          <FilterByDateRange
            startDate={start}
            setStartDate={setStartDate}
            endDate={finish}
            setEndDate={setFinishDate}
            separator={false}
          />
        </SidebarContent>
        <FiltersSidebarFooter onApply={onApply} filtersCount={filtersCount} />
      </Sidebar>
    </SidebarContainer>
  );
};

export default ScheduleFilters;
