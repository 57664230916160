import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Dropdown from 'src/components/Dropdown';
import DropdownContent from 'src/components/DropdownContent';
import { DropdownItem } from 'src/components/DropdownItem';
import DropdownTrigger from 'src/components/DropdownTrigger';
import ProgressIndicator from 'src/components/ProgressIndicator';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconTripleDots } from 'src/icons';
import { formatTime } from 'src/logic/formatTime';

import { SessionData } from '../schedule/record-session/CancelSessionModal';
import { InvoiceSession } from './billing.types';

export type BillingSingleSessionProps = InvoiceSession & {
  type: 'awaiting-confirmation' | 'confirmed' | 'cancelled';
  onDelete: (sessionId: string) => void;
  onCancel: (session: SessionData) => void;
  onConfirm: (sessionId: string) => void;
};

const BillingSingleSession = ({
  id,
  startTime,
  finishTime,
  date,
  location,
  type,
  isEditable,
  isRescheduled,
  goals,
  note,
  onDelete,
  onCancel,
  onConfirm,
}: BillingSingleSessionProps) => {
  const { formatDate } = useTimeFormat();
  const handleDelete = () => {
    onDelete(id);
  };

  const handleCancel = () => {
    onCancel({
      sessionId: id,
      location,
      startTime,
      finishTime,
      date,
    });
  };

  const handleConfirm = () => {
    onConfirm(id);
  };
  const { isMd } = useBreakpoint('md');

  return (
    <Card className="grow-0">
      <div className="flex items-center">
        <div className="flex w-full flex-col gap-1 md:w-auto">
          {!isMd ? (
            <div className="flex w-full items-center justify-between ">
              <div className="text-neutral-100 typography-heading-md">{formatDate(date)}</div>
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    preset="tertiary"
                    className="rounded-lg"
                    Icon={IconTripleDots}
                    size="xs"
                  />
                </DropdownTrigger>
                <DropdownContent align="end" side="bottom">
                  {!isRescheduled && (
                    <DropdownItem handleClick={handleCancel}>Cancel session</DropdownItem>
                  )}
                  <DropdownItem handleClick={handleDelete}>Delete session</DropdownItem>
                </DropdownContent>
              </Dropdown>
            </div>
          ) : (
            <div className="text-neutral-100 typography-heading-md">{formatDate(date)}</div>
          )}
          <div className="flex h-8 items-center justify-between gap-4 text-neutral-300 typography-body-sm md:justify-start">
            <div>
              {formatTime(startTime)} - {formatTime(finishTime)}
            </div>
            <div>Location: {location}</div>
          </div>
        </div>
        <div className="ml-auto flex gap-4">
          {(type === 'awaiting-confirmation' || type === 'confirmed') && isEditable && isMd && (
            <>
              <Button preset="secondary" size="sm" onClick={handleConfirm}>
                {type === 'awaiting-confirmation' ? 'Confirm' : 'Edit'}
              </Button>
              <Dropdown>
                <DropdownTrigger>
                  <Button preset="tertiary" className="rounded" Icon={IconTripleDots} size="sm" />
                </DropdownTrigger>
                <DropdownContent align="end" side="bottom">
                  {!isRescheduled && (
                    <DropdownItem handleClick={handleCancel}>Cancel session</DropdownItem>
                  )}
                  <DropdownItem handleClick={handleDelete}>Delete session</DropdownItem>
                </DropdownContent>
              </Dropdown>
            </>
          )}

          {type === 'cancelled' && isMd && (
            <>
              <Button preset="secondary" size="sm" onClick={handleConfirm}>
                Reschedule
              </Button>
              <Button preset="tertiary" size="sm" onClick={handleDelete}>
                Delete
              </Button>
            </>
          )}
        </div>
      </div>
      {!isMd && (
        <Button preset="secondary" size="sm" onClick={handleConfirm} className="mt-4 w-full">
          {type === 'awaiting-confirmation' ? 'Confirm' : 'Edit'}
        </Button>
      )}
      {type === 'confirmed' && goals.length && (
        <div>
          <div className="mt-4 flex flex-col gap-4 border-t border-t-neutral-800 pt-4 text-neutral-100 typography-loud-sm">
            {goals.map((g) => (
              <div key={g.id} className="flex flex-col md:flex-row md:items-center">
                <div className="w-3/6 break-words text-neutral-100 typography-loud-sm">
                  {g.name}
                </div>
                <ProgressIndicator
                  value={g.progress}
                  max={5}
                  disabled
                  className="mt-4 shrink-0 md:ml-auto md:mt-0"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {note && (
        <div className="mt-4 rounded-lg border border-neutral-800 px-4 py-3 text-neutral-100 typography-loud-sm">
          {note}
        </div>
      )}
    </Card>
  );
};

export default BillingSingleSession;
