import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ExtraLabelProps = {
  children: ReactNode;
  className?: string;
};

const ExtraLabel = ({ children, className }: ExtraLabelProps) => (
  <div className={twMerge('text-neutral-200 typography-heading-xs', className)}>{children}</div>
);

export default ExtraLabel;
