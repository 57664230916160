import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { TPagination } from 'src/types/pagination.type';
import { toQueryParams } from 'src/utils/pagination.utils';

import { UsersFilters, UsersListApiResponse } from './users.types';

const PAGE_SIZE = 20;

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    usersList: build.query<UsersListApiResponse, TPagination & UsersFilters>({
      query: ({ page, pageSize, ...filters }) => ({
        method: 'POST',
        url: `/user/list?${toQueryParams({ page, pageSize: pageSize || PAGE_SIZE })}`,
        body: filters,
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const { useUsersListQuery } = usersApi;
