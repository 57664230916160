import type { SVGProps } from 'react';
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      clipPath="url(#settings_svg__a)"
    >
      <path d="M10 13.125a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z" />
      <path d="M19.375 11.25v-2.5l-2.721-.454A6.828 6.828 0 0 0 15.91 6.5l1.604-2.245-1.769-1.768L13.5 4.09a6.828 6.828 0 0 0-1.796-.744L11.25.625h-2.5l-.454 2.721A6.828 6.828 0 0 0 6.5 4.09L4.255 2.487 2.487 4.255 4.09 6.5a6.828 6.828 0 0 0-.744 1.796L.625 8.75v2.5l2.721.454c.164.64.415 1.242.744 1.796l-1.603 2.245 1.768 1.768L6.5 15.91c.554.329 1.156.58 1.796.744l.454 2.721h2.5l.454-2.721a6.828 6.828 0 0 0 1.796-.744l2.245 1.604 1.768-1.768L15.91 13.5c.329-.554.58-1.156.744-1.796l2.721-.454Z" />
    </g>
    <defs>
      <clipPath id="settings_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSettings;
