export const getPagesToDisplay = (activePage: number, pagesCount: number) => {
  const allPages = Array.from({ length: pagesCount }).map((_, index) => index + 1);

  if (pagesCount <= 7) return allPages;

  const firstThreePages = allPages.slice(0, 3);
  const lastThreePages = allPages.slice(-3);

  if (activePage <= 3 || activePage >= pagesCount - 2) {
    return [...firstThreePages, ...lastThreePages];
  }

  const pagesAroundActive = [
    activePage > 1 ? activePage - 1 : 0,
    activePage,
    activePage < pagesCount ? activePage + 1 : 0,
  ].filter((n) => !!n);

  return [1, ...pagesAroundActive, pagesCount];
};

export const getItemsToDisplay = (activePage: number, itemsPerPage: number, totalSize: number) => {
  const start = (activePage - 1) * itemsPerPage + 1;
  const end = Math.min(activePage * itemsPerPage, totalSize);

  if (start === end) {
    return [start];
  }

  return [start, end];
};
