/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';

import Avatar from './Avatar';
import Button from './Button';

type UploadAvatarProps = {
  previewUrl: string | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonTitle: string;
};

export const UploadAvatar: React.FC<UploadAvatarProps> = ({
  previewUrl,
  buttonTitle,
  handleFileChange,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleEditFile = () => {
    if (!fileInput.current) return;
    fileInput.current.click();
  };
  return (
    <div className="mb-2">
      <label
        htmlFor="avatarInput"
        style={{ cursor: 'pointer' }}
        className="flex items-center gap-5"
      >
        <Avatar url={previewUrl} size="xl" />
        <Button preset="tertiary" size={'sm'} onClick={handleEditFile}>
          {buttonTitle}
        </Button>
        <input
          accept="image/*"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInput}
          id="avatarInput"
        />
      </label>
    </div>
  );
};
