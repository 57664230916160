import React from 'react';

interface RadioButtonProps {
  selected: boolean;
  onChange: (selected: boolean) => void;
  onlyCheck?: boolean;
  className?: string;
}

const RadioCheck: React.FC<RadioButtonProps> = ({ selected, onlyCheck, onChange, className }) => {
  const handleOptionChange = () => {
    !(onlyCheck && selected) && onChange(!selected);
  };

  const handleOptionKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleOptionChange();
    }
  };

  return (
    <div
      className={`aspect-square h-5 w-5 rounded-full border border-neutral-700 ${
        selected ? 'border-6 border-secondary-300' : ''
      } ${className || ''}`}
      onClick={handleOptionChange}
      onKeyDown={handleOptionKeyDown}
      role="button"
      tabIndex={0}
    ></div>
  );
};

export default RadioCheck;
