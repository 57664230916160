import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { Domain } from 'src/pages/single-student/singleStudent.types';

import {
  ProvidersForFilters,
  SchoolForFilters,
  StudentForFilters,
  SupervisorForFilters,
} from './filters.types';

export const filtersApi = createApi({
  reducerPath: 'filtersApi',
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    studentsForFilters: build.query<StudentForFilters[], void>({
      query: () => '/filters/student',
    }),
    schoolsForFilters: build.query<SchoolForFilters[], void>({
      query: () => '/myStudents/filters/school',
    }),
    supervisorForFilters: build.query<SupervisorForFilters[], void>({
      query: () => '/myStudents/filters/supervisor',
    }),
    domainsForFilters: build.query<Domain[], string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/Goal/${id}/domains`,
      }),
    }),
    subDomainsForFilters: build.query<Domain[], string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/Goal/${id}/subdomains`,
      }),
    }),
    goalGradesForFilters: build.query<Domain[], string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/Goal/${id}/grades`,
      }),
    }),
    providersForFilters: build.query<ProvidersForFilters[], void>({
      query: () => '/filters/providers',
    }),
  }),
});

export const {
  useStudentsForFiltersQuery,
  useSchoolsForFiltersQuery,
  useSupervisorForFiltersQuery,
  useDomainsForFiltersQuery,
  useSubDomainsForFiltersQuery,
  useGoalGradesForFiltersQuery,
  useProvidersForFiltersQuery,
} = filtersApi;
