import { EventContentArg } from '@fullcalendar/core';
import clsx from 'clsx';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { useScheduleType } from 'src/hooks/useScheduleType';
import useTimeFormat from 'src/hooks/useTimeFormat';

import { ScheduleEvent } from '../../pages/schedule/schedule.types';
import Dot from '../Dot';
import {
  EVENT_TYPE_TO_BACKGROUND_COLOR_MAP,
  EVENT_TYPE_TO_DOT_PRESET_MAP,
  EVENT_TYPE_TO_TEXT_COLOR_MAP,
  getEventDisplayName,
  getEventDisplayType,
  getEventIcon,
} from './calendar-events.utils';

type DetailedEventProps = EventContentArg & {
  onClick: (event: ScheduleEvent) => void;
  isWideEvent: boolean;
};
export const DetailedEvent = (props: DetailedEventProps) => {
  const { isSm } = useBreakpoint('sm');
  const { isWideEvent } = props;
  const { formatLongHourMinute } = useTimeFormat();
  const event = props.event.extendedProps as ScheduleEvent;
  const { isAllDayEvent } = event;
  const { start, end } = props.event;
  const startTime = (start && formatLongHourMinute(start)) || '';
  const endTime = (end && formatLongHourMinute(end)) || '';
  const eventTime = isAllDayEvent
    ? 'All day'
    : [startTime, endTime].filter((l) => l.length > 0).join(' - ');
  const displayType = getEventDisplayType(event, useScheduleType());
  return (
    <div className={clsx('h-full w-full')}>
      <button
        onClick={() => event.canViewDetails && props.onClick(event)}
        className={clsx(
          'flex h-full w-full gap-1 overflow-hidden rounded px-1 py-0.5 text-left typography-body-xs  md:rounded-none',
          !event.canViewDetails && 'cursor-default',
          EVENT_TYPE_TO_BACKGROUND_COLOR_MAP[displayType],
          EVENT_TYPE_TO_TEXT_COLOR_MAP[displayType],
          { 'justify-between': !isWideEvent },
        )}
      >
        <div className=" flex items-start truncate">
          {EVENT_TYPE_TO_DOT_PRESET_MAP[displayType] && (
            <Dot
              size="xs"
              className="pr-1 pt-1"
              preset={EVENT_TYPE_TO_DOT_PRESET_MAP[displayType]}
            />
          )}
          {getEventDisplayName(event, eventTime, !isSm, true)}
        </div>
        {(isWideEvent || isSm) && (
          <span className={clsx('pr-1 pt-1', { 'ml-2': isWideEvent })}>
            {getEventIcon(displayType)}
          </span>
        )}
      </button>
    </div>
  );
};
