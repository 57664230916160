import { useRef, useState } from 'react';
import Modal from 'src/components/Modal';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useUser from 'src/hooks/useUser';
import { useUserType } from 'src/hooks/useUserType';
import { useAddSignatureMutation } from 'src/pages/auth/authApi';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import { base64ToBlob } from 'src/utils/blob.utils';
import { createFormData } from 'src/utils/createFormData';

import Button from './Button';
import Signature from './Signature';

type SetSignatureModalProps = {
  onClose: () => void;
};

const SetSignatureModal = ({ onClose }: SetSignatureModalProps) => {
  const canvasRef = useRef<any>();
  const [hasSignature, setHasSignature] = useState(false);
  const { userType } = useUserType();
  const { fullName } = useUser();
  const [addSingature] = useAddSignatureMutation();
  const dispatch = useAppDispatch();
  const { isMd } = useBreakpoint('md');

  const onSave = async () => {
    if (!canvasRef.current?.toDataURL()) return;
    const result = await addSingature(
      createFormData({
        signature: base64ToBlob(canvasRef.current?.toDataURL().split(',')[1]),
      }),
    );
    const isError = !!result['error'];
    dispatch(
      addToast({
        text: isError
          ? 'Failed to save signature. Please try again later.'
          : 'Signature has been successfully sent to review.',
        type: isError ? 'negative' : 'positive',
      }),
    );
    if (!isError) {
      onClose();
    }
  };

  return (
    <Modal
      className="h-full max-h-full w-full md:h-auto lg:w-signature-modal"
      headerClassName="md:py-6 py-4 mb-4 md:mb-0"
      title="Add signature"
      onOpenChange={(isOpen) => !isOpen && onClose()}
      open={true}
    >
      <div className="pb-4 text-neutral-300 typography-loud-sm md:pb-6">
        To enable e-signing of monthly invoices, add your signature [full first and last name] -
        penned clearly and legibly. Be advised that illegible signatures will not be accepted.
      </div>
      <Signature
        ref={canvasRef}
        className="-mx-4 md:-mx-6"
        onHasSignatureChange={setHasSignature}
      />
      <div className="-mx-4 flex h-14 items-center border-y border-neutral-800 pl-6 typography-body-sm md:-mx-6 md:h-12">
        <div className="text-neutral-100">
          {userType === 'Principal' ? 'Principal: ' : 'Provider: '}
        </div>
        <div className="pl-1 text-neutral-300">{fullName}</div>
      </div>
      <div className="fixed bottom-0 left-0 grid w-full grid-cols-2 border-y border-neutral-800 bg-neutral-white px-6 py-4 pl-6 md:relative md:flex md:border-0 md:px-0 md:py-6">
        <Button
          size={isMd ? 'sm' : 'md'}
          preset="secondary"
          onClick={onSave}
          disabled={!hasSignature}
        >
          Add signature
        </Button>
        <Button
          size={isMd ? 'sm' : 'md'}
          preset="tertiary"
          className="ml-4"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default SetSignatureModal;
