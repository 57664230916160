import { useForgotPasswordMutation } from 'src/pages/auth/authApi';
import { useAppDispatch, useAppSelector } from 'src/store';

import { AuthenticatedUser, AuthProvider, BackdoorToken } from './auth.types';
import { selectProvider } from './authSelectors';
import { reset as resetAuth } from './authSlice';
import { reset as resetBackendAuth } from './backend/backendAuthSlice';
import { useBackendAuth } from './backend/useBackendAuth';
import { useAwsAuth } from './useAwsAuth';

type AuthInit = {
  init: () => void;
};

export const useAuth = (): AuthProvider & AuthInit => {
  const dispatch = useAppDispatch();
  const [forgotPassword] = useForgotPasswordMutation();
  const provider = useAppSelector(selectProvider);
  const backendAuth = useBackendAuth();
  const awsAuth = useAwsAuth();

  const selectedProvider = provider === 'aws' ? awsAuth : backendAuth;

  return {
    init: () => {
      dispatch(resetAuth());
      dispatch(resetBackendAuth());
    },

    backdoorSignIn: (backdoorToken: BackdoorToken) => backendAuth.backdoorSignIn(backdoorToken),

    currentSession: () => selectedProvider.currentSession(),

    signOut: () => selectedProvider.signOut(),

    currentAuthenticatedUser: () => selectedProvider.currentAuthenticatedUser(),

    forgotPassword: (username: string) => forgotPassword({ email: username }).unwrap(),

    signIn: (username: string, password: string) => selectedProvider.signIn(username, password),

    completeNewPassword: (user: AuthenticatedUser, password: string) =>
      selectedProvider.completeNewPassword(user, password),

    forgotPasswordSubmit: (username: string, code: string, password: string) =>
      selectedProvider.forgotPasswordSubmit(username, code, password),

    changePassword: (user: AuthenticatedUser, oldPassword: string, newPassword: string) =>
      selectedProvider.changePassword(user, oldPassword, newPassword),
  };
};
