import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import Card from 'src/components/Card';
import Loader from 'src/components/Loader';

import { useGetInvoiceQuery, useInvoiceReopenQuery } from './billingApi';

const BillingInvoicesReopen = () => {
  const { id: invoiceId = '' } = useParams();
  const { data: invoice, isFetching: isFetchingInvoice } = useGetInvoiceQuery(invoiceId);
  const { isSuccess, isFetching } = useInvoiceReopenQuery(invoiceId);

  const DownloadLoader = () => (
    <div className="flex items-center gap-1">
      <div>Getting data with response...</div>
      <Loader />
    </div>
  );

  return (
    <div className="pl-10 pt-10">
      {isFetchingInvoice ? (
        <DownloadLoader />
      ) : (
        <Card className="sm:w-9/10">
          <div>
            <h2 className="pb-4 typography-heading-lg">
              {invoice?.student ? 'Invoice Reopened' : 'Could not get informations'}{' '}
            </h2>
            <div className="">
              <div className="flex justify-between  pb-1">
                <div className="">Student: </div>
                <div className="pl-1 font-bold">
                  {invoice?.student.firstName} {invoice?.student.lastName}
                </div>
              </div>
              <div className="flex justify-between  pb-1">
                <div>Provider:</div>
                <div className="pl-1 font-bold">
                  {invoice?.provider.firstName} {invoice?.provider.lastName}
                </div>
              </div>
              <div className="flex justify-between  pb-1">
                <div>Invoice Month/Year: </div>
                <div className="pl-1 font-bold ">
                  {invoice?.month}/{invoice?.year}
                </div>
              </div>
              <div className="flex justify-between  pb-1">
                <div>Reopening result: </div>
                {!isFetching ? (
                  <div
                    className={clsx(
                      'font-bold, pl-1 ',
                      isSuccess ? 'text-accent-verdigris-500' : ' text-accent-tomato-500',
                    )}
                  >
                    {isSuccess ? 'Success' : 'Failure'}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default BillingInvoicesReopen;
