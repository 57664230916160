import CheckboxesGroup from 'src/components/CheckboxesGroup';
import Collapsible from 'src/components/Collapsible';

import { selectedCountLabel } from './selectedCountLabel';

type FilterByMonthsProps = {
  value?: number[];
  onChange: (v?: number[]) => void;
  separator?: boolean;
};

export const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

const FilterByMonths = ({ value, onChange, separator = true }: FilterByMonthsProps) => {
  return (
    <Collapsible
      title={`Month${selectedCountLabel(value)}`}
      separator={separator}
      defaultOpen={false}
    >
      <div className="grid grid-cols-3 gap-x-6 gap-y-2 ">
        <CheckboxesGroup
          value={value}
          onChange={onChange}
          options={MONTHS?.map((m) => ({
            label: m.label,
            value: m.value,
          }))}
        />
      </div>
    </Collapsible>
  );
};

export default FilterByMonths;
