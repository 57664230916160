import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import Collapsible from 'src/components/Collapsible';
import Loader from 'src/components/Loader';
import Modal from 'src/components/Modal';
import Signature from 'src/components/Signature';
import Tag from 'src/components/Tag';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { buildName } from 'src/logic/buildName';
import { useAppDispatch } from 'src/store';
import { addToast } from 'src/toasts/ToastsSlice';
import {
  ApiErrorResponse,
  apiErrorsToDict,
  isFetchBaseQueryError,
} from 'src/types/api-error-response';

import { InvoicesToSignRequest } from './principal.types';
import { useInvoicesToSignDetailsQuery, useSignInvoicesMutation } from './principalApi';

export type InvoicesSignatureModalRefProps = {
  open: (request: InvoicesToSignRequest) => void;
  close: () => void;
};

const InvoicesSignatureModal = forwardRef<InvoicesSignatureModalRefProps>((props, ref) => {
  const canvasRef = useRef<any>();
  const [request, setRequest] = useState<InvoicesToSignRequest>();
  const dispatch = useAppDispatch();
  const { data: details, isFetching: isFetchingDetails } = useInvoicesToSignDetailsQuery(request!, {
    skip: request === undefined,
  });
  const [localDetails, setLocalDetails] = useState(details);

  const close = () => {
    setRequest(undefined);
    setLocalDetails(undefined);
  };
  const { isLg } = useBreakpoint('lg');

  useImperativeHandle(
    ref,
    () => ({
      open: (request: InvoicesToSignRequest) => {
        setRequest(request);
      },
      close: close,
    }),
    [],
  );

  const [sign, { isLoading, error, isError, isSuccess, reset }] = useSignInvoicesMutation();

  const onSave = async () => {
    if (!canvasRef.current?.toDataURL()) return;
    if (!localDetails) return;
    try {
      await sign({
        signature: {
          content: canvasRef.current?.toDataURL().split(',')[1],
          contentType: 'image/png',
        },
        invoices: localDetails.ids.map((id) => ({ id })),
      }).unwrap();
      dispatch(addToast({ text: 'Signature submitted.', type: 'positive' }));
    } catch (err) {
      let message = 'Error: invoice not submitted. Reload the page.';
      if (isFetchBaseQueryError(err)) {
        const errorDict = apiErrorsToDict(err);
        message = errorDict?._ || message;
      }
      dispatch(addToast({ type: 'negative', text: message }));
    }
  };
  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  useEffect(() => {
    setLocalDetails(details);
  }, [details]);

  return (
    <Modal
      open={!!request}
      onOpenChange={(isOpen) => !isOpen && close()}
      title="Add your signature"
      className="h-full max-h-full w-full lg:h-auto lg:w-signature-modal"
    >
      <Signature ref={canvasRef} className="-mx-4 md:-mx-6" />
      {isFetchingDetails && (
        <div className=" pt-b flex pt-2 typography-heading-md">
          loading details...{' '}
          <span className="relative pl-4">
            <Loader />
          </span>
        </div>
      )}
      {localDetails && localDetails.ids.length === 0 && <div>No invoices to sign</div>}
      {localDetails &&
        localDetails.providers.map((provider, index) => (
          <div key={`provider-${index}`} className="-mx-4  md:-mx-6">
            <Collapsible
              clasName="px-6"
              title={
                <div className="flex gap-4">
                  <span className="text-neutral-300 typography-body-sm">
                    Provider: {buildName(provider)}
                  </span>
                  <Tag size="sm" preset="lightBlue">
                    {provider.invoicesCount} Invoices
                  </Tag>
                </div>
              }
            >
              {provider.students.map((student, index) => (
                <div className="flex items-center gap-4 pb-1" key={`student-${index}`}>
                  <Avatar size="sm" />
                  <div className="grow">
                    <span className="typography-loud-md">{buildName(student)}</span>
                    {!isLg && (
                      <div className="text-neutral-300 typography-body-sm">
                        Session locations:{' '}
                        <span className="text-neutral-100">
                          {student.sessionsLocations.join(', ')}
                        </span>
                      </div>
                    )}
                  </div>
                  {isLg && (
                    <span className="text-neutral-300 typography-body-sm">
                      Session locations:{' '}
                      <span className="text-neutral-100">
                        {student.sessionsLocations.join(', ')}
                      </span>
                    </span>
                  )}
                </div>
              ))}
            </Collapsible>
          </div>
        ))}
      <div className="fixed bottom-0 left-0 w-full border-t border-t-neutral-800 p-6 lg:relative lg:-mx-6 lg:w-auto lg:border-none">
        {/* TODO: improve error type */}
        {isError && (error as { data: ApiErrorResponse })?.data?.message && (
          <div className="mb-6 text-primary-500 typography-body-sm">
            {(error as { data: ApiErrorResponse })?.data?.message}
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 lg:flex">
          <Button
            disabled={isFetchingDetails || localDetails?.ids?.length === 0}
            preset="secondary"
            onClick={onSave}
            isLoading={isLoading}
          >
            Save signature
          </Button>
          <Button preset="tertiary" onClick={close}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
});

InvoicesSignatureModal.displayName = 'InvoicesSignatureModal';
export default InvoicesSignatureModal;
