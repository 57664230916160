import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckedState } from '@radix-ui/react-checkbox';
import * as LabelPrimitive from '@radix-ui/react-label';
import { clsx } from 'clsx';
import SvgCheck from 'src/icons/Check';

export type CheckboxProps = {
  label?: string;
  isChecked?: CheckedState;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;
};

const Checkbox = (props: CheckboxProps) => {
  return (
    <form className="flex items-center">
      <CheckboxPrimitive.Root
        id={props.label}
        className={clsx(
          'ratio flex aspect-square h-5 w-5 items-center justify-center text-neutral-white md:h-4 md:w-4',
          'radix-state-checked:bg-secondary-400',
          'radix-state-unchecked:hover:border-secondary-400',
          'rounded-sm radix-state-unchecked:border radix-state-unchecked:border-secondary-700',
          'transition-[border-color] duration-100',
        )}
        onCheckedChange={props.onCheckedChange}
        checked={props.isChecked}
      >
        <CheckboxPrimitive.Indicator>
          <SvgCheck />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      {props.label && (
        <LabelPrimitive.Label
          htmlFor={props.label}
          className="cursor-pointer truncate pl-4 text-neutral-100 typography-body-sm md:pl-3"
        >
          {props.label}
        </LabelPrimitive.Label>
      )}
    </form>
  );
};

export default Checkbox;
