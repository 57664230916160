import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { TPagination } from 'src/types/pagination.type';
import { toQueryParams } from 'src/utils/pagination.utils';

import {
  GoalsFilters,
  GoalsListApiResponse,
  Grade,
  IepReport,
  NotesListApiResponse,
  SingleStudentAllGoalsFilters,
  SingleStudentAvailableFeatures,
  SingleStudentContacts,
  SingleStudentDetails,
  SingleStudentGoalsDefinition,
  SingleStudentYersFilter,
  StudentAllGoalsApiResponse,
  StudentEvent,
  StudentEventsQuery,
  StudentGoalQuery,
  StudentNote,
  Subdomain,
} from './singleStudent.types';

const DEFAULT_GOALS_PAGE_SIZE = 10;
const DEFAULT_NOTES_PAGE_SIZE = 10;

export const singleStudentApi = createApi({
  reducerPath: 'singleStudentApi',
  baseQuery,
  tagTypes: [
    'StudentNotes',
    'StudentEvents',
    'StudentGoals',
    'StudentAvailableFeatures',
    'GoalsDefinitions',
  ],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    singleStudentDetails: build.query<SingleStudentDetails, string>({
      query: (id) => `/myStudents/student/${id}`,
    }),
    singleStudentContacts: build.query<SingleStudentContacts, string>({
      query: (id) => `/myStudents/student/${id}/contacts`,
    }),
    singleStudentGoalsList: build.query<
      GoalsListApiResponse,
      { showMasteredSkills?: boolean; id: string } & TPagination & GoalsFilters
    >({
      query: ({ id, showMasteredSkills, queryString, page, pageSize, ...filters }) => ({
        method: 'POST',
        url: `/myStudents/${id}/goals/list?${toQueryParams({
          page,
          pageSize: pageSize || DEFAULT_GOALS_PAGE_SIZE,
        })}`,
        body: {
          queryString,
          showMasteredSkills,
          ...filters,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ goals }) => ({ type: 'StudentGoals' as const, goals })),
              'StudentGoals',
            ]
          : ['StudentGoals'],
    }),
    singleStudentAllGoals: build.query<StudentAllGoalsApiResponse, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/myStudents/${id}/goals`,
      }),
    }),
    createSingleStudentGoals: build.mutation<StudentAllGoalsApiResponse, StudentGoalQuery>({
      query: (body) => ({
        method: 'POST',
        url: `/myStudents/${body.id}/goals`,
        body: { goalDefinitionIds: body.goalDefinitionIds },
      }),
      invalidatesTags: ['StudentAvailableFeatures', 'StudentGoals'],
    }),
    singleStudentAllGoalsFilters: build.query<SingleStudentAllGoalsFilters, string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/Goal/${id}/filters/all`,
      }),
    }),
    singleStudentAvailableSubdomains: build.query<
      Subdomain[],
      { studentId: string; domainIds: string[]; gradeIds: string[] }
    >({
      query: ({ studentId, gradeIds, domainIds }) => ({
        method: 'POST',
        url: `/Goal/${studentId}/subdomains`,
        body: {
          gradeIds,
          domainIds,
        },
      }),
    }),
    singleStudentAvailableGrades: build.query<Grade[], { studentId: string; domainIds: string[] }>({
      query: ({ studentId, domainIds }) => ({
        method: 'POST',
        url: `/Goal/${studentId}/grades`,
        body: {
          domainIds,
        },
      }),
    }),
    singleStudentNotesList: build.query<NotesListApiResponse, { studentId: string } & TPagination>({
      query: ({ studentId, pageSize, page }) => {
        const usedPageSize = DEFAULT_NOTES_PAGE_SIZE || pageSize;
        return {
          method: 'GET',
          url: `/myStudents/${studentId}/notes`,
          params: { pageSize: usedPageSize, from: Math.max(page - 1, 0) * usedPageSize },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'StudentNotes' as const, id })),
              'StudentNotes',
            ]
          : ['StudentNotes'],
    }),
    createStudentNote: build.mutation<
      StudentNote,
      { studentId: string } & Pick<StudentNote, 'content'>
    >({
      query: ({ studentId, content }) => ({
        method: 'POST',
        url: `/myStudents/${studentId}/notes`,
        body: {
          content,
        },
      }),
      invalidatesTags: ['StudentNotes'],
    }),
    updateStudentNote: build.mutation<
      StudentNote,
      { studentId: string } & Pick<StudentNote, 'content' | 'id'>
    >({
      query: ({ studentId, id, content }) => ({
        method: 'PATCH',
        url: `/myStudents/${studentId}/notes/${id}`,
        body: {
          content,
        },
      }),
      invalidatesTags: (_, __, args) => [{ type: 'StudentNotes', id: args.id }],
    }),
    deleteStudentNote: build.mutation<null, { studentId: string } & Pick<StudentNote, 'id'>>({
      query: ({ studentId, id }) => ({
        method: 'DELETE',
        url: `/myStudents/${studentId}/notes/${id}`,
      }),
      invalidatesTags: (_, __, args) => [{ type: 'StudentNotes', id: args.id }],
    }),
    studentEvents: build.query<StudentEvent[], StudentEventsQuery & { studentId: string }>({
      query: ({ studentId, ...body }) => ({
        url: `/schedule/${studentId}/events`,
        method: 'POST',
        body,
      }),
      providesTags: (result = []) =>
        result.map(({ id }) => ({ type: 'StudentEvents' as const, id })),
    }),

    singleStudentGoalConfirmContactWithParent: build.mutation<void, { studentId: string }>({
      query: (studentId) => ({
        url: `/myStudents/${studentId.studentId}/goals/confirm-contact-with-parent`,
        method: 'POST',
      }),
      invalidatesTags: ['StudentGoals'],
    }),
    singleStudentGoalsDefinitions: build.query<
      SingleStudentGoalsDefinition,
      {
        studentId: string;
        domainIds: string[];
        gradeIds: string[];
        subdomainIds: string[];
        pageSize: number;
      }
    >({
      query: ({ studentId, domainIds, gradeIds, subdomainIds, pageSize }) => ({
        url: `/Goal/${studentId}/definitions?pageSize=${pageSize}`,
        method: 'POST',
        body: {
          domainIds: domainIds,
          gradeIds: gradeIds,
          subdomainIds: subdomainIds,
        },
      }),
      providesTags: ['GoalsDefinitions'],
    }),
    singleStudentGoalDelete: build.mutation<void, { studentId: string; goalId: string }>({
      query: (student) => ({
        url: `/myStudents/${student.studentId}/goals/${student.goalId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StudentAvailableFeatures', 'StudentGoals'],
    }),
    singleStudentGoalUndoPatch: build.mutation<
      void,
      { studentId: string; goalId: string; status: string }
    >({
      query: (student) => ({
        url: `/myStudents/${student.studentId}/goals/${student.goalId}`,
        method: 'PATCH',
        body: {
          status: student.status,
        },
      }),
      invalidatesTags: ['StudentGoals', 'StudentAvailableFeatures'],
    }),
    singleStudentGoalsYearsFilters: build.query<SingleStudentYersFilter[], string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/MyStudents/student/${id}/years`,
      }),
    }),
    singleStudentAvailableFeatures: build.query<SingleStudentAvailableFeatures, string>({
      query: (id?: string) => `/myStudents/student/${id}/available-features`,
      providesTags: ['StudentAvailableFeatures', 'StudentGoals'],
    }),
    singleStudentReportsList: build.query<IepReport[], string>({
      query: (id?: string) => ({
        method: 'GET',
        url: `/MyStudents/student/${id}/report/list`,
      }),
    }),
    singleStudentReport: build.mutation<
      IepReport,
      { id?: string; code?: string; fileId?: string; fileName?: string }
    >({
      query: ({ id, code, fileId, fileName }) => ({
        method: 'GET',
        url: `/MyStudents/student/${id}/report/${code}/${fileId}`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          const fileName1 = fileName!;
          const blobURL = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = blobURL;
          link.download = fileName1;
          link.click();
          window.URL.revokeObjectURL(blobURL);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useSingleStudentDetailsQuery,
  useSingleStudentContactsQuery,
  useSingleStudentGoalsListQuery,
  useSingleStudentNotesListQuery,
  useSingleStudentGoalConfirmContactWithParentMutation,
  useUpdateStudentNoteMutation,
  useCreateStudentNoteMutation,
  useDeleteStudentNoteMutation,
  useLazyStudentEventsQuery,
  useLazySingleStudentAllGoalsQuery,
  useCreateSingleStudentGoalsMutation,
  useSingleStudentAllGoalsFiltersQuery,
  useSingleStudentGoalsDefinitionsQuery,
  useSingleStudentGoalDeleteMutation,
  useSingleStudentGoalUndoPatchMutation,
  useSingleStudentGoalsYearsFiltersQuery,
  useSingleStudentAvailableFeaturesQuery,
  useSingleStudentReportsListQuery,
  useSingleStudentReportMutation,
  useSingleStudentAvailableGradesQuery,
  useSingleStudentAvailableSubdomainsQuery,
  util: { invalidateTags: invalidateSingleStudentTags },
} = singleStudentApi;
