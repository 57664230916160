import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type TooltipProps = TooltipPrimitive.TooltipProps & {
  content?: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  trigger?: 'hover' | 'click'; // Dodany atrybut trigger
  className?: string;
  asChild?: boolean;
  classNameArrow?: string;
};

export const Tooltip: React.FC<TooltipProps> = ({ asChild = true, ...props }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTriggerEvent = () => {
    if (props.trigger === 'click') {
      setTooltipVisible(!tooltipVisible);
    }
  };

  const handleMouseEnter = () => {
    if (props.trigger !== 'click') {
      setTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.trigger !== 'click') {
      setTooltipVisible(false);
    }
  };

  return props.content ? (
    <TooltipPrimitive.Provider delayDuration={props.delayDuration || 0}>
      <TooltipPrimitive.Root
        open={tooltipVisible}
        defaultOpen={props.defaultOpen}
        onOpenChange={props.onOpenChange}
      >
        <TooltipPrimitive.Trigger
          asChild={asChild}
          onClick={handleTriggerEvent} // Dodane obsługi zdarzeń
          onMouseEnter={handleMouseEnter} // Dodane obsługi zdarzeń
          onMouseLeave={handleMouseLeave} // Dodane obsługi zdarzeń
          onPointerEnter={handleMouseEnter} // Dodane obsługi zdarzeń
          onPointerLeave={handleMouseLeave} // Dodane obsługi zdarzeń
          onTouchCancel={handleMouseLeave} // Dodane obsługi zdarzeń
        >
          {props.children}
        </TooltipPrimitive.Trigger>
        {tooltipVisible && (
          <TooltipPrimitive.Content
            side={props.side ?? 'top'}
            align="center"
            className={clsx(
              'items-center whitespace-pre-line rounded-md px-3 py-2',
              'z-overlay bg-neutral-100 text-neutral-white typography-caption-md',
              'radix-side-top:animate-slide-down-fade',
              'radix-side-right:animate-slide-left-fade',
              'radix-side-bottom:animate-slide-up-fade',
              'radix-side-left:animate-slide-right-fade',
              props.className,
            )}
          >
            {props.content}
            <TooltipPrimitive.Arrow
              className={twMerge('fill-neutral-100 text-neutral-100', props.classNameArrow)}
            />
          </TooltipPrimitive.Content>
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  ) : (
    <>{props.children}</>
  );
};
