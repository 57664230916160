import type { SVGProps } from 'react';
const SvgPhoneCall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.514 22c-.079 0-.158 0-.236-.009a18.298 18.298 0 0 1-7.951-2.817 17.968 17.968 0 0 1-5.505-5.495A18.293 18.293 0 0 1 2.01 5.742a2.644 2.644 0 0 1 .594-1.928 2.622 2.622 0 0 1 1.774-.933c.078 0 .157-.009.235-.009H7.26a2.62 2.62 0 0 1 2.586 2.25c.105.777.297 1.545.568 2.268.358.951.131 2.033-.594 2.757l-.63.628a13.19 13.19 0 0 0 4.011 4.003l.63-.628a2.627 2.627 0 0 1 2.77-.593c.724.27 1.485.462 2.262.567a2.617 2.617 0 0 1 2.263 2.643v2.608A2.619 2.619 0 0 1 18.514 22ZM7.26 4.617H4.62c-.306.026-.515.13-.673.314a.886.886 0 0 0-.2.637 16.576 16.576 0 0 0 2.542 7.17 16.301 16.301 0 0 0 4.98 4.97 16.504 16.504 0 0 0 7.165 2.548h.07a.86.86 0 0 0 .865-.872V16.74a.87.87 0 0 0-.751-.881c-.9-.122-1.792-.34-2.64-.654a.883.883 0 0 0-.925.192l-1.11 1.107a.874.874 0 0 1-1.049.14 14.872 14.872 0 0 1-5.574-5.556.87.87 0 0 1 .14-1.047l1.11-1.108a.865.865 0 0 0 .192-.916 12.598 12.598 0 0 1-.664-2.642.875.875 0 0 0-.865-.742l.026-.017Zm10.371 6.062a.869.869 0 0 1-.856-.707 3.462 3.462 0 0 0-2.761-2.756.871.871 0 1 1 .332-1.71 5.215 5.215 0 0 1 4.142 4.135.867.867 0 0 1-.69 1.02 1.076 1.076 0 0 1-.167.018Zm3.495-.009a.87.87 0 0 1-.865-.776c-.367-3.262-2.91-5.792-6.177-6.158a.87.87 0 0 1-.77-.96.877.877 0 0 1 .962-.767c4.09.453 7.26 3.62 7.715 7.693a.879.879 0 0 1-.769.968h-.096Z"
    />
  </svg>
);
export default SvgPhoneCall;
